import React from 'react';
import { LocationOn } from '@mui/icons-material';
import None from 'components/common/None';

export const getColumns = (globalClasses, t, cx, formatDate) => [ 
    {
        accessor: 'label',
        Header: t('itemLabel'),
        props: {
            className: globalClasses.tableCellFirst,
            style: {
                width: '12.5%',
            }
        },
    },
    {
        accessor: 'isAssociation',
        Header: t('isAssociation'),
        Cell: ({value}) => value ? t('toolAssociation') : t('toolDisassociation'),
        props: {
            style: {
                width: '12.5%',
            }
        },
    },
    {
        accessor: 'deviceAssociatedLabel',
        Header: t('toolDeviceAssociated'),
        props: {
            style: {
                width: '12.5%',
            }
        },
    },
    {
        accessor: 'deviceAssociatedType',
        Header: t('toolDeviceAssociatedType'),
        cellProps: {
            style: {
                width: '12.5%',
            }
        },
    },
    {
        accessor: 'startDate',
        Header: t('reportStartTime'),
        Cell: ({ cell: { value } }) => formatDate(value),
        props: {
            style: {
                width: '12.5%',
            }
        },
    },
    {
        accessor: 'startAddress',
        Header: t('reportStartAddress'),
        Cell: ({ cell: { value: { addressValue, isGeofence } } }) => <>
                <LocationOn className={isGeofence ? globalClasses.decorativeIcon : cx(globalClasses.decorativeIcon, globalClasses.invisible)}/>
                {addressValue || <None text={t('itemAddressNone')}/>}
            </>,
        props: {
            style: {
                width: '12.5%',
            },
        },
        headerProps: {
            className: globalClasses.tableAddressHeader,
        },
    },
    {
        accessor: 'endDate',
        Header: t('reportEndTime'),
        Cell: ({ cell: { value } }) => formatDate(value),
        props: {
            style: {
                width: '12.5%',
            }
        },
    },
    {
        accessor: 'endAddress',
        Header: t('reportEndAddress'),
        Cell: ({ cell: { value: { addressValue, isGeofence } } }) => <>
                <LocationOn className={isGeofence ? globalClasses.decorativeIcon : cx(globalClasses.decorativeIcon, globalClasses.invisible)}/>
                {addressValue || <None text={t('itemAddressNone')}/>}
            </>,
        props: {
            style: {
                width: '12.5%',
            },
        },
        headerProps: {
            className: globalClasses.tableAddressHeader,
        },
    }
];