import { useEffect, useState } from 'react';
import packageJson from '../../package.json';
import {magellanV0} from '../api/magellan';
global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());

        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
  return false;
}

const useCacheBuster = () => {
    const [state, setState] = useState({ 
        loading: true,
        isLatestVersion: false,
        latestVersion: null,
    });

    const refreshCacheAndReload = () => {
        console.log('Clearing cache and hard reloading...')
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then((names) => {
                for (let name of names) caches.delete(name);
            });
        }
        // delete browser cache and hard reload
        window.location.reload();
    }

    const checkVersion = () => {
        magellanV0.get(`/version/meta.json`)
            .then((response) => {
                const latestVersion = response.data.version;
                const currentVersion = global.appVersion;

                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                if (shouldForceRefresh) {
                    console.log(`We have a new version - ${latestVersion}.`);
                    setState({ loading: false, isLatestVersion: false, latestVersion });
                } else {
                    console.log(`You already have the latest version - ${latestVersion}.`);
                    setState({ loading: false, isLatestVersion: true, latestVersion });
                }
            })
            .catch(error => {});
    }

    useEffect(() => {
        checkVersion();
        let intervalId = setInterval(() => {
            checkVersion();
        }, 30000);

        return () => {
            clearInterval(intervalId)
        };
    }, []);

    return {loading: state.loading, isLatestVersion: state.isLatestVersion, refreshCacheAndReload};
    
}

export default useCacheBuster;