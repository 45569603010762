import 'date-fns';
import React, {useState} from 'react';
import { Switch, InputLabel, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';

import useTranslation from 'hooks/useTranslation';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const useStyles = makeStyles()(theme => ({
    tableBoxDay: {
        width: '1%',
        whiteSpace: 'nowrap',
        minWidth: 50,
    },  
    tableBoxCheckbox: {
        width: '1%',
        whiteSpace: 'nowrap',
        minWidth: 50,
        textAlign: 'end',
    },
    tableBoxDatePicker: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
        verticalAlign: 'top',
    },
    timePicker: {
        marginTop: 0,
        width: '60%'
    },
    row: {
        height: 44
    }
}));

const DayInput = ({day, disabled, names, formik, offMessage}) => {
    const { t } = useTranslation();
    const startHour = formik.values.calendar?.[names[0]];
    const endHour = formik.values.calendar?.[names[1]]; 
    const { classes } = useStyles();
    const open = Boolean(startHour);

    const handleCheckboxChange = (event) => {
        if(event.target.checked){
            formik.setFieldValue(`calendar.${names[0]}`, new Date(2000, 0, 1, 8, 30).toString());
            formik.setFieldValue(`calendar.${names[1]}`, new Date(2000, 0, 1, 17, 30).toString());        
        }else{
            formik.setFieldValue(`calendar.${names[0]}`, null);
            formik.setFieldValue(`calendar.${names[1]}`, null);            
        }
    };

    const handleStartHourChange = (date) => {
        formik.setFieldValue(`calendar.${names[0]}`, date ? date.toString() : "Invalid Date");
    };

    const handleEndHourChange = (date) => {
        formik.setFieldValue(`calendar.${names[1]}`, date ? date.toString() : "Invalid Date");
    };

    const renderHourPicker = () => {
        return (
            <>
                <td className={classes.tableBoxDatePicker}>
                    <TimePicker
                        ampm={false}
                        value={startHour ? new Date(startHour) : null}
                        onChange={handleStartHourChange}
                        className={classes.timePicker}
                        disabled={!open || disabled}
                        margin="normal"
                        label={t('companyStartHour')}
                        onBlur={() => formik.setFieldTouched(`calendar.${names[0]}`)}
                        keyboardIcon={<QueryBuilderIcon/>}
                        error={formik.touched.calendar?.[names[0]] && Boolean(formik.errors.calendar?.[names[0]])}
                        helperText={formik.touched.calendar?.[names[0]] && formik.errors.calendar?.[names[0]]}
                        renderInput={ props => <TextField {...props} /> }
                        
                    />
                </td>
                <td className={classes.tableBoxDatePicker}>
                    <TimePicker
                        ampm={false}
                        value={endHour ? new Date(endHour) : null}
                        onChange={handleEndHourChange}
                        className={classes.timePicker}
                        disabled={!open || disabled}
                        margin="normal"
                        label={t('companyEndHour')}
                        onBlur={() => formik.setFieldTouched(`calendar.${names[1]}`)}
                        keyboardIcon={<QueryBuilderIcon/>}
                        error={formik.touched.calendar?.[names[1]] && Boolean(formik.errors.calendar?.[names[1]])}
                        helperText={formik.touched.calendar?.[names[1]] && formik.errors.calendar?.[names[1]]}
                        renderInput={props => <TextField {...props}/>}
                    />
                </td>
            </>
        );
    }

    const renderClosedText = () => {
        return (
            <td colSpan={2} align="center">{offMessage}</td>
        );
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <tr className={classes.row}>
                    <td className={classes.tableBoxDay}>
                    <InputLabel>{day}</InputLabel>
                    </td>
                    { open 
                        ? renderHourPicker()
                        : renderClosedText()
                    }
                    <td className={classes.tableBoxCheckbox}>
                        <Switch 
                            checked={open} 
                            onChange={handleCheckboxChange} 
                            disabled={disabled}
                        />
                    </td>
                </tr>
            </LocalizationProvider>
        </>
    );
};

export default DayInput;