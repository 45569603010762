import React from 'react';

import { DevicesViewColumns, DeviceType } from 'utils/constant';
import EditCollectionView from '../../common/EditCollectionView';
import DevicesView from '../DevicesView';

const WarehousesPage = () => {
    return (
        <EditCollectionView 
            content={DevicesView} 
            editPath="warehouse" 
            endpoint="devices" 
            type={DeviceType.WAREHOUSE}
            columns={[
                DevicesViewColumns.LABEL,
                DevicesViewColumns.TRACKER_IDENTIFIER,
                DevicesViewColumns.GROUP,
            ]}    
        />
    );
}

export default WarehousesPage;