import React from "react";
import _ from 'lodash';
import { Autocomplete, TextField, } from "@mui/material";


const StafferSelectorCell = ({ cell, checkAndUpdateTimesheet, staffers, t }) => {         
    let { row: { original: { id, allData: { staffer, } } }, } = cell;
    let currentStafferId = staffer?.id || null;

    return (
        <Autocomplete 
            options={Object.values(staffers)}
            getOptionLabel={s =>  `${s?.firstname} ${s?.name}`}
            value={(Object.values(staffers).find(s => s.id === currentStafferId) || null)}
            onChange={(_, value) => checkAndUpdateTimesheet(id, 'stafferId', (value?.id || null))}
            renderInput={(params) => <TextField {...params} label={t('stafferName')} variant='outlined'/>}
        />
    );
};

export default StafferSelectorCell;