import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Collapse, Divider, Tooltip } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, Warning } from '@mui/icons-material';
import FilterHdrIcon from '@mui/icons-material/FilterHdr';
import SpeedIcon from '@mui/icons-material/Speed';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import { BatteryFull } from '@mui/icons-material';

import { useGlobalStyles } from 'context/StylesContext';
import useTranslation from 'hooks/useTranslation';
import useGetMotions from 'hooks/useGetMotions';
import { DeviceStatus, DeviceType } from 'utils/constant';
import { devicesActions } from 'store';
import { ReactComponent as SatIcon } from 'resources/sat.svg';
import Address from '../../common/Address';
import TimeCounter from '../../common/TimeCounter';
import MotionsSummaryTimeline from '../../common/MotionsSummaryTimeline';
import MovementStatus from 'components/common/MovementStatus';
import SignalStatus from 'components/common/SignalStatus';
import ContactStatus from 'components/common/ContactStatus';
import MotionStatusBar from './MotionStatusBar';
import QualitySignalIndicator from 'components/common/QualitySignalIndicator';
import MotionsSummaryText from 'components/common/MotionsSummaryText';
import BasicValue from 'components/common/BasicValue';
import useFormat from 'hooks/useFormat';
import FuelOrBatteryStatus from 'components/common/FuelOrBatteryStatus';


const useStyles = makeStyles()(theme => ({
    root: {
        width: '100%',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.selected.main,
        },
    },
    container: {
        padding: '16px',
        display: 'flex',
        width: '100%',
    },
    firstContainer: {
        flexDirection: 'column',
    },
    iconsAndInfosContainer: {
        display: 'grid', 
        gridTemplateColumns: 'minmax(16%, max-content) auto auto', 
        columnGap: 10, 
        marginBottom: 8,
    },
    statusIcons: {
        gridColumn: 1, 
        gridRow: 'auto',
    },
    licensePlate: {
        gridColumn: 1, 
        gridRow: 2,
    },
    assetNameAndGeofence: {
        display: 'flex',
        flexDirection: 'column',
        gridColumn: 2, 
        gridRow: '1/3',
    },
    address: {
        gridColumn: 3, 
        gridRow: '1/3',
    },
    timeCounterAndArrowButtonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    liveContainer: {
        justifyContent: 'space-between',
    },
    indicator: {
        width: 25,
        height: 25,
    },
    motionSummaryContainer: { 
        flexDirection: 'column',
    },
    motionSummary: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4vh'
    },
    satIcon: {
        width: 20,
        height: 20,
    },
    alertTooltipTitle: {
        whiteSpace: 'pre-line'
    }, 
}));

const DeviceItem = ({ item: asset, isSelected, isOpen, setOpen, isLast }) => {
    const { t } = useTranslation();
    const { formatEventType } = useFormat(); 
    const [position, geofences] = useSelector(state => [state.positions.items[asset.id], Object.values(state.geofences.items)], 
        (prev, next) => prev[0]?.id === next[0]?.id);
    const alerts = useSelector(state => Object.values(state.alerts.items).filter(alert => alert.deviceId === asset.id)); 
    const dispatch = useDispatch();
    const { motions } = useGetMotions({ deviceId: asset.id, refreshInterval: 60 * 3 });
    const { classes, cx } = useStyles();
    const { classes: globalClasses, theme } = useGlobalStyles();
    const { formatSpeed } = useFormat();

    const renderGeofenceName = () => {
        let geofenceName = geofences.filter(g => asset?.geofenceIds?.includes(g.id)).map(g => g.name);

        return geofenceName[0] ? <em>{geofenceName[0]}</em> : <br/>;
    };

    const handleItemClicked = () => {
        if(isSelected) {
            dispatch(devicesActions.deselect());
        } else {
            dispatch(devicesActions.selectAndZoom(asset.id));
        }
    }

    return (
        <div
            className={isSelected ? cx(classes.root, globalClasses.selectedItem) : classes.root}
            key={asset.id}
            onClick={handleItemClicked}
        >
            <div 
                className={cx(classes.container, classes.firstContainer)}    
            >
                <div className={classes.iconsAndInfosContainer} >
                    <span className={classes.statusIcons}>
                        <ContactStatus device={asset} position={position} />
                        <MovementStatus device={asset} position={position} />
                        <SignalStatus device={asset} />
                        { alerts.length > 0  && 
                            <Tooltip 
                                title={<span className={classes.alertTooltipTitle}>{alerts.map(alert => formatEventType(alert)).join('\n')}</span>}
                            >
                                <Warning className={globalClasses.warningIcon} />
                            </Tooltip>
                        }
                    </span>                    
                    <span className={classes.licensePlate}>{[DeviceType.VEHICLE, DeviceType.CONSTRUCTION_MACHINE, DeviceType.SMARTPHONE].includes(asset.category) && asset.licensePlate}</span>
                    <div className={classes.assetNameAndGeofence}>
                        <span className={classes.assetName}>{asset.name}</span>
                        <span className={classes.geofence}>{position && geofences?.length > 0 ? renderGeofenceName() : <br/>}</span>
                    </div>
                    <span className={classes.address}>
                        <Address placement="end" address={position?.address}/>
                    </span>
                </div>
                <MotionStatusBar deviceId={asset.id} motions={motions}/>
                <div className={classes.timeCounterAndArrowButtonContainer}>
                    <span className={globalClasses.timeCounter}><TimeCounter time={new Date(asset.lastUpdate)}/></span>
                    {isOpen 
                        ? <span onClick={e => {e.stopPropagation(); setOpen(false);}}><KeyboardArrowUp /></span> 
                        : <span onClick={e => {e.stopPropagation(); setOpen(true);}}><KeyboardArrowDown /></span>
                    }
                </div>
            </div>


            <Collapse in={isOpen}>
                <Divider className={globalClasses.transparentDivider}/>
                <div className={cx(globalClasses.infoContainer, classes.motionSummaryContainer)}>
                    <span className={globalClasses.infoTitle}>{t('daySummary')}</span>
                    <div className={classes.motionSummary}>
                        <MotionsSummaryText
                            currentPosition={position}
                            device={asset}
                            motions={motions}
                        />
                        <MotionsSummaryTimeline 
                            motions={motions}
                            geofences={geofences}
                        />
                    </div>
                </div>
                
                <Divider className={globalClasses.transparentDivider}/>

                <div className={cx(classes.container, classes.liveContainer)}>
                    { position?.speed !== undefined &&
                        <BasicValue
                            alignment='center'
                            label={t('itemStatusSpeed')}
                            value={
                                <div className={globalClasses.indicatorContainer}>
                                    <div className={globalClasses.iconSize}><SpeedIcon /></div>
                                    <span>{formatSpeed(position?.speed, 'kmh')}</span>
                                </div>
                            }
                        />
                    }
                    <FuelOrBatteryStatus item={asset} position={position}/>
                    { (asset.category === DeviceType.VEHICLE || asset.category === DeviceType.CONSTRUCTION_MACHINE) &&
                        <BasicValue
                            alignment='center'
                            label={t('itemStatusSignal')}
                            value={
                                <div className={globalClasses.indicatorContainer}>
                                    <QualitySignalIndicator position={position} />
                                </div>
                            }
                        />
                    }
                    { position?.attributes?.sat !== undefined &&
                        <BasicValue
                            alignment='center'
                            label={t('itemStatusSat')}
                            value={
                                <div className={globalClasses.indicatorContainer}>
                                    <div className={globalClasses.iconSize}>
                                        <SatIcon fill={theme.palette.on.main} className={classes.satIcon}/>
                                    </div>
                                    <span>{position?.attributes?.sat}</span>
                                </div>
                            }
                        />
                    }
                    { position?.altitude !== undefined &&
                        <BasicValue
                            alignment='center'
                            label={t('itemStatusAltitude')}
                            value={
                                <div className={globalClasses.indicatorContainer}>
                                    <div className={globalClasses.iconSize}>
                                        <FilterHdrIcon />
                                    </div>
                                    <span>{`${Math.round(position.altitude)} ${t('sharedMeterAbbreviation')}`}</span>
                                </div>
                            }
                        />
                    }
                </div>
            </Collapse>
            {!isLast && <Divider className={globalClasses.transparentDivider}/>}
        </div>
    );
};

export default DeviceItem;