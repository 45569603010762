import React, { createContext, useState } from 'react';

/*
* show : whether show or hide the message block
* type : what theme is the message block
* message : message displaying on the message block
*/

const defaultMessage = {
    show: false,
    type: 'success',
    message: ''
}

export const AlertMessageContext = createContext({
    message: defaultMessage,
    setAlertMessage: () => {}
})

export const AlertMessageProvider = ({children}) => {
    const [messageState, setAlertMessageState] = useState(defaultMessage)

    const contextValue = {
        message: messageState,
        setAlertMessage: (val) => setAlertMessageState(val)
    }

    return (
        <AlertMessageContext.Provider value={contextValue}>
            {children}
        </AlertMessageContext.Provider>
    );
}
