import React from 'react';

import { magellanV0 } from 'api/magellan';
import { MANAGER } from 'authorization/role';
import withSignageLayout from 'hoc/withSignageLayout';
import { useSignage } from 'hooks/useSignage';
import SignageHeader from '../../common/SignageHeader';
import Loading from '../../common/Loading';
import CompanyForm from './CompanyForm';

const filterDataFromServer = (companyApi) => {
    const timeToDate = (time) => {
        let date = new Date();
        if(time){ 
            let tempTime = time.split(":");
            date.setHours(tempTime[0]);
            date.setMinutes(tempTime[1]);
            return date.toString();
        }
        return null;
    }

    const company = {
        name: companyApi.name,
        legalStatus: companyApi.legalStatus || '',
        phoneNumber: companyApi.phoneNumber || '',
        mailAddress: companyApi.mailAddress || '',
        logo: companyApi.logo,
        isPrivateOutsideWorkingHours: companyApi.isPrivateOutsideWorkingHours,
        address:{
            street: companyApi.address.street,
            houseNumber: companyApi.address.houseNumber,
            locality: companyApi.address.locality,
            country : 21,
        },
        calendar: {
            mondayStartHour : timeToDate(companyApi.mondayStartHour),
            mondayEndHour: timeToDate(companyApi.mondayEndHour),
            tuesdayStartHour: timeToDate(companyApi.tuesdayStartHour),
            tuesdayEndHour: timeToDate(companyApi.tuesdayEndHour),
            wednesdayStartHour: timeToDate(companyApi.wednesdayStartHour),
            wednesdayEndHour: timeToDate(companyApi.wednesdayEndHour),
            thursdayStartHour: timeToDate(companyApi.thursdayStartHour),
            thursdayEndHour: timeToDate(companyApi.thursdayEndHour),
            fridayStartHour: timeToDate(companyApi.fridayStartHour),
            fridayEndHour: timeToDate(companyApi.fridayEndHour),
            saturdayStartHour : timeToDate(companyApi.saturdayStartHour),
            saturdayEndHour: timeToDate(companyApi.saturdayEndHour),
            sundayStartHour : timeToDate(companyApi.sundayStartHour),
            sundayEndHour: timeToDate(companyApi.sundayEndHour),
        }
        
    }
    return company;
}

const UpdateCompany = ({ role, filterDataForServer }) => {
    const {editionMode, setEditionMode, item, updateData, loading, isReadonly} = useSignage({ readApi: magellanV0, createApi: magellanV0, updateApi: magellanV0 }, 'companies', false);

    const onSubmit = formValues => {
        updateData(filterDataForServer({...formValues}))
    };

    return (
        <>
            {loading 
                ? <Loading/>
                : <>
                    <SignageHeader 
                        editionMode={editionMode} 
                        setEditionMode={setEditionMode} 
                        editionEnabled={role === MANAGER && !isReadonly} 
                        showGoBack={false}
                    />
                    <CompanyForm
                        editionMode={editionMode}
                        initialValues={filterDataFromServer(item)}
                        onSubmit={onSubmit}
                    />
                </>
            }
        </>
    );
}

export default withSignageLayout()(UpdateCompany);