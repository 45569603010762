import React, { useRef } from 'react';
import { createRoot } from 'react-dom/client';
import mapboxgl from 'mapbox-gl';
import { Provider } from 'react-redux';

import { store } from 'store';
import { ThemeProvider, useTheme } from '@mui/material';
import { StylesProvider } from 'context/StylesContext';

const useMapboxPopup = ({ map }) => {
    const popupTimeoutId = useRef();
    const popup = useRef();
    const theme = useTheme();

    const displayPopup = (coordinates, render, popupAttributes = {}) => {
        const popupSettings = { closeOnClick: true, closeButton: false, offset: 5, anchor: 'top', ...popupAttributes };
        const placeholder = document.createElement('div');
        const placeholderRoot = createRoot(placeholder);
        placeholderRoot.render(
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <StylesProvider>
                        {render()}
                    </StylesProvider>
                </ThemeProvider>
            </Provider>
        );

        popup.current = new mapboxgl.Popup(popupSettings)
            .setDOMContent(placeholder)
            .setLngLat(coordinates)
            .addTo(map);
        popup.current.getElement().childNodes[0].style.borderBottomColor = theme.palette.primary.main;
        popup.current.getElement().childNodes[1].style.backgroundColor = theme.palette.primary.main;
        popup.current.getElement().style.zIndex = 1;
    };

    const onEnterPopup = () => {
        clearTimeout(popupTimeoutId.current);
    };

    const onLeavePopup = () => {
        popupTimeoutId.current = setTimeout(() => {
            if(popup.current){
                popup.current.remove();
                popup.current = null;
            }
        }, 100);
    }
    
    return { displayPopup, onEnterPopup, onLeavePopup, popup, popupTimeoutId };
};

export default useMapboxPopup;