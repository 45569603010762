import React from 'react';
import { LocationOn } from '@mui/icons-material';

import None from 'components/common/None';
import { parseAddress } from 'utils/address';

export const getColumns = (geofences, globalClasses, cx, t, formatDate, formatAddressOneLine, formatDuration) => [{
    Header: t('reportStartTime'),
    accessor: 'startTime',
    Cell: ({ cell: { value } }) => formatDate(value),
    props: {
        className: globalClasses.tableCellFirst,
        style: {
            width: '15%',
        }
    },
}, {
    Header: t('sharedAddress'),
    accessor: 'address',  
    Cell: ({ cell: { row: { original: row } } }) => {
        let isGeofence = false;
        let addressValue = geofences?.[row?.geofenceIds?.[0]]?.name;
        if (addressValue) {
            isGeofence = true;
        } else {
            if(row?.address) {
                let address = row?.address;
                if(typeof address === 'string') {
                    address = parseAddress(address);
                }
                addressValue = formatAddressOneLine(address);
            }
        }
        return (
            <>
                <LocationOn className={isGeofence ? globalClasses.decorativeIcon : cx(globalClasses.decorativeIcon, globalClasses.invisible)}/>
                {addressValue || <None text={t('itemAddressNone')}/>}
            </>
        );
    },
    props: {
        style: {
            width: '40%',
        }
    },
    headerProps: {
        className: globalClasses.tableAddressHeader,
    },
}, {
    Header: t('reportEndTime'),
    accessor: 'endTime',
    Cell: ({ cell: { value } }) => formatDate(value),
    props: {
        style: {
            padding: 8,
            textAlign: 'start',
            width: '15%',
        }
    },
}, {
    Header: t('reportDuration'),
    accessor: 'duration',
    Cell: ({ cell: { value} }) => formatDuration(value),
    props: {
        className: cx(globalClasses.tableCellNumber, globalClasses.tableCellLast),
        style: {
            width: '10%',
        }
    },
},];