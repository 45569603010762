import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import useTranslation from 'hooks/useTranslation';

import ItemTable from '../../home/ItemTable';
import { DeviceType, Period } from 'utils/constant';
import { makeStyles } from 'tss-react/mui';
import { magellanV1 } from 'api/magellan';
import PeriodSelector from 'components/common/PeriodSelector';
import useAlertMessage from 'hooks/useAlertMessage';
import moment from 'moment';
import ItemRow from 'components/common/ItemRow';
import { getData, getColumns } from '.';
import { useExpanded, useSortBy, useTable } from 'react-table';
import ReportLayoutPage from '../ReportLayoutPage';
import { useGlobalStyles } from 'context/StylesContext';
import useFormat from 'hooks/useFormat';

export const getAggregationPeriodSafeDrivingStats = (from, to) => {
    let aggregationPeriod = 'month';
    if(to - from <= 48*60*60*1000) {
        aggregationPeriod = 'hour';
    } else if(to - from <= 14*24*60*60*1000) {
        aggregationPeriod = 'day'
    } else if(to - from <= 28*24*60*60*1000) {
        aggregationPeriod = 'week';
    }
    return aggregationPeriod;
}

const SafeDrivingPage = () => {
    const { t } = useTranslation();
    const { classes: globalClasses, cx } = useGlobalStyles();
    const { formatDecimalNumber, } = useFormat();
    const vehicles = useSelector(state => Object.values(state.devices.items).filter(d => d?.category === DeviceType.VEHICLE));
    const [period, setPeriod] = useState(Period.TODAY);
    const [from, setFrom] = useState(moment().startOf('day'));
    const [to, setTo] = useState(moment().endOf('day'));
    const [safeDrivingStats, setSafeDrivingStats] = useState(null);
    const [fuelConsumptions, setFuelConsumptions] = useState(null);
    const { setAlertMessage, dataLoadingErrorAlertMessage } = useAlertMessage();
    const columns = useMemo(() => getColumns({ t, globalClasses, cx, formatDecimalNumber, }), []);
    const data = useMemo(() => getData({vehicles, safeDrivingStats, fuelConsumptionByDevice: fuelConsumptions}), [JSON.stringify(vehicles), JSON.stringify(safeDrivingStats), JSON.stringify(fuelConsumptions)]);
    const { 
        toggleAllRowsExpanded,
        visibleColumns,
        ...tableRest
    } = useTable(
        { 
            columns, 
            data, 
            autoResetExpanded: false, 
            autoResetSortBy: false,
            getRowId: useCallback(row => row.allData.vehicle.id, []),
            initialState: {
                sortBy: [
                    {id: 'safeDrivingScore', desc: true}
                ]
            },
        }, 
        useSortBy,
        useExpanded,
    );

    useEffect(() => {
        let aggregationPeriod = getAggregationPeriodSafeDrivingStats(from.valueOf(), to.valueOf());
        if (vehicles.length > 0) {
            Promise.all([
                magellanV1.get('/api/reports/fuelConsumption', {
                    params: {
                        deviceId: vehicles.map(v => v?.id),
                        from: from.toISOString(),
                        to: to.toISOString(),
                    },
                }),
                ...vehicles.map(v => magellanV1.get('/api/reports/safeDrivingStats', {
                    params: {
                        deviceId: v.id,
                        period: aggregationPeriod,
                        from: from.toISOString(),
                        to: to.toISOString(),
                    }
                }))
            ]).then(([fuelConsumptionResponse, ...safeDrivingStatsResponses]) => {
                const result = safeDrivingStatsResponses.reduce((accumulator, response) => ({ ...accumulator, [response.config.params.deviceId]: response.data }), {});
                setSafeDrivingStats(result);
                setFuelConsumptions(fuelConsumptionResponse.data);
            }).catch(error => {
                setAlertMessage(dataLoadingErrorAlertMessage);
            });
        } 
    }, [from?.toISOString(), to?.toISOString()])

    return (
        <ReportLayoutPage
            filter={
                <div className={globalClasses.rootReportEngineContainer}>
                    <div className={globalClasses.reportFiltersContainer}>
                        <PeriodSelector 
                            period={period}
                            setPeriod={setPeriod}
                            from={from}
                            setFrom={setFrom}
                            to={to}
                            setTo={setTo}
                        />
                    </div>
                </div>
            }
        >
                <ItemTable 
                    {...tableRest}
                >
                    { ({itemRow,}) => (
                        <ItemRow 
                            key={itemRow.id} 
                            itemRow={itemRow} 
                            visibleColumns={visibleColumns} 
                            toggleAllRowsExpanded={toggleAllRowsExpanded} 
                        />
                    )}
                </ItemTable>
        </ReportLayoutPage>
    );
};

export default SafeDrivingPage;