import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { endOfDay, endOfISOWeek, endOfMonth, endOfWeek, endOfYear, startOfMonth, startOfTomorrow, startOfWeek, startOfYear } from 'date-fns';
import { makeStyles } from "tss-react/mui";

import { magellanV0, magellanV1 } from "api/magellan";
import useFormat from 'hooks/useFormat';
import useTranslation from 'hooks/useTranslation';
import AssetStatistic from "./AssetStatistic";
import { getAggregationPeriodSafeDrivingStats } from "components/reports/safeDriving/SafeDrivingPage";

const useStyles = makeStyles()(theme => ({
    container: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
    }
}));

const AssetStatistics = ({ item, position }) => {
    const { t } = useTranslation();
    const { formatDistance } = useFormat();
    const { classes } = useStyles();
    const [globalSafeDrivingStats, setGlobalSafeDrivingStats] = useState();
    const [yearSafeDrivingStats, setYearSafeDrivingStats] = useState();
    const [monthSafeDrivingStats, setMonthSafeDrivingStats] = useState();
    const [weekSafeDrivingStats, setWeekSafeDrivingStats] = useState();

    const getSafeDrivingStats = useCallback(() => {
        const dateTimeAtStartFrom = new Date(0);
        const dateTimeAtStartTo = endOfDay(Date.now());
        const dateTimeYearFrom = startOfYear(Date.now());
        const dateTimeYearTo = endOfYear(Date.now());
        const dateTimeMonthFrom = startOfMonth(Date.now());
        const dateTimeMonthTo = endOfMonth(Date.now());
        const dateTimeWeekFrom = startOfWeek(Date.now(), { weekStartsOn: 1 });
        const dateTimeWeekTo = endOfISOWeek(Date.now(), { weekStartsOn: 1 });

        Promise.all([
            magellanV1.get('/api/reports/safeDrivingStats', { params: { deviceId: item.id, from: dateTimeAtStartFrom.toISOString(), to: dateTimeAtStartTo.toISOString(), period: getAggregationPeriodSafeDrivingStats(dateTimeAtStartFrom.getTime(), dateTimeAtStartTo.getTime()) }}).catch(() => {}),
            magellanV1.get('/api/reports/safeDrivingStats', { params: { deviceId: item.id, from: dateTimeYearFrom.toISOString(), to: dateTimeYearTo.toISOString(), period: getAggregationPeriodSafeDrivingStats(dateTimeYearFrom.getTime(), dateTimeYearTo.getTime()) }}).catch(() => {}),
            magellanV1.get('/api/reports/safeDrivingStats', { params: { deviceId: item.id, from: dateTimeMonthFrom.toISOString(), to: dateTimeMonthTo.toISOString(), period: getAggregationPeriodSafeDrivingStats(dateTimeMonthFrom.getTime(), dateTimeMonthTo.getTime()) }}).catch(() => {}),
            magellanV1.get('/api/reports/safeDrivingStats', { params: { deviceId: item.id, from: dateTimeWeekFrom.toISOString(), to: dateTimeWeekTo.toISOString(), period: getAggregationPeriodSafeDrivingStats(dateTimeWeekFrom.getTime(), dateTimeWeekTo.getTime()) }}).catch(() => {}),
        ])
            .then(([globalSafeDriving, yearSafeDriving, monthSafeDriving, weekSafeDriving]) => {
                setGlobalSafeDrivingStats(globalSafeDriving.data);
                setYearSafeDrivingStats(yearSafeDriving.data);
                setMonthSafeDrivingStats(monthSafeDriving.data);
                setWeekSafeDrivingStats(weekSafeDriving.data);
            })
            .catch(error => {});
    }, []);

    useEffect(() => {
        getSafeDrivingStats();
    }, [getSafeDrivingStats]);

    return (
        <div className={classes.container}>
            { globalSafeDrivingStats && <AssetStatistic 
                label={t('assetStatisticTotal')} 
                safeDrivingStats={globalSafeDrivingStats} 
                forceNotDisplayTime={true} 
                distanceOverriden={formatDistance(position?.attributes?.totalDistance, 'km')}
            />}  
            { yearSafeDrivingStats && <AssetStatistic label={t('assetStatisticYear')} safeDrivingStats={yearSafeDrivingStats} />}  
            { monthSafeDrivingStats && <AssetStatistic label={t('assetStatisticMonth')} safeDrivingStats={monthSafeDrivingStats}  />}  
            { weekSafeDrivingStats && <AssetStatistic label={t('assetStatisticWeek')} safeDrivingStats={weekSafeDrivingStats}  />}  
        </div>
    );
};

export default AssetStatistics;