import React from 'react';
import { Autocomplete, TextField } from '@mui/material'; 

import useToolsAndTrailerEventsReport from './useToolsAndTrailerEventsReport';
import ReportLayoutPage from '../ReportLayoutPage';
import ItemTable from '../../home/ItemTable';
import PeriodSelector from 'components/common/PeriodSelector';
import ReportShowButton from '../ReportShowButton';
import FileExportReportButtons from '../FileExportReportButtons';
import None from 'components/common/None';
import _ from 'lodash';
import { sortByThenBy } from 'utils/sort';
import ItemRow from 'components/common/ItemRow';

const ToolsAndTrailersEventsPage = () => {
    const { 
        formatDeviceCategory,
        t, 
        globalClasses,
        toolsAndTrailers, 
        deviceId,
        setDeviceId,
        period,
        setPeriod,
        from,
        setFrom,
        to,
        setTo,
        handleSubmit,
        tasks,
        getFiltersKey,
        displayDisabled,
        pdfDisabled,
        excelDisabled,
        emailDisabled,
        ...rest
    } = useToolsAndTrailerEventsReport();

    return (
        <ReportLayoutPage filter={
            <div className={globalClasses.rootReportEngineContainer}>
                <div className={globalClasses.reportFiltersContainer}>
                    <Autocomplete 
                        options={toolsAndTrailers.sort((deviceA, deviceB) => sortByThenBy(deviceA.category, deviceA.name.toLowerCase(), deviceB.category, deviceB.name.toLowerCase()))}
                        groupBy={d => formatDeviceCategory(d?.category)}
                        getOptionLabel={d => d?.name || ''}
                        value={toolsAndTrailers.find(d => d.id === deviceId)}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        onChange={(_, value) => setDeviceId(value?.id || null)}
                        renderInput={(params) => <TextField {...params} label={t('reportDevice')} variant='outlined'/>}
                    />
                    <PeriodSelector 
                        period={period} 
                        setPeriod={setPeriod} 
                        from={from}
                        setFrom={setFrom}
                        to={to}
                        setTo={setTo}
                    />
                    <ReportShowButton
                        disabled={displayDisabled}
                        handleSubmit={handleSubmit}
                    />
                </div>
                <div className={globalClasses.reportButtonsContainer}>
                    <FileExportReportButtons
                        pdfDisabled={pdfDisabled}
                        excelDisabled={excelDisabled}
                        emailDisabled={emailDisabled}
                        tasks={tasks}
                        handleSubmit={handleSubmit}
                        getFiltersKey={getFiltersKey}
                    />
                </div>
            </div>}
        >
            <ItemTable 
                {...rest}
            >
                { ({itemRow, visibleColumns, toggleAllRowsExpanded}) => (
                    <ItemRow 
                        key={itemRow.id} 
                        itemRow={itemRow} 
                        visibleColumns={visibleColumns} 
                        toggleAllRowsExpanded={toggleAllRowsExpanded} 
                    />
                )}
            </ItemTable>
        </ReportLayoutPage>
    );
};

export default ToolsAndTrailersEventsPage;