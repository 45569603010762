import React from 'react';
import { useFormik } from 'formik';
import { Card, CardHeader, FormControlLabel, MenuItem, Switch, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import useTranslation from 'hooks/useTranslation';
import AddressInput from '../../common/AddressInput';
import { Sex } from 'utils/constant';
import PickCoordinatesOnMap from '../PickCoordinatesOnMap';

const useStyles = makeStyles()(theme => ({
    personalInformationsSmallFieldsContainer : {
        display: 'grid',
        columnGap: '10%',
        rowGap: theme.spacing(2),
        gridTemplateColumns: '45% 45%',
        width: '100%',
    },
    personalInformationsCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
}));

const GeofenceForm = ({ onSubmit, initialValues, editionMode, id }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const formik = useFormik({
        initialValues,
        validationSchema: yup.object().shape({
            firstname: yup.string()
                .trim()
                .required(t('validationRequired'))
                .min(2, `\${min} ${t('validationLengthMin')}`)
                .max(128, `\${max} ${t('validationLengthMax')}`),
            name: yup.string()
                .trim()
                .required(t('validationRequired'))
                .min(2, `\${min} ${t('validationLengthMin')}`)
                .max(128, `\${max} ${t('validationLengthMax')}`),
            uniqueId: yup.string()
                .trim()
                .required(t('validationRequired'))
                .min(2, `\${min} ${t('validationLengthMin')}`)
                .max(128, `\${max} ${t('validationLengthMax')}`),
            sex: yup.string()
                .nullable(),
            phone: yup.string()
                .trim()
                .nullable()
                .min(2, `\${min} ${t('validationLengthMin')}`)
                .max(128, `\${max} ${t('validationLengthMax')}`),
            email: yup.string()
                .trim()
                .nullable()
                .min(2, `\${min} ${t('validationLengthMin')}`)
                .max(128, `\${max} ${t('validationLengthMax')}`),
            nationalRegisterNumber: yup.string()
                .trim()
                .nullable()
                .min(2, `\${min} ${t('validationLengthMin')}`)
                .max(128, `\${max} ${t('validationLengthMax')}`),
            onssNumber: yup.string()
                .trim()
                .nullable()
                .min(2, `\${min} ${t('validationLengthMin')}`)
                .max(128, `\${max} ${t('validationLengthMax')}`),
            socialSecurityNumber: yup.string()
                .trim()
                .nullable()
                .min(2, `\${min} ${t('validationLengthMin')}`)
                .max(128, `\${max} ${t('validationLengthMax')}`),
            comment: yup.string()
                .trim()
                .max(4000, `\${max} ${t('validationLengthMax')}`)
                .nullable(),
            address: yup.object().shape({
                street: yup.string().trim().required(t('validationRequired')).max(128, `\${max} ${t('validationLengthMax')}`),
                houseNumber: yup.string().trim().required(t('validationRequired')).max(16, `\${max} ${t('validationLengthMax')}`),
            }),
        }),
        onSubmit
    });

    return (
        <form id="form" onSubmit={e => {
            e.preventDefault();
            formik.submitForm();
        }}>
            <CardHeader title={t('signagePersonalInformations')} />
            <Card className={classes.personalInformationsCard}>
                <div className={classes.personalInformationsSmallFieldsContainer}>
                <TextField 
                    required
                    id="uniqueId"
                    name="uniqueId"
                    label={t('signageStafferIdentifier')}
                    disabled={!editionMode}
                    value={formik.values.uniqueId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.uniqueId && Boolean(formik.errors.uniqueId)}
                    helperText={formik.touched.uniqueId && formik.errors.uniqueId}
                />
                <FormControlLabel
                    style={{
                        display: "flex",
                        width: '100%',
                        justifyContent: "space-between",
                        marginLeft: '0px',
                    }}
                    disabled={!editionMode}
                    control={<Switch
                        name="active"
                        onBlur={formik.handleBlur}
                        checked={formik.values.active} 
                        onChange={e => formik.setFieldValue('active', e.target.checked)}
                    />}
                    label={t('signageActive')}
                    labelPlacement="start"
                />
                    <TextField 
                        required
                        id="firstname"
                        name="firstname"
                        label={t('signageFirstname')}
                        disabled={!editionMode}
                        value={formik.values.firstname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                        helperText={formik.touched.firstname && formik.errors.firstname}
                    />
                    <TextField 
                        required
                        id="name"
                        name="name"
                        label={t('signageName')}
                        disabled={!editionMode}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <TextField 
                        id="phone"
                        name="phone"
                        label={t('signagePhone')}
                        disabled={!editionMode}
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                        helperText={formik.touched.phone && formik.errors.phone}
                    />
                    <TextField 
                        id="sex"
                        name="sex"
                        label={t('signageSex')}
                        disabled={!editionMode}
                        select
                        value={formik.values.sex}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.sex && Boolean(formik.errors.sex)}
                        helperText={formik.touched.sex && formik.errors.sex}
                    >
                        <MenuItem key={Sex.FEMALE} value={Sex.FEMALE}>{t('signageFemale')}</MenuItem>
                        <MenuItem key={Sex.MALE} value={Sex.MALE}>{t('signageMale')}</MenuItem>
                    </TextField>
                    <TextField 
                        id="function"
                        name="function"
                        label={t('signageFunction')}
                        disabled={!editionMode}
                        value={formik.values.function}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.function && Boolean(formik.errors.function)}
                        helperText={formik.touched.function && formik.errors.function}
                    />
                    <TextField 
                        id="email"
                        name="email"
                        label={t('signageEmail')}
                        disabled={!editionMode}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </div>
                <TextField 
                    id="comment"
                    name="comment"
                    label={t('signageComment')}
                    disabled={!editionMode}
                    value={formik.values.comment}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.comment && Boolean(formik.errors.comment)}
                    helperText={formik.touched.comment && formik.errors.comment}
                    multiline
                    rows={10}
                    maxRows={35}
                    fullWidth={true}
                />
            </Card>
            <CardHeader title={t('signageAdditionalInformations')} />
            <Card>
                <TextField 
                    id="nationalRegisterNumber"
                    name="nationalRegisterNumber"
                    label={t('signageNationalRegisterNumber')}
                    disabled={!editionMode}
                    value={formik.values.nationalRegisterNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.nationalRegisterNumber && Boolean(formik.errors.nationalRegisterNumber)}
                    helperText={formik.touched.nationalRegisterNumber && formik.errors.nationalRegisterNumber}
                />
                <TextField 
                    id="onssNumber"
                    name="onssNumber"
                    label={t('signageOnssNumber')}
                    disabled={!editionMode}
                    value={formik.values.onssNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.onssNumber && Boolean(formik.errors.onssNumber)}
                    helperText={formik.touched.onssNumber && formik.errors.onssNumber}
                />
                <TextField 
                    id="socialSecurityNumber"
                    name="socialSecurityNumber"
                    label={t('signageSocialSecurityNumber')}
                    disabled={!editionMode}
                    value={formik.values.socialSecurityNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.socialSecurityNumber && Boolean(formik.errors.socialSecurityNumber)}
                    helperText={formik.touched.socialSecurityNumber && formik.errors.socialSecurityNumber}
                />
            </Card>
            <AddressInput editionMode={editionMode} formik={formik}/>
             {/*<PickCoordinatesOnMap  editionMode={editionMode} formik={formik}/>*/}
        </form>
    );
};

export default GeofenceForm;