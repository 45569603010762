
import { comparatorByCategoryThenByName, sortByThenBy } from 'utils/sort';
import { getGeofenceOrAddress } from 'utils/address';

export const getData = (staffers, checkingsByStaffer, lastCheckingByStaffer, devices, geofences, formatAddressOneLine) => (staffers && checkingsByStaffer && lastCheckingByStaffer) 
    ? Object.values(staffers)
        .filter(item => item.active)
        .map(item => {
            let data = {};
            let lastChecking = lastCheckingByStaffer?.[item.id];
            let checkingTimestamps = checkingsByStaffer?.[item.id];
            let geofence = geofences?.[lastChecking?.position?.attributes?.geofenceIds?.[0]];
            let device = devices?.[lastChecking?.deviceId];
            let startTime = checkingTimestamps?.at(0) || null;
            let endTime = checkingTimestamps?.at(-1) || null;
            
            if(endTime === startTime) {
                endTime = null;
            }

            data =  {
                allData: { item, lastChecking, checkingTimestamps, geofence, device },
                itemStatus: checkingTimestamps?.length,
                stafferLastname: item?.name,
                stafferFirstname: item?.firstname,
                startTime,
                endTime,
                address: getGeofenceOrAddress(geofence, lastChecking?.position, formatAddressOneLine),
                device: { device, position: lastChecking?.position },
                checkingTimestamps
            };

            return data;
        })
        .sort((item1, item2) => {
            const itemA = item1.allData.item;
            const itemB = item2.allData.item;
            return sortByThenBy(itemA.name, itemA.firstname, itemB.name, itemB.firstname);
        })
    : [];