import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Collapse, Divider, Tooltip } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, Warning } from '@mui/icons-material';

import { useGlobalStyles } from 'context/StylesContext';
import useTranslation from 'hooks/useTranslation';
import useGetMotions from 'hooks/useGetMotions';
import { devicesActions } from 'store';
import Address from '../../common/Address';
import TimeCounter from '../../common/TimeCounter';
import MotionsSummaryTimeline from '../../common/MotionsSummaryTimeline';
import MovementStatus from 'components/common/MovementStatus';
import SignalStatus from 'components/common/SignalStatus';
import ContactStatus from 'components/common/ContactStatus';
import MotionStatusBar from './MotionStatusBar';
import MotionsSummaryText from 'components/common/MotionsSummaryText';
import useBeaconData from 'hooks/useBeaconData';
import useFormat from 'hooks/useFormat';


const useStyles = makeStyles()(theme => ({
    root: {
        width: '100%',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.selected.main,
        },
    },
    container: {
        padding: '16px',
        display: 'flex',
        width: '100%',
    },
    firstContainer: {
        flexDirection: 'column',
    },
    iconsAndInfosContainer: {
        display: 'grid', 
        gridTemplateColumns: 'minmax(16%, max-content) auto auto', 
        columnGap: 10, 
        marginBottom: 8,
    },
    statusIcons: {
        gridColumn: 1, 
        gridRow: 'auto',
    },
    licensePlate: {
        gridColumn: 1, 
        gridRow: 2,
    },
    assetNameAndGeofence: {
        display: 'flex',
        flexDirection: 'column',
        gridColumn: 2, 
        gridRow: '1/3',
    },
    address: {
        gridColumn: 3, 
        gridRow: '1/3',
    },
    timeCounterAndArrowButtonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    liveContainer: {
        justifyContent: 'space-between',
    },
    indicator: {
        width: 25,
        height: 25,
    },
    motionSummaryContainer: { 
        flexDirection: 'column',
    },
    motionSummary: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4vh'
    },
    satIcon: {
        width: 20,
        height: 20,
    },
    alertTooltipTitle: {
        whiteSpace: 'pre-line'
    }, 
}));

const TrailerItem = ({ item: asset, isSelected, isOpen, setOpen, isLast }) => {
    const { t } = useTranslation();
    const { formatEventType } = useFormat(); 
    const [devices, positions, geofences, groups] = useSelector(state => 
        [Object.values(state.devices.items), state.positions.items, state.geofences.items, state.groups.items], 
        (prev, next) => prev[0]?.id === next[0]?.id); 
    const alerts = useSelector(state => Object.values(state.alerts.items).filter(alert => alert.deviceId === asset.id)); 
    const { extractBeaconData } = useBeaconData(devices, positions, geofences, groups);
    const { allData: { deviceAssociated, positionDeviceAssociated, position, geofence }} = extractBeaconData(asset);
    const dispatch = useDispatch();
    const { motions } = useGetMotions({ deviceId: deviceAssociated?.id || asset.id, refreshInterval: 60 * 3 });
    const { classes, cx } = useStyles();
    const { classes: globalClasses } = useGlobalStyles();

    const renderGeofenceName = () => {
        return geofence?.name ? <em>{geofence?.name}</em> : <br/>;
    };

    const handleItemClicked = () => {
        if(isSelected) {
            dispatch(devicesActions.deselect());
        } else {
            dispatch(devicesActions.select(asset.id));
        }
    }

    return (
        <div 
            className={isSelected ? cx(classes.root, globalClasses.selectedItem) : classes.root}
            key={asset.id}
            onClick={handleItemClicked}
        >
            <div 
                className={cx(classes.container, classes.firstContainer)}    
            >
                <div className={classes.iconsAndInfosContainer} >
                    <span className={classes.statusIcons}>
                        <ContactStatus device={asset} position={position} />
                        <MovementStatus device={asset} position={positionDeviceAssociated || position} />
                        <SignalStatus device={asset} />
                    { alerts.length > 0  && 
                        <Tooltip 
                            title={<span className={classes.alertTooltipTitle}>{alerts.map(alert => formatEventType(alert)).join('\n')}</span>}
                        >
                            <Warning className={globalClasses.warningIcon} />
                        </Tooltip>
                    }
                    </span>                                        
                    <div className={classes.assetNameAndGeofence}>
                        <span className={classes.assetName}>{asset.name}</span>
                        <span className={classes.geofence}>{geofence ? renderGeofenceName() : <br/>}</span>
                    </div>
                    <span className={classes.address}>
                        <Address placement="end" address={positionDeviceAssociated?.address || position?.address}/>
                    </span>
                </div>
                <MotionStatusBar deviceId={deviceAssociated?.id || asset.id} motions={motions}/>
                <div className={classes.timeCounterAndArrowButtonContainer}>
                    <span className={globalClasses.timeCounter}><TimeCounter time={new Date(asset.lastUpdate)}/></span>
                    {isOpen 
                        ? <span onClick={e => {e.stopPropagation(); setOpen(false);}}><KeyboardArrowUp /></span> 
                        : <span onClick={e => {e.stopPropagation(); setOpen(true);}}><KeyboardArrowDown /></span>
                    }
                </div>
            </div>


            <Collapse in={isOpen}>
                <Divider className={globalClasses.transparentDivider}/>
                <div className={cx(globalClasses.infoContainer, classes.motionSummaryContainer)}>
                    <span className={globalClasses.infoTitle}>{t('daySummary')}</span>
                    <div className={classes.motionSummary}>
                        <MotionsSummaryText
                            currentPosition={positionDeviceAssociated || position}
                            device={deviceAssociated || asset}
                            motions={motions}
                        />
                        <MotionsSummaryTimeline 
                            motions={motions}
                            geofences={Object.values(geofences)}
                        />
                    </div>
                </div>
                
                <Divider className={globalClasses.transparentDivider}/>
            </Collapse>
            {!isLast && <Divider className={globalClasses.transparentDivider}/>}
        </div>
    );
};

export default TrailerItem;