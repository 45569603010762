import "@fontsource/roboto";
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';

import App from './components/App';
import {store} from './store';
import { AuthenticationProvider } from './context/AuthenticationContext';
import { StylesProvider } from './context/StylesContext';
import { ColorModeProvider } from "context/ColorModeContext";
import { MapProvider } from "context/MapContext";
import ErrorBoundary from "components/common/ErrorBoundary";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <ColorModeProvider>
            {(theme) => (
                <ThemeProvider theme={theme}>
                    <StylesProvider>
                        <AuthenticationProvider>
                            {({ authenticated }) => (
                                <ErrorBoundary>
                                    <MapProvider authenticated={authenticated} >
                                        <App authenticated={authenticated} />
                                    </MapProvider>
                                </ErrorBoundary>
                            )}
                        </AuthenticationProvider>
                    </StylesProvider>
                </ThemeProvider>
            )}
        </ColorModeProvider>
    </Provider>    
);