import useFormat from "./useFormat";

const useExtractAddress = ({ positions, geofences }) => {
    const { formatAddressOneLine } = useFormat();

    const extractAddress = (positionId, geofenceId) => {
        let position = Object.values(positions).find(position => position.id === positionId);
        let isGeofence = false;
        let addressValue = '';
        addressValue = geofences?.[geofenceId]?.name;
        if (addressValue) {
            isGeofence = true;
        } else if (position?.address) {
            let address = position?.address;
            if (address) {
                if(typeof address === 'string') {
                    address = JSON.parse(address);
                }
                addressValue = formatAddressOneLine(address);
            }
        }
        return { isGeofence, addressValue };
    };
    
    const extractAddressFromDevice = (item) => {
        let positionId = item?.positionId;
        let geofenceId = item?.geofenceIds?.[0];
        return extractAddress(positionId, geofenceId);
    };

    return { extractAddress, extractAddressFromDevice };
};

export default useExtractAddress;