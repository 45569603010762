import mapboxgl from 'mapbox-gl';
import { useEffect, useContext } from 'react';

import { MapContext } from 'context/MapContext';
import { mapPositionsByTripsToLineStringFeatures } from '../replay';
import { useTheme } from '@mui/material';
import { changeCursorToNormal, changeCursorToPointer } from '../map/mapUtil';

const ReplayPathMap = ({ positionsByTrip, allPositions, selectedTripId, setSelectedTripId, forceRefresh }) => {
    const sourceId = 'replaySource';
    const nonTripReplayLayerId= 'nonTripReplayLayerId';
    const replayLayerId = 'replayLayer';
    const { map, mapReady, isSatelliteStyle } = useContext(MapContext);
    const theme = useTheme();

    const handleClick = (event) => {
        let trip;

        try {
            trip = event?.features?.[0]?.properties?.trip;
            if (trip) {
                trip = JSON.parse(trip);
                setSelectedTripId((trip?.startPositionId || 0));
            }
        } catch (e) { }
        
    };

    useEffect(() => {
        if (mapReady) {
            map?.addSource(sourceId, {
                'type': 'geojson',
                'data': {
                    type: 'FeatureCollection',
                    features: [],
                },
            });
            map?.addLayer({
                'source': sourceId,
                'id': nonTripReplayLayerId,
                'type': 'line',
                'filter': ['!', ['get', 'isTrip']],
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                'paint': {
                    'line-color': ['get', 'color'],
                    'line-width': 5,
                },
            });
            map?.addLayer({
                'source': sourceId,
                'id': replayLayerId,
                'type': 'line',
                'filter': ['get', 'isTrip'],
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                'paint': {
                    'line-color': ['get', 'color'],
                    'line-width': 5,
                },
            });
            
            map.on('click', replayLayerId, handleClick);
            map.on('mouseenter', replayLayerId, changeCursorToPointer);
            map.on('mouseleave', replayLayerId, changeCursorToNormal);

            return () => {
                map.off('click', replayLayerId, handleClick);
                map.off('mouseenter', replayLayerId, changeCursorToPointer);
                map.off('mouseleave', replayLayerId, changeCursorToNormal);
                if(map?.getLayer(nonTripReplayLayerId)){
                    map?.removeLayer(nonTripReplayLayerId);
                }
                if(map?.getLayer(replayLayerId)){
                    map?.removeLayer(replayLayerId);
                }
                if(map?.getSource(sourceId) !== undefined){
                    map?.removeSource(sourceId);
                }        
            };
        }
    }, [mapReady]);

    useEffect(() => {
        let coordinates;
        let features;

        if (mapReady && map?.getSource(sourceId)){
            features = mapPositionsByTripsToLineStringFeatures(positionsByTrip, allPositions, selectedTripId, isSatelliteStyle, theme);
            if (selectedTripId) {
                coordinates = features?.at(-1)?.geometry?.coordinates ?? [];
            } else {
                coordinates = features.reduce((accumulator, feature) => [...accumulator, ...feature.geometry.coordinates], []);
            } 
            map?.getSource(sourceId)?.setData({
                type: 'FeatureCollection',
                features,
            });
            if (coordinates.length) {
                const bounds = coordinates.reduce((bounds, item) => bounds.extend(item), new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
                map.fitBounds(bounds, {
                    padding: { top: 50, bottom: 250, left: 25, right: 25 },
                    animate: false,
                });
            }
            forceRefresh();
        }        
    }, [mapReady, JSON.stringify(positionsByTrip), selectedTripId]);

    return null;
}

export default ReplayPathMap;