import React, { useEffect, useState, useMemo, useCallback } from "react";
import { magellanV1 } from "api/magellan";
import _ from 'lodash';
import moment from 'moment';
import { pdf, Text, View } from '@react-pdf/renderer';

import { downloadFile } from 'utils/downloadFile';
import useFormat from "hooks/useFormat";
import useTranslation from "hooks/useTranslation";
import { useDispatch, useSelector } from "react-redux";
import { useGlobalStyles } from "context/StylesContext";
import usePagination from "hooks/usePagination";
import { AsyncServerTasksType, Period, ReportFormat } from "utils/constant";
import useReport from "hooks/useReport";
import TemplatePdf from "components/reports/templates/TemplatePdf";
import TablePdf from "components/reports/templates/TablePdf";
import { isValidDate } from "utils/date";
import { useExpanded, useSortBy, useTable } from "react-table";
import { sortByThenBy } from "utils/sort";
import { removeItemAction, validateAndUpdateItemAction, fetchItemsAction } from "./asyncTimesheetReportActions";
import useAlertMessage from "hooks/useAlertMessage";
import { getData, getColumns } from '.';
import { makeStyles } from "tss-react/mui";
import { timesheetReportActions } from "store";

const useStyles = makeStyles()(theme => ({
    noResultContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
    },
    icon: {
        height: 100,
        width: 100,
    },
    iconError: {
        color: theme.palette.error.main,
    },
    editButton: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    }
}));

const useTimesheetReport = () => {
    const { classes } = useStyles();
    const { classes: globalClasses, cx } = useGlobalStyles();
    const { t } = useTranslation();
    const { formatDuration, formatLocalDate, formatAddressOneLine, formatDate } = useFormat();
    const { setAlertMessage, dataLoadingErrorAlertMessage, timesheetSavedAlertMessage, updateErrorAlertMessage, timesheetRemovedAlertMessage, } = useAlertMessage();
    const dispatch = useDispatch();
    const staffers = useSelector(state =>state.staffers.items);
    const items = useSelector(state => state.timesheetReport.items);
    const page = useSelector(state => state.timesheetReport.pagination.page);
    const limit = useSelector(state => state.timesheetReport.pagination.limit);
    const [stafferId, setStafferId] = useState('allStaffers');
    const [period, setPeriod] = useState(Period.TODAY);
    const [from, setFrom] = useState(moment().subtract(1, 'hour'));
    const [to, setTo] = useState(moment());
    const pagination = usePagination({ 
        clientPagination:false,
        limit, 
        page,
        changePage: (newPage) => dispatch(timesheetReportActions.updatePaginationPage(newPage)),
        changeLimit: (newLimit) => dispatch(timesheetReportActions.refreshPagination({ page: 0, limit: newLimit })),
    });
    let queryObject = { from: from.toISOString(), to: to.toISOString(), verified: true, limit, offset: pagination?.offset };
    if(stafferId !== 'allStaffers') {
        queryObject = { ...queryObject, stafferId };
    }
    const query = new URLSearchParams(queryObject);
    const getFiltersKey = () => {
        let filtersKey =`stafferId:${stafferId}`;
        if(period === "custom"){
            filtersKey += `-from:${new Date(from.toISOString()).setSeconds(0, 0)}-to:${new Date(to.toISOString()).setSeconds(0, 0)}`;
        } else {
            filtersKey += `-from:${new Date(from.toISOString()).setHours(0, 0, 0, 0)}-to:${new Date(to.toISOString()).setHours(0, 0, 0, 0)}`;
        }
        return filtersKey;
    };
    const currentStaffer =  Object.values(staffers).find(d => d.id === stafferId);
    const pdfReportFilters = [
        {
            key: t('stafferFilter'),
            value: stafferId === 'allStaffers' ? t('allStaffers') : `${currentStaffer?.firstname} ${currentStaffer?.name}`,
        },
        {
            key: t('fromFilter'),
            value: formatDate(from)
        },
        {
            key: t('toFilter'),
            value: formatDate(to)
        }
    ];
    const availableReportActions = [ReportFormat.JSON, ReportFormat.PDF, ReportFormat.EXCEL, ReportFormat.MAIL];
    const displayDisabled = !availableReportActions.includes(ReportFormat.JSON) || !stafferId || to.diff(from, 'months', true) > 1;
    const pdfDisabled = !availableReportActions.includes(ReportFormat.PDF) || !stafferId || to.diff(from, 'months', true) > 1;
    const excelDisabled = !availableReportActions.includes(ReportFormat.EXCEL) || !stafferId;
    const emailDisabled = !availableReportActions.includes(ReportFormat.MAIL) || !stafferId;
    const { handleSubmit, pdfData, setPdfData, companyName, companyAddress, setLoading, ...reportRest } = useReport({ 
        api: magellanV1, 
        report: 'timesheets', 
        taskType: AsyncServerTasksType.TIMESHEET_REPORT_EMAIL, 
        query,
        getFiltersKey,
        pagination, 
        setItems: (newItems) => dispatch(timesheetReportActions.initialize(newItems)), 
    });
    const columns = useMemo(() => getColumns({ t, classes, globalClasses, formatLocalDate, formatDuration, }), []);
    const data = useMemo(() => getData(items, staffers), [JSON.stringify(items)]);
    const { 
        ...tableRest
    } = useTable(
        { 
            columns, 
            data, 
            autoResetExpanded: false, 
            autoResetSortBy: false,
            getRowId: useCallback(row => row.allData.timesheet.id, []),
            meta: {
                updateTimesheet: (id, prop, value) => dispatch(timesheetReportActions.updateItem({ id, prop, value })),
                validateTimesheet: (id) => dispatch(validateAndUpdateItemAction({ 
                    id, 
                    items, 
                    nbItemsOnPage: pagination.nbItemsOnPage, 
                    setAlertMessage, 
                    timesheetSavedAlertMessage, 
                    updateErrorAlertMessage, 
                    t,
                    fetchNewItems: () => dispatch(fetchItemsAction({ 
                        mustShowLoading: false,
                        setAlertMessage, 
                        dataLoadingErrorAlertMessage, 
                        setLimit: pagination.setPageSize,
                        setTotalCount: pagination.setTotalCount,
                        limit: pagination.limit,
                        offset: pagination.offset, 
                        queryObject,
                    })),
                })), 
                removeTimesheet: (id) => dispatch(removeItemAction({ 
                    id, 
                    nbItemsOnPage: pagination.nbItemsOnPage, 
                    setAlertMessage, 
                    timesheetRemovedAlertMessage, 
                    updateErrorAlertMessage,
                    fetchNewItems: () => dispatch(fetchItemsAction({ 
                        mustShowLoading: false,
                        setAlertMessage, 
                        dataLoadingErrorAlertMessage, 
                        setLimit: pagination.setPageSize,
                        setTotalCount: pagination.setTotalCount,
                        limit: pagination.limit,
                        offset: pagination.offset, 
                        queryObject,
                    })),
                })),
                dispatch,
            }
        }, 
        useSortBy,
        useExpanded,
    );

    const staffersOptions = [
        { id: 'allStaffers', label: t('allStaffers') }, 
        ...Object.values(staffers).sort((stafferA, stafferB) => stafferA.firstname < stafferB.firstname ? -1 : 1)
    ];

    const handleDisplay = () => {
        dispatch(fetchItemsAction({ 
            setItems: (newItems) =>  dispatch(timesheetReportActions.initialize(newItems)), 
            setAlertMessage, 
            dataLoadingErrorAlertMessage, 
            setLimit: pagination.setPageSize,
            setTotalCount: pagination.setTotalCount,
            ...pagination, 
            queryObject 
        }));
    }

    useEffect(() => {
        if(stafferId && from && to && Object.values(items)?.length > 0) {
            handleDisplay();
        }
    }, [pagination?.nbItemsOnPage, pagination?.page]);

    const pdfReportColumns = React.useMemo(() => [
        {
            accessor: 'stafferName',
            Header: t('stafferName'),
            cellWidth: '16%',
        },
        {
            accessor: 'date',
            id: 'day',
            Header: t('day'),
            Cell: ({ cell: { value: date } }) => {
                if(isValidDate(new Date(date))) {
                    return _.capitalize(formatLocalDate(date, 'iiii'));
                }
                return null;
            },
            cellWidth: '10%',
        },
        {
            accessor: 'date',
            id: 'date',
            Header: t('date'),
            Cell: ({ cell: { value: date, }}) => {
                if(isValidDate(new Date(date))) {
                    return formatLocalDate(date, 'PPP');
                }
                return null;
            },
            cellWidth: '14%',
        },
        {
            accessor: 'startTime',
            Header: t('timesheetStartTime'),
            cellWidth: '17%',
            Cell: ({ cell: { value,  }, }) => formatLocalDate(new Date(value)),
        },
        {
            accessor: 'endTime',
            Header: t('timesheetEndTime'),
            cellWidth: '17%',
            Cell: ({ cell: { value,  }, }) => formatLocalDate(new Date(value)),
        },
        {
            accessor: 'breakTime',
            Header: t('timesheetBreakTime'),
            cellWidth: '13%',
            Cell: ({ cell: { value,  }, }) => formatDuration(value*1000),
        },
        {
            accessor: 'timesheetWorkingTime',
            Header: t('timesheetWorkingTime'),
            Cell: ({ cell: { value }}) => formatDuration(value),
            cellWidth: '13%',
        },
    ], []);

    let pdfDataFormatted = useMemo(() => {
        let extractedData = pdfData.map(timesheet => {
            let staffer = staffers?.[timesheet.stafferId];
            let data = {};

            let timesheetWorkingTime = null;
            let endTime = new Date(timesheet.endTime);
            let startTime = new Date(timesheet.startTime);
            if(!isNaN(endTime) && !isNaN(startTime) && !isNaN(timesheet.breakTime)) {
                timesheetWorkingTime = endTime - startTime - (timesheet.breakTime * 1000);
            }

            data =  {
                allData: { timesheet, staffer },
                timesheetWorkingTime,
                stafferName: `${staffer?.firstname} ${staffer?.name}`,
                ...timesheet
            };
            
            return data;
        }).sort((item1, item2) => {
            const itemA = item1.allData;
            const itemB = item2.allData;
            return sortByThenBy(itemA.staffer.firstname, itemA.timesheet.date, itemB.staffer.firstname, itemB.timesheet.date);
        });

        let iItem = 0;
        let totalWorkTime;
        let previousStafferName = '';
        while(iItem < extractedData.length) {
            totalWorkTime = 0;
            previousStafferName = extractedData[iItem].stafferName;
            while(iItem < extractedData.length && extractedData[iItem].stafferName === previousStafferName) {
                totalWorkTime += extractedData[iItem].timesheetWorkingTime;
                iItem++;
            }
            extractedData.splice(iItem, 0, { isSummary: true, timesheetWorkingTime: totalWorkTime });
            iItem++;
        }
        return extractedData;
    }, [JSON.stringify(pdfData)]);
    
    const { rows: pdfRows, headerGroups: pdfHeaderGroups, prepareRow: pdfPrepareRow } = useTable({ columns: pdfReportColumns, data: pdfDataFormatted });
    
    const renderTemplate = () => {
        return (
            <TemplatePdf 
                reportTitle={t('reportTimesheetTitlePdf')}
                companyName={companyName}
                companyAddress={formatAddressOneLine(companyAddress)}
                filters={pdfReportFilters} 
                isPortrait={false}
            >
                <TablePdf
                    headerGroups={pdfHeaderGroups} 
                    rows={pdfRows} 
                    prepareRow={pdfPrepareRow}
                    displayRowNumber={false}
                    renderSummaryRow={(row, index, styles) => {
                        const { key, ...restRowProps} = row.getRowProps();
                        
                        return (
                            <View key={key} style={styles.tableRow} wrap={false} {...restRowProps}> 
                                {row.cells.map(cell => {
                                    const { key, ...restCellProps } = cell.getCellProps();
                                    
                                    return (
                                        <View key={key} style={{...styles.tableCol, ...((index % 2 === 0) || styles.tableOddCol), width: cell.column.cellWidth}} {...restCellProps}> 
                                            <Text style={styles.tableCell}>{cell.column.id === 'timesheetWorkingTime' ? cell.render('Cell') : null}</Text>
                                        </View> 
                                    );
                                })}
                            </View>
                        );
                    }}
                />
            </TemplatePdf>
        );
    } 

    useEffect(() => {
        if(pdfRows && pdfRows.length > 0) {
            const doc = renderTemplate();
            const file = pdf(doc);
            file.toBlob().then(blob => {
                downloadFile(blob, 'timesheetReport.pdf');
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            })
            setPdfData([]);
        }
    }, [JSON.stringify(pdfRows)]); 

    return {
        t,  
        formatDuration,
        formatLocalDate, 
        globalClasses, 
        cx, 
        items,
        handleSubmit,
        handleDisplay,
        staffers,
        stafferId,
        setStafferId,
        period, 
        setPeriod,
        from,
        setFrom,
        to,
        setTo,
        getFiltersKey,
        displayDisabled,
        pdfDisabled,
        excelDisabled,
        emailDisabled,
        staffersOptions,
        setAlertMessage,
        timesheetSavedAlertMessage,
        updateErrorAlertMessage,
        timesheetRemovedAlertMessage,
        page,
        limit,
        ...pagination,
        ...reportRest,
        ...tableRest,
    };
};

export default useTimesheetReport;