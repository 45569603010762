import { createSlice } from '@reduxjs/toolkit';
import { AsyncServerTasksType } from '../utils/constant';

const initialState = {};
Object.values(AsyncServerTasksType).forEach(type => {
    initialState[type] = {
        initiatedTask: {},
        ongoingTask: {},
        completedTask: {},
        archivedTask: {}
    };
});


const { reducer, actions } = createSlice({
    name: 'asyncServerTasks',
    initialState,
    reducers: {
        initiateAsyncTask(state, action) {
            state[action.payload.type].initiatedTask[action.payload.filtersKey] = action.payload;
        },
        markAsyncTaskAsBegun(state, action) {
            const key = action.payload.filtersKey;
            const task = {...state[action.payload.type].initiatedTask[key], ...action.payload};
            delete state[action.payload.type].initiatedTask[key];
            state[action.payload.type].ongoingTask[key] = task;
        },
        markAsyncTaskAsAborted(state, action) {
            const key = action.payload.filtersKey;
            const task = {...state[action.payload.type].initiatedTask[key], ...action.payload};
            delete state[action.payload.type].initiatedTask[key];
            state[action.payload.type].archivedTask[key] = task;
        },
        markAsyncTaskAsCompleted(state, action){
            const taskFound =  Object.values(state[action.payload.type].ongoingTask).find(t => action.payload.taskId === t.taskId);
            if(taskFound) {
                const key = taskFound.filtersKey;
                const task = {...taskFound, ...action.payload};
                delete state[action.payload.type].ongoingTask[key];
                state[action.payload.type].completedTask[key] = task;
            }
        },
        archiveAsyncTask(state, action){
            const key = action.payload.filtersKey;
            const task = {...state[action.payload.type].completedTask[key], ...action.payload};
            delete state[action.payload.type].completedTask[key];
            state[action.payload.type].archivedTask[key] = task;
        },
        removeAsyncTask(state, action) {
            const key = action.payload.filtersKey;
            delete state[action.payload.type].archivedTask[key];
        }
    }
});

export { actions as asyncServerTasksActions };
export { reducer as asyncServerTasksReducer };
