import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    drawer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    drawerPinned: {
        position: 'relative',
    },
    drawerUnpinned: {
        height: `calc(100vh - ${theme.fixedSize.toolbar.height})`,
        right: 0,
        position: 'absolute',
        backgroundColor: theme.palette.primary.main,
    },
    drawerOpen: {
        minWidth: 400,
        maxWidth: 400,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        width: 0,        
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    openDrawerArea: {
        height: `calc(100vh - ${theme.fixedSize.toolbar.height})`,
        position: 'absolute',
        width: theme.spacing(7) + 1,
        right: 0, 
        backgroundColor: 'blue',
    },
}));

const Drawer = ({ children, pinned, open, setOpen }) => {
    const { classes, cx } = useStyles();

    return (
        <>
            <div
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => {
                    if(!pinned){
                        setOpen(false);
                    }
                }}
                className={cx(classes.drawer, pinned ? classes.drawerPinned : classes.drawerUnpinned, open ? classes.drawerOpen : classes.drawerClose)}
            >
                {children}
            </div>
        </>
    );
};

export default Drawer;