import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import ServerPage from '../components/admin/ServerPage';
import CompanyPage from '../components/admin/company/CompanyPage';
import UsersPage from '../components/admin/user/UsersPage';
import UserPage from '../components/admin/user/UserPage';
import { AdminEnabled } from '../authorization';
import { UserAndManagerEnabled } from '../authorization';
import { ManagerAndAdminEnabled } from '../authorization';
import { ManagerEnabled } from '../authorization';

const AdminRoutes = () => {
    const ServerPageForAdmin = AdminEnabled(ServerPage);
    const CompanyPageForUserAndManager = UserAndManagerEnabled(CompanyPage);
    const UsersPageForManagerAndAdmin = ManagerAndAdminEnabled(UsersPage);
    const UserPageForManager = ManagerEnabled(UserPage);    

    return (
        <Routes>
            <Route exact path="server" element={<ServerPageForAdmin/>}/>
            <Route exact path="company">
                <Route exact path="" element={<CompanyPageForUserAndManager/>}/>
                <Route exact path=":id" element={<CompanyPageForUserAndManager/>}/>
            </Route>
            <Route exact path="users" element={<UsersPageForManagerAndAdmin/>}/>
            <Route exact path="user">
                <Route exact path="" element={<UserPageForManager/>}/>
                <Route exact path=":id" element={<UserPageForManager/>}/>
            </Route>
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    )
}

export default AdminRoutes;