import React, { useContext, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import { AuthenticationContext } from 'context/AuthenticationContext';
import useCacheBuster from 'hooks/useCacheBuster';
import UpdateBanner from './UpdateBanner';
import ApplicationBar from './ApplicationBar';
import NavigationMenu from './NavigationMenu';

const useStyles = makeStyles()(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    mainPart: {
        display: 'flex',
        flexDirection: 'row',
    },
    appBar: {
        height: theme.fixedSize.toolbar.height,
        width: '100%',
    },
    content: {
        flex: 1,
        overflowY: 'auto',
        scrollBehavior: 'smooth',
    },
}));

const LoggedInPage = ({ children }) => {
    const [drawerOpen, setDrawerOpen] = useState(true);
    const { classes } = useStyles();
    const {adminEnabled, managerEnabled, user} = useSelector(state => ({
        adminEnabled: state.session.user && state.session.user.administrator,
        managerEnabled: state.session.user && (state.session.user.userLimit > 0 || state.session.user.userLimit === -1),
        user: state.session.user
    }), (prev, next) => prev.user && next.user && prev.adminEnabled === next.adminEnabled && prev.managerEnabled === next.managerEnabled && prev.user.id === next.user.id);    
    const {loading, isLatestVersion, refreshCacheAndReload} = useCacheBuster();
    const theme = useTheme();
    const { handleLogout } = useContext(AuthenticationContext);

    const toggleDrawer = () => {setDrawerOpen(!drawerOpen)};

    const renderNavigationMenu = () => {
        return (
            <NavigationMenu 
                drawerOpen={drawerOpen}
                adminEnabled={adminEnabled}
                managerEnabled={managerEnabled}
                user={user}
                loading={loading}
                isLatestVersion={isLatestVersion}
            />
        );
    }

    return (
        <>
            <div className={classes.root}>
                <div style={{position: 'fixed', zIndex: theme.zIndex.appBar, width: '100%'}}>
                    {!loading && !isLatestVersion &&
                        <UpdateBanner action={refreshCacheAndReload}/>                      
                    }
                    <ApplicationBar  
                        adminEnabled={adminEnabled}
                        user={user}
                        toggleDrawer={toggleDrawer} 
                        handleLogout={handleLogout}
                    />
                </div>
                <main 
                    className={classes.mainPart} 
                    style={{
                        height: !loading && !isLatestVersion 
                            ? `calc(100vh - ${theme.fixedSize.toolbar.height} - ${theme.fixedSize.updateBanner.height})` 
                            : `calc(100vh - ${theme.fixedSize.toolbar.height})`,
                        marginTop: !loading && !isLatestVersion
                            ? `calc(${theme.fixedSize.toolbar.height} + ${theme.fixedSize.updateBanner.height})`
                            : theme.fixedSize.toolbar.height,
                    }}
                >
                    { renderNavigationMenu() }                    
                    <div className={classes.content}>
                        {children}
                    </div>
                </main>
            </div>
        </>
    );
}

export default LoggedInPage;