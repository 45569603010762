import { useEffect } from "react";
import _ from "lodash";

const useValidateField = (formik, path, validationSchema) => {
    useEffect(() => {
        validationSchema.validateAt(path, formik.values)
            .then(response => {                
                formik.setFieldError(path, undefined);
            })
            .catch(yupError => {
                formik.setFieldError(path, yupError.message);
            });
    }, [formik.values[path]]); 
};

export default useValidateField;