import React from 'react';
import { Tooltip } from '@mui/material';

import useTranslation from 'hooks/useTranslation';
import { ReactComponent as NetworkIcon } from 'resources/network.svg';
import { DeviceStatus } from 'utils/constant';
import { useGlobalStyles } from 'context/StylesContext';

 const SignalStatus = ({ device }) => {
    const { t } = useTranslation();
    const { theme, classes } = useGlobalStyles();

    return device.status === DeviceStatus.ONLINE
        ? <Tooltip placement="top" title={`${device.name} ${t('deviceIsOnlineMessage')}`}><NetworkIcon fill={theme.palette.on.main} stroke={theme.palette.on.main} className={classes.iconStatus} /></Tooltip>
        : <Tooltip placement="top" title={`${device.name} ${t('deviceIsOfflineMessage')}`}><NetworkIcon fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.iconStatus} /></Tooltip>;
};

export default SignalStatus;