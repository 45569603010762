import React, { useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { MenuItem, TextField } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import { Period } from 'utils/constant';

const PeriodSelector = ({ period, setPeriod, from, setFrom, to, setTo, rest, maxDuration }) => {
    const { t } = useTranslation();

    useEffect(() => {
        switch (period) {
            case Period.TODAY:
                setFrom(moment().startOf('day'));
                setTo(moment().endOf('day'));
                break;
            case Period.YESTERDAY:
                setFrom(moment().subtract(1, 'day').startOf('day'));
                setTo(moment().subtract(1, 'day').endOf('day'));
                break;
            case Period.THIS_WEEK:
                setFrom(moment().startOf('isoweek'));
                setTo(moment().endOf('isoweek'));
                break;
            case Period.PREVIOUS_WEEK:
                setFrom(moment().subtract(1, 'week').startOf('isoweek'));
                setTo(moment().subtract(1, 'week').endOf('isoweek'));
                break;
            case Period.THIS_MONTH:
                setFrom(moment().startOf('month'));
                setTo(moment().endOf('month'));
                break;
            case Period.PREVIOUS_MONTH:
                setFrom(moment().subtract(1, 'month').startOf('month'));
                setTo(moment().subtract(1, 'month').endOf('month'));
                break;
            default:
                break;
        }
    }, [period]);

    const handleChangeFrom = (newFrom) => {
        if (newFrom._isValid) {
            if (maxDuration && moment.duration(to.diff(newFrom)) > maxDuration) {
                setTo(moment(newFrom).add(maxDuration));
            }
            setFrom(newFrom);
        }
    };

    const handleChangeTo = (newTo) => {
        if (newTo._isValid) {
            if (maxDuration && moment.duration(newTo.diff(from)) > maxDuration) {
                setFrom(moment(newTo).subtract(maxDuration));
            }
            setTo(newTo);
        }
    };

    return (
        <>
            {/* <FormControl variant="outlined" margin="normal" fullWidth>
                <InputLabel>{t('reportPeriod')}</InputLabel>
                <Select value={period} onChange={(e) => setPeriod(e.target.value)}>
                    <MenuItem value="today">{t('reportToday')}</MenuItem>
                    <MenuItem value="yesterday">{t('reportYesterday')}</MenuItem>
                    <MenuItem value="thisWeek">{t('reportThisWeek')}</MenuItem>
                    <MenuItem value="previousWeek">{t('reportPreviousWeek')}</MenuItem>
                    <MenuItem value="thisMonth">{t('reportThisMonth')}</MenuItem>
                    <MenuItem value="previousMonth">{t('reportPreviousMonth')}</MenuItem>
                    <MenuItem value="custom">{t('reportCustom')}</MenuItem>
                </Select>
            </FormControl> */}
            <TextField 
                value={period} 
                onChange={(e) => setPeriod(e.target.value)}
                select variant="outlined" 
                label={t('reportPeriod')}
                size="small"
                {...rest}
            >
                <MenuItem value="today">{t('reportToday')}</MenuItem>
                <MenuItem value="yesterday">{t('reportYesterday')}</MenuItem>
                {(!maxDuration || maxDuration >= moment.duration(1, 'week')) && <MenuItem value="thisWeek">{t('reportThisWeek')}</MenuItem>}
                {(!maxDuration || maxDuration >= moment.duration(1, 'week')) && <MenuItem value="previousWeek">{t('reportPreviousWeek')}</MenuItem>}
                {(!maxDuration || maxDuration >= moment.duration(1, 'month')) && <MenuItem value="thisMonth">{t('reportThisMonth')}</MenuItem>}
                {(!maxDuration || maxDuration >= moment.duration(1, 'month')) && <MenuItem value="previousMonth">{t('reportPreviousMonth')}</MenuItem>}
                <MenuItem value="custom">{t('reportCustom')}</MenuItem>
            </TextField>
            {period === 'custom' && (
                <TextField
                    variant="outlined"
                    label={t('reportFrom')}
                    type="datetime-local"
                    value={from.format(moment.HTML5_FMT.DATETIME_LOCAL)}
                    onChange={e => handleChangeFrom(moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL))}
                    fullWidth 
                />
            )}
            {period === 'custom' && (
                <TextField
                    variant="outlined"
                    label={t('reportTo')}
                    type="datetime-local"
                    value={to.format(moment.HTML5_FMT.DATETIME_LOCAL)}
                    onChange={e => handleChangeTo(moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL))}
                    fullWidth 
                />
            )}
        </>
    );
};

export default PeriodSelector;