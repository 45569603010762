import React, { useEffect, useMemo, useCallback } from 'react';
import { pdf } from '@react-pdf/renderer';
import { useTable, useExpanded, usePagination, useSortBy } from 'react-table';
import { downloadFile } from 'utils/downloadFile';
import useTranslation from 'hooks/useTranslation';
import { useAttributePreference, usePreference } from 'hooks/usePreferences';
import useFormat from 'hooks/useFormat';
import { AsyncServerTasksType, ReportFormat } from 'utils/constant';
import useReport from 'hooks/useReport';
import { magellanV0 } from 'api/magellan';
import { Text, View } from '@react-pdf/renderer';
import TemplatePdf from '../templates/TemplatePdf';
import TablePdf from '../templates/TablePdf';
import { useGlobalStyles } from 'context/StylesContext';
import useDeviceReport from 'hooks/useDeviceReport';
import { parseAddress } from 'utils/address';
import { getColumns } from '.';

const useRouteReport = () => {
    const { t } = useTranslation();
    const { formatAddress, formatAddressOneLine, formatDistance, formatSpeed, formatBoolean, formatDate, formatCoordinate, formatDeviceCategory, } = useFormat();
    const { classes: globalClasses } = useGlobalStyles();
    const distanceUnit = useAttributePreference('distanceUnit');
    const speedUnit = useAttributePreference('speedUnit');
    const coordinateFormat = usePreference('coordinateFormat');
    const availableReportActions = [ReportFormat.JSON, ReportFormat.EXCEL, ReportFormat.MAIL];
    const { deviceId, setDeviceId, period, setPeriod, from, setFrom, to, setTo, items, setItems, devices, query, pdfReportFilters, getFiltersKey, ...restDeviceReport } = useDeviceReport({availableReportActions});
    const { loading, setLoading, pdfData, setPdfData, companyName, companyAddress, ...restReport } = useReport({
        api: magellanV0, 
        report: 'reports/route', 
        taskType: AsyncServerTasksType.ROUTE_REPORT_EMAIL,
        query,
        getFiltersKey,
        setItems,
    });
    const columns = useMemo(() => getColumns({ speedUnit, coordinateFormat, t, globalClasses, formatDate, formatSpeed, formatCoordinate, formatAddress, formatBoolean }), []);
    const data = useMemo(() => items, [JSON.stringify(items)]);const { 
        toggleAllRowsExpanded,
        visibleColumns, 
        ...tableRest
    } = useTable(
        { 
            columns, 
            data, 
            autoResetExpanded: false, 
            autoResetSortBy: false,
            getRowId: useCallback(row => row.id, []),
            initialState: {
                pageSize: 100,
                pageIndex: 0,
            }
        }, 
        useSortBy,
        useExpanded,
        usePagination,
    );

    const pdfReportColumns = React.useMemo(() => [
        {
            accessor: 'itemFixTime',
            Header: t('positionFixTime'), 
            Cell: ({ cell: { value } }) => formatDate(value),
            cellWidth: '12%'
        },
        {
            accessor: 'itemDriverName',
            Header: t('driverName'), 
            Cell: ({ cell: { value } }) => formatDate(value),
            cellWidth: '10%'
        },
        {
            accessor: 'itemLatitude',
            Header: t('positionLatitude'),
            Cell: ({ cell: { value } }) => formatCoordinate('latitude', value, coordinateFormat),
            cellWidth: '10%',
        },
        {
            accessor: 'itemLongitude',
            Header: t('positionLongitude'),
            Cell: ({ cell: { value } }) => formatCoordinate('longitude', value, coordinateFormat),
            cellWidth: '10%',
        },
        {
            accessor: 'itemSpeed',
            Header: t('positionSpeed'),
            Cell: ({ cell: { value } }) => formatSpeed(value, speedUnit),
            cellWidth: '11%',
        },
        {
            accessor: 'itemAddress',
            Header: t('sharedAddress'), 
            Cell: ({ cell: { value } }) => <View>{Object.values(formatAddressOneLine(parseAddress(value))).map(l => l && <Text>{l}</Text>)}</View>,
            cellWidth: '22%'
        },
        {
            accessor: 'itemIgnition',
            Header: t('positionIgnition'),
            Cell: ({ cell: { value } }) => t(formatBoolean(value)),
            cellWidth: '11%',
        },
        {
            accessor: 'itemTotalDistance',
            Header: t('deviceTotalDistance'), 
            Cell: ({ cell: { value } }) => formatDistance(value, distanceUnit),
            cellWidth: '12%'
        },
    ], []);

    const pdfDataFormatted = useMemo(() => pdfData.map(item => (
        {
            itemFixTime: item.fixTime,
            itemDriverName: item.driverName,
            itemLatitude: item.latitude,
            itemLongitude: item.longitude,
            itemSpeed: item.speed,
            itemAddress: item.address,
            itemIgnition: item.attributes.ignition,
            itemTotalDistance: item.attributes.totalDistance,
        }
    ), [pdfData]));
    
    const { rows: pdfRows, headerGroups, prepareRow } = useTable({ columns: pdfReportColumns, data: pdfDataFormatted });
    
    const renderTemplate = () => {
        return (
            <TemplatePdf 
                reportTitle={t('reportRouteTitlePdf')}
                companyName={companyName}
                companyAddress={formatAddressOneLine(companyAddress)}
                filters={pdfReportFilters} 
                isPortrait={false}
            >
                <TablePdf
                    headerGroups={headerGroups} 
                    rows={pdfRows} 
                    prepareRow={prepareRow}
                />
            </TemplatePdf>
        );
    } 

    useEffect(() => {
        if(pdfRows && pdfRows.length > 0) {
            const doc = renderTemplate();
            const file = pdf(doc);
            file.toBlob().then(blob => {
                downloadFile(blob, 'routeReport.pdf');
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            })
            setPdfData([]);
        }
    }, [JSON.stringify(pdfRows)]); 
    
    return { 
        t, 
        formatDistance, 
        formatAddress, 
        formatBoolean, 
        formatCoordinate, 
        formatDate, 
        formatSpeed, 
        formatDeviceCategory,
        globalClasses, 
        distanceUnit, 
        speedUnit, 
        coordinateFormat, 
        items, 
        loading, 
        deviceId, 
        setDeviceId, 
        period, 
        setPeriod, 
        from, 
        setFrom, 
        to, 
        setTo, 
        devices, 
        query, 
        pdfReportFilters, 
        getFiltersKey, 
        availableReportActions, 
        ...restDeviceReport,
        ...restReport,
        ...tableRest
     };
};

export default useRouteReport;