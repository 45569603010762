import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from 'lodash';

import { magellanV1 } from "api/magellan";

export const fetchItems = createAsyncThunk(
    'checkings/fetch',
    async({ setAlertMessage, dataLoadingErrorAlertMessage },) => {
        let from = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
        let to = new Date(new Date().setHours(23, 59, 59, 999)).toISOString();
        try {
            let response =  await magellanV1.get('/api/checkings', { params: { from, to }});
            return response.data;
        } catch(error) {
            setAlertMessage(dataLoadingErrorAlertMessage);
        }
    }
);