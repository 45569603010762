import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
    name: 'notifications',
    initialState: {
        items: {},
        newItems: [],
        selectedId: null
    },
    reducers: {
        refresh(state, action) {
            state.items = {};
            action.payload.forEach(item => state.items[item['id']] = item);
        },
        select(state, action) {
            state.selectedId = action.payload;
        },
        deselect(state, action) {
            state.selectedId = null;
        },
        removeFromHistory(state, action) {
            delete state.items[action.payload];
        },
        addNewNotifications(state, action) {
            state.newItems.push(action.payload);
        },
        addToHistory(state, action){
            action.payload.forEach(item => state.items[item['id']] = item);
        }
    }
});

export { actions as notificationsActions };
export { reducer as notificationsReducer };
