import { useEffect, useContext } from 'react';

import { MapContext } from 'context/MapContext';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { ThemeProvider, useTheme } from '@mui/material';
import { StylesProvider } from 'context/StylesContext';
import { store } from 'store';
import { makeStyles } from 'tss-react/mui';
import { changeCursorToNormal, changeCursorToPointer } from '../map/mapUtil';
import { extractCoordinates, mapStopPositionsToPointFeatures } from '.';
import useMapboxPopup from '../map/useMapboxPopup';

const useStyles = makeStyles()(theme => ({
    popup: {
        width: 200,
    },
}));

const StopsMap = ({ positions, renderPopupContent }) => {
    const sourceId = 'stopsSource';
    const stopsLayerId = 'stopsLayer';
    const { classes } = useStyles();
    const { map, mapReady } = useContext(MapContext);
    const theme = useTheme();
    const { displayPopup, onEnterPopup, onLeavePopup, popup, popupTimeoutId } = useMapboxPopup({ map });

    const onStopPositionEnter = async event => {
        if(!popup.current || (event?.features?.[0] && popup.current?.id !== event.features?.[0]?.properties?.position?.id)) {
            if(popup.current) {
                clearTimeout(popupTimeoutId.current);
                await popup.current.remove();
            }
            const position = JSON.parse(event.features?.[0]?.properties?.position);
            const stopPopup = () => (
                <div onMouseLeave={onLeavePopup} onMouseEnter={onEnterPopup} className={classes.popup}>
                    {renderPopupContent({ 
                        position,
                    })}
                </div>
            );
            const coordinates = extractCoordinates(position)
            displayPopup(coordinates, stopPopup, { anchor: 'bottom' });
            popup.current.id = position?.id;
        } else {
            clearTimeout(popupTimeoutId.current);
        }
    };

    useEffect(() => {
        if (mapReady) {
            map?.addSource(sourceId, {
                'type': 'geojson',
                'data': {
                    type: 'FeatureCollection',
                    features: [],
                },
            });
            map?.addLayer({
                'source': sourceId,
                'id': stopsLayerId,
                'type': 'circle',
                'paint': {
                    'circle-stroke-color': theme.palette.secondary.main,
                    'circle-stroke-width': 2,
                    'circle-color': theme.palette.primary.main,
                },
            });
            
            map.on('mouseenter', stopsLayerId, changeCursorToPointer);
            map.on('mouseleave', stopsLayerId, changeCursorToNormal);
            map.on('mouseenter', stopsLayerId, onStopPositionEnter);
            map.on('mouseleave', stopsLayerId, onLeavePopup);

            return () => {
                map.off('mouseenter', stopsLayerId, changeCursorToPointer);
                map.off('mouseleave', stopsLayerId, changeCursorToNormal);
                map.off('mouseenter', stopsLayerId, onStopPositionEnter);
                map.off('mouseleave', stopsLayerId, onLeavePopup);
                if(map?.getLayer(stopsLayerId)){
                    map?.removeLayer(stopsLayerId);
                }
                if(map?.getSource(sourceId) !== undefined){
                    map?.removeSource(sourceId);
                }        
            };
        }
    }, [mapReady]);

    useEffect(() => {
        if (mapReady && map?.getSource(sourceId) && positions) {
            map?.getSource(sourceId)?.setData({
                type: 'FeatureCollection',
                features: mapStopPositionsToPointFeatures(positions),
            });
        }
        
    }, [mapReady, JSON.stringify(positions)]);

    return null;
}

export default StopsMap;
