import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material';
import { BatteryAlert, CarCrash, Close, Power, PowerOff, PrecisionManufacturing } from '@mui/icons-material';
import { LocationOn } from '@mui/icons-material';
import SpeedIcon from '@mui/icons-material/Speed';

import { AlarmType, DeviceType, EventType } from 'utils/constant';
import useFormatNotificationTitle from 'hooks/useFormatNotificationTitle';
import { ReactComponent as EngineIcon } from 'resources/circled_engine.svg'; 
import { ReactComponent as CarIcon } from 'resources/car.svg';
import { ReactComponent as CarInactiveIcon } from 'resources/car_inactive.svg';
import { ReactComponent as SmartphoneIcon } from 'resources/smartphone.svg';
import { ReactComponent as ConstructionMachineIcon } from 'resources/construction_machine.svg';
import { ReactComponent as NetworkIcon } from 'resources/network.svg';
import { ReactComponent as ToolIcon } from 'resources/tool.svg';
import { ReactComponent as TrailerIcon } from 'resources/trailer.svg';
import { devicesActions } from 'store';
import Address from '../../common/Address';
import TimeCounter from '../../common/TimeCounter';
import { useGlobalStyles } from 'context/StylesContext';
import useFormat from 'hooks/useFormat';
import { parseAddress } from 'utils/address';

const useStyles = makeStyles()(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 16,
        '&:hover': {
            backgroundColor: theme.palette.selected.main,
        },
        cursor: 'pointer',
    },
    selectedItem:{
        backgroundColor: theme.palette.selected.main,
    },
    icon: {
        width: 20,
        height: 20,
    },
    infoContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 10,
        margin: '0px 5%',
    },
    titleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    highlighted: {
        color: theme.palette.secondary.main,
        fontStyle: 'normal',
    }
}));

const EventItem = ({ event, isSelected, onClose = () => {}, selectItem = () => {}, deselectItem = () => {} }) => {
    const { classes, cx } = useStyles();
    const { classes: globalClasses } = useGlobalStyles();
    const theme = useTheme(); 
    const dispatch = useDispatch();
    const { formatTitle } = useFormatNotificationTitle();
    const { formatEventType } = useFormat();

    const renderIcon = () => {
        switch(event.type){
            case EventType.DEVICE_ONLINE:
                return <NetworkIcon fill={theme.palette.on.main} stroke={theme.palette.on.main} className={classes.icon}/>;
            case EventType.DEVICE_OFFLINE:
                return <NetworkIcon fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.icon}/>;                        
            case EventType.DEVICE_UNKNOWN:
                return<NetworkIcon fill={theme.palette.unknown.main} stroke={theme.palette.unknown.main} className={classes.icon}/>;
            case EventType.DEVICE_MOVING:
                switch(event.tracker.category){
                    case DeviceType.CONSTRUCTION_MACHINE:
                        return <ConstructionMachineIcon fill={theme.palette.on.main} stroke={theme.palette.on.main} className={classes.icon}/>;
                    case DeviceType.VEHICLE:
                        return <CarIcon fill={theme.palette.on.main} stroke={theme.palette.on.main} className={classes.icon}/>;
                    case DeviceType.SMARTPHONE:
                        return <SmartphoneIcon fill={theme.palette.on.main} stroke={theme.palette.on.main} className={classes.icon}/>;
                    default: 
                        return null;
                }
            case EventType.DEVICE_STOPPED:
                switch(event.tracker.category){
                    case DeviceType.CONSTRUCTION_MACHINE:
                        return <ConstructionMachineIcon fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.icon}/>;
                    case DeviceType.VEHICLE:
                        return <CarIcon fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.icon}/>;
                    case DeviceType.SMARTPHONE:
                        return <SmartphoneIcon fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.icon}/>;
                    default: 
                        return null;
                }
            case EventType.GEOFENCE_ENTER:
                return <LocationOn fill={theme.palette.on.main} stroke={theme.palette.on.main} className={classes.icon}/>;
            case EventType.GEOFENCE_EXIT:
                return <LocationOn fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.icon}/>;      
            case EventType.IGNITION_ON:
                return<EngineIcon fill={theme.palette.on.main} stroke={theme.palette.on.main} className={classes.icon}/>;
            case EventType.IGNITION_OFF:
                return <EngineIcon fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.icon}/>;
            case EventType.DEVICE_OVERSPEED:
                return <SpeedIcon fill={theme.palette.on.main} stroke={theme.palette.on.main} className={classes.icon}/>;
            case EventType.DEVICE_INACTIVE:
                 return <CarInactiveIcon fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.icon}/>;
            case EventType.BEACON_IN:
                switch(event.beacon.category) {
                    case DeviceType.TRAILER: 
                        return <TrailerIcon fill={theme.palette.on.main} stroke={theme.palette.on.main} className={classes.icon} />;
                    case DeviceType.TOOL:
                        return <ToolIcon fill={theme.palette.on.main} stroke={theme.palette.on.main} className={classes.icon} />;
                    default: 
                        return null;
                }
            case EventType.BEACON_OUT:
                switch(event.beacon.category) {
                    case DeviceType.TRAILER: 
                        return <TrailerIcon fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.icon} />;
                    case DeviceType.TOOL:
                        return <ToolIcon fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.icon} />;
                    default: 
                        return null;
                }
            case EventType.ALARM:
                switch(event.attributes.alarm) {
                    case AlarmType.unplugged.key:
                        return <PowerOff fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.icon}/>;
                    case AlarmType.plugged.key:
                        return <Power fill={theme.palette.on.main} stroke={theme.palette.on.main} className={classes.icon}/>;
                    case AlarmType.lowBattery.key:
                        return <BatteryAlert fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.icon}/>;
                    case AlarmType.criticalBattery.key:
                        return <BatteryAlert fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.icon}/>;
                    case AlarmType.tow.key:
                        return <PrecisionManufacturing fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.icon}/>;
                    case AlarmType.accident.key:
                        return <CarCrash fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.icon}/>;
                    default: 
                        return null;
                }
            default: 
                return null;
        }
    };

    const handleItemClicked = () => {
        if(isSelected) {
            dispatch(devicesActions.deselect());
            deselectItem()
        } else {
            dispatch(devicesActions.select(event.deviceId));
            selectItem(event.id);
        }
    }

    const handleCloseItem = e => {
        e.stopPropagation();
        onClose();
    }

    return (
        <div 
            className={isSelected ? cx(classes.container, classes.selectedItem) : classes.container} 
            onClick={handleItemClicked} 
        >
            {renderIcon()}
            <div className={classes.infoContainer}>
                <div className={classes.titleContainer}>
                    <span>
                        <em className={classes.highlighted}>{event.type === EventType.BEACON_IN || event.type === EventType.BEACON_OUT ? event.beacon.name : event.tracker.name} </em>
                        {formatTitle(event)}
                        {event.type === EventType.GEOFENCE_ENTER || event.type === EventType.GEOFENCE_EXIT ? <em className={classes.highlighted}> {event.concernedGeofence.name}</em> : null}
                        {event.type === EventType.BEACON_IN || event.type === EventType.BEACON_OUT ? ` ${event.tracker.name}` : null}
                    </span>
                    <span className={globalClasses.timeCounter}><TimeCounter time={new Date(event.eventTime)}/></span> 
                </div>
                <Address placement="end" address={parseAddress(event?.position?.address)}/>                
            </div>
            <div onClick={handleCloseItem}>
                <Close className={classes.close}/>
            </div>
        </div>
    );
}

export default EventItem;