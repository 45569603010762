import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Card } from '@mui/material';

import useTranslation from 'hooks/useTranslation';
import useCacheBuster from 'hooks/useCacheBuster';
import MagellanSigle from 'resources/magellan_sigle.png';
import UpdateBanner from '../common/UpdateBanner';
import { useGlobalStyles } from 'context/StylesContext';
import { useColorMode } from 'context/ColorModeContext';

const useStyles = makeStyles()(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        height: '100vh',
        flexDirection: 'column',
    },
    main: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1
    },
    leftBanner: {
        display: 'flex',
        flex: 7,
        background: `linear-gradient(to right, ${theme.palette.quaternary.main}, ${theme.palette.tertiary.main})`,
        justifyContent: 'center',
        alignItems: 'center',
    },
    restOfThePage: {
        flex: 18,
    },
    logo: {
        width: '68%',
    },
    filigrane: {
        position: 'fixed',
        right: theme.spacing(5),
        bottom: theme.spacing(5),
        height: '50%',
        opacity: '0.045',
        objectFit: 'contains',
    },
}));

const AuthenticationTemplate = ({ children }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const { classes: globalClasses } = useGlobalStyles();
    const { MagellanLogo } = useColorMode();
    const {loading, isLatestVersion, refreshCacheAndReload} = useCacheBuster();

    return (
        <div className={classes.root}>
            {!loading && !isLatestVersion &&
                <UpdateBanner action={refreshCacheAndReload} />
            }
            <main className={classes.main}>
                <div className={classes.leftBanner}>
                    <img 
                        alt={t('magellanLogo')} 
                        className ={classes.logo} 
                        src={MagellanLogo}
                    />
                </div>
                <div className={classes.restOfThePage}>
                    <img 
                        alt={t('magellanWatermark')}
                        className={classes.filigrane}
                        src={MagellanSigle}
                    />
                </div>
                <Card className={globalClasses.authCard}>
                    {children}
                </Card>
            </main>
        </div>
    );
};

export default AuthenticationTemplate;