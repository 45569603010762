import { useLayoutEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import circle from '@turf/circle';

import { MapContext } from 'context/MapContext';

const AccuracyMap = () => {
    const id = 'accuracy';
    const { map, mapReady } = useContext(MapContext);

    const positions = useSelector(state => ({
        type: 'FeatureCollection',
        features: Object.values(state.positions.items).filter(position => position.accuracy > 0).map(position =>
            circle([position.longitude, position.latitude], position.accuracy * 0.001)
        ),
    }));

    useLayoutEffect(() => {
        if(mapReady) {
            map.addSource(id, {
                'type': 'geojson',
                'data': {
                    type: 'FeatureCollection',
                    features: []
                }
            });
            map.addLayer({
                'source': id,
                'id': id,
                'type': 'fill',
                'filter': [
                    'all',
                    ['==', '$type', 'Polygon'],
                ],
                'paint': {
                    'fill-color':'#3bb2d0',
                    'fill-outline-color':'#3bb2d0',
                    'fill-opacity':0.25,
                },
            });

            return () => {
                if(map.getLayer(id)){
                    map.removeLayer(id);
                }
                
                if(map.getSource(id)){
                    map.removeSource(id);
                };
            };
        }
    }, [mapReady]);

    useLayoutEffect(() => {
        if(mapReady && map.getSource(id)){
            map.getSource(id).setData(positions);
        }
    }, [mapReady, positions]);

    return null;
}

export default AccuracyMap;
