import nominatim from "api/nominatim";
import { useState, useEffect } from "react";
import useAlertMessage from "./useAlertMessage";
import { useAsyncDebounce } from "react-table";

const useGeocoding = ({ street, locality, houseNumber, setCoordinates }) => {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const { setAlertMessage, addressNotFoundWarningAlertMessage } = useAlertMessage();

    const getCoordinatesByAddress = useAsyncDebounce(async () => {
        if (street && locality && houseNumber) {
            setLoading(true);
            const response = await nominatim.get(`/search?street=${houseNumber} ${street}&city=${locality}`);
            if (response.status === 200) {
                const data = response.data;
                if(data && data[0] && data[0].lat && data[0].lon){
                    setCoordinates({ longitude: Number(data[0].lon), latitude: Number(data[0].lat) });
                } else {
                    setAlertMessage(addressNotFoundWarningAlertMessage);
                }
            } else {
                setAlertMessage(addressNotFoundWarningAlertMessage);
            }
            setLoading(false);
        }
    }, 1000);
    
    const getSuggestionsForQuery = useAsyncDebounce(async () => {
        if (query) {
            setLoading(true);
            const response = await nominatim.get(`/search?q=${query}&addressdetails=true`);
            if (response.status === 200) {
                setSuggestions(response.data);
            }
            setLoading(false);
        }
    }, 1000);

    const onAddressChosen = (option) => {
        if(option && option.lat && option.lon){
            setCoordinates({ longitude: Number(option.lon), latitude: Number(option.lat) });
        } 
    };

    useEffect(() => {
        getCoordinatesByAddress();
    }, [street, locality, houseNumber]);

    useEffect(() => {
        if (query) {
            getSuggestionsForQuery();
        }
    }, [query]);


    return { 
        addressQuery: query, 
        setAddressQuery: setQuery,
        addressLoading: loading,
        suggestions,
        onAddressChosen,
    };
};

export default useGeocoding;

