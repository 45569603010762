import React from 'react';
import { Route, useParams } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import * as yup from 'yup';

import { MANAGER } from 'authorization/role';
import useTranslation from 'hooks/useTranslation';
import { DeviceType } from 'utils/constant';
import UpdateDevice from '../UpdateDevice';
import CreateDevice from '../CreateDevice';

const initialValues = {
    name: '',
    uniqueId: '',
    licensePlate: '',
    groupId: '',
    brand: '',
    model: '',
    fuelType: '',
    chassisNumber: '',
    emissionStandard: '',
    //maxSpeed: null,
    comment: '',
};

const VehiclePage = ({ role }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const type = DeviceType.VEHICLE;

    const validationSchema = yup.object().shape({
        name: yup.string()
            .trim()
            .required(t('validationRequired'))
            .max(128, `\${max} ${t('validationLengthMax')}`),
        uniqueId: yup.string()
            .trim()
                .required(t('validationRequired'))
                .max(128, `\${max} ${t('validationLengthMax')}`),
        licensePlate: yup.string()
            .trim()
            .max(16, `\${max} ${t('validationLengthMax')}`),
        brand: yup.string()
            .trim()
            .max(128, `\${max} ${t('validationLengthMax')}`),
        model: yup.string()
            .trim()
            .max(128, `\${max} ${t('validationLengthMax')}`),
        fuelType: yup.string()
            .trim()
            .max(32, `\${max} ${t('validationLengthMax')}`),
        chassisNumber: yup.string()
            .trim()
            .max(32, `\${max} ${t('validationLengthMax')}`),
        // maxSpeed: yup.number()
        //     .typeError(t('validationNumber'))
        //     .min(0, `\${min} ${t('validationMinValue')}`)
        //     .max(250, `\${max} ${t('validationMaxValue')}`)
        //     .transform((value, originalValue) => (typeof originalValue === 'string' && originalValue === '') ? null : value)
        //     .nullable(),
        comment: yup.string()
            .trim()
            .max(4000, `\${max} ${t('validationLengthMax')}`),
    });

    const filterDataForServer = item => {
        item.category = type;
        if(!item.groupId){
            delete item.groupId;
        }
        if(!item.emissionStandard){
            delete item.emissionStandard;
        }
        delete item.ownerIds;
        delete item.lastUpdate;
        delete item.positionId;
        delete item.detectedBy;
        return item;
    }

    if(role === MANAGER){
        return (id) 
            ? <UpdateDevice 
                id={ id } 
                filterDataForServer={filterDataForServer} 
                validationSchema={validationSchema}
                role={role} 
                deviceType={DeviceType.VEHICLE}
            /> 
            : <CreateDevice 
                filterDataForServer={filterDataForServer} 
                validationSchema={validationSchema}
                initialValues={initialValues}  
                deviceType={DeviceType.VEHICLE}
            />;
    }
    return (id) 
        ? <UpdateDevice 
            id={ id } 
            filterDataForServer={filterDataForServer} 
            validationSchema={validationSchema}
            role={role} 
            deviceType={DeviceType.VEHICLE}
        /> 
        : <Route element={<Navigate to="signage/vehicles" />}/>;
};

export default VehiclePage;