import React from 'react';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';

import { useGlobalStyles } from 'context/StylesContext';


const ZoomInAndOutMapControl = ({zoomIn, zoomOut}) => {
    const { classes, cx } = useGlobalStyles();

    return (
        <div className={classes.mapControlButtonBorder}>
            <div className={cx(classes.mapControlButton, classes.mapControlButtonBorderTopRadius)} onClick={zoomIn}>
                <Add className={classes.mapControlButtonIcon}/>
            </div>
            <div 
                className={cx(classes.mapControlButton, classes.mapControlButtonBorderBottomRadius, classes.mapControlButtonTopSeparator)}
                onClick={zoomOut}    
            >
                <Remove className={classes.mapControlButtonIcon}/>
            </div>
        </div>
    );
};

export default ZoomInAndOutMapControl;