import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTable } from 'react-table/dist/react-table.development';
import { pdf } from '@react-pdf/renderer';

import { downloadFile } from 'utils/downloadFile';
import useTranslation from 'hooks/useTranslation';
import { useAttributePreference } from 'hooks/usePreferences';
import useReport from 'hooks/useReport';
import { AsyncServerTasksType, ReportFormat } from 'utils/constant';
import useFormat from 'hooks/useFormat';
import { magellanV0 } from 'api/magellan';
import TemplatePdf from '../templates/TemplatePdf';
import TablePdf from '../templates/TablePdf';
import useDeviceReport from 'hooks/useDeviceReport';
import { useGlobalStyles } from 'context/StylesContext';
import { getColumns } from '.';
import { useExpanded, useSortBy } from 'react-table';

const useSummaryReport = () => {
    const { t } = useTranslation();
    const { formatDistance, formatDuration, formatDate, formatSpeed, formatAddressOneLine, formatDeviceCategory, } = useFormat();
    const { classes: globalClasses, cx } = useGlobalStyles();
    const distanceUnit = useAttributePreference('distanceUnit');
    const speedUnit = useAttributePreference('speedUnit');
    const availableReportActions = [ReportFormat.JSON, ReportFormat.PDF, ReportFormat.EXCEL, ReportFormat.MAIL];
    const { deviceId, setDeviceId, period, setPeriod, from, setFrom, to, setTo, items, setItems, devices, query, pdfReportFilters, getFiltersKey, ...restDeviceReport } = useDeviceReport({ availableReportActions });
    const [daily, setDaily] = useState(false);
    const { loading, setLoading, pdfData, setPdfData, companyName, companyAddress, ...restReport } = useReport({
        api: magellanV0, 
        report: 'reports/summary', 
        taskType: AsyncServerTasksType.SUMMARY_REPORT_EMAIL, 
        query,
        addExtraQueryParams: (query) => { query.append('daily', daily);},
        getFiltersKey,
        setItems,
    });
    const columns = useMemo(() => getColumns({ t, globalClasses, cx, distanceUnit, speedUnit, formatDate, formatDistance, formatSpeed, formatDuration }), []);
    const data = useMemo(() => items, [JSON.stringify(items)]);
    const { 
        toggleAllRowsExpanded,
        visibleColumns,  
        ...tableRest
    } = useTable(
        { 
            columns, 
            data, 
            autoResetExpanded: false, 
            autoResetSortBy: false,
            getRowId: useCallback(row => row.startTime, []),
        }, 
        useSortBy,
        useExpanded,
    );

    const pdfReportColumns = React.useMemo(() => [
        {
            accessor: 'itemStartTime',
            Header: t('reportStartDate'), 
            Cell: ({ cell: { value } }) => formatDate(value),
            cellWidth: '12.125%'
        },
        {
            accessor: 'itemDistance',
            Header: t('sharedDistance'),
            Cell: ({ cell: { value } }) => formatDistance(value, distanceUnit),
            cellWidth: '12.125%',
        },
        {
            accessor: 'itemStartOdometer',
            Header: t('reportStartOdometer'),
            Cell: ({ cell: { value } }) => formatDistance(value, distanceUnit),
            cellWidth: '12.125%',
        },
        {
            accessor: 'itemEndOdometer',
            Header: t('reportEndOdometer'),
            Cell: ({ cell: { value } }) => formatDistance(value, distanceUnit),
            cellWidth: '12.125%',
        },
        {
            accessor: 'itemAverageSpeed',
            Header: t('reportAverageSpeed'), 
            Cell: ({ cell: { value } }) => formatSpeed(value, speedUnit),
            cellWidth: '12.125%'
        },
        {
            accessor: 'itemMaxSpeed',
            Header: t('reportMaximumSpeed'),
            Cell: ({ cell: { value } }) => formatSpeed(value, speedUnit),
            cellWidth: '12.125%',
        },
        {
            accessor: 'itemEngineHours',
            Header: t('reportEngineHours'), 
            Cell: ({ cell: { value } }) => formatDuration(value),
            cellWidth: '12.125%'
        },
        {
            accessor: 'itemNbTrips',
            Header: t('reportNumberTrips'),
            Cell: ({ cell: { value } }) => value,
            cellWidth: '12.125%',
        },
    ], []);

    const pdfDataFormatted = useMemo(() => pdfData.map(item => (
        {
            itemStartTime: item.startTime,
            itemDistance: item.distance,
            itemStartOdometer: item.startOdometer,
            itemEndOdometer: item.endOdometer,
            itemAverageSpeed: item.averageSpeed,
            itemMaxSpeed: item.maxSpeed,
            itemEngineHours: item.engineHours,
            itemNbTrips: item.nbTrips,
        }
    ), [pdfData]));
    
    const { rows, headerGroups, prepareRow } = useTable({ columns: pdfReportColumns, data: pdfDataFormatted });
    
    const renderTemplate = () => {
        return (
            <TemplatePdf 
                reportTitle={t('reportSummaryTitlePdf')}
                companyName={companyName}
                companyAddress={formatAddressOneLine(companyAddress)}
                filters={pdfReportFilters} 
                isPortrait={false}
            >
                <TablePdf
                    headerGroups={headerGroups} 
                    rows={rows} 
                    prepareRow={prepareRow}
                />
            </TemplatePdf>
        );
    } 

    useEffect(() => {
        if(rows && rows.length > 0) {
            const doc = renderTemplate();
            const file = pdf(doc);
            file.toBlob().then(blob => {
                downloadFile(blob, 'summaryReport.pdf');
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            })
            setPdfData([]);
        }
    }, [JSON.stringify(rows)]); 
    
    return { 
        t, 
        formatDistance, 
        formatDuration, 
        formatDate, 
        formatSpeed, 
        formatDeviceCategory,
        globalClasses, 
        distanceUnit, 
        speedUnit, 
        items, 
        daily, 
        setDaily, 
        deviceId, 
        setDeviceId, 
        period, 
        setPeriod, 
        from, 
        setFrom, 
        to, 
        setTo, 
        devices, 
        query, 
        pdfReportFilters, 
        getFiltersKey, 
        availableReportActions, 
        ...restDeviceReport,
        ...restReport,
        ...tableRest
    };
}

export default useSummaryReport;