import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { magellanV1 } from 'api/magellan';
import useTranslation from 'hooks/useTranslation';
import useAlertMessage from 'hooks/useAlertMessage';
import EditCollectionView from '../../common/EditCollectionView';
import Address from '../../common/Address';
import Loading from '../../common/Loading';
import StateInfo from 'components/common/StateInfo';
import { LocationCity } from '@mui/icons-material';
import { useGlobalStyles } from 'context/StylesContext';
import None from 'components/common/None';

const useStyles = makeStyles()(theme => ({
    columnAction: {
        width: theme.spacing(1),
        padding: theme.spacing(0, 1),
    },
    contentContainer: {
        height: '75%',
    },
}));

const GeofencesView = ({ updateTimestamp, onMenuClick, displayAddButton }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const { classes: globalClasses, cx } = useGlobalStyles();
    const [loading, setLoading] = useState(true); 
    const [items, setItems] = useState([]);  
    const { setAlertMessage, dataLoadingErrorAlertMessage } = useAlertMessage();

    useEffect(() => {
        setLoading(true);
        magellanV1.get('/api/geofences')
            .then(response => {
                setItems(response.data);
            })
            .catch(error => {
                if(error.response.status !== 404){
                    setAlertMessage(dataLoadingErrorAlertMessage);
                }
                
            })
            .finally(() => {
                setLoading(false);
            });
    }, [updateTimestamp]);

    const renderItems = () => {
        return items
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
                    <TableRow key={item.id}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.description}</TableCell>
                        <TableCell>{item.address ? <Address placement="start" address={item.address}/> : <None text={t('itemAddressNone')}/>}</TableCell>
                        <TableCell className={classes.columnAction} padding="none">
                            <IconButton
                                color='primary'
                                onClick={(event) => onMenuClick(event.currentTarget, item.id)}
                                size="large">
                                <MoreVertIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
            ));
    };

    const renderCreateFirstGeofence = () => {
        if (!loading && items.length === 0) {
            return (
                <div className={cx(globalClasses.centerOfPageContainer, classes.contentContainer)}>
                    <StateInfo
                        title={t('createFirstGeofenceTitle')}
                        message={t('createFirstGeofenceMessage')}
                        logo={LocationCity}
                    />
                </div>
            );
        }
        
    };

    return (
        <>
            <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('sharedName')}</TableCell>
                        <TableCell>{t('sharedDescription')}</TableCell>
                        <TableCell>{t('sharedAddress')}</TableCell>
                        <TableCell className={classes.columnAction} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading 
                        ? <TableRow><TableCell colSpan="100%"><Loading/></TableCell></TableRow>
                        : renderItems()
                    }
                </TableBody>
            </Table>
            </TableContainer>
            { renderCreateFirstGeofence() }
            {!loading && displayAddButton(-1, items && items.length, false)}
        </>
    );
}

const GeofencesPage = () => {

    return (
        <EditCollectionView content={GeofencesView} editPath="geofence" endpoint="geofences" />
    );
}

export default GeofencesPage;
