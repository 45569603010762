import { Image, Text, } from '@react-pdf/renderer';

import { styles } from './sharedPdfReportStyles';
import geofenceIconPdf from 'resources/geofenceIconPdf.png';
import noGeofenceIconPdf from 'resources/noGeofenceIconPdf.png';
import NonePdf from './NonePdf';

const AddressPdf = ({ addressValue, isGeofence, t }) => {

    return (
        <>
            <Image src={isGeofence ? geofenceIconPdf : noGeofenceIconPdf} style={styles.decorativeIcon} />
            {addressValue ? <Text style={styles.address}>{addressValue}</Text>: <NonePdf text={t('itemAddressNone')} t={t}/>}
        </>
    );
};

export default AddressPdf;