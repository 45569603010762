import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTable, useExpanded, useSortBy } from 'react-table';
import { pdf } from '@react-pdf/renderer';

import { downloadFile } from 'utils/downloadFile';
import useTranslation from 'hooks/useTranslation';
import useReport from 'hooks/useReport';
import { AsyncServerTasksType, ReportFormat } from 'utils/constant';
import useFormat from 'hooks/useFormat';
import { useGlobalStyles } from 'context/StylesContext';
import { magellanV0 } from 'api/magellan';
import AddressPdf from '../templates/AddressPdf';
import TemplatePdf from '../templates/TemplatePdf';
import TablePdf from '../templates/TablePdf';
import useDeviceReport from 'hooks/useDeviceReport';
import { parseAddress } from 'utils/address';
import { getColumns } from '.';

const useStopReport = () => {
    const { t } = useTranslation();
    const { formatDuration, formatDate, formatAddressOneLine, formatDeviceCategory, } = useFormat();
    const { classes: globalClasses, cx} = useGlobalStyles();
    const geofences = useSelector(state => state.geofences.items);
    const availableReportActions = [ReportFormat.JSON, ReportFormat.PDF, ReportFormat.EXCEL, ReportFormat.MAIL];
    const { deviceId, setDeviceId, period, setPeriod, from, setFrom, to, setTo, items, setItems, devices, query, pdfReportFilters, getFiltersKey, ...restDeviceReport } = useDeviceReport({ availableReportActions });
    const { loading, setLoading, pdfData, setPdfData, companyName, companyAddress, ...restReport } = useReport({
        api: magellanV0, 
        report: 'reports/stops', 
        taskType: AsyncServerTasksType.STOPS_REPORT_EMAIL,
        query,
        getFiltersKey,
        setItems,
    });
    const columns = useMemo(() => getColumns(geofences, globalClasses, cx, t, formatDate, formatAddressOneLine, formatDuration), []);
    const data = useMemo(() => items, JSON.stringify[items]);
    const { 
        toggleAllRowsExpanded,
        visibleColumns, 
        ...tableRest
    } = useTable(
        { 
            columns, 
            data, 
            autoResetExpanded: false, 
            autoResetSortBy: false,
            getRowId: useCallback(row => row.positionId, []),
        }, 
        useSortBy,
        useExpanded,
    );

    const extractAddress = stop => {
        let isGeofence = false;
        let addressValue = geofences?.[stop?.geofenceIds?.[0]]?.name;
        if (addressValue) {
            isGeofence = true;
        } else {
            if(stop?.address) {
                let address = stop?.address;
                if(typeof address === 'string') {
                    address = parseAddress(address);
                }
                addressValue = formatAddressOneLine(address);
            }
        }
        return { isGeofence, addressValue }
    };

    const pdfReportColumns = React.useMemo(() => [
        {
            accessor: 'itemStartTime',
            Header: t('reportStartTime'), 
            Cell: ({ cell: { value } }) => formatDate(value),
            cellWidth: '20%'
        },
        {
            accessor: 'itemAddress',
            Header: t('reportAddress'),
            Cell: ({ cell: { value: { addressValue, isGeofence } } }) => <AddressPdf addressValue={addressValue} isGeofence={isGeofence} t={t}/>,
            cellWidth: '40%',
        },
        {
            accessor: 'itemEndTime',
            Header: t('reportEndTime'), 
            Cell: ({ cell: { value } }) => formatDate(value),
            cellWidth: '20%'
        },
        {
            accessor: 'itemDuration',
            Header: t('reportDuration'),
            Cell: ({ cell: { value } }) => formatDuration(value),
            cellWidth: '17%',
        },
        
    ], []);
    const pdfDataFormatted = useMemo(() => pdfData.map(item => (
        {
            itemStartTime: item.startTime,
            itemAddress: extractAddress(item),
            itemEndTime: item.endTime,
            itemDuration: item.duration            
        }
    ), [pdfData]));
    
    const { rows, headerGroups, prepareRow } = useTable({ columns: pdfReportColumns, data: pdfDataFormatted });
    
    const renderTemplate = () => {
        return (
            <TemplatePdf 
                reportTitle={t('reportStopsTitlePdf')}
                companyName={companyName}
                companyAddress={formatAddressOneLine(companyAddress)}
                filters={pdfReportFilters} 
                isPortrait={false}
            >
                <TablePdf
                    headerGroups={headerGroups} 
                    rows={rows} 
                    prepareRow={prepareRow}
                />
            </TemplatePdf>
        );
    } 

    useEffect(() => {
        if(rows && rows.length > 0) {
            const doc = renderTemplate();
            const file = pdf(doc);
            file.toBlob().then(blob => {
                downloadFile(blob, 'stopReport.pdf');
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            })
            setPdfData([]);
        }
    }, [JSON.stringify(rows)]); 

    return {
        globalClasses,
        cx,
        t,
        formatDuration,
        formatDate,
        formatAddressOneLine,
        formatDeviceCategory,
        geofences,
        items,
        loading,
        deviceId,
        setDeviceId,
        period,
        setPeriod,
        from,
        setFrom,
        to,
        setTo,
        devices,
        query,
        pdfReportFilters,
        getFiltersKey,
        availableReportActions,
        ...restDeviceReport,
        ...restReport,
        ...tableRest
    };
};

export default useStopReport;