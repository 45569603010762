import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';

import useAlertMessage from './useAlertMessage';
import { useIsReadonly } from './useIsReadonly';

export const useSignage = ({ readApi, createApi, updateApi }, endpoint, initialEditionMode = false, initialLoading = true, filterDataFromServer = (data => data)) => {
    const [state, setState] = useState({item: null, editionMode: initialEditionMode, loading: initialLoading});
    const { id } = useParams();
    const navigate = useNavigate();
    const { setAlertMessage, dataLoadingErrorAlertMessage, savedAlertMessage, creationErrorAlertMessage, updateErrorAlertMessage } = useAlertMessage();
    const [isReadonly] = useIsReadonly();

    useEffect( () => {
        if(id){
            readApi.get(`/api/${endpoint}/${id}`)
                .then(({data}) => {
                    setState(s => ({...s, item: filterDataFromServer(_.cloneDeep(data)), loading: false}));
                }).catch(error => {
                    setAlertMessage(dataLoadingErrorAlertMessage);
                });
        }
    }, []);

    const createData = async (submission, redirection = true) => {
        setState(s => ({...s, loading: true}));
        await createApi.post(`/api/${endpoint}`, submission, { headers: {'Content-Type': 'application/json'} })
                .then(response => {
                    if(response.data.id){
                        setAlertMessage(savedAlertMessage);
                        setState(s => ({...s, loading: false}));
                        if(redirection){
                            navigate(`${response.data.id}`, { replace: true });
                        }
                        
                    }
                })
                .catch(error => {
                    setAlertMessage(creationErrorAlertMessage);
                    setState({...state, loading: false});
                })
    }

    const updateData = async (submission, formValues = submission) => {
        setState(s => ({...s, loading: true}));
        await updateApi.put(`/api/${endpoint}/${id}`, submission, { headers: {'Content-Type': 'application/json'} })
            .then(() => {
                setState(s => ({...s, item: formValues, editionMode:false}));
                setAlertMessage(savedAlertMessage);
            })
            .catch(error => {
                setAlertMessage(updateErrorAlertMessage);
            })
            .finally(() => {
                setState( s=> ({...s, loading: false}));
            });
    }

    return {
        editionMode: state.editionMode, 
        setEditionMode: e => setState(s => ({...s, editionMode: e})), 
        item: state.item, 
        setItem: i => setState(s => ({...s, item: i})), 
        createData, 
        updateData, 
        id, 
        loading: state.loading, 
        setLoading: l => setState(s => ({...s, loading: l})), 
        isReadonly,
        setState,
    };
}