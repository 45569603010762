import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    labelAndValueContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        opacity: 0.5,
        fontSize: 10,
    },
    alignRight: {
        alignItems: 'flex-end',
    },
    alignCenter: {
        alignItems: 'center',
    },
}))

const BasicValue = ({ label, value, alignment = 'left' }) => {
    const { classes, cx } = useStyles();
    let classAlignment = '';
    switch(alignment) {
        case 'right': 
            classAlignment = classes.alignRight;
            break;
        case 'center':
            classAlignment = classes.alignCenter;
            break;
        case 'left':
        default:
            classAlignment = '';
    }

    return (
        <div className={classAlignment ? cx(classes.labelAndValueContainer, classAlignment) : classes.labelAndValueContainer}>
            <span className={classes.label}>{label}</span>
            <span>{value}</span>
        </div>
    );
};

export default BasicValue;