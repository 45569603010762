import _ from 'lodash';
import { sortByThenBy } from 'utils/sort';

export const getData = (items, staffers) => Object.values(items)
    .map(timesheet => {
        let staffer = staffers?.[timesheet.stafferId];
        let data = {};

        let timesheetWorkingTime = null;
        let endTime = new Date(timesheet.endTime);
        let startTime = new Date(timesheet.startTime);
        if(!isNaN(endTime) && !isNaN(startTime) && !isNaN(timesheet.breakTime)) {
            timesheetWorkingTime = endTime - startTime - (timesheet.breakTime * 1000);
        }

        data =  {
            allData: { timesheet, staffer },
            timesheetWorkingTime,
            stafferName: `${staffer?.firstname} ${staffer?.name}`,
            ...timesheet,
            isEditable: timesheet.isEditable || false,
        };
        
        return data;
    }).sort((item1, item2) => {
    const itemA = item1.allData;
    const itemB = item2.allData;
    return sortByThenBy(itemA.staffer.firstname, itemA.timesheet.date, itemB.staffer.firstname, itemB.timesheet.date);
    });