import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {USER, MANAGER, ADMIN} from './role';


const Authorization = (allowedRoles) => (WrappedComponent) => {
    const WithAuthorization = (props) => {
        const { adminEnabled, managerEnabled } = useSelector(state => ({
            adminEnabled: state.session.user && state.session.user.administrator, 
            managerEnabled: state.session.user && (state.session.user.userLimit > 0 || state.session.user.userLimit === -1)
        }), (prev, next) => prev.adminEnabled === next.adminEnabled && prev.managerEnabled === next.managerEnabled);
        let role;

        if(adminEnabled){
            role = ADMIN;
        }else if(managerEnabled){
            role = MANAGER;
        }else{
            role = USER;
        }

        if (allowedRoles.includes(role)) {
            return <WrappedComponent {...props} role={role}/>;
        } else {
            return <Route element={(role === ADMIN) ? <Navigate to="/admin/server"/> :  <Navigate to="/home"/>}/>;
        }
    }

    return WithAuthorization;
}

export default Authorization;