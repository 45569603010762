import React, { useState, useContext } from 'react';
import { makeStyles } from 'tss-react/mui';
import LayersIcon from '@mui/icons-material/Layers';

import useTranslation from 'hooks/useTranslation';
import { useGlobalStyles } from 'context/StylesContext';
import { MapContext } from 'context/MapContext';
import SatelliteMapIcon from 'resources/satellite.png';
import StreetsMapIcon from 'resources/streets.png';
import { styleMapbox } from './mapStyles';
import MapChoiceItem from './MapChoiceItem';

const useStyles = makeStyles()(theme => ({
    selectorOpen: {
        display: 'flex',
        flexDirection: 'column',
        width: 100,
        height: 'auto',
        backgroundColor: theme.palette.primary.main,
    },
    borderOpen: {
        width: 'auto',
    }
}));

const SelectorMapControl = () => {
    const { t } = useTranslation();
    const { classes, cx } = useStyles();
    const { classes: globalClasses } = useGlobalStyles();
    const [open, setOpen] = useState(false);
    const { changeMapStyle, mapReady } = useContext(MapContext);

    return (
        <div className={open ? cx(globalClasses.mapControlButtonBorder, classes.borderOpen) : globalClasses.mapControlButtonBorder}>
            <div
                onMouseEnter={()=>{
                    if(mapReady) {
                        setOpen(true);
                    }
                }} 
                onMouseLeave={()=>setOpen(false)} 
                className={
                open 
                    ? cx(globalClasses.mapControlButton, globalClasses.mapControlButtonBorderRadius, classes.selectorOpen)
                    : cx(globalClasses.mapControlButton, globalClasses.mapControlButtonBorderRadius)
            }>
                { open 
                    ?  [
                        { title: t('mapMapboxStreets'), uri: styleMapbox('streets-v11'), img: StreetsMapIcon },
                        { title: t('mapMapboxSatellite'), uri: styleMapbox('satellite-streets-v11'), img: SatelliteMapIcon },
                    ].map(item => <MapChoiceItem key={item.title} item={item} onClick={() => {
                        setOpen(false);
                        changeMapStyle(item.uri);
                    }}/>)
                    : <LayersIcon className={globalClasses.mapControlButtonIcon}/>
                }
            </div>
        </div>
    );
};

export default SelectorMapControl;