import _ from 'lodash';

import { magellanV1 } from "api/magellan";
import { DATE_PROP_NAME } from "./DatePickerCell";
import { TimesheetErrorCode } from "utils/constant";
import { getTimesheetValidation } from "./validation";

const STAFFER_PROP_NAME = 'stafferId';

export const fetchItems = async({ mustShowLoading = true, filters, setTotalCount, setAlertMessage, dataLoadingErrorAlertMessage, verified = false, limit = 50, offset = 0, queryObject }, { accessor, actions }, { dispatch }) => {
    if (mustShowLoading) {
        dispatch(actions.load(true));
    }
    let filtersFormatted = {};
    if(filters) {
        filtersFormatted = filters.reduce((accumulator, filter) => (accumulator[filter.filterKey] = filter.values, accumulator), {});
    }
    magellanV1.get('/api/timesheets', { params: { ...filtersFormatted, verified, limit, offset, ...queryObject } })
        .then(response => {
            dispatch(actions.updatePaginationLimit(response.data.limit));
            setTotalCount(response.data.totalCount);
            dispatch(actions.initialize(response.data.data));
        })
        .catch(error => {
            setAlertMessage(dataLoadingErrorAlertMessage);
        })
        .finally(() => {
            if (mustShowLoading) {
                dispatch(actions.load(false));
            }
        });
};


export const checkAndUpdate = async({ id, prop, value }, { accessor, actions }, { dispatch, getState }) => {
    let { stafferId, date } = getState()[accessor].newItems[id];
    dispatch(actions.updateItem({ id, prop, value }));
    switch(prop) {
        case DATE_PROP_NAME: 
            date = value;
            break;
        case STAFFER_PROP_NAME:
            stafferId = value;
            break;
        default: ;
    }
    if(stafferId && date) {
        magellanV1.get('/api/timesheets/isAlreadyExists', { params: { stafferId, date } })
            .then(response => {
                if(response.data) {
                    dispatch(actions.updateItem({ id, prop: 'errorCode', value: TimesheetErrorCode.DUPLICATE }));
                } else {
                    dispatch(actions.updateItem({ id, prop: 'errorCode', value: null }));
                }
            })
            .catch(error => { })
    }  
};


export const validateAndHideItem = async ({ id, nbItemsOnPage, setAlertMessage, timesheetSavedAlertMessage, updateErrorAlertMessage, t, fetchNewItems, }, { accessor, actions }, { dispatch, getState }) => {
    let items = getState()[accessor].items;
    let newItems = getState()[accessor].newItems;
    let validatedTimesheet = _.cloneDeep(items?.[id]);
    if(validatedTimesheet === undefined) {
        validatedTimesheet = _.cloneDeep(newItems?.[id]);
    }
    if(validatedTimesheet !== undefined) {
        let submission = _.cloneDeep(validatedTimesheet);
        let timesheetValidation = getTimesheetValidation(t);
        if(timesheetValidation.isValidSync(submission)) {
            delete submission.checkings;
            delete submission.id;
            submission.verified = true;
            dispatch(actions.removeItem(validatedTimesheet.id));
            try {
                if (submission.isNewItem) {
                    delete submission.isNewItem;
                    await magellanV1.post(`/api/timesheets`, submission);
                } else {
                    await magellanV1.put(`/api/timesheets/${id}`, submission);
                }
                if(Object.values(items).length -1 < nbItemsOnPage) {
                    fetchNewItems();
                }
                setAlertMessage(timesheetSavedAlertMessage);
            } catch(error) {
                dispatch(actions.restoreItem(validatedTimesheet));
                setAlertMessage(updateErrorAlertMessage);
            };
        }
    }   
}; 

export const validateAndUpdateItem = async ({ id, setAlertMessage, timesheetSavedAlertMessage, updateErrorAlertMessage, t, }, { accessor, actions }, { dispatch, getState }) => {
    let items = getState()[accessor].items;
    let validatedTimesheet = _.cloneDeep(items?.[id]);
    if(validatedTimesheet !== undefined) {
        let submission = _.cloneDeep(validatedTimesheet);
        let timesheetValidation = getTimesheetValidation(t);
        if(timesheetValidation.isValidSync(submission)) {
            delete submission.checkings;
            delete submission.isEditable;
            submission.verified = true;
            dispatch(actions.disableEdition(id));
            try {
                await magellanV1.put(`/api/timesheets/${id}`, submission)
                setAlertMessage(timesheetSavedAlertMessage);
            } catch(error) {
                dispatch(actions.enableEdition(id));
                setAlertMessage(updateErrorAlertMessage);
            };
        }           
    }   
};

export const removeItem = async({ id, nbItemsOnPage, setAlertMessage, timesheetRemovedAlertMessage, updateErrorAlertMessage, fetchNewItems, }, { accessor, actions }, { dispatch, getState }) => {
    let items = getState()[accessor].items;
    let removedTimesheet = _.cloneDeep(items?.[id]);
    if(removedTimesheet !== undefined) {
        dispatch(actions.removeItem(removedTimesheet.id));
        try {
            await magellanV1.delete(`/api/timesheets/${id}`)
            if(Object.values(items).length -1 < nbItemsOnPage) {
                fetchNewItems();
            }
            setAlertMessage(timesheetRemovedAlertMessage);
        } catch(error) {
            dispatch(actions.restoreItem(removedTimesheet));
            setAlertMessage(updateErrorAlertMessage);
        };
    }
};


