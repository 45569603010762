import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
    name: 'devices',
    initialState: {
        items: {},
        assets: {
            filters: [],
            pagination: {
                limit: 50,
                page: 0,
            },
        },
        tools: {
            filters: [],
            pagination: {
                limit: 50,
                page: 0,
            },
        },
        selectedId: null,
        mustZoom: false,
    },
    reducers: {
        refresh(state, action) {
            state.items = {};
            action.payload.forEach(item => state.items[item['id']] = item);
        },
        update(state, action) {
            action.payload.forEach(item => state.items[item['id']] = item);
        },
        select(state, action) {
            state.selectedId = action.payload;
        },
        selectAndZoom(state, action) {
            state.selectedId = action.payload;
            state.mustZoom = true;
        },
        endZoom(state, action) {
            state.mustZoom = false;
        },
        deselect(state, action) {
            state.selectedId = null;
            state.mustZoom = false;
        },
        remove(state, action) {
            delete state.items[action.payload];
        },
        updateDetectedBy(state, action) {
            let { beaconId, trackerId } = action.payload;
            state.items[beaconId].detectedBy = trackerId;
        },
        refreshAssetsFilters(state, action) {
            state.assets.filters = action.payload;
        },
        refreshToolsFilters(state, action) {
            state.tools.filters = action.payload;
        },
        refreshAssetsPagination(state, action) {
            state.assets.pagination = action.payload;
        },
        refreshToolsPagination(state, action) {
            state.tools.pagination = action.payload;
        },
        updateAssetsPaginationPage(state, action) {
            state.assets.pagination.page = action.payload;
        },
        updateToolsPaginationPage(state, action) {
            state.tools.pagination.page = action.payload;
        },
        updateAssetsPaginationLimit(state, action) {
            state.assets.pagination.limit = action.payload;
        },
        updateToolsPaginationLimit(state, action) {
            state.tools.pagination.limit = action.payload;
        }
    }
});

export { actions as devicesActions };
export { reducer as devicesReducer };
