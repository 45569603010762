import React, { useContext, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, } from '@mui/material';

import SupportPopup from './SupportPopup';
import useTranslation from 'hooks/useTranslation';
import { AuthenticationContext } from 'context/AuthenticationContext';
import { useSelector } from 'react-redux';
import { makeStyles } from 'makeStyles';

const useStyles = makeStyles()(theme => ({
    accordion: {
        width: '100%',
    },
    accordionSummary: {
        '&$expanded': {
            margin: 'auto'
        }
    }
}));


const ErrorFallback = ({ message, stack }) => {
    const { t } = useTranslation();
    const { handleLogout } = useContext(AuthenticationContext);
    const mustRedirectToLogin = useSelector(state => state.session.user === null);
    const { classes } = useStyles();

    useEffect(() => {
        if (mustRedirectToLogin) {
            document.location.reload(true);
        }
    }, [mustRedirectToLogin]);

    return (
        <SupportPopup open={true}>
            <Accordion className={classes.accordion}>
                <AccordionSummary className={classes.accordionSummary} >{t('sharedShowDetails')}</AccordionSummary>
                <AccordionDetails style={{whiteSpace: 'pre-wrap'}}>{stack}</AccordionDetails>
            </Accordion> 
            <Button color="secondary" variant='outlined' onClick={handleLogout}>{t('loginBackToLoginPage')}</Button>
        </SupportPopup>
    );
};

export default ErrorFallback;