import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import useTranslation from 'hooks/useTranslation';
import RemoveDialog from './RemoveDialog';
import { useGlobalStyles } from 'context/StylesContext';

const EditCollectionView = ({ content, editPath, endpoint, type, columns, forceNoLimit = false }) => {
    const { t } = useTranslation();
    const { classes: globalClasses } = useGlobalStyles();
    const navigate = useNavigate();

    const [selectedId, setSelectedId] = useState(null);
    const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
    const [removeDialogShown, setRemoveDialogShown] = useState(false);
    const [updateTimestamp, setUpdateTimestamp] = useState(Date.now());
    const managerEnabled = useSelector(state => state.session.user && (state.session.user.userLimit > 0 || state.session.user.userLimit === -1));

    const menuShow = (anchorId, itemId) => {
        setSelectedAnchorEl(anchorId);
        setSelectedId(itemId);
    }

    const menuHide = () => {
        setSelectedAnchorEl(null);
    }

    const handleAdd = () => {
        navigate(`../${editPath}`);
        menuHide();
    }

    const handleEdit = () => {
        navigate(`../${editPath}/${selectedId}`);
        menuHide();
    }

    const handleRemove = () => {
        setRemoveDialogShown(true);
        menuHide();
    }

    const handleRemoveResult = () => {
        setRemoveDialogShown(false);
        setUpdateTimestamp(Date.now());
    }

    const displayAddButton = (itemLimit, itemCount, mustBeManager = true) => {
        if(mustBeManager){
            if (managerEnabled && (forceNoLimit || itemLimit === -1 || itemCount < itemLimit)) {
                return (
                    <Fab size="medium" color="primary" className={globalClasses.addButton} onClick={handleAdd}>
                        <AddIcon />
                    </Fab>
                );
            }
            return null;
        } else {
            return (
                <Fab size="medium" color="primary" className={globalClasses.addButton} onClick={handleAdd}>
                    <AddIcon />
                </Fab>
            );
        }
    }

    const Content = content;

    return (
        <>
            <Content updateTimestamp={updateTimestamp} onMenuClick={menuShow} displayAddButton={displayAddButton} managerEnabled={managerEnabled} type={type} columns={columns}/>
            <Menu open={!!selectedAnchorEl} anchorEl={selectedAnchorEl} onClose={menuHide}>
                <MenuItem onClick={handleEdit}>{t('sharedEdit')}</MenuItem>
                <MenuItem onClick={handleRemove}>{t('sharedRemove')}</MenuItem>
            </Menu>
            <RemoveDialog open={removeDialogShown} endpoint={endpoint} itemId={selectedId} onResult={handleRemoveResult} />      
        </>
    );
}

export default EditCollectionView;
