import React from 'react';
import icalParser from 'ical';
import axios from 'axios';
import _ from 'lodash';

import { magellanV0, magellanV1 } from 'api/magellan';
import withSignageLayout from 'hoc/withSignageLayout';
import { useSignage } from 'hooks/useSignage';
import useAlertMessage from 'hooks/useAlertMessage';

import Loading from '../../common/Loading';
import SignageHeader from '../../common/SignageHeader';
import NotificationForm from './NotificationForm';
import { getEventType } from 'utils/event';
import { CRUDAction } from 'utils/constant';

const filterDataFromServer = (data) => {
    let calendar;
    let item = {
        id: data.id,
        name: data.name,
        type: getEventType(data),
        active: data.active,
        notificators: data.notificators.split(','),
        currentDevicesSelector: data.always ? 'always' : null,
        groups: {allItems: [], initialItems: [], finalItems: []},
        devices: {allItems: [], initialItems: [], finalItems: []},
        calendarId: null,
        calendar: null,
    }

    if(data.calendar) {
        calendar = icalParser.parseICS(window.atob(data.calendar.data));
        item.calendarId = data.calendar.id;
        item.calendar = {
            mondayStartHour: calendar?.monday?.start,
            mondayEndHour: calendar?.monday?.end,
            tuesdayStartHour: calendar?.tuesday?.start,
            tuesdayEndHour: calendar?.tuesday?.end,
            wednesdayStartHour: calendar?.wednesday?.start,
            wednesdayEndHour: calendar?.wednesday?.end,
            thursdayStartHour: calendar?.thursday?.start,
            thursdayEndHour: calendar?.thursday?.end,
            fridayStartHour: calendar?.friday?.start,
            fridayEndHour: calendar?.friday?.end,
            saturdayStartHour: calendar?.saturday?.start,
            saturdayEndHour: calendar?.saturday?.end,
            sundayStartHour: calendar?.sunday?.start,
            sundayEndHour: calendar?.sunday?.end,
        }
    }

    return item;
}

const UpdateNotification = ({filterDataForServer}) => {
    const {item, setItem, id, editionMode, setEditionMode, setLoading, loading, isReadonly} = useSignage({ readApi: magellanV1, createApi: magellanV0, updateApi: magellanV0 }, 'notifications', false, true, filterDataFromServer);
    const { setAlertMessage, savedAlertMessage, updateErrorAlertMessage } = useAlertMessage();

    const onSubmit = async (formValues, initialValues) => {
        setLoading(true);
        const {calendar, calendarAction, notification, groupsToLink, groupsToUnlink, devicesToLink, devicesToUnlink} = await filterDataForServer(_.cloneDeep(formValues), initialValues);
        try{
            switch (calendarAction) {
                case CRUDAction.CREATE:
                    let response = await magellanV0.post(`/api/calendars`, calendar, { headers: {'Content-Type': 'application/json'} });
                    notification.calendarId = response.data.id;
                    break;
                case CRUDAction.UPDATE:
                    await magellanV0.put(`/api/calendars/${initialValues.calendarId}`, calendar, { headers: {'Content-Type': 'application/json'} });
                    break;
                case CRUDAction.DELETE:
                    await magellanV0.delete(`/api/calendars/${initialValues.calendarId}`, { headers: {'Content-Type': 'application/json'} });
                    break;
                default:
                    break;
            }
            await magellanV0.put(`/api/notifications/${id}`, notification);
            let permission = {notificationId: id};
            let permissionSubmission;
            Promise.all([
                groupsToLink.map(groupId => {
                    permissionSubmission = Object.assign({groupId}, _.cloneDeep(permission));
                    return magellanV0.post('/api/permissions', permissionSubmission);
                }),
                groupsToUnlink.map(groupId => {
                    permissionSubmission = Object.assign({groupId}, _.cloneDeep(permission));
                    return magellanV0.delete('/api/permissions', {data: permissionSubmission});
                }),
                devicesToLink.map(deviceId => {
                    permissionSubmission = Object.assign({deviceId}, _.cloneDeep(permission));
                    return magellanV0.post('/api/permissions', permissionSubmission);
                }),
                devicesToUnlink.map(deviceId => {
                    permissionSubmission = Object.assign({deviceId}, _.cloneDeep(permission));
                    return magellanV0.delete('/api/permissions', {data: permissionSubmission});
                }),
            ]);
            setAlertMessage(savedAlertMessage);
            setItem({ 
                ...formValues, 
                devices: { 
                    ...formValues.devices, 
                    initialItems: formValues.devices.finalItems 
                },
                groups: {
                    ...formValues.groups,
                    initialItems: formValues.groups.finalItems
                }
            });
            setEditionMode(false);
        } catch(error) {
            setAlertMessage(updateErrorAlertMessage);
        } finally{
            setLoading(false);
        }
    };

    return (
        <>
            {loading 
                ? <Loading/>
                : <>
                    <SignageHeader editionMode={editionMode} setEditionMode={setEditionMode} editionEnabled={true && !isReadonly} />
                    <NotificationForm 
                        editionMode={editionMode} 
                        onSubmit={onSubmit} 
                        initialValues={item} 
                        id={id} 
                    />
                </>
            }
        </>
    );
};

export default withSignageLayout()(UpdateNotification);