import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';
import { timesheetReducer as reducers } from "./timesheetReducer";

const initialState = {
    loading: false,
    initialItems: {},
    items: {},
    newItems: {},
    lastAction: null,
    pagination: {
        limit: 50,
        page: 0,
    },
};

const { reducer, actions } = createSlice({
    name: 'timesheetReport',
    initialState, 
    reducers,
});

export { actions as timesheetReportActions };
export { reducer as timesheetReportReducer };