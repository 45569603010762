import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    header: {
        width: '100%',
        height: 33.6756,
        marginBottom: 10,
    },  
    headerContent: {
        height: '100%',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: '4%',
    },
    headerBackground: {
        position: 'absolute',
    },
    footer: {
        width: '100%',
        height: 25.2567,
        marginTop: 10,
    },
    footerText: {
        fontSize: 10,
        color: 'black',
    },
    company: {
        alignItems: 'flex-end',
    },
    logo: {
        height: '65%',
        objectFit: 'contain',
    },  
    banner: {
        backgroundColor: '#F5F5F5',
        paddingHorizontal: '5%',
        paddingVertical: 8,
        marginBottom: 10,
    },
    reportTitle: {
        marginBottom: 4,
        color: '#7F7F7F',
        textTransform: 'uppercase',
        fontSize: 16,
    },
    filtersContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    filterText: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 9,
        borderColor: '#7F7F7F',
        borderStyle: 'solid',
        borderWidth: 1.3,
        color: '#7F7F7F',
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 4.5,
        marginTop: '1.5%',
        marginRight: '1.5%',
    },
    content: {
        flex: 1,
        padding: '0 30',
        maxHeight: '100%'
    },
    table: { 
        display: "table", 
        width: "auto", 
    }, 
    tableRow: { 
        flexDirection: 'row',
        justifyContent: 'flex-start'
    }, 
    tableRowHeader: {
        backgroundColor: '#7F7F7F',
    },  
    tableCol: {
        width: '25%',
        color: '#7F7F7F' 
    },
    tableFirstCol: {
        width: '3%',
    },  
    tableColHeader: {
        textTransform: 'uppercase',
        height: 30, 
        backgroundColor: '#7F7F7F',
        color: 'white',
    },  
    tableOddCol: { 
        backgroundColor: '#F5F5F5', 
    },
    tableCell: { 
        fontSize: 9,
        paddingTop: 5,
        paddingBottom: 5,
        margin: 'auto 0 auto 5', 
    },
    tableFirstCell: {
        margin: 'auto',
    },  
    pageNumber: {
        fontSize: 10,
        color: 'black',
    },
    none: {
        color: '#BDBDBD',
    },
    decorativeIcon: {
        width: 10,
    },
 });