import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

import useStopReport from './useStopReport';
import PeriodSelector from 'components/common/PeriodSelector';
import ReportLayoutPage from '../ReportLayoutPage';
import ReportShowButton from '../ReportShowButton';
import FileExportReportButtons from '../FileExportReportButtons';
import { sortByThenBy } from 'utils/sort';
import ItemTable from 'components/home/ItemTable';
import ItemRow from 'components/common/ItemRow';


const StopReportPage = () => {
    const {
        formatDeviceCategory,
        globalClasses,  
        t,
        loading,
        devices, 
        deviceId,
        setDeviceId,
        period,
        setPeriod,
        from,
        setFrom,
        to,
        setTo,
        handleSubmit,
        tasks,
        getFiltersKey,
        displayDisabled,
        pdfDisabled,
        excelDisabled,
        emailDisabled,
        ...rest
    } = useStopReport();
    
    return (
        <ReportLayoutPage 
            loading={loading}
            filter={
                <div className={globalClasses.rootReportEngineContainer}>
                    <div className={globalClasses.reportFiltersContainer}>
                        <Autocomplete 
                            options={devices.sort((deviceA, deviceB) => sortByThenBy(deviceA.category, deviceA.name.toLowerCase(), deviceB.category, deviceB.name.toLowerCase()))}
                            groupBy={d => formatDeviceCategory(d?.category)}
                            getOptionLabel={d => d?.name || ''}
                            value={devices.find(d => d.id === deviceId)}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            onChange={(_, value) => setDeviceId(value?.id || null)}
                            renderInput={(params) => <TextField {...params} label={t('reportDevice')} variant='outlined'/>}
                        />
                        <PeriodSelector 
                            period={period} 
                            setPeriod={setPeriod} 
                            from={from}
                            setFrom={setFrom}
                            to={to}
                            setTo={setTo}
                        />
                        <ReportShowButton
                            disabled={displayDisabled}
                            handleSubmit={handleSubmit}
                        />
                    </div>
                    <div className={globalClasses.reportButtonsContainer}>
                        <FileExportReportButtons
                            pdfDisabled={pdfDisabled}
                            excelDisabled={excelDisabled}
                            emailDisabled={emailDisabled}
                            tasks={tasks}
                            handleSubmit={handleSubmit}
                            getFiltersKey={getFiltersKey}
                        />
                    </div>
                </div>
            }
        >
            <ItemTable 
                {...rest}
            >
                { ({ itemRow, ...rest }) => (
                    <ItemRow 
                        key={itemRow.positionId} 
                        itemRow={itemRow}
                        {...rest}
                    />
                )}
            </ItemTable>
        </ReportLayoutPage>
    );
};

export default StopReportPage;
