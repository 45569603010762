import React from 'react';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import { useTheme } from '@mui/material';

import useTranslation from 'hooks/useTranslation';
import useFormat from 'hooks/useFormat';
import { ReactComponent as CarIcon } from 'resources/car.svg';
import Address from './Address';
import { parseAddress } from 'utils/address';
import { Link } from 'react-router-dom';
import { Replay } from '@mui/icons-material';
import { format } from 'date-fns';

const useStyles = makeStyles()(theme => ({
    date: {
        fontWeight: 'bold',
    },
    timelineContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'start',
        width: '100%', 
    },
    timeline: {
        padding: 0,
    },
    timelineItem: {
        '::before': {
            padding: 0,
            width: 0,
            height: 0,
        }
    },
    timelineHour: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginRight: 10,
        marginTop: 7,
    },
    timelineHours: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginRight: 10,
        gap: 10,
        marginTop: -5
    },
    timelineRightContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        '&::before': {
            padding: 0,
        }
    },
    timelineIcon: {
        height: 30,
        width: 30,
    },
    timelineConnector: {
        height: 50,
        backgroundColor: theme.palette.on.main,
    },
    timelineTripAddress: {
        marginLeft: 10,
        marginTop: 7,
    },
    timelineTripDetail: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
        gap: 10,
        color: theme.palette.on.main,

    },
    timelineTripDetailSelected: {
        fontWeight: 'bold',
    },
    selectable: {
        cursor: 'pointer',
    }
}));

const MotionsSummaryTimeline = ({ geofences, motions, defaultContent = null, selectedTripId = null, setSelectedTripId = () => {}, selectable = false, openInReplay = true }) => {
    const { t } = useTranslation();
    const { formatTripDistance, formatDuration, formatTime, formatLocalDate } = useFormat();
    const { classes, cx } = useStyles();
    const theme = useTheme();
    let groupedByDateTrips = null;
    if (motions?.trips?.length > 0) {
        groupedByDateTrips = _.cloneDeep(motions.trips).reduce((accumulator, trip) => {
            let key = format(new Date(trip.startTime), 'yyyy-MM-dd');
            accumulator[key] = accumulator[key] || [];
            accumulator[key].push(trip);
            return accumulator;
        }, {});
    }

    const renderDefaultContent = () => {
        if(defaultContent){
            return <>{defaultContent}</>;
        } 
        return null;
    };

    const renderTripDetail = (trip) => {
        const tripDetailClasses = [classes.timelineTripDetail];
        if (selectable) {
            tripDetailClasses.push(classes.selectable);
            if (selectedTripId === trip.startPositionId) {
                tripDetailClasses.push(classes.timelineTripDetailSelected);
            }
        }

        return (
            <>
                <span className={cx(...tripDetailClasses)}>
                    {`${formatTripDistance(trip.distance)} ${t('travelledIn')} ${formatDuration(trip.duration)}`}
                    { openInReplay &&
                        <Link 
                            target="_blank" 
                            to={`/app/replay?${
                                new URLSearchParams({ 
                                    deviceId: trip.deviceId, 
                                    from: new Date(trip.startTime).toISOString(), 
                                    to: new Date(trip.endTime).toISOString() }).toString()
                                }`
                            } 
                        >
                            <Replay/>
                        </Link>
                    }
                </span>

            </>
        );
    };

    const renderConnector = (trip) => {
        const connectorClasses = [classes.timelineConnector];
        if (selectable) {
            connectorClasses.push(classes.selectable);
        }

        return (
            <TimelineConnector 
                onClick={() => setSelectedTripId(trip.startPositionId)} 
                className={cx(...connectorClasses)} 
            />
        );
    };

    

    return (
        groupedByDateTrips
            ? ( 
                <div className={classes.timelineContainer}>
                    { Object.entries(groupedByDateTrips).reverse().map(([date, dailyTrips]) => (
                        <div key={date}>
                            <span className={classes.date}>{_.capitalize(formatLocalDate(date, 'eeee d MMMM yyyy'))}</span>
                            <Timeline position='left' className={classes.timeline}>
                                { dailyTrips.reverse().map((trip, index, allTrips) => (
                                    <TimelineItem key={trip.startTime} className={classes.timelineItem}>
                                        <div className={classes.timelineRightContent} onClick={() => setSelectedTripId(trip.startPositionId)}>
                                            <div className={classes.timelineTripAddress}>
                                                { trip?.endGeofenceIds?.length > 0 
                                                    ? <span>{(geofences?.find(g => g.id === trip?.endGeofenceIds[0]))?.name}</span>
                                                    : <Address 
                                                        address={parseAddress(trip.endAddress)} 
                                                        placement='start'
                                                        oneLiner={true}   
                                                        maxLength={40}  
                                                    />
                                                }
                                            </div>
                                            {renderTripDetail(trip)}
                                        </div>
                                        <TimelineSeparator>
                                            { index === 0
                                                ? <CarIcon fill={theme.palette.off.main} className={classes.timelineIcon}/>
                                                : <CarIcon fill={theme.palette.on.main} className={classes.timelineIcon}/>
                                            }
                                            {renderConnector(trip)}
                                        </TimelineSeparator>
                                        
                                        { index === 0
                                            ? <div className={classes.timelineHour}>
                                                <span>{formatTime(trip.endTime, 'hour')}</span>
                                            </div>
                                            : <div className={classes.timelineHours}>
                                                <span>{`${formatTime(allTrips[index - 1].startTime, 'hour')}`}</span>
                                                <span>{`${formatTime(trip.endTime, 'hour')}`}</span>
                                            </div>
                                        }
                                    </TimelineItem>
                                ))}
                                <TimelineItem className={classes.timelineItem}>
                                    <div className={classes.timelineRightContent}>
                                        <div className={classes.timelineTripAddress}>
                                            { motions.trips.at(0)?.startGeofenceIds?.length > 0 && geofences.find(g => g.id === motions.trips.at(0)?.startGeofenceIds[0])
                                                ? <span>{(geofences.find(g => g.id === motions.trips.at(0)?.startGeofenceIds[0])).name}</span>
                                                : <Address 
                                                    address={parseAddress(motions.trips.at(0).startAddress)} 
                                                    placement='start'
                                                    oneLiner={true} 
                                                    maxLength={40}   
                                                />
                                            }
                                        </div>    
                                    </div>
                                    <TimelineSeparator>
                                        <CarIcon fill={theme.palette.off.main} storke={theme.palette.off.main} className={classes.timelineIcon}/>
                                    </TimelineSeparator>
                                    <div className={classes.timelineHour}>
                                        <span>{formatTime(motions.trips.at(0).startTime, 'hour')}</span>
                                    </div>
                                </TimelineItem>
                            </Timeline>
                        </div>
                    ))}
                </div>
            )
            : renderDefaultContent()
    );
}

export default MotionsSummaryTimeline;