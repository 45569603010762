import React from 'react';
import SignalCellularConnectedNoInternet0BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet0Bar';
import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';

import { useGlobalStyles } from 'context/StylesContext';
import useTranslation from 'hooks/useTranslation';

const QualitySignalIndicator = ({ position }) => {
    const { t } = useTranslation();
    const { classes: globalClasses, theme } = useGlobalStyles();

    const renderSignalQuality = () => {
        switch(position?.attributes?.gsmSignalStrength){
            case 0: 
            case 1:
                return (
                    <>
                        <div className={globalClasses.iconSize}><SignalCellular0BarIcon /></div>
                        <span>{t('signalQualityBad')}</span>
                    </>
                );
            case 2:
                return (
                    <>
                        <div className={globalClasses.iconSize}><SignalCellular1BarIcon /></div>
                        <span>{t('signalQualityPoor')}</span>
                    </>
                );
            case 3:
                return (
                    <>
                        <div className={globalClasses.iconSize}><SignalCellular2BarIcon /></div>
                        <span>{t('signalQualityAcceptable')}</span>
                    </>
                );
            case 4:
                return (
                    <>
                        <div className={globalClasses.iconSize}><SignalCellular3BarIcon /></div>
                        <span>{t('signalQualityGood')}</span>
                    </>
                );
            case 5: 
                return (
                    <>
                        <div className={globalClasses.iconSize}><SignalCellular4BarIcon /></div>
                        <span>{t('signalQualityGreat')}</span>
                    </>
                );
            default: 
                return (
                    <>
                        <div className={globalClasses.iconSize}><SignalCellularConnectedNoInternet0BarIcon style={{ color: theme.palette.off.main }} /></div>
                        <span >{t('itemStatusUnknown')}</span>
                    </>
                );
        }
    };

    return (
        <div className={globalClasses.indicatorContainer}>
            {renderSignalQuality()}
        </div>
    );
};

export default QualitySignalIndicator;