import _ from 'lodash';
import React from "react";
import { Button, Tooltip, } from "@mui/material";
import { isValidDate } from "utils/date";
import { Check, DangerousOutlined, DeleteForever, RestartAlt, WarningOutlined, } from "@mui/icons-material";
import { TimesheetErrorCode } from "utils/constant";
import TimePickerCell from "../TimePickerCell";
import DatePickerCell from "../DatePickerCell";
import StafferSelectorCell from "../StafferSelectorCell";
import BreakTimeField from "../BreakTimeField";
import { timesheetValidationActions } from 'store';

export const getColumns = ({ staffers, t, theme, globalClasses, formatLocalDate, formatDuration }) => [ 
    {
        accessor: 'stafferName',
        Header: t('stafferName'),
        Cell: ({ cell, meta: { checkAndUpdateTimesheet } }) => {  
            let { value, row: { original: { isNewItem, } }, } = cell;
            if(isNewItem) {
                return <StafferSelectorCell 
                    cell={cell} 
                    checkAndUpdateTimesheet={checkAndUpdateTimesheet}
                    staffers={staffers} 
                    t={t}
                />
            } 
            return value;
        },
        props: {
            className: globalClasses.tableCellFirst,
            style: {
                width: '14%',
            }
        },
    },
    {
        accessor: 'date',
        id: 'day',
        Header: t('day'),
        Cell: ({ cell: { value: date, }}) => {
            if(isValidDate(new Date(date))) {
                return _.capitalize(formatLocalDate(date, 'iiii'));
            }
            return null;
        },
        props: {
            style: {
                width: '6%',
            }
        },
    },
    {
        accessor: 'date',
        id: 'date',
        Header: t('date'),
        Cell: ({ cell: { value: date, row: { id, original: { isNewItem }} }, meta: { checkAndUpdateTimesheet, } }) => {
            if(isNewItem){
                return (
                    <DatePickerCell 
                        initialValue={date}
                        id={id}
                        checkAndUpdateTimesheet={checkAndUpdateTimesheet}
                    />
                );
            } else {
                if(isValidDate(new Date(date))) {
                    return formatLocalDate(date, 'PPP');
                }
                return null;
            }
        }, 
        props: {
            style: {
                width: '8%',
            }
        },
    },
    {
        accessor: 'startTime',
        Header: t('timesheetStartTime'),
        Cell: ({ cell: { value, row: { id, }, column: { id: prop } }, meta: { updateTimesheet, }}) => (
            <TimePickerCell
                initialValue={value}
                id={id}
                prop={prop}
                updateTimesheet={updateTimesheet}
            />
        ),
        props: {
            className: globalClasses.tableCellFixedSize,
            style: {
                width: '14%',
            }
        },
    },
    {
        accessor: 'endTime',
        Header: t('timesheetEndTime'),
        Cell: ({ cell: { value, row: { id, }, column: { id: prop } }, meta: { updateTimesheet, }}) => (
            <TimePickerCell
                initialValue={value}
                id={id}
                prop={prop}
                updateTimesheet={updateTimesheet}
            />
        ),
        props: {
            className: globalClasses.tableCellFixedSize,
            style: {
                width: '14%',
            }
        },
    },
    {
        accessor: 'breakTime',
        Header: t('timesheetBreakTime'),
        Cell: ({ cell: { value: initialValue, row: { id }, column: { id: prop } }, meta: { updateTimesheet, dispatch } }) => (
            <BreakTimeField 
                initialValue={initialValue}
                id={id}
                prop={prop}
                updateTimesheet={updateTimesheet}
                dispatch={dispatch}
                t={t}
            />
        ),
        props: {
            className: globalClasses.tableCellFixedSize,
            style: {
                width: '14%',
            }
        },
    },
    {
        accessor: 'timesheetWorkingTime',
        Header: t('timesheetWorkingTime'),
        Cell: ({ cell: { value }}) => formatDuration(value),
        props: {
            className: globalClasses.tableCellNumber,
            style: {
                width: '10%',
            }
        },
    },
    {
        Header: () => null, 
        accessor: 'id',
        id: 'errorAndEdition',
        Cell: ({ cell: { value: id, row: { original: { isNewItem, errorCode } } }, meta: { removeTimesheet, validateTimesheet, dispatch } }) => {
            let icon = null;
            let errorMessage = '';
            let style =  { display: 'block', margin: 'auto', };

            switch(errorCode) {
                case TimesheetErrorCode.ODD_CHECKING:
                    style.color = theme.palette.error.main;
                    icon = <DangerousOutlined style={style}/>; 
                    errorMessage = t('lackOfCheckingsErrorMessage');
                    break;
                case TimesheetErrorCode.DUPLICATE:
                    style.color = theme.palette.warning.main;
                    icon = <WarningOutlined style={style}/>
                    errorMessage = t('timesheetDuplicatedSuccessMessage');
                    break;
                case TimesheetErrorCode.NO_ERROR:
                default: 
                    icon = <DangerousOutlined className={globalClasses.deselectedIcon}/>; 
                }

            return (
                <div className={globalClasses.timesheetEditionContainer}>
                   { errorCode !== TimesheetErrorCode.NO_ERROR 
                    ? (
                        <Tooltip
                            title={errorMessage}
                            placement='top'
                        >
                            <div><Button disabled>{icon}</Button></div>
                        </Tooltip>
                    )
                    : <Button disabled>{icon}</Button>}
                    <Tooltip
                        title={t('timesheetReset')}
                        placement='top'
                    >
                        <Button onClick={() => dispatch(timesheetValidationActions.resetItem(id))}>
                            <RestartAlt/>
                        </Button>
                    </Tooltip>
                    <Tooltip
                        title={t('timesheetSave')}
                        placement='top'
                    >
                        <Button 
                            onClick={() => validateTimesheet(id)}
                        >  
                            <Check/>
                        </Button>
                    </Tooltip>
                    <Tooltip 
                        title={t('timesheetRemove')} 
                        placement='top'
                    >
                        <Button 
                            onClick={() => {
                                if(isNewItem) {
                                    dispatch(timesheetValidationActions.removeItem(id));
                                } else {
                                    removeTimesheet(id);
                                }
                            }}
                        >
                            <DeleteForever/>
                        </Button>
                    </Tooltip>
                </div>
            );
        },
        disableSortBy: true,
        props: {
            style: {
                width: '20%',
            }
        },
    }
];