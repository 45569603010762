import React, { createContext, useState, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';

import { ColorMode, motionLocationIcon } from 'utils/constant';
import { usePreference, useAttributePreference } from 'hooks/usePreferences';
import { loadIcon } from 'components/home/map/mapUtil';
import { styleMapbox } from 'components/home/map/mapStyles';
import { useColorMode } from './ColorModeContext';

const MapContext = createContext(null);



const formatIconName = (icon, colorMode) => `${colorMode}_${icon}`;

const addImagesToMap = async (map, setMapReady) => {
    Object.values(ColorMode).forEach(colorMode => {
        Object.values(motionLocationIcon).forEach(async icon => {
            if (!map.hasImage(formatIconName(icon, colorMode))) {
                const imageData = await loadIcon(`images/icon/${colorMode}/${icon}.svg`, 40);
                map.addImage(formatIconName(icon, colorMode), imageData, { pixelRatio: window.devicePixelRatio });
            }
        })
    });
    setMapReady(true);
};

const useMapIcon = () => {
    const { colorMode } = useColorMode();

    const getMapIcon = (icon) => {
        return formatIconName(icon, colorMode);
    };

    return { getMapIcon };
};

const MapProvider = ({ children, authenticated }) => {
    const mapContainer = document.createElement('div');
    mapContainer.style.width = '100%';
    mapContainer.style.height = '100%';
    const [map, setMap] = useState(null);
    const [mapReady, setMapReady] = useState(false);
    const mapUrl = usePreference('mapUrl');
    const mapboxAccessToken = useAttributePreference('mapboxAccessToken');
    const [mapStyle, setMapStyle] = useState(null);
    const isSatelliteStyle = (mapStyle === "mapbox://styles/mapbox/satellite-streets-v11");

    const checkIfReadyAndAddImagesToMap = () => {
        const waiting = () => {
            if (!map.isStyleLoaded()) {
              setTimeout(waiting, 200);
            } else {
                addImagesToMap(map, setMapReady);
            }
          };        
        waiting();
    }

    const initMap = () => {
        let mapboxMap = new mapboxgl.Map({
            container: mapContainer,
        }); 
        mapboxMap.dragRotate.disable();
        mapboxMap.touchZoomRotate.disableRotation();
        setMap(mapboxMap);
    }

    const reactToError = () => {
        setMapReady(false);
        setMapStyle(null);
        initMap();
    };

    useEffect(() => {
        initMap();   
    }, []);

    useEffect(() => {
        if(map && authenticated) {
            mapboxgl.accessToken = mapboxAccessToken;
            map.once('error', reactToError);
            setMapStyle(mapUrl);

            return () => {
                map.once('error', reactToError);
            };
        }
    }, [map, authenticated]);  
    
    useEffect(() => {
        if(map && mapStyle){
            map.on('style.load', checkIfReadyAndAddImagesToMap);
            map.setStyle(mapStyle);
            if(mapStyle === styleMapbox('satellite-streets-v11')){
                map.setMaxZoom(17);
            } else {
                map.setMaxZoom(19);
            }

            return () => map.off('style.load', checkIfReadyAndAddImagesToMap);
        }
    }, [mapStyle])

    const changeMapStyle = (newMapStyle) => {
        if(mapStyle !== newMapStyle) {
            setMapReady(false);
            setMapStyle(newMapStyle);
        }
    }

    return (
        <MapContext.Provider value={{map, mapReady, mapStyle, isSatelliteStyle, changeMapStyle}}>
            {children}
        </MapContext.Provider>
    );
};

export { MapProvider, MapContext, useMapIcon };