import { cloneDeep } from "lodash";
import React from "react";
import { Autocomplete, Chip, TextField, useTheme } from "@mui/material";
import useGlobalSearch from "hooks/useGlobalSearch";
import useTranslation from "hooks/useTranslation";
import { makeStyles } from "tss-react/mui";
import { Clear, Search } from "@mui/icons-material";
import { useGlobalStyles } from "context/StylesContext";

const useStyles = makeStyles()(theme => ({
    fieldContainer: {
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        minHeight: 34,
        borderBottom: `1px ${theme.palette.secondary.main} solid`,
    },
    searchInput: {
        color: theme.palette.text.primary,
        display: 'flex',
        flexWrap: 'wrap',
        '& input': {
            flex: 1,
        }
    },
}));

const GlobalSearch = ({ filters, setFilters, availableFilters }) => {
    const { activeFilters, setActiveFilters, inputFilter, setInpuFilter, getOptions } = useGlobalSearch({ filters, setFilters, availableFilters });
    const { t } = useTranslation();
    const { classes } = useStyles();
    const { classes: globalClasses, theme } = useGlobalStyles();
    
    return (
        <Autocomplete
            multiple
            id="tags-filled"
            value={activeFilters}
            onChange={(_, values, reason, details) => setActiveFilters(old => {
                let value = details.option;
                let result;
                switch(reason) {
                    case 'removeOption': 
                        result =  old.filter(o => o.filterKey !== value.filterKey);
                        break;
                    case 'selectOption': 
                        let index = old.findIndex(o => o.filterKey === value.filterKey);
                        if(index > -1) {
                            result = cloneDeep(old);
                            result.splice(index, 1, value);
                        } else {
                            result = values;
                        }
                        break;
                    default:
                        result = values;
                }
                return result;
            })}
            fullWidth
            disableClearable
            autoHighlight
            inputValue={inputFilter}
            popupIcon={null}
            onInputChange={(_, newInputFilter) => setInpuFilter(newInputFilter)}
            options={getOptions()}
            getOptionLabel={option => `${t(option.filterKey)}: ${option.values.join(` ${t('or')} `)}`}
            noOptionsText={t('enterYourSearch')}
            open={Boolean(inputFilter.trim())}
            renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                    <Chip
                        variant="outlined"
                        label={`${t(option.filterKey)}: ${option.values.join(` ${t('or')} `)}`}
                        {...getTagProps({ index })}
                        deleteIcon={<Clear style={{ color: theme.palette.action.active, fontSize: 16 }}/>}
                    />
                ))
            }}
            renderInput={(params) => {
                return (
                    <div className={classes.fieldContainer}>
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder={filters.length === 0 ? t('search') : ''}
                            fullWidth={true}
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                                className: classes.searchInput,
                            }}
                        />
                        <Search className={globalClasses.actionIcon}/>
                    </div>
            )}}
        />
    );
};

export default GlobalSearch;