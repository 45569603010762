import React from 'react';
import { makeStyles } from 'tss-react/mui';

import useTranslation from 'hooks/useTranslation';
import Address from 'components/common/Address';
import BasicValue from 'components/common/BasicValue';
import None from 'components/common/None';

const useStyles = makeStyles()(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    }, 
    liveContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        rowGap: '2.5vh'
    },
    address: {
        gridColumn: '1 / span 2',
    }
}));

const AssetLive = ({ item, position, geofence }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
 
    return (
        <div className={classes.container}>
            <div className={classes.liveContainer}>
                <BasicValue
                    label={t('itemLabel')}
                    value={item.name}
                />
                <BasicValue
                    alignment='right'
                    label={t('itemGeofence')}
                    value={geofence?.name || <None text={t('itemGeofenceNone')}/>}
                />  
                <div className={classes.address}>
                    <BasicValue
                        label={t('itemAddress')}
                        value={position?.address
                            ? <Address address={position.address} placement="start" />
                            : <None text={t('itemAddressNone')}/>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default AssetLive;