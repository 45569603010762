import React, { createContext, useContext } from 'react';
import { makeStyles } from 'tss-react/mui';

const StylesContext = createContext(null);

const useGlobalStyles = () => {
    const styles = useContext(StylesContext);

    return styles;
}

const useStyles = makeStyles()(theme => ({
    actionIcon: {
        color: theme.palette.action.active,
    },
    addButton: {
        position: 'absolute',
        bottom: theme.spacing(4),
        right: theme.spacing(6),
    },
    authCard: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        alignItems: 'center',
        position: 'absolute',
        padding: theme.spacing(3),
        zIndex: 1,
        width: '400px'
    },
    centerOfPageContainer: {
        height: '100vh', 
        width: '100%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
    },
    clickable: {
        cursor: 'pointer',
    },
    decorativeIcon: {
        verticalAlign: 'middle',
        height: 20,
        width: 20,
        marginRight: 4,
    },
    deselectedIcon: {
        color: 'transparent'
    },
    dialogTitle: {
        color: theme.palette.secondary.main,
        fontSize: '1.4rem',
    },
    disabled: {
        color: theme.palette.off.main,
    },
    iconSize: {
        height: 30,
        width: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconStatus: {
        width: 20,
        height: 20,
    },
    iconStatusContainer: {
        flex: 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        gap: 2,
    },
    indicator: {
        width: 25,
        height: 25,
    },
    indicatorContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
    },
    infoContainer: {
        display: 'flex',
        width: '100%',
        padding: 16,
    },
    infoTitle: {
        marginRight: 'auto',
        marginLeft: 'auto',
        marginBottom: '2.5vh',
        textTransform: 'uppercase',
    },
    invisible: {
        color: 'transparent',
    },
    link: {
        color: theme.palette.secondary.main,
        textDecoration: 'none',
    },
    listCheckableItemItem: {
        padding: '1px 8px',
        lineHeight: 1,
    },
    listCheckableItemCheck: {
        minWidth: 0,
        paddingRight: 4,
    },
    listCheckableItemList: {
        padding: 0,
    },
    listCheckableItemText: {
        minHeight: 30,
        display: 'inline-flex',
        alignItems: 'center',
    },
    loginFormControl: {
        display: 'flex',
        marginTop: theme.spacing(2),
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 38,
        whiteSpace: 'nowrap',
        '& > *': {
          flexBasis: '40%',
        },
    },
    mapControlButtonBorder: {
        border: '#00000022 2px solid',
        borderRadius: '4px',
        width: 33,
    },
    mapControlButtonBorderTopRadius: {
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
    },
    mapControlButtonBorderBottomRadius: {
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
    },
    mapControlButtonBorderRadius: {
        borderRadius: '4px',
    },
    mapControlButtonsLayout: {
        position: 'absolute',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        marginTop: 10,
        marginLeft: 10,
    },
    mapControlButton: {
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 29,
        width: 29,
        cursor: 'pointer',
    }, 
    mapControlButtonIcon: {
        color: theme.palette.mode === 'light' ? theme.palette.common.black : theme.palette.common.white,
    },
    mapControlButtonTopSeparator: {
        borderTop: '1px #00000022 solid',
    },
    notLinked: {
        color: theme.palette.off.main,
    },
    rootReportEngineContainer: {
        display: 'flex',
        margin: '1.2%',
    },
    reportFiltersContainer: {
        flex: 9,
        alignItems: 'center',
        display: 'grid',
        gridTemplateColumns: 'repeat(6, 1fr)',
        gridAutoRows: 'minmax(auto, auto)',
        columnGap: '1.5%',
        rowGap: '2vh',
    }, 
    reportButtonsContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: '2%',
    },
    selectedItem: {
        backgroundColor: theme.palette.selected.main,
    },
    switchCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
    },
    tableAddressHeader: {
        paddingLeft: 28,
    },
    tableIconColumn: {
        paddingLeft: 24,
    },
    tableCellNumber: {
        textAlign: 'end',
        '& > *': {
            marginRight: 0,
            justifyContent: 'flex-end',
        }
    },
    tableCellFixedSize: {
        textAlign: 'center',
        '& > *': {
            marginRight: 0,
            justifyContent: 'center',
        }
    },
    tableCellFirst: {
        textAlign: 'start',
        paddingLeft: 16,
    },
    tableCellLast: {
        paddingRight: 16,
    },
    tableCellExpander: {
        padding: 0,
    },
    tableEngineContainer: {
        display: 'flex',
        flexDirection: 'column',
        float: 'right',
        width: '50%',
        padding: '16px 16px 0px 0px',
        alignSelf: 'flex-end',
        //right: 0,
        //top: 0, 
        //position: 'sticky',
        //zIndex: theme.zIndex.mobileStepper,
        //backgroundColor: 'white',
    },
    tableEngineFilterAndPagination: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    tableEngineFilterButton: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.text.primary,
        gap: 5,
        cursor: 'pointer',
    },
    // filterCategory: {
    //     padding: '2px 8px',
    //     lineHeight: 1,
    // },
    tablePageLayout: {
        display: 'flex', 
        height: '100%', 
        flexFlow: 'column'
    },
    timeCounter: {
        fontSize: '0.65rem',
        fontStyle: 'italic'
    },
    timesheetEditionContainer: {
        '&' : {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between !important',
        }
    },
    timetableCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '32px 32px 32px 32px',
        width: '100%',
    },
    transparentDivider: {
        opacity: 0.2,
    },
    warningIcon: {
        color: theme.palette.warning.main,
    }
}));

const StylesProvider = ({ children }) => {
    const styles = useStyles()
 
    return (
        <StylesContext.Provider value={styles}>
            {children}
        </StylesContext.Provider>
    );
};

export { StylesProvider, useGlobalStyles };