import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as yup from 'yup';

import {magellanV0, magellanV1} from 'api/magellan';
import withSignageLayout from 'hoc/withSignageLayout';
import { useSignage } from 'hooks/useSignage';
import useTranslation from 'hooks/useTranslation';
import useAlertMessage from 'hooks/useAlertMessage';
import SignageHeader from '../../common/SignageHeader';
import UserForm from '../../common/UserForm';
import Loading from '../../common/Loading';

const initialDefaultValues = {
    name: '',
    email: '',
    password: '',
    disabled: false,
    readonly: false,
    expirationTime: null,
};

const CreateUser = ({ filterDataForServer }) => {
    const {editionMode, setEditionMode, id, loading, setLoading} = useSignage({ readApi: magellanV0, createApi: magellanV0, updateApi: magellanV0 }, 'users', true, false);
    const navigate = useNavigate();  
    const { setAlertMessage, creationErrorAlertMessage, savedAlertMessage, updateErrorAlertMessage } = useAlertMessage(); 
    const { t } = useTranslation();

    const validationPassword = {
        password: yup.string()
        .trim()
        .required(t('validationRequired'))
        .min(8, `\${min} ${t('validationLengthMin')}`),
    };

    const onSubmit = async (formValues) => {
        setLoading(true);

        try {
            const response = await magellanV0.post(`/api/users`, filterDataForServer({...formValues}), { headers: {'Content-Type': 'application/json'} });
            if(response.data.id){
                Promise.all([
                    magellanV1.put('/api/password/newUserReset', new URLSearchParams(`id=${response.data.id}`)),
                    formValues.groups.groupToLink.map(g => magellanV0.post('/api/permissions', {userId : response.data.id, groupId : g})),
                ])
                    .then(res => {
                        setAlertMessage(savedAlertMessage);
                        setEditionMode(false);
                        navigate(`${response.data.id}`, { replace: true });
                    })
                    .catch(error => {
                        setAlertMessage(updateErrorAlertMessage);
                    });
            }
        } catch(error) {
            setAlertMessage(creationErrorAlertMessage);
            setLoading(false);
        }
    };

    return (
        <>
            {loading 
                ? <Loading/>
                : <>
                    <SignageHeader 
                        editionMode={editionMode} 
                        setEditionMode={setEditionMode} 
                        editionEnabled={true}
                    />
                    <UserForm 
                        initialValues={initialDefaultValues} 
                        editionMode={editionMode} 
                        onSubmit={onSubmit} 
                        managerEnabled={true} 
                        isCreation={true} 
                        id={id}
                        validationPassword={validationPassword}
                    />
                </>
            }
            
        </>
    );
}

export default withSignageLayout()(CreateUser);