import React from 'react';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { prefixString } from 'utils/stringUtils';

const CustomizedAxisTick = ({ x, y, payload }) =>{
    if(payload.value) {
        const parts = payload.value.split(' ');
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{parts[0]}</text>
                <text x={0} y={16} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{parts[1]}</text>
            </g>
        );
    }
    return null;
}

const Graph = ({ type, items, height, width, t, formatNumber, speedUnit, chartRef }) => {
    let unit = '';

    switch(type) {
        case 'altitude':
        case 'accuracy':
            unit = t('sharedMeterAbbreviation');
            break;
        case 'speed':
        default:
            unit = t(prefixString('shared', speedUnit));
    }
    
    return (
        <LineChart data={items} height={height} width={width} ref={chartRef} >
            <XAxis dataKey="fixTime" tick={<CustomizedAxisTick/>} height={60} />
            <YAxis unit={unit} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip formatter={value => formatNumber(value, 2)} />
            <Legend />
            <Line type="natural" dataKey={type} unit={unit} />
        </LineChart>  
    );
}

export default Graph;
