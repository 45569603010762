import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Menu, TablePagination, Typography } from '@mui/material';

import DeviceItem from './DeviceItem';
import { comparatorByCategoryThenByName, groupByCategory, sortByThenBy } from 'utils/sort';
import { AssetColumns, DeviceStatus, DeviceType, Filters, Parameters } from 'utils/constant';
import TrailerItem from './TrailerItem';
import { Check, ExpandMore, FilterAlt } from '@mui/icons-material';
import useFormat from 'hooks/useFormat';
import usePagination from 'hooks/usePagination';
import useTranslation from 'hooks/useTranslation';
import GlobalSearch from 'components/common/GlobalSearch';
import _ from 'lodash';
import { containsText, matchExactText } from 'utils/filters';
import useFilters from 'hooks/useFilters';
import useMenuAnchor from 'hooks/useMenuAnchor';
import useExtractAddress from 'hooks/useExtractAddress';
import { useGlobalStyles } from 'context/StylesContext';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { devicesActions } from 'store';

const useStyles = makeStyles()(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    list: {
        overflowY: 'auto',
        width: '100%',
    },
    accordionSummary: {
        minHeight: 40,
        maxHeight: 40,
        borderBottomWidth: 0,
        '&.Mui-expanded': {
            minHeight: 40,
            maxHeight: 40,
        }
    },
    accordionDetails: {
        padding: 0,
        gap: 0,
    }, 
    engineContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 16,
        width: '100%',
    }
}));

const DevicesList = ({ deviceTypesTakenIntoAccount }) => {
    const { classes } = useStyles();
    const { classes: globalClasses } = useGlobalStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { formatDeviceCategory } = useFormat();
    const [groupsOpen, setGroupsOpen] = useState({ 
        [DeviceType.VEHICLE]: true, 
        [DeviceType.CONSTRUCTION_MACHINE]: true, 
        [DeviceType.TRAILER]: true, 
        [DeviceType.SMARTPHONE]: true, 
    });
    const { menuAnchor, handleMenuOpen, handleMenuClose } = useMenuAnchor();
    const allItems = useSelector(state => Object.values(state.devices.items).filter(device => deviceTypesTakenIntoAccount.includes(device.category)));
    const filters = useSelector(state => state.devices.assets.filters);
    const page = useSelector(state => state.devices.assets.pagination.page);
    const limit = useSelector(state => state.devices.assets.pagination.limit);
    const groups = useSelector(state => state.groups.items);
    const positions = useSelector(state => state.positions.items);
    const geofences = useSelector(state => state.geofences.items);
    const { extractAddressFromDevice } = useExtractAddress({ positions, geofences });
    let filteredItems = _.cloneDeep(allItems);
    for (let filter of filters) {
        switch (filter.filterKey) {
            case AssetColumns.ITEM_STATUS:
                filteredItems = matchExactText(filteredItems, filter.values, item => t(item.status));
                break;
            case AssetColumns.ITEM_CATEGORY:
                filteredItems = containsText(filteredItems, filter.values, item => formatDeviceCategory(item.category));
                break;
            case AssetColumns.ITEM_LABEL:
                filteredItems = containsText(filteredItems, filter.values, item => item.name);
                break;
            case AssetColumns.ITEM_LICENSE_PLATE:
                filteredItems = containsText(filteredItems, filter.values, item => item.licensePlate);
                break;
            case AssetColumns.ITEM_GROUP:
                filteredItems = containsText(filteredItems, filter.values, item => item.groupId ? groups?.[item.groupId]?.name : '');
                break;
            case 'all':
            default:
                let all = [
                    ...matchExactText(filteredItems, filter.values, item => t(item.status)),
                    ...containsText(filteredItems, filter.values, item => formatDeviceCategory(item.category)),
                    ...containsText(filteredItems, filter.values, item => item.name),
                    ...containsText(filteredItems, filter.values, item => item.licensePlate),
                    ...containsText(filteredItems, filter.values, item => item.groupId ? groups?.[item.groupId]?.name : ''),
                    ...containsText(filteredItems, filter.values, item => extractAddressFromDevice(item)?.addressValue || '')
                ];
                filteredItems = _.uniqBy(all, x => x.id);

        }
    }

    const selectedId = useSelector(state => state.devices.selectedId);
    const [selectedIdOpen, setSelectedIdOpen] = useState();
    const filteredAndSortedItems = filteredItems.sort((item1, item2) => sortByThenBy(item1.category.toLowerCase(), item1.name.toLowerCase(), item2.category.toLowerCase(), item2.name.toLowerCase()));
    const { 
        totalCount, 
        gotoPage,
        setPageSize,
        paginatedItems: items 
    } = usePagination({ 
        items: filteredAndSortedItems, 
        parameterKey: Parameters.ASSET_PAGINATION,
        limit, 
        page,
        changePage: (newPage) => dispatch(devicesActions.updateAssetsPaginationPage(newPage)),
        changeLimit: (newLimit) => dispatch(devicesActions.refreshAssetsPagination({ page: 0, limit: newLimit })),
    });
   
    const toggleGroupOpen = (isExpanded, deviceTypeSelected) => {
        setGroupsOpen(g => ({ ...g, [deviceTypeSelected]: isExpanded }));
    }
    const { handleFilterChange, handleGlobalSearchChange } = useFilters({ 
        filters, 
        filtersId: Parameters.ASSET_FILTERS, 
        setFiltersInCache: (newFilters) => dispatch(devicesActions.refreshAssetsFilters(newFilters)),
        resetPage: () => dispatch(devicesActions.updateAssetsPaginationPage(0)),
    });

    return (
        <div className={classes.root}>
            <div className={classes.engineContainer}>
                <GlobalSearch
                    filters={filters}
                    setFilters={(getFilters) => handleGlobalSearchChange(getFilters)}
                    availableFilters={[
                        'all', 
                        AssetColumns.ITEM_LABEL, 
                        AssetColumns.ITEM_LICENSE_PLATE, 
                        AssetColumns.ITEM_GROUP, 
                        AssetColumns.ITEM_CATEGORY
                    ]}
                />
                <div className={globalClasses.tableEngineFilterAndPagination}>
                    <div onClick={handleMenuOpen} className={globalClasses.tableEngineFilterButton}>
                        <span>{t('filters')}</span>
                        <FilterAlt className={globalClasses.actionIcon}/>
                    </div>
                    <Menu
                        MenuListProps={{
                            className: globalClasses.listCheckableItemList
                        }}
                        anchorEl={menuAnchor}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={Boolean(menuAnchor)}
                        onClose={handleMenuClose}
                    >
                        <List 
                            // subheader={
                            //     <ListSubheader className={classes.filterCategory}>
                            //         <ListItemIcon className={globalClasses.listCheckableItemCheck}>
                            //             <Check className={globalClasses.deselectedIcon}/>
                            //         </ListItemIcon>
                            //         {t(AssetColumns.ITEM_CATEGORY)}
                            //     </ListSubheader>
                            // }
                            disablePadding
                        >
                            { [DeviceType.VEHICLE, DeviceType.CONSTRUCTION_MACHINE, DeviceType.SMARTPHONE, DeviceType.TRAILER, DeviceType.WAREHOUSE].map(value => (
                                <ListItemButton
                                    className={globalClasses.listCheckableItemItem}
                                    key={value}
                                    dense
                                    onClick={(event) => handleFilterChange(AssetColumns.ITEM_CATEGORY, event.currentTarget.textContent)}
                                >
                                    <ListItemIcon className={globalClasses.listCheckableItemCheck}>
                                        { filters.find(f => f.filterKey === AssetColumns.ITEM_CATEGORY)?.values?.includes(formatDeviceCategory(value))
                                            ? <Check/>
                                            : <Check className={globalClasses.deselectedIcon}/>
                                        }
                                    </ListItemIcon>
                                    <span className={globalClasses.listCheckableItemText}>{formatDeviceCategory(value)}</span>    
                                </ListItemButton>
                            ))}
                        </List>
                        <Divider className={globalClasses.transparentDivider}/>
                        <List 
                            // subheader={
                            //     <ListSubheader className={classes.filterCategory}>
                            //         <ListItemIcon className={globalClasses.listCheckableItemCheck}>
                            //             <Check className={globalClasses.deselectedIcon}/>
                            //         </ListItemIcon>
                            //         {t('itemStatusTitle')}
                            //     </ListSubheader>
                            // }
                            disablePadding
                        >
                            { [DeviceStatus.ONLINE, DeviceStatus.OFFLINE].map(value => (
                                <ListItemButton
                                    className={globalClasses.listCheckableItemItem}
                                    key={value}
                                    dense
                                    onClick={(event) => handleFilterChange(AssetColumns.ITEM_STATUS, event.currentTarget.textContent)}
                                >
                                    <ListItemIcon className={globalClasses.listCheckableItemCheck}>
                                        { filters.find(f => f.filterKey === AssetColumns.ITEM_STATUS)?.values?.includes(t(value))
                                            ? <Check/>
                                            : <Check className={globalClasses.deselectedIcon}/>
                                        }
                                    </ListItemIcon>
                                    <span className={globalClasses.listCheckableItemText}>{t(value)}</span>
                                </ListItemButton>
                            ))}
                        </List>
                    </Menu>
                    <TablePagination
                        SelectProps={{ style: { marginRight: 8, marginLeft: 4, paddingTop: 1 }}}
                        sx={{ '.MuiTablePagination-toolbar .MuiTablePagination-actions': {
                                margin: 0,
                            }
                        }}
                        style={{overflow: 'unset'}}
                        page={page}
                        rowsPerPage={limit}
                        count={totalCount}
                        onPageChange={(_, newPage) => gotoPage(newPage)}
                        onRowsPerPageChange={event => setPageSize(parseInt(event.target.value, 10))}
                        component="div"
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('paginationOf')} ${count !== -1 ? count : `${t('paginationMoreThan')} ${to}`}`}
                        labelRowsPerPage={t('paginationRowsPerPage')}
                        ActionsComponent={TablePaginationActions}
                    />
                </div>
            </div>
            <div className={classes.list}>
                { items.length > 0 && Object.entries(groupByCategory(items))
                    .map(([key, values]) => (
                        <Accordion square={true} key={key} expanded={groupsOpen[key]} onChange={(_, isExpanded) => toggleGroupOpen(isExpanded, key)}>
                            <AccordionSummary
                                expandIcon={<ExpandMore style={{alignSelf: 'center'}}/>}
                                classes={{ root: classes.accordionSummary }}
                            >
                                <Typography style={{alignSelf: 'center'}}>
                                    {formatDeviceCategory(key, true).toUpperCase()}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordionDetails}>
                                { values
                                    .map((item, index) => ( item.category === DeviceType.TRAILER 
                                        ? <TrailerItem 
                                            key={item.id} 
                                            item={item} 
                                            index={index} 
                                            isLast={index === (values.length - 1)}
                                            isSelected={selectedId === item.id} 
                                            isOpen={selectedIdOpen === item.id}
                                            setOpen={(open) => {
                                                if(open) {
                                                    setSelectedIdOpen(item.id)
                                                } else {
                                                    setSelectedIdOpen(null);
                                                }
                                            }}
                                        />
                                        : <DeviceItem 
                                            key={item.id} 
                                            item={item} 
                                            index={index} 
                                            isLast={index === (values.length - 1)}
                                            isSelected={selectedId === item.id} 
                                            isOpen={selectedIdOpen === item.id}
                                            setOpen={(open) => {
                                                if(open) {
                                                    setSelectedIdOpen(item.id)
                                                } else {
                                                    setSelectedIdOpen(null);
                                                }
                                            }}
                                        />
                                    ))
                                }
                            </AccordionDetails>
                            
                        </Accordion>
                    ))
                }
            </div>
        </div>
    );
}

export default DevicesList;