import { useState, useEffect } from 'react';
import axios from 'axios';
import {  useDispatch, useSelector } from 'react-redux';

import { geofencesActions, groupsActions, devicesActions, staffersActions, languagesActions, companyActions, sessionActions } from 'store';
import { magellanV0, magellanV1 } from 'api/magellan';
import useTranslation, { navigatorLanguage } from './useTranslation';
import { alertsActions } from 'store';
import { Parameters } from 'utils/constant';
import { filtersActions } from 'store';

const useCachingController = (authenticated) => {
    const [loading, setLoading] = useState();
    const userId = useSelector(state => state?.session?.user?.id);
    const companyId = useSelector(state => state?.session?.user?.companyId);
    const dispatch = useDispatch();
    const { handleChangeLanguage } = useTranslation();

    useEffect(() => {
        if (authenticated) {
            setLoading(true);
            Promise.all([
                magellanV0.get('/api/geofences'),
                magellanV0.get('/api/groups'),
                magellanV0.get('/api/devices'),
                magellanV1.get('/api/staffers'),
                magellanV1.get('/api/languages'),
                magellanV1.get(`/api/users/${userId}`),
                magellanV1.get(`/api/companies/${companyId}`),
                magellanV1.get(`/api/events?isAcked=false`),
                magellanV1.get(`/api/users/${userId}/parameters/${Parameters.ASSET_FILTERS}`),
                magellanV1.get(`/api/users/${userId}/parameters/${Parameters.TOOL_FILTERS}`),
                magellanV1.get(`/api/users/${userId}/parameters/${Parameters.ASSET_PAGINATION}`),
                magellanV1.get(`/api/users/${userId}/parameters/${Parameters.TOOL_PAGINATION}`),
            ])
            .then(([
                geofencesResponse, 
                groupsResponse, 
                devicesResponse, 
                staffersResponse,
                languagesResponse, 
                userResponse,
                companyResponse,
                alertsResponse,
                assetFiltersResponse,
                toolFiltersResponse,
                assetPaginationResponse,
                toolPaginationResponse,
            ]) => {
                dispatch(geofencesActions.update(geofencesResponse.data));
                dispatch(groupsActions.update(groupsResponse.data));
                dispatch(devicesActions.update(devicesResponse.data));
                dispatch(staffersActions.update(staffersResponse.data));
                dispatch(languagesActions.refresh(languagesResponse.data));
                if (companyResponse){
                    dispatch(companyActions.update(companyResponse.data));
                }
                let userColorMode = userResponse.data?.attributes?.colorMode;
                if(userColorMode) {
                    dispatch(sessionActions.updateColorMode(userColorMode));
                }
                dispatch(alertsActions.refresh(alertsResponse.data));
                if (assetFiltersResponse?.data) {
                    dispatch(devicesActions.refreshAssetsFilters(assetFiltersResponse.data));
                }
                if (toolFiltersResponse?.data) {
                    dispatch(devicesActions.refreshToolsFilters(toolFiltersResponse.data));
                }
                if (assetPaginationResponse?.data) {
                    dispatch(devicesActions.refreshAssetsPagination(assetPaginationResponse.data));
                }
                if (toolPaginationResponse?.data) {
                    dispatch(devicesActions.refreshToolsPagination(toolPaginationResponse.data));
                }
                let userLanguageId = userResponse.data?.language?.id;
                if(userLanguageId) {
                    dispatch(languagesActions.select(userLanguageId));
                } else {
                    let languageId = languagesResponse.data.find(l => l.alpha2Code === navigatorLanguage)?.id;
                    if(languageId){
                        handleChangeLanguage(languageId);
                    }
                }
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
        }
    }, [authenticated]);

    return { loading };
}

export default useCachingController;
