import { useState, useEffect } from 'react';
import { subHours } from 'date-fns';

import { magellanV0 } from 'api/magellan';

const useGetMotions = (props) => {
    const today = new Date();
    const {
        deviceId, 
        from = new Date(new Date(today).setHours(0, 0, 0, 0)), 
        to = new Date(today.setHours(23, 59, 59, 999)), 
        refreshInterval = 0
    } = props;
    const [motions, setMotions] = useState({trips:[], stops:[]});

    useEffect(() => {
        if (deviceId && from && to) {
            const fetchMotions = async () => {
                let adaptedFrom = subHours(from, 2);
                try {
                    await Promise.all([
                        magellanV0.get(`/api/reports/trips?deviceId=${deviceId}&from=${adaptedFrom.toISOString()}&to=${to.toISOString()}`),
                        magellanV0.get(`/api/reports/stops?deviceId=${deviceId}&from=${adaptedFrom.toISOString()}&to=${to.toISOString()}`)
                    ])
                    .then(([tripsResponse, stopsResponse]) => {
                        setMotions({
                            trips: tripsResponse.data.filter(trip => new Date(trip.endTime) > from), 
                            stops: stopsResponse.data.filter(stop => new Date(stop.endTime) > from)
                        });
                    });
                } catch (error) { }
            };
    
    
            fetchMotions();
            if (refreshInterval > 0) {
                const intervalId = setInterval(() => fetchMotions(), 1000 * refreshInterval);
                
                return () => {
                    clearInterval(intervalId);
                };
            }
        }
    }, [deviceId, from.toISOString(), to.toISOString()]);

    return { motions };
};

export default useGetMotions;