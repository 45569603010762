import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    SvgIcon,
    ThemeProvider,
    useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { 
    AllInbox,
    Business,
    Construction,
    DriveEta,
    Map,
    EnergySavingsLeaf,
    Folder,
    FormatListBulleted,
    LocationOn,
    Notifications,
    NotificationsActive,
    PauseCircleFilled,
    People,
    Person,
    PlayCircleFilled,
    Replay,
    Smartphone,
    Storage,
    Timeline,
    TrendingUp,
    Warehouse,
    BuildCircle,
    Badge,
    Rule,
    EventNote,
    AccountCircleOutlined,
} from '@mui/icons-material';
import { createTheme } from '@mui/material/styles';
import { FaTrailer } from 'react-icons/fa';

import useTranslation from 'hooks/useTranslation';
import { useColorMode } from 'context/ColorModeContext';
import { ColorMode } from 'utils/constant';

const drawerWidthOpen = 240;
const drawerWidthClose = 64;

const useStyles = makeStyles()(theme => ({
    drawer: {
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overflowY: 'auto',
        scrollbarWidth: 'thin',    
        '&::-webkit-scrollbar':{
            width: 10,
        }
    },
    greenScrollbar: {
        scrollbarColor: `${theme.palette.tertiary.main} ${theme.palette.quaternary.main}`, 
        '&::-webkit-scrollbar':{
            backgroundColor: theme.palette.quaternary.main,
        },
        '&::-webkit-scrollbar-thumb':{
            backgroundColor: theme.palette.tertiary.main,
        },
    },
    blueScrollbar: {
        scrollbarColor: `${theme.palette.tertiary.main} ${theme.palette.tertiary.main}`, 
        '&::-webkit-scrollbar':{
            backgroundColor: theme.palette.quaternary.main,
        },
        '&::-webkit-scrollbar-thumb':{
            backgroundColor: theme.palette.tertiary.main,
        },
    },
    darkScrollbar: {
        scrollbarColor: `${theme.palette.tertiary.main} ${theme.palette.tertiary.main}`, 
        '&::-webkit-scrollbar':{
            backgroundColor: theme.palette.quaternary.main,
        },
        '&::-webkit-scrollbar-thumb':{
            backgroundColor: theme.palette.selected.main,
        },
    },
    drawerOpen: {
        width: drawerWidthOpen,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: drawerWidthClose,
        // width: theme.spacing(7) + 1,
        // [theme.breakpoints.up('sm')]: {
        //     width: theme.spacing(7) + 1,
        // },
    },
    list:{
        overflowX: 'hidden',
    },
    initial: {
        textAlign: 'center',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
    },
}));


const NavigationMenu = ({drawerOpen, adminEnabled, managerEnabled, user, loading, isLatestVersion}) => {
    const { t } = useTranslation();
    const { classes, cx } = useStyles();
    const { colorMode } = useColorMode();
    const navigate = useNavigate();    
    const { pathname } = useLocation();
    const theme = useTheme();
    let navigationMenuTheme = theme;

    const blueNavigationMenuTheme = createTheme(theme, {
        components: {        
            MuiDivider:{
                styleOverrides: {
                    root: {
                        backgroundColor : theme.palette.primary.main,
                    }
                }
                
            }, 
            MuiList : {
                styleOverrides: {
                    root: {
                        backgroundColor: theme.palette.secondary.main,
                    }
                }
            },
            MuiListItem : {
                styleOverrides: {
                    root: {
                        color: theme.palette.primary.main,
                        
                    },
    
                    button: {
                        "&:hover, &.Mui-selected, &.Mui-selected:hover":{
                            backgroundColor : theme.palette.primary.main,
                            color: theme.palette.secondary.main,
                        },
                        "&:hover .MuiSvgIcon-root, &.Mui-selected .MuiSvgIcon-root": {
                            color : theme.palette.secondary.main,
                        },
                    },
                }
            },
            MuiListSubheader: {
                styleOverrides: {
                    root: {
                        backgroundColor: theme.palette.secondary.main,
                        color : theme.palette.primary.main,
                    }
                }
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        color: theme.palette.primary.main,
                    },
                }
            },
        }
    });

    const greenNavigationMenuTheme = createTheme(theme, {
        components: {        
            MuiList : {
                styleOverrides: {
                    root: {
                        backgroundColor: theme.palette.quaternary.main,
                    }
                }
            },
            MuiListSubheader: {
                styleOverrides: {
                    root: {
                        backgroundColor: theme.palette.quaternary.main,
                    }
                }
            },
        }
    });

    let scrollbarClass = null;
    switch (colorMode) {
        case ColorMode.BLUE:
            scrollbarClass = classes.blueScrollbar;
            navigationMenuTheme = blueNavigationMenuTheme;
            break;
        case ColorMode.DARK: 
            scrollbarClass = classes.darkScrollbar;
            break;
        case ColorMode.GREEN:
        default:   
            scrollbarClass = classes.greenScrollbar;
            navigationMenuTheme = greenNavigationMenuTheme         
    }


    const renderCarteAndReplay = () =>  {
        return (
            <List className={colorMode === ColorMode.GREEN ? classes.forceDarkList: null}>
                 <ListItem selected={pathname === '/app/assets'} dense button onClick={() => navigate(`/app/assets`)}>
                    <ListItemIcon>
                        <AllInbox />
                    </ListItemIcon>
                    <ListItemText primary={t('assetsTitle')} />
                </ListItem>
                <ListItem selected={pathname === '/app/tools'} dense button onClick={() => navigate(`/app/tools`)}>
                    <ListItemIcon>
                        <Construction />
                    </ListItemIcon>
                    <ListItemText  
                        classes={{primary:classes.listItemText}}
                        primary={t("toolsTitle")} 
                    />
                </ListItem>
                <ListItem selected={pathname === '/app/home'} dense button onClick={() => navigate(`/app/home`)}>
                    <ListItemIcon>
                        <Map />
                    </ListItemIcon>
                    <ListItemText primary={t('mapTitle')} />
                </ListItem>
                <ListItem selected={pathname === '/app/replay'} dense button onClick={() => navigate(`/app/replay`)}>
                    <ListItemIcon>
                        <Replay />
                    </ListItemIcon>
                    <ListItemText primary={t('reportReplay')} />
                </ListItem>
            </List>
        );
    };

    const renderTimesheetList = () => {
        return (
            <>
                <Divider />
                <List className={colorMode === ColorMode.GREEN ? classes.forceDarkList: null}
                    subheader={
                        <ListSubheader className={cx(classes.listSubheader, (colorMode === ColorMode.GREEN ? classes.forceDarkList: null))}>
                          {drawerOpen ? t('timesheetTitle') : <div className={classes.initial}>{t('timesheetTitle')[0]}</div>}
                        </ListSubheader>
                    }
                >
                    <ListItem selected={pathname === '/app/timesheets/presence'} dense button onClick={() => navigate(`/app/timesheets/presence`)}>
                        <ListItemIcon>
                            <AccountCircleOutlined/>
                        </ListItemIcon>
                        <ListItemText primary={t('presenceTitle')} />
                    </ListItem>
                    <ListItem selected={pathname === '/app/timesheets/validation'} dense button onClick={() => navigate(`/app/timesheets/validation`)}>
                        <ListItemIcon>
                            <Rule/>
                        </ListItemIcon>
                        <ListItemText primary={t('timesheetValidationTitle')} />
                    </ListItem>
                    <ListItem selected={pathname === '/app/timesheets/history'} dense button onClick={() => navigate(`/app/timesheets/history`)}>
                        <ListItemIcon>
                            <EventNote/>
                        </ListItemIcon>
                        <ListItemText primary={t('timesheetHistoryTitle')} />
                    </ListItem>
                </List>
            </>
        );
    };

    const renderReportList = () => {
        return (
            <>
                <Divider />
                <List className={colorMode === ColorMode.GREEN ? classes.forceDarkList: null}
                    subheader={
                        <ListSubheader className={cx(classes.listSubheader, (colorMode === ColorMode.GREEN ? classes.forceDarkList: null))}>
                          {drawerOpen ? t('reportTitle') : <div className={classes.initial}>{t('reportTitle')[0]}</div>}
                        </ListSubheader>
                    }
                >
                    <ListItem selected={pathname === '/app/reports/safeDriving'} dense button onClick={() => navigate(`/app/reports/safeDriving`)}>
                        <ListItemIcon>
                            <EnergySavingsLeaf/>
                        </ListItemIcon>
                        <ListItemText primary={t('safeDrivingTitle')} />
                    </ListItem>
                    <ListItem selected={pathname === '/app/reports/toolsAndTrailersEvents'} dense button onClick={() => navigate(`/app/reports/toolsAndTrailersEvents`)}>
                        <ListItemIcon>
                            <BuildCircle/>
                        </ListItemIcon>
                        <ListItemText primary={t('toolsAndTrailersEventsTitle')} />
                    </ListItem>
                    <ListItem selected={pathname === '/app/reports/event'} dense button onClick={() => navigate(`/app/reports/event`)}>
                        <ListItemIcon>
                            <NotificationsActive />
                        </ListItemIcon>
                        <ListItemText primary={t('reportEvents')} />
                    </ListItem>
                    <ListItem selected={pathname === '/app/reports/trip'} dense button onClick={() => navigate(`/app/reports/trip`)}>
                        <ListItemIcon>
                            <PlayCircleFilled />
                        </ListItemIcon>
                        <ListItemText primary={t('reportTrips')} />
                    </ListItem>
                    <ListItem selected={pathname === '/app/reports/stop'} dense button onClick={() => navigate(`/app/reports/stop`)}>
                        <ListItemIcon>
                            <PauseCircleFilled />
                        </ListItemIcon>
                        <ListItemText primary={t('reportStops')} />
                    </ListItem>
                    <ListItem selected={pathname === '/app/reports/summary'} dense button onClick={() => navigate(`/app/reports/summary`)}>
                        <ListItemIcon>
                            <FormatListBulleted />
                        </ListItemIcon>
                        <ListItemText primary={t('reportSummary')} />
                    </ListItem>
                    <ListItem selected={pathname === '/app/reports/route'} dense button onClick={() => navigate(`/app/reports/route`)}>
                        <ListItemIcon>
                            <Timeline />
                        </ListItemIcon>
                        <ListItemText primary={t('reportRoute')} />
                    </ListItem>
                    <ListItem selected={pathname === '/app/reports/chart'} dense button onClick={() => navigate(`/app/reports/chart`)}>
                        <ListItemIcon>
                            <TrendingUp />
                        </ListItemIcon>
                        <ListItemText primary={t('reportChart')} />
                    </ListItem>
                </List>
            </>
        );
    };

    const renderSignageList = () =>  {
        return (
            <>
                <Divider/>
                <List className={colorMode === ColorMode.GREEN ? classes.forceDarkList: null}                    
                    subheader={
                    <ListSubheader className={cx(classes.listSubheader, (colorMode === ColorMode.GREEN ? classes.forceDarkList: null))}>
                        {drawerOpen ? t('signageTitle') : <div className={classes.initial}>{t('signageTitle')[0]}</div>}
                    </ListSubheader>
                    }
                >
                    <ListItem selected={pathname.includes('/app/signage/vehicle')} dense button disabled={!(user && user.id)} onClick={() => navigate(`/app/signage/vehicles`)}>
                        <ListItemIcon>
                            <DriveEta />
                        </ListItemIcon>
                        <ListItemText  
                            classes={{primary:classes.listItemText}}
                            primary={t("signageVehicles")} 
                        />
                    </ListItem>
                    <ListItem selected={pathname.includes('/app/signage/constructionmachine')} dense button onClick={() => navigate(`/app/signage/constructionmachines`)}>
                        <ListItemIcon >
                            <SvgIcon >
                                <path d="M 21.609375 7.023438 C 21.441406 6.789062 21.167969 6.648438 20.875 6.648438 L 17.417969 6.648438 C 17.050781 6.648438 16.71875 6.871094 16.582031 7.210938 L 14.234375 13.003906 L 12.125 13.003906 L 11.238281 4.753906 C 11.191406 4.296875 10.804688 3.949219 10.34375 3.949219 L 0.902344 3.949219 C 0.402344 3.949219 0 4.351562 0 4.847656 C 0 5.347656 0.402344 5.75 0.902344 5.75 L 1.296875 5.75 L 1.296875 13.09375 C 1.175781 13.035156 1.042969 13.003906 0.902344 13.003906 C 0.402344 13.003906 0 13.40625 0 13.902344 L 0 15.882812 C 0 16.242188 0.210938 16.550781 0.515625 16.695312 C 1.058594 16.1875 1.785156 15.878906 2.585938 15.878906 L 12.433594 15.878906 L 12.316406 14.804688 L 14.84375 14.804688 C 15.210938 14.804688 15.539062 14.582031 15.675781 14.242188 L 18.023438 8.449219 L 20.410156 8.449219 L 21.003906 9.28125 L 23.214844 9.28125 Z M 4.160156 11.1875 L 3.097656 11.1875 L 3.097656 5.75 L 4.160156 5.75 Z M 5.960938 11.1875 L 5.960938 5.75 L 9.535156 5.75 L 10.117188 11.1875 Z M 5.960938 11.1875 "/>
                                <path d="M 21.183594 10.179688 L 21.183594 12.472656 C 22.824219 13.199219 24.160156 14.4375 25 16.019531 L 25 10.179688 Z M 21.183594 10.179688 "/>
                                <path d="M 17.875 16.777344 L 2.585938 16.777344 C 1.40625 16.777344 0.449219 17.738281 0.449219 18.914062 C 0.449219 20.09375 1.40625 21.050781 2.585938 21.050781 L 17.875 21.050781 C 19.054688 21.050781 20.011719 20.09375 20.011719 18.914062 C 20.011719 17.738281 19.054688 16.777344 17.875 16.777344 Z M 2.792969 19.777344 C 2.316406 19.777344 1.929688 19.390625 1.929688 18.914062 C 1.929688 18.4375 2.316406 18.050781 2.792969 18.050781 C 3.269531 18.050781 3.65625 18.4375 3.65625 18.914062 C 3.65625 19.390625 3.269531 19.777344 2.792969 19.777344 Z M 17.78125 19.777344 C 17.304688 19.777344 16.921875 19.390625 16.921875 18.914062 C 16.921875 18.4375 17.304688 18.050781 17.78125 18.050781 C 18.257812 18.050781 18.644531 18.4375 18.644531 18.914062 C 18.644531 19.390625 18.257812 19.777344 17.78125 19.777344 Z M 17.78125 19.777344 "/>
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText  
                            classes={{primary:classes.listItemText}}
                            primary={t('signageConstructionMachines')} 
                        />
                    </ListItem>
                    <ListItem selected={pathname.includes('/app/signage/smartphone')} dense button onClick={() => navigate(`/app/signage/smartphones`)}>
                        <ListItemIcon>
                            <Smartphone />
                        </ListItemIcon>
                        <ListItemText  
                            classes={{primary:classes.listItemText}}
                            primary={t("signageSmartphones")} 
                        />
                    </ListItem>
                    <ListItem selected={pathname.includes('/app/signage/warehouse')} dense button onClick={() => navigate(`/app/signage/warehouses`)}>
                        <ListItemIcon>
                            <Warehouse />
                        </ListItemIcon>
                        <ListItemText  
                            classes={{primary:classes.listItemText}}
                            primary={t("signageWarehouses")} 
                        />
                    </ListItem>
                    <ListItem selected={pathname.includes('/app/signage/tool')} dense button onClick={() => navigate(`/app/signage/tools`)}>
                        <ListItemIcon>
                            <Construction />
                        </ListItemIcon>
                        <ListItemText  
                            classes={{primary:classes.listItemText}}
                            primary={t("signageTools")} 
                        />
                    </ListItem>
                    <ListItem selected={pathname.includes('/app/signage/trailer')} dense button onClick={() => navigate(`/app/signage/trailers`)}>
                        <ListItemIcon>
                            <SvgIcon>
                                <FaTrailer/>
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText  
                            classes={{primary:classes.listItemText}}
                            primary={t("signageTrailers")} 
                        />
                    </ListItem>
                    <ListItem selected={pathname.includes('/app/signage/geofence')} dense button onClick={() => navigate(`/app/signage/geofences`)}>
                        <ListItemIcon>
                            <LocationOn/>
                        </ListItemIcon>
                        <ListItemText  
                            classes={{primary:classes.listItemText}}
                            primary={t('sharedGeofences')} 
                        />
                    </ListItem>
                    <ListItem selected={pathname.includes('/app/signage/staffer')} dense button onClick={() => navigate('/app/signage/staffers')}>
                        <ListItemIcon>
                            <Badge/>
                        </ListItemIcon>
                        <ListItemText 
                            classes={{primary:classes.listItemText}}
                            primary={t('sharedStaffers')} 
                        />
                    </ListItem>
                </List>
            </>
        );
    };

    const renderSettingList = () => {
        return (
            <>
                <Divider />
                <List className={colorMode === ColorMode.GREEN ? classes.forceDarkList: null}                   
                    subheader={
                        <ListSubheader className={cx(classes.listSubheader, (colorMode === ColorMode.GREEN ? classes.forceDarkList: null))}>
                            {drawerOpen ? t('settingsTitle') : <div className={classes.initial}>{t('settingsTitle')[0]}</div>}
                        </ListSubheader>
                    }>
                    <ListItem selected={pathname.includes('/app/settings/account')} dense button disabled={!(user && user.id)} onClick={() => navigate(`/app/settings/account/${user && user.id}`)}>
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText primary={t('settingsUser')} />
                    </ListItem>
                    <ListItem selected={pathname.includes('/app/settings/notification')} dense button onClick={() => navigate(`/app/settings/notifications`)}>
                        <ListItemIcon>
                            <Notifications />
                        </ListItemIcon>
                        <ListItemText primary={t('sharedNotifications')} />
                    </ListItem>
                    {managerEnabled && (
                        <>
                            <ListItem selected={pathname.includes('/app/settings/group')} dense button onClick={() => navigate(`/app/settings/groups`)}>
                                <ListItemIcon>
                                    <Folder />
                                </ListItemIcon>
                                <ListItemText primary={t('settingsGroups')} />
                            </ListItem>
                        </>
                    )}
                </List>
            </>
        );
    };

    const renderAdminList = () => {
        return (
            <>
                <Divider />
                <List className={colorMode === ColorMode.GREEN ? classes.forceDarkList: null}                   
                    subheader={
                        <ListSubheader className={cx(classes.listSubheader, (colorMode === ColorMode.GREEN ? classes.forceDarkList: null))}>
                            {drawerOpen ? t('userAdmin') : <div className={classes.initial}>{t('userAdmin')[0]}</div>}
                        </ListSubheader>
                    }>
                    {adminEnabled && (
                        <ListItem selected={pathname === '/app/admin/server'} dense button onClick={() => navigate(`/app/admin/server`)}>
                            <ListItemIcon>
                                <Storage />
                            </ListItemIcon>
                            <ListItemText primary={t('settingsServer')} />
                        </ListItem>
                    )}
                    {!adminEnabled && (
                        <ListItem selected={pathname.includes('/app/admin/company')} dense button onClick={() => navigate(`/app/admin/company${user && user.companyId ? `/${user.companyId}` : ''}`)}>
                            <ListItemIcon>
                                <Business />
                            </ListItemIcon>
                            <ListItemText primary={t('settingsCompany')} />
                        </ListItem>
                    )}
                    {(adminEnabled || managerEnabled) && (
                        <ListItem selected={pathname.includes('/app/admin/user')} dense button onClick={() => navigate(`/app/admin/users`)}>
                            <ListItemIcon>
                                <People />
                            </ListItemIcon>
                            <ListItemText primary={t('settingsUsers')} />
                        </ListItem>
                    )}
                </List>
            </>
        );
    };

    const render = () => {
        return (
            <>
                <div 
                    className={cx(classes.drawer, scrollbarClass, (drawerOpen ? classes.drawerOpen :classes.drawerClose))}
                >
                    <div style={{height: !loading && !isLatestVersion ? theme.fixedSize.updateBannerHeight : 0/*`calc(${theme.fixedSize.toolbar.height} + ${theme.fixedSize.updateBanner.height})` : theme.fixedSize.toolbar.height*/}}/>
                    <div
                        className={classes.list}
                        tabIndex={0}
                        role="button"
                    >
                        {!adminEnabled && renderCarteAndReplay()}       
                        {!adminEnabled && renderTimesheetList()}   
                        {!adminEnabled && renderReportList()}
                        {!adminEnabled && renderSignageList()}
                        {!adminEnabled && renderSettingList()}
                        {renderAdminList()}
                    </div>
                </div>
            </>
        );
    }

    return ( 
        <ThemeProvider theme={navigationMenuTheme}>
            {render()}
        </ThemeProvider>
    );
}

export default NavigationMenu;