import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Link } from "react-router-dom";
import { makeStyles } from 'tss-react/mui';
import { Button, TextField, Typography, CircularProgress, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import * as yup from 'yup';

import { magellanV0NotIntercept401 } from 'api/magellan';
import { useGlobalStyles } from 'context/StylesContext';
import useTranslation from 'hooks/useTranslation';

const useStyles = makeStyles()(theme => ({
    title: {
        color: theme.palette.secondary.main,
        fontSize: '1.4rem',
    },
    form: {
        width: '100%'
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    loadingContainer: {
        height: '20vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));

const AccountRecovery = () => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const { classes: globalClasses } = useGlobalStyles();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const handleErrorResponseStatus = (status, email) => {
        switch(status) {
            case 404:
                setError(`${t('errorNoAccountFound')} ${email}`);
                break;
            default: 
            setError(t('errorOccured'));
        }
    }

    const onSubmit = ({ email }) => {
        setLoading(true);
        magellanV0NotIntercept401.put('/api/password/reset', new URLSearchParams(`email=${email}`))
            .then(() => {
                setEmailSent(true);
            })
            .catch(e => {
                handleErrorResponseStatus(e.response.status, email);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: yup.object().shape({
            email: yup.string()
                .trim()
                .required(t('validationRequired'))
                .email(t('validationEmailAddress')),
        }), 
        onSubmit,
    });

    const renderAccountRecovery = () => {
        return (
            <>
                <Typography className={classes.title}>{t('loginRecoverAccount')}</Typography>
                <form onSubmit={formik.handleSubmit} className={classes.form}>
                    <div className={classes.inputContainer}>
                        <TextField
                            margin='normal'
                            required
                            fullWidth
                            label={t('userEmail')}
                            name='email'
                            value={formik.values.email}
                            autoFocus
                            type='text'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <Tooltip title={t('loginEmailAccountRecoveryHelp')} placement="right">
                            <HelpOutlineIcon fontSize="small"/>
                        </Tooltip>
                    </div>
                    
    
                    <div className={globalClasses.loginFormControl}>
                        <Link className={globalClasses.link} to="/auth">{t('loginBackToLoginPage')}</Link>
                        <Button 
                            type="submit" 
                            variant="contained" 
                            color="secondary" 
                            disabled={formik.errors.email}
                        >
                            {t('loginSendEmail')}
                        </Button>
                    </div>
                </form>
            </>
        );
    };

    const render = () => {
        if(loading) {
            return (
                <div className={classes.loadingContainer}>
                    <CircularProgress color="secondary"/>
                </div>
            );
        } else {
            if(error) {
                return (
                    <>
                        <Typography>{error}</Typography>
                        <Link className={globalClasses.link} to="/auth">{t('loginBackToLoginPage')}</Link>
                    </>
                );
            } else {
                if(emailSent){
                    return <Typography>{t('loginEmailSent')}</Typography>
                }  
                return renderAccountRecovery();
            }
        }
    };

    return render();
};

export default AccountRecovery;
