import { nanoid } from "@reduxjs/toolkit";
import _ from 'lodash';
import { TimesheetErrorCode } from "utils/constant";

const createNewItem = () => { 
    let startTime = new Date();
    startTime.setHours(8, 30, 0);
    let endTime = new Date();
    endTime.setHours(17, 0, 0);
    return _.cloneDeep({ id: nanoid(), date: new Date().toISOString(), startTime: startTime.toISOString(), endTime: endTime.toISOString(), breakTime: 0, errorCode: TimesheetErrorCode.NO_ERROR, stafferId: null, verified: false, isNewItem: true });
};

export const timesheetReducer = {
    load(state, action) {
        state.loading = action.payload;
        state.lastAction = action.type;
    },
    initialize(state, action) {
        state.initialItems = {};
        action.payload.forEach(item => state.initialItems[item.id] = item);
        state.items = _.cloneDeep(state.initialItems);
        state.loading = state.loading = false;
        state.lastAction = action.type;
    },
    enableEdition(state, action) {
        let { payload: id } = action;
        if(state.items[id]) {
            state.items[id].isEditable = true;
        }
    },
    disableEdition(state, action) {
        let { payload: id } = action;
        if(state.items[id]) {
            state.items[id].isEditable = false;
        }
    },
    updateItem(state, action) {
        let { payload: { id, prop, value: newValue } } = action;
        let oldStartTime, oldEndTime, newStartTime, newEndTime;

        if(state.items?.[id]) {
            state.items[id][prop] = newValue;
        } else if(state.newItems?.[id]) {
            state.newItems[id][prop] = newValue;
            if(prop === 'date') {
                oldStartTime = new Date(state.newItems[id].startTime);
                oldEndTime = new Date(state.newItems[id].endTime);
                newStartTime = new Date(newValue);
                newEndTime = new Date(newValue);
                newStartTime.setHours(oldStartTime.getHours(), oldStartTime.getMinutes(), oldStartTime.getSeconds(), oldStartTime.getMilliseconds());
                newEndTime.setHours(oldEndTime.getHours(), oldEndTime.getMinutes(), oldEndTime.getSeconds(), oldEndTime.getMilliseconds());
                state.newItems[id].startTime = newStartTime.toISOString();
                state.newItems[id].endTime = newEndTime.toISOString();
            }
        }
        state.lastAction = action.type;
    },
    removeItem(state, action) {
        let { payload: id } = action;
        if(state.items?.[id]) {
            delete state.items?.[id];
        } else if(state.newItems?.[id]) {
            delete state.newItems?.[id];
        }
        state.lastAction = action.type;
    },
    resetItem(state, action) {
        let { payload: id } = action;
        if(state.initialItems?.[id]) {
            state.items[id] = state.initialItems?.[id];
        } else {
            state.newItems[id] = { ...createNewItem(), id };
        }
        state.lastAction = action.type;
    },
    restoreItem(state, action) {
        let { payload: item } = action;
        if(state.initialItems?.[item?.id]) {
            state.items[item.id] = item;
        } else {
            state.newItems[item.id] = item;
        }
        state.lastAction = action.type;
    },
    addNewItem(state, action) {
        let newItem = createNewItem();
        state.newItems = { ...state.newItems, [newItem.id]: newItem }
        state.lastAction = action.type;
    },
    refreshPagination(state, action) {
        state.pagination = action.payload;
    },
    updatePaginationLimit(state, action) {
        state.pagination.limit = action.payload;
    },
    updatePaginationPage(state, action) {
        state.pagination.page = action.payload;
    },
    
};