import { magellanV1 } from "api/magellan";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const useMemoizePagination = ({ parameterKey, gotoPage, setPageSize, saveLimit, savePage, savedPage, savedLimit }) => {
    const userId = useSelector(state => state.session.user.id);

    const handleChangePage = (newPage) => {
        savePage(newPage);
        magellanV1.post(`api/users/${userId}/parameters/${parameterKey}`, {
            page: newPage || 0,
            limit: savedLimit || 50,
        }).catch();
    };

    const handleChangeLimit = (newLimit) => {
        saveLimit(newLimit);   
        magellanV1.post(`api/users/${userId}/parameters/${parameterKey}`, {
            page: 0,
            limit: newLimit || 50,
        }).catch();     
    }

    useEffect(() => {
        if (!isNaN(savedPage)) {
            gotoPage(savedPage);
        }
    }, [savedPage]);

    useEffect(() => {
        if (!isNaN(savedLimit)) {
            setPageSize(savedLimit);
        }
    }, [savedLimit]);

    return { handleChangePage, handleChangeLimit };
};

export default useMemoizePagination;