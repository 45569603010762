import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    root: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%'
    },
    content: {
        width: '60%',
    },
}));

const withSignageLayout = () => WrappedComponent => {
    const WithSignageLayout = props =>{ 
        const { classes } = useStyles(); 

        return (
            <div className={classes.root}>
                <div className={classes.content}>
                    <WrappedComponent {...props}/>
                </div>
            </div>
        );
    }

    return WithSignageLayout;
}

export default withSignageLayout;