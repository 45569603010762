import React from 'react';

import { magellanV0 } from 'api/magellan';
import withSignageLayout from 'hoc/withSignageLayout';
import { useSignage } from 'hooks/useSignage';
import SignageHeader from '../../common/SignageHeader';
import Loading from '../../common/Loading';
import CompanyForm from './CompanyForm';
import { useDispatch } from 'react-redux';
import { usersActions } from 'store/users';
import useTranslation from 'hooks/useTranslation';
import useAlertMessage from 'hooks/useAlertMessage';
import { useNavigate } from 'react-router-dom';
import { getInitialTimetable } from 'utils/timetable';

const initialDefaultValues = {
    name: '',
    legalStatus: '',
    mailAddress: '',
    phoneNumber: '',
    logo: '',
    isPrivateOutsideWorkingHours: false,
    address: {
        street: '',
        houseNumber: '',
        locality: null,
        latitude: 0,
        longitude: 0
    },
    calendar: getInitialTimetable(),
}

const CreateCompany = ({ role, filterDataForServer }) => {
    const { editionMode, setEditionMode, loading, setLoading } = useSignage({ readApi: magellanV0, createApi: magellanV0, updateApi: magellanV0 }, 'companies', true, false);
    const { setAlertMessage, creationErrorAlertMessage, savedAlertMessage } = useAlertMessage();
    const navigate = useNavigate();
    const dispatch = useDispatch(); 

    const createData = async (submission, redirection = true) => {
        setLoading(true);
        await magellanV0.post(`/api/companies`, submission, { headers: {'Content-Type': 'application/json'} })
                .then(response => {
                    if(response.data.id){
                        let companyId = response.data.id;
                        setAlertMessage(savedAlertMessage);
                        dispatch(usersActions.updateCompany(companyId))
                        setLoading(false);
                        if(redirection){
                            navigate(`${companyId}`, { replace: true });
                        }
                        
                    }
                })
                .catch(error => {
                    setAlertMessage(creationErrorAlertMessage);
                    setLoading(false);
                })
    }

    const onSubmit = (formValues) => {
        createData(filterDataForServer({...formValues}));
    };
    
    return (
        <>
            {loading 
            ? <Loading />
            : <>
                <SignageHeader 
                    editionMode={editionMode} 
                    setEditionMode={setEditionMode} 
                    editionEnabled={true}
                    showGoBack={false}
                />
                <CompanyForm
                    editionMode={editionMode}
                    initialValues={initialDefaultValues}
                    onSubmit={onSubmit}
                />
            </>}
           
        </>
    );
}

export default withSignageLayout()(CreateCompany);