import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
    name: 'company',
    initialState: {
        item: {},
    },
    reducers: {
        update(state, action) {
            state.item = action.payload;
        },
    }
});

export { actions as companyActions };
export { reducer as companyReducer };
