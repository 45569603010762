import React from 'react';
import _ from 'lodash';

import { LocationOn } from '@mui/icons-material';
import None from 'components/common/None';


export const getColumns = (cx, globalClasses, t, formatDate, formatEventType, extractAddress) => [{
    Header: t('positionFixTime'),
    accessor: 'eventTime',
    Cell: ({ cell: { value } }) => formatDate(value),
    props: {
        className: globalClasses.tableCellFirst,
    }, 
}, {
    Header: t('sharedType'),
    accessor: 'type',
    Cell: ({ cell: { row: { original: row } } }) => formatEventType(row),
    sortType: (rowA, rowB) => { 
        let typeA = formatEventType(rowA.original);
        let typeB = formatEventType(rowB.original);
        return t(typeA).localeCompare(t(typeB));
    },
}, {
    Header: t('sharedAddress'),
    accessor: 'geofenceId',
    Cell: ({ cell: { row: { original: row } } }) => {
        const { isGeofence, addressValue } = extractAddress(row);
        return (
            <>
                <LocationOn className={isGeofence ? globalClasses.decorativeIcon : cx(globalClasses.decorativeIcon, globalClasses.invisible)}/>
                {addressValue || <None text={t('itemAddressNone')}/>}
            </>
        );
    },
    headerProps: {
        className: globalClasses.tableAddressHeader,
    },
},];