import * as yup from 'yup';

export const getDateValidation = (t) => yup.date()
    .required(t('validationRequired'))
    .typeError(t('validationDate'));

export const getTimesheetValidation = (t) => yup.object().shape({
    date: getDateValidation(t),
    startTime: getDateValidation(t),
    endTime: getDateValidation(t),
});