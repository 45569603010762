import { useState, } from "react";

const useGlobalSearch = ({ filters: activeFilters, setFilters: setActiveFilters, availableFilters }) => {
    const [inputFilter, setInpuFilter] = useState('');
    
    const getOptions = () => {
        let options = [];
        if(inputFilter.trim() !== '') {
            options = availableFilters.map(filterKey => {
                let currentValues = activeFilters.find(activeFilter => activeFilter.filterKey === filterKey)?.values || [];
                return { filterKey, values: [...currentValues, inputFilter] }
            });
        }

        return options;
    };

    return { activeFilters, setActiveFilters, inputFilter, setInpuFilter, getOptions }
};

export default useGlobalSearch;