import { useState, useEffect } from "react";
import useAlertMessage from "hooks/useAlertMessage";

const useFetchGlobalSearchFilters = ({ api, location }) => {
    const [availableFilters, setAvailableFilters] = useState([]);
    const { setAlertMessage, dataLoadingErrorAlertMessage } = useAlertMessage();

    const getAvailableFilters = async () => {
        try {
            let response = await api.get(location);
            setAvailableFilters(response.data);
        } catch (e) {
            setAlertMessage(dataLoadingErrorAlertMessage);
        };
    };

    useEffect(() => {
        getAvailableFilters();
    }, []);

    return { availableFilters };
};

export default useFetchGlobalSearchFilters;