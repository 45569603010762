import _ from 'lodash';

const defaultStartDate = new Date(2000, 0, 1, 8, 30); 
const defaultEndDate = new Date(2000, 0, 1, 17, 30);

export const getInitialTimetable = () => ({
    mondayStartHour : defaultStartDate.toString(),
    mondayEndHour: defaultEndDate.toString(),
    tuesdayStartHour: defaultStartDate.toString(),
    tuesdayEndHour: defaultEndDate.toString(),
    wednesdayStartHour: defaultStartDate.toString(),
    wednesdayEndHour: defaultEndDate.toString(),
    thursdayStartHour: defaultStartDate.toString(),
    thursdayEndHour: defaultEndDate.toString(),
    fridayStartHour: defaultStartDate.toString(),
    fridayEndHour: defaultEndDate.toString(),
    saturdayStartHour : null,
    saturdayEndHour: null,
    sundayStartHour : null,
    sundayEndHour: null,
});