import { Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
    },
    logo: {
        height: 100,
        width: 100,
    }
}));

const StateInfo = ({ title, message, logo: Logo }) => {
    const { classes } = useStyles();

    return (
        <div className={classes.container}>
            <Logo className={classes.logo}/>
            <Typography variant="h5" sx={{ fontWeight: 'bold'}}>{title}</Typography>
            <span>{message}</span>
        </div>
    );
};

export default StateInfo;