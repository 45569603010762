import React from 'react';
import { Route, useParams } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import * as yup from 'yup';

import { MANAGER } from 'authorization/role';
import useTranslation from 'hooks/useTranslation';
import { DeviceType } from 'utils/constant';
import CreateDevice from '../CreateDevice';
import UpdateDevice from '../UpdateDevice';

const initialValues = {
    name: '',
    uniqueId: '',
    licensePlate: '',
    groupId: '',
    brand: '',
    model: '',
    comment: '',
};




const SmartphonePage = ({ role }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const type = DeviceType.SMARTPHONE;

    const validationSchema = yup.object().shape({
        name: yup.string()
            .trim()
            .required(t('validationRequired'))
            .max(128, `\${max} ${t('validationLengthMax')}`),
        uniqueId: yup.string()
            .trim()
                .required(t('validationRequired'))
                .max(128, `\${max} ${t('validationLengthMax')}`),
        licensePlate: yup.string()
            .trim()
            .max(16, `\${max} ${t('validationLengthMax')}`),
        brand: yup.string()
            .trim()
            .max(128, `\${max} ${t('validationLengthMax')}`),
        model: yup.string()
            .trim()
            .max(128, `\${max} ${t('validationLengthMax')}`),
        comment: yup.string()
            .trim()
            .nullable()
            .max(4000, `\${max} ${t('validationLengthMax')}`),
    });

    const filterDataForServer = item => {
        item.category = type;
        if(!item.groupId){
            delete item.groupId;
        }
        delete item.ownerIds;
        delete item.lastUpdate;
        delete item.positionId;
        delete item.detectedBy;
        return item;
    }

    if(role === MANAGER){
        return (id) 
            ? <UpdateDevice 
                id={ id } 
                filterDataForServer={filterDataForServer} 
                validationSchema={validationSchema}
                role={role} deviceType={DeviceType.SMARTPHONE}
            /> 
            : <CreateDevice 
                filterDataForServer={filterDataForServer} 
                validationSchema={validationSchema}
                initialValues={initialValues} 
                deviceType={DeviceType.SMARTPHONE}
            />;
    }
    return (id) 
        ? <UpdateDevice 
            id={ id } 
            filterDataForServer={filterDataForServer} 
            validationSchema={validationSchema}
            role={role} 
            deviceType={DeviceType.SMARTPHONE}
        /> 
        : <Route element={<Navigate to="signage/smartphones" />}/>;
};

export default SmartphonePage;