import React from 'react';
import _ from 'lodash';
import { magellanV1} from 'api/magellan';
import withSignageLayout from 'hoc/withSignageLayout';
import { useSignage } from 'hooks/useSignage';
import Loading from '../../common/Loading';
import SignageHeader from '../../common/SignageHeader';
import StafferForm from './StafferForm';

const initialValues = {
    firstname: '',
    name: '',
    active: true,
    uniqueId: '',
    sex: null,
    phone: null,
    email: null,
    function: null,
    comment: null,
    nationalRegisterNumber: null,
    onssNumber: null,
    socialSecurityNumber: null,
    address: {
        street: '',
        houseNumber: '',
        locality: null,
        latitude: 0,
        longitude: 0
    },
};

const CreateStaffer = ({ filterDataForServer }) => {
    const {editionMode, setEditionMode, loading, createData} = useSignage({ readApi: magellanV1, createApi: magellanV1, updateApi: magellanV1 }, 'staffers', true, false);

    const onSubmit = (formValues) => {
        const submission = filterDataForServer(_.cloneDeep(formValues));
        createData(submission);
    };

    return (
        <>
            {loading 
                ? <Loading/>
                : <>
                    <SignageHeader 
                        editionMode={editionMode} 
                        setEditionMode={setEditionMode} 
                        editionEnabled={true}
                    />
                    <StafferForm 
                        editionMode={editionMode} 
                        onSubmit={onSubmit} 
                        initialValues={initialValues}
                    />
                </>
            }
            
        </>
    );
}

export default withSignageLayout()(CreateStaffer);