import React from 'react';
import axios from 'axios';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

import {magellanV0} from 'api/magellan';
import { MANAGER } from 'authorization/role';
import withSignageLayout from 'hoc/withSignageLayout';
import useTranslation from 'hooks/useTranslation';
import { useSignage } from 'hooks/useSignage';
import useAlertMessage from 'hooks/useAlertMessage';
import SignageHeader from '../../common/SignageHeader';
import UserForm from '../../common/UserForm';
import Loading from '../../common/Loading';

const filterDataFromServer = (data) => {
    data.password = '';
    return data;
}



const UpdateUser = ({ filterDataForServer, role, showGoBack=true }) => {
    const { t } = useTranslation();
    const validationPassword = {
        password: yup.string()
            .transform(value => value === '' ? null : value)
            .nullable(true)
            .min(8, `\${min} ${t('validationLengthMin')}`)
    };
    const {editionMode, setEditionMode, item, id, loading, setLoading, setItem, isReadonly} = useSignage({ readApi: magellanV0, createApi: magellanV0, updateApi: magellanV0 }, 'users', false, true, filterDataFromServer);
    const currentUserId = useSelector(state => state.session.user.id);
    const { setAlertMessage, savedAlertMessage, updateErrorAlertMessage } = useAlertMessage();

    const onSubmit = (formValues) => {
        setLoading(true);
        Promise.all([
            magellanV0.put(`/api/users/${id}`, filterDataForServer({...formValues}), { headers: {'Content-Type': 'application/json'} }),
            formValues?.groups?.groupToUnlink?.map(g => magellanV0.delete('/api/permissions', {data : {userId : id, groupId : g}})),
            formValues?.groups?.groupToLink?.map(g => magellanV0.post('/api/permissions', {userId : id, groupId : g})),
        ])
            .then(res => {
                setAlertMessage(savedAlertMessage);
                setItem(formValues);
                setEditionMode(false);
            })
            .catch(error => {
                setAlertMessage(updateErrorAlertMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading
                ? <Loading/>
                : <>
                    <SignageHeader 
                        editionMode={editionMode} 
                        setEditionMode={setEditionMode} 
                        editionEnabled={(role === MANAGER || currentUserId === Number(id)) && !isReadonly}
                        showGoBack={showGoBack}
                        title={item && item.name}
                    />
                    <UserForm 
                        editionMode={editionMode} 
                        onSubmit={onSubmit} 
                        initialValues={item} 
                        managerEnabled={role === MANAGER} 
                        isCreation={false} 
                        id={Number(id)} 
                        currentUserId={currentUserId}
                        validationPassword={validationPassword}
                    />
                </>
            } 
        </>
    );
}

export default withSignageLayout()(UpdateUser);