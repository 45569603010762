import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

import { AlertMessageProvider } from 'context/AlertMessageContext';
import { PinnedProvider } from 'context/PinnedContext';
import { GeofencePointerProvider } from 'context/GeofencePointerContext';

import useSocketController from 'hooks/useSocketController';
import useCachingController from 'hooks/useCachingController';
import { UserAndManagerEnabled } from 'authorization';

import MainPage from './home/main/MainPage';
import AssetsPage from './home/assets/AssetsPage';
import ToolsPage from './home/tools/ToolsPage';
import ReplayPage from './home/replay/ReplayPage';
import AlertMessageSnackbar from './common/AlertMessageSnackbar';
import Loading from './common/Loading';
import HandleNotification from './common/HandleNotifications';
import LoggedInPage from './common/LoggedInPage';
import ResetPassword from './authentication/ResetPassword';
import AuthenticationTemplate from './authentication/AuthenticationTemplate';
import AccountRecovery from './authentication/AccountRecovery';
import LoginPage from './authentication/Login';

import TimesheetsRoutes from 'routing/TimesheetsRoutes';
import ReportsRoutes from 'routing/ReportsRoutes';
import SignageRoutes from 'routing/SignageRoutes';
import SettingsRoutes from 'routing/SettingsRoutes';
import AdminRoutes from 'routing/AdminRoutes';
import RequireAuth from 'routing/RequireAuth';
import DisplayTemplate from './reports/templates/DisplayTemplate';

const App = ({authenticated}) => {
    useSocketController(authenticated);
    const {loading} = useCachingController(authenticated);
    const MainPageForUserAndManager = UserAndManagerEnabled(MainPage);
    const AssetsPageForUserAndManager = UserAndManagerEnabled(AssetsPage);
    const ToolsPageForUserAndManager = UserAndManagerEnabled(ToolsPage);
    const ReplayPageForUserAndManager = UserAndManagerEnabled(ReplayPage);
 
    return (
        <>
            <CssBaseline />
            <AlertMessageProvider>
                <HashRouter>
                    <Routes>
                        <Route
                            exact
                            path="/"
                            element={authenticated 
                                ? <Navigate to="app/home"/>
                                : <Navigate to="auth"/>
                            }
                        />
                        <Route 
                            path="auth/*" 
                            element={(
                                <AuthenticationTemplate>
                                    <Routes>
                                        <Route exact path="/" element={<LoginPage/>}/>
                                        <Route exact path="accountRecovery" element={<AccountRecovery/>}/>
                                        <Route path="resetPassword">
                                            <Route path=":token" element={<ResetPassword/>}/>
                                        </Route>
                                        <Route path="*" element={<Navigate to="/auth" />}/>
                                    </Routes>
                                </AuthenticationTemplate>
                        )}/>
                        <Route
                            path="app/*"
                            element={(
                                <RequireAuth authed={authenticated}>
                                    { loading ?
                                            <Loading/>
                                        : <>
                                            <HandleNotification/>
                                            <LoggedInPage>
                                                <GeofencePointerProvider>
                                                    <PinnedProvider>
                                                        
                                                        <Routes>
                                                            <Route exact path="home" element={<MainPageForUserAndManager/>}/>
                                                            <Route exact path="assets" element={<AssetsPageForUserAndManager/>}/>
                                                            <Route exact path="tools" element={<ToolsPageForUserAndManager/>}/>
                                                            <Route exact path="replay" element={<ReplayPageForUserAndManager/>}/>
                                                            <Route path="timesheets/*" element={<TimesheetsRoutes/>}/>
                                                            <Route path="reports/*" element={<ReportsRoutes/>}/>
                                                            <Route path="signage/*" element={<SignageRoutes/>}/>
                                                            <Route path="settings/*" element={<SettingsRoutes/>}/>
                                                            <Route path="admin/*" element={<AdminRoutes/>}/>
                                                            {/* <Route path="position/:id?">{UserAndManagerEnabled(PositionPage)}</Route> */}
                                                            {/* <Route path="device/:id?">{UserAndManagerEnabled(DevicePage)}</Route> */}
                                                            <Route path="*" element={<Navigate to="home"/>}/>
                                                        </Routes> 
                                                    </PinnedProvider>
                                                </GeofencePointerProvider>
                                            </LoggedInPage>
                                        </>
                                    }                                   
                                </RequireAuth>
                            )}
                        />
                        <Route path="templateforreports" element={<DisplayTemplate/>}/>
                        <Route path="*" element={<Navigate to="/"/>}/>
                    </Routes>
                </HashRouter>
                <AlertMessageSnackbar/>
            </AlertMessageProvider>
        </>
    );
};

export default App;