import React from 'react';
import { Card, CardHeader, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';
import { useFormik } from 'formik';

import useTranslation from 'hooks/useTranslation';

const useStyles = makeStyles()(theme => ({
    labelContainer : {
        display: 'grid',
        columnGap: '10%',
        gridTemplateColumns: '45% 45%',
        width: '100%',
    },
    propertyCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
}));


const GroupForm = ({ onSubmit, initialValues, editionMode }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const formik = useFormik({
        initialValues,
        validationSchema: yup.object().shape({
            name: yup.string()
                .trim()
                .required(t('validationRequired'))
                .max(128, `\${max} ${t('validationLengthMax')}`),
            description: yup.string()
                .trim()
                .max(4000, `\${max} ${t('validationLengthMax')}`),
        }),
        onSubmit
    });

    return (
        <form id="form" onSubmit={e => {
            e.preventDefault();
            formik.submitForm();
        }}>
            <CardHeader title={t('signageProperties')} />
            <Card className={classes.propertyCard}>
                <div className={classes.labelContainer}>
                    <TextField 
                        required
                        id="name"
                        name="name"
                        label={t('signageLabel')}
                        disabled={!editionMode}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                </div>
                <TextField 
                    id="description"
                    name="description"
                    label={t('signageDescription')}
                    multiline
                    rows={10}
                    maxRows={35}
                    fullWidth={true} 
                    disabled={!editionMode}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                /> 
            </Card>
        </form>
    );
};

export default GroupForm;