import React, { useContext, useLayoutEffect, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { changeCursorToNormal, changeCursorToPointer, fitBounds } from './mapUtil';
import { MapContext, useMapIcon } from 'context/MapContext';
import { useGeofencePointerVisibility } from 'context/GeofencePointerContext';
import { DeviceType, motionLocationIcon } from 'utils/constant';
import { devicesActions, store } from 'store';
import { makeStyles } from 'tss-react/mui';
import { getLocationMotionIcon } from 'utils/motionStatus';
import useMapboxPopup from './useMapboxPopup';

const positionsLayerId = 'positions';
const directionsLayoutId = 'directions';
const positionsSourceId = 'positions';
const clustersLayerId = 'clusters';
const clusterNameLayerId = 'cluster-count';

export const removePositionsSourceAndLayers = (map) => {
    if(map.getLayer(positionsLayerId)){
        map.removeLayer(positionsLayerId);
    }

    if(map.getLayer(directionsLayoutId)){
        map.removeLayer(directionsLayoutId);
    }

    if(map.getLayer(clustersLayerId)){
        map.removeLayer(clustersLayerId);
    }

    if(map.getLayer(clusterNameLayerId)){
        map.removeLayer(clusterNameLayerId);
    }
    
    if(map.getSource(positionsSourceId)){
        map.removeSource(positionsSourceId);
    }
};

const useStyles = makeStyles()(theme => ({
    mapboxPopup: {
        backgroundColor: theme.palette.primary.main,
    },
    popup: {
        width: 200,
    },
    popupCluster: {
        width: 150,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));



const PositionsMap = ({ displayedPositions, renderPopupContent, forcedRefresh }) => {
    const { classes, cx, theme } = useStyles();
    const { map, mapReady } = useContext(MapContext);
    const { devices, selectedDeviceId, positions } = useSelector(state => 
        ({
            devices: state.devices.items, 
            selectedDeviceId: state.devices.selectedId,
            positions: state.positions.items
        }));
    const [geofencePointerVisible, setGeofencePointerVisible] = useGeofencePointerVisibility();
    const dispatch = useDispatch();
    const { displayPopup, onEnterPopup, onLeavePopup, popup, popupTimeoutId } = useMapboxPopup({ map });
    const { getMapIcon } = useMapIcon();

    const createFeature = (device, position, selectedDeviceId) => {
        let features = [];
        let icon = getMapIcon(getLocationMotionIcon(position, device, selectedDeviceId));
    
        if(!position?.attributes?.motion){
            features = [{
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [position?.longitude, position?.latitude],
                },
                properties: {
                    deviceId: position.deviceId,
                    name: device ? device.name + '\n' : '',
                    position,
                    device,
                    icon: icon,
                },
            }];
        }else {
            let coordinates = [position?.longitude, position?.latitude];
            if (position?.address?.fixedLatitude && position?.address?.fixedLongitude && device?.category !== DeviceType.CONSTRUCTION_MACHINE) {
                coordinates = [position.address.fixedLongitude, position.address.fixedLatitude];
            }
            features = [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates,
                    },
                    properties: {
                        deviceId: position.deviceId,
                        name: device ? device.name + '\n' : '',
                        icon: icon,
                        position,
                        device,
                        bearing: position?.course || 0,
                    },
                },
                
            ]
        }
        
        return features;
    };

    const onClusterClicked = e => {
        const clusterFeatures = map.queryRenderedFeatures(e.point, {
            layers: [clustersLayerId]
        });
        const clusterId = clusterFeatures[0].properties.cluster_id;
        const pointCount = clusterFeatures[0].properties.point_count;
        
        map.getSource(positionsSourceId).getClusterLeaves(clusterId, pointCount, 0, (err, features)=> {
            if(err === null) {
                const allCoordinates = features.map(f => f.geometry.coordinates);
                fitBounds(map, allCoordinates);
            }
        })
    }

    const extractCoordinates = (event) => {
        const feature = event.features[0];
        let coordinates = feature.geometry.coordinates.slice();
        while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        return coordinates;
    }

    const onEnterDevice = async event => {
        if(!popup.current || (event?.features?.[0] && popup.current?.deviceId !== event.features?.[0]?.properties?.deviceId)) {
            if(popup.current) {
                clearTimeout(popupTimeoutId.current);
                await popup.current.remove();
            }
            const feature = event.features[0];
            const devicePopup = () => (
                <div onMouseLeave={onLeavePopup} onMouseEnter={onEnterPopup} className={classes.popup}>
                    {renderPopupContent({ 
                        deviceId: feature.properties.deviceId, 
                        position: JSON.parse(feature.properties.position), 
                        device: JSON.parse(feature.properties.device),
                    })}
                </div>
            );
            const coordinates = extractCoordinates(event);
            displayPopup(coordinates, devicePopup, { offset: 15 });
            popup.current.deviceId = feature?.properties?.deviceId;
        } else {
            clearTimeout(popupTimeoutId.current);
        }
    };

    const onEnterCluster = async event => {
        if(!popup.current || (event?.features?.[0] && popup.current?.clusterId !== event.features?.[0]?.id)) {
            if(popup.current) {
                clearTimeout(popupTimeoutId.current);
                await popup.current.remove();
            }
            const feature = event.features[0];
            const clusterPopup = () => (
                <div onMouseLeave={onLeavePopup} onMouseEnter={onEnterPopup} className={cx(classes.popup, classes.popupCluster)}>
                    {event?.features?.[0]?.properties?.names.split('\n').map(name => <span key={name}>{name}</span>)}
                </div>
            );
            const coordinates  = extractCoordinates(event);
            displayPopup(coordinates, clusterPopup, { offset: 15 });
            popup.current.clusterId = feature?.id;
        } else {
            clearTimeout(popupTimeoutId);
        }
    }

    useLayoutEffect(() => {
        const addSourcesAndLayers = () => {
            map.addSource(positionsSourceId, {
                'type': 'geojson',
                'data': {
                    type: 'FeatureCollection',
                    features: [],
                },
                cluster: true,
                clusterProperties: {
                    names: ['concat', ['get', 'name']],
                },
                clusterMaxZoom: 18,
                clusterRadius: 20,
            });
            
            map.addLayer({
                'id': positionsLayerId,
                'type': 'symbol',
                'source': positionsSourceId,
                'filter': ['any', ['!',['has', 'point_count']]],
                'layout': {
                    'icon-image': '{icon}',
                    'icon-allow-overlap': true,
                    'text-variable-anchor': ['top-left', 'top', 'top-right', 'left', 'right', 'bottom-left', 'bottom', 'bottom-right'],
                    'text-field': '{name}',
                    'text-allow-overlap': true,
                    'text-offset': [0, 2],
                    'text-font': ['Roboto Regular'],
                    'text-size': 12,
                },
                'paint': {
                    'text-halo-color': 'white',
                    'text-halo-width': 1,
                },
            });

            map.addLayer({
                'id': directionsLayoutId,
                'type': 'symbol',
                'source': positionsSourceId,
                'filter': ['all', ['!',['has', 'point_count']], ['has', 'bearing']],
                'layout': {
                    'icon-image': getMapIcon(motionLocationIcon.DIRECTION),
                    'icon-allow-overlap': true,
                    'icon-rotate': ['get', 'bearing'],
                },
            });

            map.addLayer({
                id: clustersLayerId,
                type: 'circle',
                source: positionsSourceId,
                filter: ['has', 'point_count'],
                paint: {
                    'circle-color': theme.palette.common.white,
                    'circle-stroke-color': theme.palette.common.black,
                    'circle-stroke-width': 2,
                    'circle-radius': [
                        'step',
                        ['get', 'point_count'],
                        15,
                        5,
                        20,
                        10,
                        25,
                    ]
                },
            });

            map.addLayer({
                id: clusterNameLayerId,
                type: 'symbol',
                source: positionsSourceId,
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': '{point_count_abbreviated}',
                    'text-font': ['Roboto Regular'],
                    'text-size': 12,
                },
                paint: {
                    'text-color': theme.palette.common.black,
                },
            });
        };

        if(mapReady) {
            if(map.getSource(positionsSourceId)) {
                removePositionsSourceAndLayers(map);
                addSourcesAndLayers();
            } else {
                addSourcesAndLayers();
            }

            map.on('click', clustersLayerId, onClusterClicked);
            map.on('mouseenter', positionsLayerId, onEnterDevice);
            map.on('mouseleave', positionsLayerId, onLeavePopup);
            map.on('mouseenter', positionsLayerId, changeCursorToPointer);
            map.on('mouseleave', positionsLayerId, changeCursorToNormal);
            map.on('mouseenter', clustersLayerId, onEnterCluster);
            map.on('mouseleave', clustersLayerId, onLeavePopup);
            map.on('mouseenter', clustersLayerId, changeCursorToPointer);
            map.on('mouseleave', clustersLayerId, changeCursorToNormal);
        
            return () => {
                Array.from(map.getContainer().getElementsByClassName('mapboxgl-popup')).forEach(el => el.remove());
                map.off('click', clustersLayerId, onClusterClicked);
                map.off('mouseenter', positionsLayerId, onEnterDevice);
                map.off('mouseleave', positionsLayerId, onLeavePopup);
                map.off('mouseenter', positionsLayerId, changeCursorToPointer);
                map.off('mouseleave', positionsLayerId, changeCursorToNormal);
                map.off('mouseenter', clustersLayerId, onEnterCluster);
                map.off('mouseleave', clustersLayerId, onLeavePopup);
                map.off('mouseenter', clustersLayerId, changeCursorToPointer);
                map.off('mouseleave', clustersLayerId, changeCursorToNormal);
            };
        }        
    }, [mapReady, geofencePointerVisible, forcedRefresh]);

    useEffect(() => {
        const onSelectDevice = e => {
            let newSelectedDeviceId = e.features[0].properties.deviceId;
            if(newSelectedDeviceId !== selectedDeviceId) {
                dispatch(devicesActions.selectAndZoom(newSelectedDeviceId));
            } else {
                dispatch(devicesActions.deselect());
            }
        };

        if(mapReady) {
            map.on('click', positionsLayerId, onSelectDevice);

            return () => {
                map.off('click', positionsLayerId, onSelectDevice);
            };
        }
    }, [mapReady, selectedDeviceId]);

    useLayoutEffect(() => {
        const createFeatures = () => {
            let features = [];
            let newFeatures;
            let device;
            let positionDeviceAssociated; 
            for (let displayedPosition of displayedPositions) {
                if (displayedPosition) {
                    device = devices?.[displayedPosition.deviceId];
                    
                    if (device) {
                        if (device.beacon && device.detectedBy != null) {
                            positionDeviceAssociated = positions?.[device.detectedBy];
                            newFeatures = positionDeviceAssociated ? createFeature(device, positionDeviceAssociated, selectedDeviceId) : [];
                        } else {
                            newFeatures = createFeature(device, displayedPosition, selectedDeviceId);
                        }
                        features = [...features, ...newFeatures];
                    }
                }                
            }
            return features;
        };

        if(mapReady && map.getSource(positionsSourceId) && positions){
            map.getSource(positionsSourceId).setData({
                type: 'FeatureCollection',
                features: createFeatures(),
            });
        }
    }, [mapReady, JSON.stringify(devices), JSON.stringify(displayedPositions), geofencePointerVisible, selectedDeviceId, JSON.stringify(positions), forcedRefresh]);

    return null;
}

export default PositionsMap;
