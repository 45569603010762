import _ from 'lodash';
import { View, Text } from "@react-pdf/renderer";
import { styles } from "./sharedPdfReportStyles";

const TablePdf = ({ headerGroups, rows, prepareRow, displayRowNumber = true, renderSummaryRow }) => {
    
    return (
        <>
            <View style={styles.table} > 
                    { headerGroups.map(headerGroup => {
                        const { key, ...restHeaderGroupProps} = headerGroup.getHeaderGroupProps();
                        return (
                            <View key={key} style={{...styles.tableRow, ...styles.tableRowHeader}} fixed {...restHeaderGroupProps}>
                                { displayRowNumber && (
                                    <View style={{...styles.tableFirstCol, ...styles.tableColHeader}}/>
                                )}
                                { headerGroup.headers.map(column => {
                                    const { key, ...restColumnProps } = column.getHeaderProps();
                                    return (
                                        <View key={key} style={{...styles.tableCol, ...styles.tableColHeader, width: column.cellWidth}} {...restColumnProps}> 
                                            <Text style={styles.tableCell}>{column.render('Header')}</Text> 
                                        </View> 
                                    );
                                })}
                            </View>
                        );
                    })}
                    {rows.map((row, index) => {
                        prepareRow(row);
                        const { key, ...restRowProps } = row.getRowProps();

                        if(row.original.isSummary && renderSummaryRow){
                            return renderSummaryRow(row, index, styles);
                        } else {
                            return (
                                <View key={key} style={styles.tableRow} wrap={false} {...restRowProps}> 
                                    { displayRowNumber && (
                                        <View style={{...styles.tableCol, ...styles.tableFirstCol, ...((index % 2 === 0) || styles.tableOddCol)}}> 
                                            <Text style={{...styles.tableCell, ...styles.tableFirstCell}}>{index + 1}</Text> 
                                        </View>
                                    )}
                                    {row.cells.map(cell => {
                                        const { key, ...restCellProps } = cell.getCellProps();
                                        return (
                                            <View key={key} style={{...styles.tableCol, ...((index % 2 === 0) || styles.tableOddCol), width: cell.column.cellWidth}} {...restCellProps}> 
                                                <Text style={styles.tableCell}>{cell.render('Cell')}</Text> 
                                            </View> 
                                        );
                                    })}
                                </View>
                            );
                        }
                    })}
                </View>
        </>
    )
};

export default TablePdf;