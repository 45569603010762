import React, { useState, useEffect } from 'react';
import { TextField, MenuItem } from '@mui/material';

import { magellanV0 } from 'api/magellan';
import useTranslation from 'hooks/useTranslation';
import useAlertMessage from 'hooks/useAlertMessage';

const SelectTextField = ({options, endpoint, valuePropertyName, namePropertyLabel, onChange, onBlur, mandatory, formatLabel = (label) => label, ...custom}) => {
    const { t } = useTranslation();
    const [items, setItems] = useState(options ? options : []);
    const { setAlertMessage, dataLoadingErrorAlertMessage } = useAlertMessage();

    useEffect(() => {
        if (endpoint) {
            magellanV0.get(endpoint, {headers: {'Accept': 'application/json'}})
                .then(response => {
                    setItems(response.data);
                })
                .catch(error => {
                    setAlertMessage(dataLoadingErrorAlertMessage);
                });
        }
    }, []);
    
    return (
        <TextField 
            select 
            onChange={onChange}
            onBlur={onBlur}
            {...custom}
        >
            {!mandatory && 
                <MenuItem value=""><em>{t('none')}</em></MenuItem>
            }
            {
                items.map(item => <MenuItem key={item[valuePropertyName]} value={item[valuePropertyName]}>{formatLabel(item[namePropertyLabel])}</MenuItem>)
            }
        </TextField>
    );
    
};

export default SelectTextField;