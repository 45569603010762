import React from 'react';

import { magellanV1 } from 'api/magellan';
import withSignageLayout from 'hoc/withSignageLayout';
import useTranslation from 'hooks/useTranslation';
import { useSignage } from 'hooks/useSignage';
import Loading from 'components/common/Loading';
import SignageHeader from 'components/common/SignageHeader';
import WarehouseForm from './WarehouseForm';
import useAlertMessage from 'hooks/useAlertMessage';
import { useNavigate } from 'react-router-dom';

const initialValues = {
    name: '',
    uniqueId: '',
    groupId: '',
    comment: '',
    address: {
        street: '',
        houseNumber: '',
        localityId: null,
        latitude: 0,
        longitude: 0
    }
}

const CreateWarehouse = ({ filterDataForServer }) => {
    const { editionMode, setEditionMode, loading, setLoading } = useSignage({ readApi: magellanV1, createApi: magellanV1, updateApi: magellanV1 }, 'devices', true, false);
    const navigate = useNavigate();
    const { setAlertMessage, creationErrorAlertMessage, savedAlertMessage } = useAlertMessage();

    const onSubmit = async (formValues) => {
        let submission = filterDataForServer({...formValues});
        setLoading(true);
        await magellanV1.post(`/api/devices`, submission, { headers: {'Content-Type': 'application/json'} })
            .then(response => {
                if(response.data.id){
                    setAlertMessage(savedAlertMessage);
                    setLoading(false);
                    navigate(`${response.data.id}`, { replace: true });
                }
            })
            .catch(error => {
                setAlertMessage(creationErrorAlertMessage);
                setLoading(false);
            });
    }

    return (
        <>
            {loading 
                ? <Loading/>
                : <>
                    <SignageHeader editionMode={editionMode} setEditionMode={setEditionMode} editionEnabled={true}/>
                    <WarehouseForm 
                        editionMode={editionMode} 
                        onSubmit={onSubmit}
                        initialValues={initialValues}
                    />                
                </>
            }
        </>
    );
}

export default withSignageLayout()(CreateWarehouse);