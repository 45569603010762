import { useEffect, useContext } from 'react';

import { MapContext } from 'context/MapContext';
import mapboxgl from 'mapbox-gl';

const ScaleMapControl = () => {
    const { map, mapReady } = useContext(MapContext);


    useEffect(() => {
        if(map && mapReady) {
            const scaleControl = new mapboxgl.ScaleControl({
                position: 'bottom-left',
                unit: 'metric',
            });
            map.addControl(scaleControl);

            return () => {
                map.removeControl(scaleControl);
            }
        }
    }, [map, mapReady]);

    return null;
};

export default ScaleMapControl;