import React from 'react';
import { useParams } from 'react-router-dom';

import UpdateUser from '../admin/user/UpdateUser';

const filterDataForServer = item => {
    if(!item.password){
        delete item.password;
    }
    delete item.groups;
    return item;
}

const AccountPage = ({ role }) => {
    const { id } = useParams();

    return <UpdateUser id={ id } filterDataForServer={filterDataForServer} role={role} showGoBack={false}/>
}

export default AccountPage;
