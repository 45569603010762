import React from 'react';
import _ from 'lodash';
import { MenuItem, TextField, Autocomplete } from '@mui/material';

import useEventReport from './useEventReport';
import { AlarmType, } from 'utils/constant';
import ReportLayoutPage from '../ReportLayoutPage';
import PeriodSelector from 'components/common/PeriodSelector';
import FileExportReportButtons from '../FileExportReportButtons';
import ReportShowButton from '../ReportShowButton';
import { sortByThenBy } from 'utils/sort';
import ItemRow from 'components/common/ItemRow';
import ItemTable from 'components/home/ItemTable';

const EventReportPage = () => {
    const { 
        t, 
        formatDate, 
        formatDeviceCategory,
        formatEventType,
        globalClasses, 
        cx, 
        items, 
        loading, 
        getFiltersKey, 
        tasks,
        handleSubmit,
        deviceId,
        setDeviceId,
        period,
        setPeriod,
        from,
        setFrom,
        to,
        setTo,
        devices,
        eventTypes,
        setEventTypes,
        displayDisabled,
        pdfDisabled,
        excelDisabled,
        emailDisabled,
        ...rest
    } = useEventReport();

    return (
        <ReportLayoutPage 
            loading={loading}
            filter={
                <div className={globalClasses.rootReportEngineContainer}>
                    <div className={globalClasses.reportFiltersContainer}>
                        <Autocomplete 
                            options={devices.sort((deviceA, deviceB) => sortByThenBy(deviceA.category, deviceA.name.toLowerCase(), deviceB.category, deviceB.name.toLowerCase()))}
                            groupBy={d => formatDeviceCategory(d?.category)}
                            getOptionLabel={d => d?.name || ''}
                            value={devices.find(d => d.id === deviceId)}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            onChange={(_, value) => setDeviceId(value?.id || null)}
                            renderInput={(params) => <TextField {...params} label={t('reportDevice')} variant='outlined'/>}
                        />
                        <PeriodSelector 
                            period={period} 
                            setPeriod={setPeriod} 
                            from={from}
                            setFrom={setFrom}
                            to={to}
                            setTo={setTo}
                        />
                        <TextField 
                            variant="outlined" 
                            fullWidth
                            select
                            label={t('reportEventTypes')}
                            SelectProps={{
                                multiple: true,
                                value: eventTypes,
                                onChange: (e) => setEventTypes(e.target.value)
                            }}
                        >
                            <MenuItem key="allEvents" value="allEvents">{t('allEvents')}</MenuItem>
                            <MenuItem key="deviceOnline" value="deviceOnline">{t('eventDeviceOnline')}</MenuItem>
                            <MenuItem key="deviceOffline" value="deviceOffline">{t('eventDeviceOffline')}</MenuItem>
                            <MenuItem key="deviceInactive" value="deviceInactive">{t('eventDeviceInactive')}</MenuItem>
                            <MenuItem key="deviceMoving" value="deviceMoving">{t('eventDeviceMoving')}</MenuItem>
                            <MenuItem key="deviceStopped" value="deviceStopped">{t('eventDeviceStopped')}</MenuItem>
                            <MenuItem key="deviceOverspeed" value="deviceOverspeed">{t('eventDeviceOverspeed')}</MenuItem>
                            <MenuItem key="deviceFuelDrop" value="deviceFuelDrop">{t('eventDeviceFuelDrop')}</MenuItem>
                            <MenuItem key="commandResult" value="commandResult">{t('eventCommandResult')}</MenuItem>
                            <MenuItem key="geofenceEnter" value="geofenceEnter">{t('eventGeofenceEnter')}</MenuItem>
                            <MenuItem key="geofenceExit" value="geofenceExit">{t('eventGeofenceExit')}</MenuItem>
                            <MenuItem key="alarm" value="alarm">{t('eventAlarm')}</MenuItem>
                            <MenuItem key="ignitionOn" value="ignitionOn">{t('eventIgnitionOn')}</MenuItem>
                            <MenuItem key="ignitionOff" value="ignitionOff">{t('eventIgnitionOff')}</MenuItem>
                            <MenuItem key="maintenance" value="maintenance">{t('eventMaintenance')}</MenuItem>
                            <MenuItem key="textMessage" value="textMessage">{t('eventTextMessage')}</MenuItem>
                            <MenuItem key="stafferChanged" value="stafferChanged">{t('eventStafferChanged')}</MenuItem>
                            <MenuItem key={AlarmType.plugged.key} value={AlarmType.plugged.key}>{t(AlarmType.plugged.translatorKey)}</MenuItem>
                            <MenuItem key={AlarmType.unplugged.key} value={AlarmType.unplugged.key}>{t(AlarmType.unplugged.translatorKey)}</MenuItem>
                            <MenuItem key={AlarmType.lowBattery.key} value={AlarmType.lowBattery.key}>{t(AlarmType.lowBattery.translatorKey)}</MenuItem>
                            {/* <MenuItem key={AlarmType.criticalBattery.key} value={AlarmType.criticalBattery.key}>{t(AlarmType.criticalBattery.translatorKey)}</MenuItem> */}
                            <MenuItem key={AlarmType.tow.key} value={AlarmType.tow.key}>{t(AlarmType.tow.translatorKey)}</MenuItem>
                            <MenuItem key={AlarmType.accident.key} value={AlarmType.accident.key}>{t(AlarmType.accident.translatorKey)}</MenuItem>
                        </TextField>
                        <ReportShowButton
                            disabled={displayDisabled}
                            handleSubmit={handleSubmit}
                        />
                    </div>
                    <div className={globalClasses.reportButtonsContainer}>
                    <FileExportReportButtons
                        pdfDisabled={pdfDisabled}
                        excelDisabled={excelDisabled}
                        emailDisabled={emailDisabled}
                        tasks={tasks}
                        handleSubmit={handleSubmit}
                        getFiltersKey={getFiltersKey}
                    />
                    </div>
                </div>
            }
        >
            <ItemTable 
                {...rest}
            >
                { ({ itemRow, ...rest }) => {
                    return (
                    <ItemRow 
                        key={itemRow.id} 
                        itemRow={itemRow}
                        {...rest}
                    />
                )}}
            </ItemTable>           
        </ReportLayoutPage>
    );
}

export default EventReportPage;
