import React from 'react';
import _ from 'lodash';
import { IconButton, Tooltip } from '@mui/material';

import useTranslation from 'hooks/useTranslation';
import { ReportFormat } from 'utils/constant';
import { PlayCircleOutlineOutlined } from '@mui/icons-material';

const ReportShowButton = ({ handleSubmit, disabled }) => {
    const { t } = useTranslation();

    return (
        <div>
            <Tooltip 
                disableHoverListener={disabled}
                disableFocusListener={disabled}
                disableInteractive={disabled}
                disableTouchListener={disabled}
                title={t('reportShow')}
            >
                <span>
                    <IconButton 
                        disabled={disabled} 
                        onClick={() => handleSubmit(ReportFormat.JSON)}
                    >
                        <PlayCircleOutlineOutlined fontSize='large'/>
                    </IconButton>
                </span>
                
            </Tooltip>
        </div>
    );
};

export default ReportShowButton;