import React, { useEffect, useRef, useContext, useLayoutEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import mapboxgl from 'mapbox-gl';

import { MapContext } from 'context/MapContext';
import useFormat from 'hooks/useFormat';
import Pointer from 'resources/default_point.svg';
import Loading from '../common/Loading';
import { fitBounds } from '../home/map/mapUtil';
import Map from '../home/map/Map';
import { removePositionsSourceAndLayers } from 'components/home/map/PositionsMap';

const useStyles = makeStyles()(theme => ({
    mapContainer: {
        flexGrow: 1,
        height: '50vh',
        marginBottom: theme.spacing(3),
    }
}));

const PickCoordinatesOnMap = ({ editionMode, loading, coordinates, setCoordinates, }) => {
    const { formatNumber } = useFormat();
    const {map, mapReady} = useContext(MapContext);
    const { classes } = useStyles();
    const firstRender = useRef(true);
    const markerRef = useRef();

    useLayoutEffect(() => {
        if(map, mapReady) {
            removePositionsSourceAndLayers(map);
        }
    }, [map, mapReady]);
    
    useEffect(() => {
        if(mapReady) {
            if(firstRender.current){
                firstRender.current = false;
                if(coordinates.latitude && coordinates.longitude){
                    fitBounds(map, [coordinates]);
                    addMarkerToMap(coordinates.latitude, coordinates.longitude);
                } else {
                    map.jumpTo({zoom: 7, center : [4.668889, 50.641111]});
                }
            } else if(coordinates.latitude, coordinates.longitude){
                placeCoordinatesOnMap();
            }
        }
    }, [JSON.stringify(coordinates), mapReady]);

    useEffect(() => {
        if(mapReady && editionMode) {
            map.on('click', pickCoordinates);

            return () => {
                map.off('click', pickCoordinates);
            }
        }
        
    }, [editionMode, mapReady]);

    useEffect(() => {
        if(mapReady) {
            map.setMinZoom(1);

            return () => {
                if(markerRef.current){
                    markerRef.current.remove();
                }
            }   
        }
             
    }, [mapReady]);

    const pickCoordinates = (event) => {
        let coordinates = event.lngLat;
        setCoordinates(formatNumber(coordinates.lat, 8), formatNumber(coordinates.lng, 8));
        addMarkerToMap(coordinates.lat, coordinates.lng);
    };

    const placeCoordinatesOnMap = async () => {
        if(coordinates.latitude && coordinates.longitude){
            addMarkerToMap(coordinates.latitude, coordinates.longitude);
            map.jumpTo({center : [coordinates.longitude, coordinates.latitude], zoom: 15});
        }
    };

    const addMarkerToMap = (latitude, longitude) => {
        let newMarker;
        const el = document.createElement('div');
        const width = 27;
        const height = 41;
        el.style.backgroundImage = `url(${Pointer})`;
        el.style.width = `${width}px`;
        el.style.height = `${height}px`;
        el.style.backgroundSize = '100%';
        if(markerRef.current) {
            markerRef.current.remove();
        }
        newMarker = new mapboxgl.Marker({element: el, anchor: 'bottom'}).setLngLat([longitude, latitude]).addTo(map);

        markerRef.current = newMarker;
    };
    
    return (
        <div className={classes.mapContainer} style={editionMode? {} :{pointerEvents:'none'}}>
            { loading 
                ? <Loading />
                : <Map/>
            }
        </div>
    );
};

export default React.memo(PickCoordinatesOnMap);