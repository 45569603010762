import React from 'react';
import { Button, Card, Modal, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useGlobalStyles } from 'context/StylesContext';

import { ReactComponent as MagellanLogo } from 'resources/magellan_mini_logo.svg'

import useTranslation from 'hooks/useTranslation';
import BasicValue from './BasicValue';

const useStyles = makeStyles()(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    emailAndActionsContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
}));

const SupportPopup = ({ open, onClose = () => {}, titleKey='supportTitle', children }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const { classes: globalClasses } = useGlobalStyles();
    const supportEmail = "support@magellan.be";

    return (
        <Modal 
            open={open}
            onClose={onClose}
            className={classes.root}
        >
            <Card className={globalClasses.authCard}>
                <Typography className={globalClasses.dialogTitle}>{t(titleKey)}</Typography>
                <div className={classes.content}>
                    <MagellanLogo height={100} width={100}/>
                    <div className={classes.emailAndActionsContainer}>
                        <BasicValue
                            label={t('supportEmail')}
                            value={supportEmail}
                        />
                        <Button 
                            variant="contained" 
                            color="secondary"
                            href={`mailto:${supportEmail}`}
                            target="_blank"
                        >{t('supportContact')}</Button>
                    </div>
                </div>
                { children }
            </Card>
        </Modal>
    );
};

export default SupportPopup;