import React from 'react';
import { LocationOn } from '@mui/icons-material';

import { useGlobalStyles } from 'context/StylesContext';
import useTranslation from 'hooks/useTranslation';
import None from './None';

const GeofenceOrAddress = ({ isGeofence, addressValue }) => {
    const { classes: globalClasses, cx } = useGlobalStyles();
    const { t } = useTranslation();
    
    return (
        <>
            <LocationOn className={isGeofence ? globalClasses.decorativeIcon : cx(globalClasses.decorativeIcon, globalClasses.invisible)}/>
            { addressValue || <None text={t('itemAddressNone')}/> }
        </>
    );
};

export default GeofenceOrAddress;