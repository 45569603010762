import axios from "axios";

const retryBaseURL = 'https://nominatim.openstreetmap.org';

const nominatim = axios.create({
    baseURL: 'https://nominatim.magellan.be/api',
    params: {
        format: 'json',
    },
});

nominatim.interceptors.response.use(
    response => {
        if (response.status === 200 && response.data?.length > 0) {
            return response;
        }

        return new Promise((resolve) => {
            resolve(axios({...response.config, baseURL: retryBaseURL }));
        });
    }, 
    error => {
        return new Promise((resolve) => {
            resolve(axios({...error.config, baseURL: retryBaseURL }));
        });
    }
);

export default nominatim;