import React, { useState, useRef, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs, Tab, } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';

import { MapContext } from 'context/MapContext';
import { usePinned } from 'context/PinnedContext';
import useTranslation from 'hooks/useTranslation';
import TabPanel from '../../common/TabPanel';
import Map from '../map/Map';
import AccuracyMap from "../map/AccuracyMap";
import PositionsMap from "../map/PositionsMap";
import SelectedDeviceMap from "../map/SelectedDeviceMap";
import GeofenceMap from "../map/GeofenceMap";
import FitBoundsMapControl from "../map/FitBoundsMapControl";
import ZoomInAndOutMapControl from '../map/ZoomInAndOutMapControl';
import SelectorMapControl from '../map/SelectorMapControl';
import GeofencePointerToggleMapControl from '../map/GeofencePointerToggleMapControl';
import DevicesList from './DevicesList';
import NotificationsHistory from './NotificationsHistory';
import AddressSearchBar from './AddressSearchBar';
import { DeviceType } from 'utils/constant';
import ScaleMapControl from '../map/ScaleMapControl';
import useFitBounds from '../useFitBounds';
import { useGlobalStyles } from 'context/StylesContext';
import Drawer from 'components/common/Drawer';
import StatusView from '../map/StatusView';
import AlertsHistory from './AlertsHistory';

const useStyles = makeStyles()(theme => ({
    content: {
        flexGrow: 1,
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    mapContainer: {
        flexGrow: 1,
    },
    drawer: {
        overflowY: 'auto',
    },
    drawerPinned: {
        position: 'relative',
    },
    drawerUnpinned: {
        height: `calc(100vh - ${theme.fixedSize.toolbar.height})`,
        right: 0,
        position: 'absolute',
        backgroundColor: theme.palette.primary.main,
    },
    drawerOpen: {
        width: 400,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        width: 0,        
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    openDrawerArea: {
        height: `calc(100vh - ${theme.fixedSize.toolbar.height})`,
        position: 'absolute',
        width: theme.spacing(7) + 1,
        right: 0, 
    },
    pinContainer: {
        display: 'flex',
        justifyContent: 'center',
        zIndex: theme.zIndex.drawer + 1,
        paddingTop: 5
    }, 
    pinned: {
        color: theme.palette.on.main,
    }, 
    unpinned: {
        color: theme.palette.off.main,
    },
    tabs: {
        position: 'absolute',
        backgroundColor: theme.palette.primary.main,
    },
    tabsOpen: {
        right: 400,
        transition: theme.transitions.create('right', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    tabsClose: {
        right: 0,
        transition: theme.transitions.create('right', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    tabRoot: {
        width: 15,
        minWidth: 15,
        height: 100,
        minHeight: 100,
        padding: '0px 15px',
        opacity: 1,
    },
    tabWrapper: {
        width: 0,
    },
    textTab: {
        transform: 'rotate(-90deg)',
        WebkitTransform: 'rotate(-90deg)',
        MozTransformStyle: 'rotate(-90deg)',
        fontWeight: 'normal',
        color: theme.palette.text.primary,
        opacity: 1,
        
    },
    tabPanel: {
        height: '100%',
    },    
}));

const SelectedTab = Object.freeze({ DEVICES: 1, NOTIFICATIONS: 2, ALERTS: 3 });

const deviceTypesTakenIntoAccount = [
    DeviceType.VEHICLE,
    DeviceType.CONSTRUCTION_MACHINE,
    DeviceType.SMARTPHONE,
    DeviceType.TRAILER,
];

const MainPage = () => {
    const { t } = useTranslation();
    const { classes, cx } = useStyles();
    const { classes: globalClasses } = useGlobalStyles();
    const [pinned, setPinned] = usePinned();
    const [drawerOpen, setDrawerOpen] = useState(pinned);
    const [selectedTab, setSelectedTab] = useState(SelectedTab.DEVICES);
    const { positions, devices } = useSelector(state => ({ positions: state.positions.items, devices: state.devices.items }));
    const { map, mapReady } = useContext(MapContext);
    const { deselectDeviceAndFitDevicesPositions, deselectDeviceAndZoomToCoordinates } = useFitBounds({ positions, map });

    const togglePin = () => {
        setPinned(pinned => !pinned)
    };

    return (
        <div className={classes.content}>
            <div className={classes.mapContainer}>
                <Map fitBounds={deselectDeviceAndFitDevicesPositions}>
                    <GeofenceMap />
                    <AccuracyMap />
                    <PositionsMap 
                        displayedPositions={Object.values(devices)
                            .filter(device => deviceTypesTakenIntoAccount.includes(device.category) && positions?.[device?.id] !== undefined)
                            .map(device => positions?.[device?.id])
                        }
                        renderPopupContent={({ deviceId }) => <StatusView deviceId={deviceId} />}
                     />
                    <SelectedDeviceMap />
                    <div hidden={!mapReady} className={globalClasses.mapControlButtonsLayout}>
                        <ZoomInAndOutMapControl zoomIn={() => map.zoomIn()} zoomOut={() => map.zoomOut()} />
                        <AddressSearchBar zoomToCoordinates={deselectDeviceAndZoomToCoordinates}/>
                        <GeofencePointerToggleMapControl />
                        <FitBoundsMapControl fitBounds={deselectDeviceAndFitDevicesPositions} positions={Object.values(positions)}/>
                        <SelectorMapControl/>
                        <ScaleMapControl/>
                    </div>
                </Map>                
            </div>
            <Tabs
                orientation="vertical"
                value={selectedTab}
                onChange={(e, newTab) => setSelectedTab(newTab)}
                className={cx(classes.tabs, (drawerOpen ? classes.tabsOpen : classes.tabsClose))}
            >
                <div 
                    onMouseEnter={() => setDrawerOpen(true)}
                    onMouseLeave={() => {
                        if(!pinned){
                            setDrawerOpen(false);
                        }
                    }}
                    className={classes.pinContainer} 
                    onClick={togglePin}
                >
                    <PushPinOutlinedIcon 
                        fontSize="small"
                        className={pinned ? classes.pinned : classes.unpinned}
                    /> 
                </div>
                <Tab 
                    onMouseEnter={() => setDrawerOpen(true)}
                    onMouseLeave={() => {
                        if(!pinned){
                            setDrawerOpen(false);
                        }
                    }}
                    label={<span className={classes.textTab}>{t('deviceTitle')}</span>}
                    id={`vertical-tab-${SelectedTab.DEVICES}`}
                    classes={{root: classes.tabRoot, wrapper: classes.tabWrapper}}
                />
                <Tab 
                    onMouseEnter={() => setDrawerOpen(true)}
                    onMouseLeave={() => {
                        if(!pinned){
                            setDrawerOpen(false);
                        }
                    }}
                    label={<span className={classes.textTab}>{t('sharedNotifications')}</span>}
                    id={`vertical-tab-${SelectedTab.NOTIFICATIONS}`}
                    classes={{root: classes.tabRoot, wrapper: classes.tabWrapper}}
                />
                <Tab 
                    onMouseEnter={() => setDrawerOpen(true)}
                    onMouseLeave={() => {
                        if(!pinned){
                            setDrawerOpen(false);
                        }
                    }}
                    label={<span className={classes.textTab}>{t('sharedAlerts')}</span>}
                    id={`vertical-tab-${SelectedTab.ALERTS}`}
                    classes={{root: classes.tabRoot, wrapper: classes.tabWrapper}}
                />
            </Tabs>
            <Drawer pinned={pinned} open={drawerOpen} setOpen={setDrawerOpen} >
                <TabPanel className={classes.tabPanel} value={selectedTab} index={SelectedTab.DEVICES}>
                    <DevicesList deviceTypesTakenIntoAccount={deviceTypesTakenIntoAccount}/>
                </TabPanel>
                <TabPanel className={classes.tabPanel} value={selectedTab} index={SelectedTab.NOTIFICATIONS}>
                    <NotificationsHistory/>
                </TabPanel>
                <TabPanel className={classes.tabPanel} value={selectedTab} index={SelectedTab.ALERTS}>
                    <AlertsHistory/>
                </TabPanel>
            </Drawer>
        </div>
    );

}

export default MainPage;