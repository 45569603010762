import { checkingStore } from 'components/timesheet/presence/checkingStore';
import { checkingActions } from 'components/timesheet/presence/checkingReducer';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { positionsActions, devicesActions, alertsActions, notificationsActions, geofencesActions, asyncServerTasksActions } from 'store';
import { decodeStringInJson } from '../utils/urlDecode';
import { parseAddress } from 'utils/address';
import useTranslation from './useTranslation';
import { staffersActions } from 'store';

const useSocketController = (authenticated) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        let timeoutIdReconnect;
        let socket;
        const connectSocket = () => {
            if(authenticated) {
                const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
                socket = new WebSocket(protocol + '//' + window.location.host + '/api/socket');       
            
                socket.onclose = () => {
                    timeoutIdReconnect = setTimeout(() => {
                        connectSocket();
                    }, 60 * 1000);
                };
            
                socket.onmessage = (event) => {
                    const data = decodeStringInJson(JSON.parse(event.data));
                    if (data.devices) {
                        dispatch(devicesActions.update(data.devices));
                    }
                    if (data.positions) {
                        try{
                            data.positions.forEach(p => p.address = p.address ? parseAddress(p.address) : null);
                        }catch(error){
                            console.log(error);
                        }
                        dispatch(positionsActions.update(data.positions));
                    }
                    if (data.notifications) {
                        for (event of data.notifications){
                            if(data.eventPosition[0]){
                                try {
                                    data.eventPosition.forEach(p => p.address = p.address ? parseAddress(p.address) : null);
                                } catch(error) {
                                    console.log(error);
                                }
                                event.position = data.eventPosition[0];
                            }
                        }
                        dispatch(notificationsActions.addNewNotifications(data.notifications));
                    }
                    if (data.alerts) {
                        for (event of data.alerts) {
                            if (data.eventPosition[0]){
                                try {
                                    data.eventPosition.forEach(p => p.address = p.address ? parseAddress(p.address) : null);
                                } catch(error) {
                                    console.log(error);
                                }
                                event.position = data.eventPosition[0];
                            }
                        }
                        dispatch(alertsActions.update(data.alerts));
                    }
                    if (data.geofences) {
                        dispatch(geofencesActions.update(data.geofences));
                    }
                    if (data.remove_geofence) {
                        dispatch(geofencesActions.remove(data.remove_geofence[0]));
                    }
                    if (data.staffers) {
                        dispatch(staffersActions.update(data.staffers));
                    }
                    if (data.remove_staffer) {
                        dispatch(staffersActions.remove(data.remove_staffer[0]));
                    }
                    if (data.remove_device) {
                        dispatch(devicesActions.remove(data.remove_device[0]));
                    }
                    if (data.asyncTaskComplete) {
                        const task = {
                            taskId: data.asyncTaskComplete[0].taskId,
                            message: t(data.asyncTaskComplete[0].title),
                            succeeded: data.asyncTaskComplete[0].succeeded,
                            type: data.asyncTaskComplete[0].type
                        };
                        dispatch(asyncServerTasksActions.markAsyncTaskAsCompleted(task));
                    }
                    if (data.checking) {
                        checkingStore.dispatch(checkingActions.addItem(data.checking[0]));
                    }
                };
            
                socket.onerror = (e) => {
                    console.log("error", e);
                }
            }
        };

        if(authenticated) {
            connectSocket();
        
            return () => {
                clearTimeout(timeoutIdReconnect);
                socket.onclose = () => {};
                socket.close();
            };
        }
    }, [authenticated]);
}

export default useSocketController;
