import { format } from "date-fns";

const filterOnStafferName = (values, staffer) => {
    let enabled = true;
    if(staffer !== undefined) {
        enabled = values.some(value => `${staffer.firstname} ${staffer.name}`.toLowerCase().includes(value.toLowerCase()));
    }
    return enabled;
};

const filterOnDate = (values, date) => {
    return values.some(value => format(new Date(date), 'dd/MM/yyyy').includes(value));
};

export const filterNewItems = (item, staffer, filters) => {
    let enabled = true;
    
    filters.forEach(filter => {
        switch(filter.filterKey) {
            case 'stafferName': 
                enabled &&= filterOnStafferName(filter.values, staffer);
                break;
            case 'date':
                enabled &&= filterOnDate(filter.values, item.date);
                break;
            case 'all':
                enabled &&= (filterOnStafferName(filter.values, staffer) || filterOnDate(filter.values, item.date))
                break;
            default:
        }
    });
        
    return enabled;
};

