import { magellanV0, magellanV1 } from "api/magellan";
import { useEffect, useState } from "react";
import { Command } from "utils/constant";

const useCommands = (id) => {
    const [availableCommands, setAvailableCommands] = useState(null);
    const startOFFCommand = availableCommands?.find(c => c.description === Command.IMMOBILIZATION_ON);
    const startONCommand = availableCommands?.find(c => c.description === Command.IMMOBILIZATION_OFF);
    const antiHackingONCommand = availableCommands?.find(c => c.description === Command.ANTIHACKING_ON);
    const antiHackingOFFCommand = availableCommands?.find(c => c.description === Command.ANTIHACKING_OFF);

    useEffect(() => {
        if(id) {
            magellanV0.get('/api/commands/send', { params: { deviceId: id }})
               .then(response => {
                    setAvailableCommands(response.data);
                })
                .catch(error => {

                });
        }
    }, [id]);

    const sendCommand = async (commandId) => {
        return await magellanV1.post('/api/commands/send', null, { params: { deviceId: id, commandId }, headers: { 'Content-Type': 'application/json'}})
    };

    return { startOFFCommand, startONCommand, antiHackingONCommand, antiHackingOFFCommand, sendCommand };
};

export default useCommands;