import { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import useTranslation from "./useTranslation";
import useFormat from './useFormat';
import { DeviceType, Period, ReportFormat } from 'utils/constant';
import { useLocation, } from 'react-router';

const useDeviceReport = ({ otherFilters, devicesTakenIntoAccount = [DeviceType.VEHICLE, DeviceType.CONSTRUCTION_MACHINE, DeviceType.SMARTPHONE], availableReportActions }) => {
    const { t } = useTranslation();
    const { formatDate } = useFormat();
    const location = useLocation();
    const queryData = new URLSearchParams(location?.search || '');
    const [deviceId, setDeviceId] = useState(Number(queryData?.get('deviceId')) || null);
    const [period, setPeriod] = useState((queryData?.get('from') && queryData?.get('to')) ? 'custom' : Period.TODAY);
    const [from, setFrom] = useState(queryData?.get('from') ? moment(queryData.get('from')) : moment().subtract(1, 'hour'));
    const [to, setTo] = useState(queryData?.get('to') ? moment(queryData.get('to')) : moment());
    const [items, setItems] = useState([]);
    const devices = useSelector(state => 
        Object.values(state.devices.items)
            .filter(device => devicesTakenIntoAccount.includes(device.category)) || []);
    const query = (from && to && to.diff(from) > 0) ? new URLSearchParams({ from: from.toISOString(), to: to.toISOString() }) : '';
    if (query && deviceId) {
        query.append('deviceId', deviceId);
    }
    const displayDisabled = !availableReportActions.includes(ReportFormat.JSON) || !deviceId || to.diff(from, 'months', true) > 1;
    const pdfDisabled = !availableReportActions.includes(ReportFormat.PDF) || !deviceId || to.diff(from, 'months', true) > 1;
    const excelDisabled = !availableReportActions.includes(ReportFormat.EXCEL) || !deviceId;
    const emailDisabled = !availableReportActions.includes(ReportFormat.MAIL) || !deviceId;
    const pdfReportFilters = [
        {
            key: t('deviceFilter'),
            value: devices.find(d => d.id === deviceId)?.name
        },
        {
            key: t('fromFilter'),
            value: formatDate(from)
        },
        {
            key: t('toFilter'),
            value: formatDate(to)
        }
    ];

    const getFiltersKey = () => {
        let filtersKey = `deviceId:${deviceId}`;
        if(otherFilters) {
            for (const [key, value] of Object.entries(otherFilters)) {
                filtersKey += (`-${key}:${value}`);
            }
        }
        if(period === "custom"){
            filtersKey += `-from:${new Date(from.toISOString()).setSeconds(0, 0)}-to:${new Date(to.toISOString()).setSeconds(0, 0)}`;
        } else {
            filtersKey += `-from:${new Date(from.toISOString()).setHours(0, 0, 0, 0)}-to:${new Date(to.toISOString()).setHours(0, 0, 0, 0)}`;
        }
        return filtersKey;
    };

    return  { 
        devices, 
        deviceId, 
        setDeviceId, 
        period, 
        setPeriod, 
        from, 
        setFrom, 
        to, 
        setTo, 
        items, 
        setItems,
        query, 
        pdfReportFilters, 
        getFiltersKey,
        displayDisabled,
        pdfDisabled,
        excelDisabled,
        emailDisabled,
    };
};

export default useDeviceReport;