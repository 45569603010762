import { useDispatch } from "react-redux";
import { devicesActions } from "store";
import { fitBounds } from "./map/mapUtil";

const useFitBounds = ({ positions, map }) => {
    const dispatch = useDispatch();
    
    const deselectDeviceAndFitDevicesPositions = () => {
        dispatch(devicesActions.deselect());
        fitBounds(map, Object.values(positions).map(p => [p.longitude, p.latitude]));
    };

    const deselectDeviceAndZoomToCoordinates = (coordinates) => {
        dispatch(devicesActions.deselect());
        map.flyTo({
            center: coordinates,
            zoom: 17
        })
    };

    return { deselectDeviceAndFitDevicesPositions, deselectDeviceAndZoomToCoordinates };    
};

export default useFitBounds;