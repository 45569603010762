import React from 'react';
import { makeStyles } from 'tss-react/mui';
import FilterHdrIcon from '@mui/icons-material/FilterHdr';
import SpeedIcon from '@mui/icons-material/Speed';
import MyLocationIcon from '@mui/icons-material/MyLocation';

import { useGlobalStyles } from 'context/StylesContext';
import useTranslation from 'hooks/useTranslation';
import useFormat from 'hooks/useFormat';
import { ReactComponent as SatIcon } from 'resources/sat.svg';
import BasicValue from 'components/common/BasicValue';
import QualitySignalIndicator from 'components/common/QualitySignalIndicator';
import FuelOrBatteryStatus from 'components/common/FuelOrBatteryStatus';

const useStyles = makeStyles()(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    }, 
    statusContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        alignItems: 'flex-start',
        rowGap: '2.5vh'
    },
    address: {
        display: 'flex',
        flexDirection: 'column',
    },
    satIcon: {
        width: 19,
        height: 19,
    },
}));

const AssetStatus = ({ item, position }) => {
    const { t } = useTranslation();
    const { formatDistance, formatSpeed } = useFormat();
    const { classes, theme } = useStyles();
    const { classes: globalClasses } = useGlobalStyles();    
 
    return (
        <div className={classes.container}>
            <div className={classes.statusContainer}>
                <BasicValue
                    alignment='center'
                    label={t('itemStatusSpeed')}
                    value={
                        <div className={globalClasses.indicatorContainer}>
                            <div className={globalClasses.iconSize}>
                                <SpeedIcon style={{ color: (position?.speed !== undefined ? theme.palette.on.main : theme.palette.off.main)}} />
                            </div>
                            {position?.speed !== undefined 
                                ? <span>{formatSpeed(position?.speed, 'kmh')}</span>
                                : <span>{t('itemStatusOffline')}</span>
                            }
                        </div>
                    }
                />
               
               <FuelOrBatteryStatus item={item} position={position}/>
                
                <BasicValue
                    alignment='center'
                    label={t('itemStatusSignal')}
                    value={<QualitySignalIndicator position={position} />}
                />

                <BasicValue
                    alignment='center'
                    label={t('itemStatusSat')}
                    value={
                        <div className={globalClasses.indicatorContainer}>
                            <div className={globalClasses.iconSize}>
                                <SatIcon fill={position?.attributes?.sat ? theme.palette.on.main : theme.palette.off.main} className={classes.satIcon}/>
                            </div>
                            {position?.attributes?.sat
                                ? <span>{position?.attributes?.sat}</span>
                                : <span>{t('itemStatusOffline')}</span>
                            }
                        </div>
                    }
                />

                <BasicValue
                    alignment='center'
                    label={t('itemStatusAltitude')}
                    value={
                        <div className={globalClasses.indicatorContainer}>
                            <div className={globalClasses.iconSize}>
                                <FilterHdrIcon style={{color: position?.altitude !== undefined ? theme.palette.on.main : theme.palette.off.main}} />
                            </div>
                            {position?.altitude !== undefined 
                                ? <span>{`${Math.round(position.altitude)} ${t('sharedMeterAbbreviation')}`}</span>
                                : <span>{t('itemStatusOffline')}</span>
                            }
                        </div>
                    }
                />
                
                <BasicValue
                    alignment='center'
                    label={t('itemStatusAccuracy')}
                    value={
                        <div className={globalClasses.indicatorContainer}>
                            <div className={globalClasses.iconSize}>
                                <MyLocationIcon style={{color: position?.accuracy !== undefined ? theme.palette.on.main : theme.palette.off.main}} />
                            </div>
                            {position?.accuracy !== undefined 
                                ? <span>{`${formatDistance(position.accuracy, 'm')}`}</span>
                                : <span>{t('itemStatusOffline')}</span>
                            }
                        </div>
                    }
                />
            </div>
        </div>
    );
};

export default AssetStatus;