import React from 'react';
import { Route, useParams } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { MANAGER } from 'authorization/role';
import { DeviceType } from 'utils/constant';
import CreateTool from './CreateTool';
import UpdateTool from './UpdateTool';

const ToolPage = ({ role }) => {
    const { id } = useParams();
    const type = DeviceType.TOOL;

    const filterDataForServer = item => {
        item.category = type;
        if(!item.groupId){
            delete item.groupId;
        }
        delete item.ownerIds;
        item.beacon = true;
        delete item.lastUpdate;
        delete item.positionId;
        delete item.detectedBy;
        return item;
    }

    if(role === MANAGER){
        return (id) 
            ? <UpdateTool 
                id={ id } 
                filterDataForServer={filterDataForServer} 
                role={role} 
            /> 
            : <CreateTool 
                filterDataForServer={filterDataForServer} 
            />;
    }
    return (id) 
        ? <UpdateTool 
            id={ id } 
            filterDataForServer={filterDataForServer} 
            role={role} 
        /> 
        : <Route element={<Navigate to="signage/tools" />}/>;
};

export default ToolPage;