import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
    name: 'staffers',
    initialState: {
        items: {},
    },
    reducers: {
        update(state, action) {
            action.payload.forEach(item => state.items[item['id']] = item);
        },
        remove(state, action) {
            delete state.items[action.payload];
        }
    },
});

export { actions as staffersActions };
export { reducer as staffersReducer };
