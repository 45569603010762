import _ from 'lodash';
import moment from 'moment';
import useTranslation from 'hooks/useTranslation';
import { format, format as formatDateFns, formatDistanceToNow, formatRelative, getDay, isToday, isYesterday } from 'date-fns';
import { DeviceType, EventType } from '../utils/constant';
import { prefixString } from 'utils/stringUtils';

const useFormat = () => {
    const { t, dateFnsLocale } = useTranslation();

    const formatDecimalNumber = (number, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
        return number?.toLocaleString('nl', { minimumFractionDigits, maximumFractionDigits, useGrouping: true });
    };
    
    const formatBoolean = (value) => {
        return value ? t('sharedYes') : t('sharedNo');
    };
    
    const formatNumber = (value, precision = 1) => {
        return Number(value).toFixed(precision);
    };
    
    const formatDate = (value, format = 'DD/MM/YYYY HH:mm') => {
        return value ? moment(value).format(format) : t('sharedNo');
    };

    const formatLocalDate = (value, format = 'dd/MM/yyyy HH:mm') => {
        return value ? formatDateFns(new Date(value), format, { locale: dateFnsLocale }) : t('sharedNo');
    }
    
    const formatDistance = (value, unit) => {
        switch (unit) {
            case 'mi':
                return `${formatDecimalNumber(value * 0.000621371)} ${t('sharedMi')}`;
            case 'nmi':
                return `${formatDecimalNumber(value * 0.000539957)} ${t('sharedNmi')}`;
            case 'm': 
                return `${Math.floor(value)} ${t('sharedMeterAbbreviation')}`
            case 'km':
            default:
                return `${formatDecimalNumber(value * 0.001)} ${t('sharedKilometerAbbreviation')}`;
        }
    };
    
    const formatDuration = (durationInMillis) => {
        let durationFormatted = "";
        let hours = Math.floor(durationInMillis/1000/60/60);
        let minutes = Math.floor(durationInMillis%(1000*60*60)/1000/60);
        if(hours) {
            durationFormatted = hours + t('sharedHourAbbreviation') + ('0'+minutes).slice(-2);
        } else {
            durationFormatted = `${minutes} ${t('sharedMinuteAbbreviation')}`
        }
        return durationFormatted;
    };

    const formatTimeToNow = (time) => {
        let now = Date.now();
        let formatedTime
        if(isToday(time)){
            formatedTime = formatDistanceToNow(time, {locale: dateFnsLocale, addSuffix: false});
        } else if (isYesterday(time)) {
            formatedTime = formatRelative(time, now, {locale: dateFnsLocale});
        } else {
            formatedTime = format(time, 'PP', {locale: dateFnsLocale});
        }
        return formatedTime;
    };
    
    const formatTripDistance = (distance) => {
        let distanceFormatted = "";
        if(distance < 1000){
            distanceFormatted = formatDistance(distance, 'm');
        } else {
            distanceFormatted = formatDistance(distance, 'km');
        }
        return distanceFormatted;
    }
    
    const formatSpeed = (value, unit) => {
        switch (unit) {
            case 'kmh':
                return `${formatDecimalNumber(value * 1.852)} ${t('sharedKmh')}`;
            case 'mph':
                return `${formatDecimalNumber(value * 1.15078)} ${t('sharedMph')}`;
            case 'kn':
            default:
                return `${formatDecimalNumber(value * 1)} ${t('sharedKn')}`;
        }  
    };
    
    const formatVolume = (value, unit) => {
        switch (unit) {
            case 'impGal':
                return `${formatDecimalNumber(value / 4.546)} ${t('sharedGallonAbbreviation')}`;
            case 'usGal':
                return `${formatDecimalNumber(value / 3.785)} ${t('sharedGallonAbbreviation')}`;
            case 'ltr':
            default:
                return `${formatDecimalNumber(value / 1)} ${t('sharedLiterAbbreviation')}`;
        }  
    }
    
    const formatTime = (value, unit) => {
        switch(unit) {
            case 'hour': 
            default:
                return `${formatDateFns(new Date(value), 'HH:mm').replace(':', t('sharedHourAbbreviation'))}`;
        }
    }
    
    const formatCoordinate = (key, value, unit) => {
        var hemisphere, degrees, minutes, seconds;
        if (key === 'latitude') {
            hemisphere = value >= 0 ? 'N' : 'S';
        } else {
            hemisphere = value >= 0 ? 'E' : 'W';
        }
    
        switch (unit) {
            case 'ddm':
                value = Math.abs(value);
                degrees = Math.floor(value);
                minutes = (value - degrees) * 60;
                return degrees + '° ' + minutes.toFixed(6) + '\' ' + hemisphere;
            case 'dms':
                value = Math.abs(value);
                degrees = Math.floor(value);
                minutes = Math.floor((value - degrees) * 60);
                seconds = Math.round((value - degrees - minutes / 60) * 3600);
                return degrees + '° ' + minutes + '\' ' + seconds + '" ' + hemisphere;
            default:
                return value.toFixed(6) + '°';
        }  
    };
    
    const formatAddress = (address) => {
        const streetAndNumber = address?.street && address?.street + (address?.houseNumber ? `, ${address?.houseNumber}` : '');
        const postalCodeAndLocality = address?.locality?.name && (address?.locality?.postalCode ? (address?.locality?.postalCode + ' ') : '') + address.locality.name;
    
        return {streetAndNumber, postalCodeAndLocality};
    }
    
    const formatAddressOneLine = (address) => {
        const { streetAndNumber } = formatAddress(address);
        if(streetAndNumber && address?.locality?.name) {
            return `${streetAndNumber} - ${address?.locality?.name}`;
        } else {
            if(streetAndNumber) {
                return streetAndNumber;
            } 
            if(address?.locality?.name) {
                return address?.locality?.name;
            }
        }
    }
    
    const formatDeviceCategory = (deviceCategory, plural) => {
        let result = '';
        switch(deviceCategory) {
            case DeviceType.CONSTRUCTION_MACHINE: 
                result = 'assetConstructionMachine';
                break;
            case DeviceType.VEHICLE:
                result = 'assetVehicle';
                break;
            case DeviceType.SMARTPHONE:
                result = 'assetSmartphone';
                break;
            case DeviceType.TRAILER: 
                result = 'assetTrailer';
                break;
            case DeviceType.TOOL:
                result = 'itemTool';
                break;
            case DeviceType.WAREHOUSE:
                result = 'itemWarehouse';
                break;
            default :
                result = '';
        }
        return t(result + (plural ? 's': ''));
    };
    
    const formatDayName = (date) => {
        let dayName = '';
        switch(getDay(date)) {
            case 0:
                dayName = t('sunday');
                break;
            case 1:
                dayName = t('monday');
                break;
            case 2:
                dayName = t('tuesday');
                break;
            case 3:
                dayName = t('wednesday');
                break;
            case 4:
                dayName = t('thursday');
                break;
            case 5:
                dayName = t('friday');
                break;
            case 6:
                dayName = t('saturday');
                break;
            default: dayName = '';
        }
    
        return dayName;
    };

    const formatEventType = (event) => {
        if(event.type === EventType.ALARM && event.attributes.alarm) {
            return t(prefixString('alarm', event.attributes.alarm));
        }
        return t(prefixString('event', event.type));
    }

    return { 
        formatDecimalNumber, 
        formatBoolean, 
        formatNumber, 
        formatDate, 
        formatLocalDate, 
        formatDistance, 
        formatDuration, 
        formatTripDistance, 
        formatSpeed, 
        formatVolume, 
        formatTime, 
        formatCoordinate, 
        formatAddress, 
        formatAddressOneLine, 
        formatDeviceCategory, 
        formatDayName, 
        formatTimeToNow, 
        formatEventType,
        currentLocale: dateFnsLocale 
    };
};

export default useFormat;

