import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
    name: 'alerts',
    initialState: {
        items: {},
        selectedId: null
    },
    reducers: {
        refresh(state, action) {
            state.items = {};
            action.payload.forEach(item => state.items[item['id']] = item);
        },
        select(state, action) {
            state.selectedId = action.payload;
        },
        deselect(state, action) {
            state.selectedId = null;
        },
        removeFromHistory(state, action) {
            delete state.items[action.payload];
        },
        addToHistory(state, action) {
            action.payload.forEach(item => state.items[item['id']] = item);
        },
        update(state, action) {
            for (let alert of action.payload) {
                if (alert.acked) {
                    delete state.items[alert.id];
                } else {
                    state.items[alert.id] = alert;
                }
            }
        }
    }
});

export { actions as alertsActions };
export { reducer as alertsReducer };
