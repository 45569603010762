export const getColumns = ({ t, globalClasses, cx, distanceUnit, speedUnit, formatDate, formatDistance, formatSpeed, formatDuration }) => [{
    Header: t('reportStartDate'),
    accessor: 'startTime',
    Cell: ({ cell: { value } }) => formatDate(value),
    props: {
        className: globalClasses.tableCellFirst,
        style: {
            width: '12.5%',
        },
    }
}, {
    Header: t('sharedDistance'),
    accessor: 'distance',
    Cell: ({ cell: { value } }) => formatDistance(value, distanceUnit),
    props: {
        className: globalClasses.tableCellNumber,
        style: {
            width: '12.5%',
        },
    }
}, {
    Header: t('reportStartOdometer'),
    accessor: 'startOdometer',
    Cell: ({ cell: { value } }) => formatDistance(value, distanceUnit),
    props: {
        className: globalClasses.tableCellNumber,
        style: {
            width: '12.5%',
        },
    }
}, {
    Header: t('reportEndOdometer'),
    accessor: 'endOdometer',
    Cell: ({ cell: { value } }) => formatDistance(value, distanceUnit),
    props: {
        className: globalClasses.tableCellNumber,
        style: {
            width: '12.5%',
        },
    }
}, {
    Header: t('reportAverageSpeed'),
    accessor: 'averageSpeed',
    Cell: ({ cell: { value } }) => formatSpeed(value, speedUnit),
    props: {
        className: globalClasses.tableCellNumber,
        style: {
            width: '12.5%',
        },
    }
}, {
    Header: t('reportMaximumSpeed'),
    accessor: 'maxSpeed',
    Cell: ({ cell: { value } }) => formatSpeed(value, speedUnit),
    props: {
        className: globalClasses.tableCellNumber,
        style: {
            width: '12.5%',
        },
    }
}, {
    Header: t('reportEngineHours'),
    accessor: 'engineHours',
    Cell: ({ cell: { value } }) => formatDuration(value),
    props: {
        className: globalClasses.tableCellNumber,
        style: {
            width: '12.5%',
        },
    }
}, {
    Header: t('reportNumberTrips'),
    accessor: 'nbTrips',
    props: {
        className: cx(globalClasses.tableCellNumber, globalClasses.tableCellLast),
        style: {
            width: '12.5%',
        },
    }
},];