import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { 
    IconButton,
    TextField,
} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import useTranslation from 'hooks/useTranslation';

const useStyles = makeStyles()(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0px 32px 0px 32px',
    },
    companyDetailsContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    imageContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxHeight: 260,
    },
    media: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 180
    },
    image: {
        maxHeight: '100%',
        maxWidth: '100%',
    },
    imageButton: {
        width: 35,
        height: 35,
    },
}));

const CompanyDetails = ({setWarn, editionMode, formik}) => {
    const { t } = useTranslation();
    const { classes } = useStyles();

    const renderIconButton = () => {
        const allowedExtensions =  ['image/jpeg', 'image/png', 'image/bmp', 'image/gif', 'image/svg+xml'];
    
        const onLogoChange = (logoFile) => {
            const sizeLimit = 1000000; // 1 megabyte
            const { type, size } = logoFile;    
            const fileReader = new FileReader();
    
            fileReader.addEventListener("load", (e) => formik.setFieldValue('logo', e.target.result));
            
            if(!allowedExtensions.includes(type.toLowerCase())){
                setWarn(t('imageFormatErrorMessage'));
            }else if(size > sizeLimit){
                setWarn(t('imageSizeErrorMessage'));
            }else{
                fileReader.readAsDataURL(logoFile);          
            }
        }

        return <>
            <div className={classes.media}>
                <img alt={t('companyLogo')} className={classes.image} src={formik.values.logo}/>
            </div>
            <IconButton
                color='primary'
                disabled={!editionMode}
                className={classes.imageButton}
                component="label"
                size="large">
                <AddAPhotoIcon/>
                <input 
                    onChange={(event) => onLogoChange(event.target.files[0])}
                    hidden
                    type="file"
                    accept={allowedExtensions.join()}
                />
            </IconButton>
        </>;
    }

    return(
        <div className={classes.root}>
            <div className={classes.companyDetailsContainer}>
                <TextField 
                    required
                    id="name"
                    name="name"
                    label={t('companyName')}
                    disabled={!editionMode}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
                <TextField 
                    id="legalStatus"
                    name="legalStatus"
                    label={t('companyLegalStatus')}
                    disabled={!editionMode}
                    value={formik.values.legalStatus}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.legalStatus && Boolean(formik.errors.legalStatus)}
                    helperText={formik.touched.legalStatus && formik.errors.legalStatus}
                />
                <TextField
                    id="mailAddress"
                    name="mailAddress"
                    label={t('companyMailAddress')}
                    disabled={!editionMode}
                    value={formik.values.mailAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.mailAddress && Boolean(formik.errors.mailAddress)}
                    helperText={formik.touched.mailAddress && formik.errors.mailAddress}
                />
                <TextField 
                    id="phoneNumber"
                    name="phoneNumber"
                    label={t('companyPhoneNumber')}
                    disabled={!editionMode}
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                />
            </div>
            <div className={classes.imageContainer}>
                {renderIconButton()}                   
            </div>
        </div>
    );
};

export default CompanyDetails;