import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {magellanV0} from 'api/magellan';
import useTranslation from 'hooks/useTranslation';
import useAlertMessage from 'hooks/useAlertMessage';
import Loading from '../common/Loading';
import { DevicesViewColumns, DeviceType } from 'utils/constant';

const useStyles = makeStyles()(theme => ({
    columnAction: {
        width: theme.spacing(1),
        padding: theme.spacing(0, 1),
    },
}));

const DevicesView = ({ updateTimestamp, onMenuClick, displayAddButton, type, columns }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);  
    const groups = useSelector(state => state.groups.items);
    const deviceLimit = useSelector(state => state.session.user.deviceLimit);
    const [nbUserDevices, setNbUserDevices] = useState(null);
    const { setAlertMessage, dataLoadingErrorAlertMessage } = useAlertMessage();

    useEffect(() => {
        magellanV0.get('/api/devices')
            .then(response => {
                const vehicles = response.data.filter(i => i.category === type);
                const devices = response.data.filter(i => ([
                    DeviceType.VEHICLE, 
                    DeviceType.CONSTRUCTION_MACHINE, 
                    DeviceType.SMARTPHONE, 
                    DeviceType.WAREHOUSE
                    ].includes(i.category)));
                setItems(vehicles);
                setNbUserDevices(devices.length);
            })
            .catch(err => {
                setAlertMessage(dataLoadingErrorAlertMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [updateTimestamp]);

    return <>
    <TableContainer>
    <Table>
        <TableHead>
            <TableRow>
                { columns.includes(DevicesViewColumns.LABEL) && <TableCell>{t('signageLabel')}</TableCell> }
                { columns.includes(DevicesViewColumns.TRACKER_IDENTIFIER) && <TableCell>{t('signageTrackerIdentifier')}</TableCell> }
                { columns.includes(DevicesViewColumns.BRAND) && <TableCell>{t('signageBrand')}</TableCell> }
                { columns.includes(DevicesViewColumns.MODEL) && <TableCell>{t('signageModel')}</TableCell> }
                { columns.includes(DevicesViewColumns.GROUP) && <TableCell>{t('signageGroup')}</TableCell>}
                <TableCell className={classes.columnAction} />
            </TableRow>
        </TableHead>
        <TableBody> 
            {loading
                ? <TableRow><TableCell colSpan="100%"><Loading/></TableCell></TableRow>
                : items
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item) => (
                        <TableRow key={item.id}>
                            { columns.includes(DevicesViewColumns.LABEL) && <TableCell>{item.name}</TableCell> }
                            { columns.includes(DevicesViewColumns.TRACKER_IDENTIFIER) && <TableCell>{item.uniqueId}</TableCell> }
                            { columns.includes(DevicesViewColumns.BRAND) && <TableCell>{item.brand}</TableCell> }
                            { columns.includes(DevicesViewColumns.MODEL) && <TableCell>{item.model}</TableCell> }
                            { columns.includes(DevicesViewColumns.GROUP) && <TableCell>{groups?.[item?.groupId]?.name}</TableCell>}
                            <TableCell className={classes.columnAction} padding="none">
                                <IconButton
                                    color='primary'
                                    onClick={(event) => onMenuClick(event.currentTarget, item.id)}
                                    size="large">
                                    <MoreVertIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))
            }
        </TableBody>
    </Table>
    </TableContainer>
    {!loading && displayAddButton(deviceLimit, nbUserDevices)}
    </>;
}

export default DevicesView;