export const getGeofenceOrAddress = (geofence, position, formatAddressOneLine) => {
    let addressValue = geofence?.name;
    let isGeofence = Boolean(addressValue);
    if(!addressValue) {
        addressValue = position && formatAddressOneLine(JSON.parse(position?.address));
    }

    return { isGeofence, addressValue };
};

export const parseAddress = (address) => {
    try {
        return JSON.parse(address);
    } catch(error) {
        return {};
    }
};