import React from 'react';
import _ from 'lodash';

import { magellanV1 } from 'api/magellan';
import withSignageLayout from 'hoc/withSignageLayout';
import { useSignage } from 'hooks/useSignage';
import SignageHeader from '../../common/SignageHeader';
import Loading from '../../common/Loading';
import StafferForm from './StafferForm';
import { MANAGER } from 'authorization/role';

const UpdateStaffer = ({ role, filterDataForServer }) => {
    const {item, editionMode, setEditionMode, updateData, loading, isReadonly} = useSignage({ readApi: magellanV1, createApi: magellanV1, updateApi: magellanV1 }, 'staffers', false);

    const onSubmit = (formValues) => {
        const submission = filterDataForServer(_.cloneDeep(formValues));
        updateData(submission, formValues);
    };

    return (
        <>
            {loading 
                ? <Loading/>
                : <>
                    <SignageHeader editionMode={editionMode} setEditionMode={setEditionMode} editionEnabled={role === MANAGER && !isReadonly} title={item && `${item.firstname} ${item.name}`}/>
                    <StafferForm editionMode={editionMode} onSubmit={onSubmit} initialValues={item}/>
                </>
            }
        </>
    );
}

export default withSignageLayout()(UpdateStaffer);