import React from 'react';

import { magellanV1 } from 'api/magellan';
import { MANAGER } from 'authorization/role';
import withSignageLayout from 'hoc/withSignageLayout';
import { useSignage } from 'hooks/useSignage';
import Loading from '../common/Loading';
import SignageHeader from '../common/SignageHeader';
import DeviceForm from './DeviceForm';

const UpdateDevice = ({ filterDataForServer, role, deviceType, validationSchema }) => {
    const {editionMode, setEditionMode, item, updateData, loading, isReadonly} = useSignage({ readApi: magellanV1, createApi: magellanV1, updateApi: magellanV1 }, 'devices', false);


    const onSubmit = (formValues) => {
        updateData(filterDataForServer({...formValues}), formValues);
    };

    return (
        <>
            {loading 
                ? <Loading/>
                : <>
                    <SignageHeader editionMode={editionMode} setEditionMode={setEditionMode} editionEnabled={role === MANAGER && !isReadonly} title={item && item.name}/>
                    <DeviceForm editionMode={editionMode} onSubmit={onSubmit} initialValues={item} validationSchema={validationSchema} deviceType={deviceType}/>
                </>
            }
        </>
    );
}

export default withSignageLayout()(UpdateDevice);