import React from 'react';
import { useParams } from 'react-router-dom';
import nextMonday from 'date-fns/nextMonday';
import nextTuesday from 'date-fns/nextTuesday';
import nextWednesday from 'date-fns/nextWednesday';
import nextThursday from 'date-fns/nextThursday';
import nextFriday from 'date-fns/nextFriday';
import nextSaturday from 'date-fns/nextSaturday';
import nextSunday from 'date-fns/nextSunday';
import ical from 'ical-generator';

import CreateNotification from './CreateNotification';
import UpdateNotification from './UpdateNotification';
import { DayOfWeek, EventType } from 'utils/constant';
import { CRUDAction } from 'utils/constant';

const NotificationPage = ({role}) => {
    const { id } = useParams();

    const filterDataForServer = (item, initialValues) => {
        let groupsToLink = item.groups.finalItems.filter(g => !item.groups.initialItems.includes(g));
        let groupsToUnlink = item.groups.initialItems.filter(g => !item.groups.finalItems.includes(g));
        let devicesToLink =  item.devices.finalItems.filter(g => !item.devices.initialItems.includes(g));
        let devicesToUnlink = item.devices.initialItems.filter(g => !item.devices.finalItems.includes(g));
        let calendar = {};
        let calendarAction = null;
        
        let notification = {
            name: item.name,
            active: item.active,
            always: item.currentDevicesSelector === 'always',
            notificators: item.notificators.join(',') || '',
        }

                if (item.id){
            notification.id = item.id;
        }

        if (Object.values(EventType).includes(item.type)) {
            notification.type = item.type;
            if(notification?.attributes?.alarm) {
                delete notification.attributes.alarm;
            }
        } else {
            notification.type = EventType.ALARM;
            notification.attributes = { alarm: item.type };
        }
       
        if (item.calendar !== null) {
            calendar = {
                name: 'timetable-for-notification',
                data : convertTimeStringToCalendar(item.calendar),
            };
        }
        if (initialValues.calendarId) {
            if (initialValues.calendar && item.calendar === null) {
                notification.calendarId = null;
                calendarAction = CRUDAction.DELETE;
            } else if (initialValues.calendar === null && item.calendar) {
                calendarAction = CRUDAction.CREATE;
            } else if (initialValues.calendar && item.calendar) {
                notification.calendarId = initialValues.calendarId;
                calendarAction = CRUDAction.UPDATE;
            }
            calendar.id = initialValues.calendarId;
        } else {
            if (item.calendar)  {
                calendarAction = CRUDAction.CREATE;
            } 
        }
         
        return { calendar, calendarAction, notification, groupsToLink, groupsToUnlink, devicesToLink, devicesToUnlink };
    }

    const standardizeDate = (dateNotif, dayOfWeek) => {
        let dateBase = new Date(dateNotif)
        let standardDate = new Date('2000-01-01')

        switch (dayOfWeek) {
            case DayOfWeek.MONDAY:
                standardDate = nextMonday(standardDate);
                break;
            case DayOfWeek.TUESDAY:
                standardDate = nextTuesday(standardDate);
                break;
            case DayOfWeek.WEDNESDAY:
                standardDate = nextWednesday(standardDate);
                break;
            case DayOfWeek.THURSDAY:
                standardDate = nextThursday(standardDate);
                break;
            case DayOfWeek.FRIDAY:
                standardDate = nextFriday(standardDate);
                break;
            case DayOfWeek.SATURDAY:
                standardDate = nextSaturday(standardDate);
                break;
            case DayOfWeek.SUNDAY:
                standardDate = nextSunday(standardDate);
                break;
            default :
                throw 'Day of week exception!';
        }
        standardDate.setHours(dateBase.getHours(), dateBase.getMinutes());
        return(standardDate);
    }

    const convertTimeStringToCalendar = (item) => {
        const calendar = new ical();
        (item.mondayStartHour && item.mondayEndHour) && addEventWeekly(
            calendar,
            DayOfWeek.MONDAY,
            standardizeDate(new Date(item.mondayStartHour), DayOfWeek.MONDAY),
            standardizeDate(new Date(item.mondayEndHour), DayOfWeek.MONDAY)
        );
        (item.tuesdayStartHour && item.tuesdayEndHour) && addEventWeekly(
            calendar,
            DayOfWeek.TUESDAY,
            standardizeDate(new Date(item.tuesdayStartHour), DayOfWeek.TUESDAY),
            standardizeDate(new Date(item.tuesdayEndHour), DayOfWeek.TUESDAY)
        );
        (item.wednesdayStartHour && item.wednesdayEndHour) && addEventWeekly(
            calendar,
            DayOfWeek.WEDNESDAY,
            standardizeDate(new Date(item.wednesdayStartHour), DayOfWeek.WEDNESDAY),
            standardizeDate(new Date(item.wednesdayEndHour), DayOfWeek.WEDNESDAY)
        );
        (item.thursdayStartHour && item.thursdayEndHour) && addEventWeekly(
            calendar,
            DayOfWeek.THURSDAY,
            standardizeDate(new Date(item.thursdayStartHour), DayOfWeek.THURSDAY),
            standardizeDate(new Date(item.thursdayEndHour), DayOfWeek.THURSDAY)
        );
        (item.fridayStartHour && item.fridayEndHour) && addEventWeekly(
            calendar,
            DayOfWeek.FRIDAY,
            standardizeDate(new Date(item.fridayStartHour), DayOfWeek.FRIDAY),
            standardizeDate(new Date(item.fridayEndHour), DayOfWeek.FRIDAY)
        );
        (item.saturdayStartHour && item.saturdayEndHour) && addEventWeekly(
            calendar,
            DayOfWeek.SATURDAY,
            standardizeDate(new Date(item.saturdayStartHour), DayOfWeek.SATURDAY),
            standardizeDate(new Date(item.saturdayEndHour), DayOfWeek.SATURDAY)
        );
        (item.sundayStartHour && item.sundayEndHour) && addEventWeekly(
            calendar,
            DayOfWeek.SUNDAY,
            standardizeDate(new Date(item.sundayStartHour), DayOfWeek.SUNDAY),
            standardizeDate(new Date(item.sundayEndHour), DayOfWeek.SUNDAY)
        );
        return window.btoa(calendar.toString());
    };

    const addEventWeekly = (calendar, day, startDate, endDate) => {
        calendar.createEvent({
            id: day,
            start: startDate,
            end: endDate,
            repeating: {
                freq: "WEEKLY"
            }
        });
    };

    return (id) ? <UpdateNotification id={id} role={role} filterDataForServer={filterDataForServer} /> : <CreateNotification filterDataForServer={filterDataForServer}/>;

};

export default NotificationPage;
