import React from 'react';
import { makeStyles } from 'tss-react/mui';

import useTranslation from 'hooks/useTranslation';

const useStyles = makeStyles()(theme => ({
    none: {
        color: theme.palette.off.main,
    }
}));

const None = ({ text }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    let none = text || t('none');

    return <span className={classes.none}>{none}</span>;
};

export default None;