import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {magellanV0} from 'api/magellan';
import useTranslation from 'hooks/useTranslation';
import useFormat from 'hooks/useFormat';
import useAlertMessage from 'hooks/useAlertMessage';
import EditCollectionView from '../../common/EditCollectionView';
import Loading from '../../common/Loading';

const useStyles = makeStyles()(theme => ({
    columnAction: {
        width: theme.spacing(1),
        padding: theme.spacing(0, 1),
    },
}));

const UsersView = ({ updateTimestamp, onMenuClick, displayAddButton }) => {
    const { t } = useTranslation();
    const { formatBoolean, formatDate } = useFormat();
    const { classes } = useStyles();
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);  
    const [companies, setCompanies] = useState([]);  
    const [userLimit, adminEnabled] = useSelector(state => [state.session.user.userLimit, state.session.user.administrator],
        (prev, next) => prev.userLimit === next.userLimit && prev.adminEnabled === next.adminEnabled);
    const { setAlertMessage, dataLoadingErrorAlertMessage } = useAlertMessage();

    useEffect(() => {
        const requests = [];
        requests.push(magellanV0.get('/api/users')
            .catch(error => {
                setAlertMessage(dataLoadingErrorAlertMessage);
            })
        );
        if(adminEnabled){
            requests.push(magellanV0.get('/api/companies')
                .catch(error => {
                    setAlertMessage(dataLoadingErrorAlertMessage);
                })
            );
        }

        Promise.all(requests)
            .then(([usersResponse, companiesResponse]) => {
                setItems(usersResponse.data);
                if(adminEnabled){
                    setCompanies(companiesResponse.data);
                }
            })
            .catch(error => {
                
            })
            .finally(() => {
                setLoading(false);
            });

    }, [updateTimestamp]);

    return <>
    <TableContainer>
    <Table>
        <TableHead>
            <TableRow>
                <TableCell>{t('sharedName')}</TableCell>
                <TableCell>{t('userEmail')}</TableCell>
                {adminEnabled && <TableCell>{t('userCompany')}</TableCell>}
                <TableCell>{t('sharedDisabled')}</TableCell>
                <TableCell>{t('userReadonly')}</TableCell>
                <TableCell>{t('userExpirationTime')}</TableCell>
                <TableCell className={classes.columnAction} />
            </TableRow>
        </TableHead>
        <TableBody>
            {loading 
                ? <TableRow><TableCell colSpan="100%"><Loading/></TableCell></TableRow>
                : items
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            {adminEnabled && <TableCell>{companies.length > 0 && item.companyId  && companies.find(c => c.id === item.companyId).name}</TableCell>}
                            <TableCell>{formatBoolean(item.disabled)}</TableCell>
                            <TableCell>{formatBoolean(item.readonly)}</TableCell>
                            <TableCell>{formatDate(item.expirationTime)}</TableCell>
                            <TableCell className={classes.columnAction} padding="none">
                                <IconButton
                                    color='primary'
                                    onClick={(event) => onMenuClick(event.currentTarget, item.id)}
                                    size="large">
                                    <MoreVertIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))
            }
        </TableBody>
    </Table>
    </TableContainer>
    {!loading && displayAddButton(userLimit, items.length - 1)}
    </>;
}

const UsersPage = () => {

    return (
        <EditCollectionView content={UsersView} editPath="user" endpoint="users" />
    );
}

export default UsersPage;
