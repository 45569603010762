import React from 'react';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';

import { useGlobalStyles } from 'context/StylesContext';

const FitBoundsMapControl = ({ fitBounds, }) => {
    const { classes, cx } = useGlobalStyles();

    return (
        <div className={classes.mapControlButtonBorder}>
            <div
                className={cx(classes.mapControlButton, classes.mapControlButtonBorderRadius)}
                onClick={fitBounds}
            >
                <CenterFocusWeakIcon className={classes.mapControlButtonIcon}/>
            </div>
        </div>
    );
};

export default FitBoundsMapControl;