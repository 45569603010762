export const DayOfWeek = Object.freeze({
    'MONDAY': 'monday',
    'TUESDAY': 'tuesday',
    'WEDNESDAY': 'wednesday',
    'THURSDAY': 'thursday',
    'FRIDAY': 'friday',
    'SATURDAY': 'saturday',
    'SUNDAY': 'sunday',
});

export const DeviceType = Object.freeze({ 
    'SMARTPHONE': 'SMARTPHONE', 
    'VEHICLE': 'VEHICLE', 
    'CONSTRUCTION_MACHINE': 'CONSTRUCTION_MACHINE', 
    'WAREHOUSE': 'WAREHOUSE', 
    'TOOL':'TOOL', 
    'TRAILER': 'TRAILER' 
});

export const DeviceStatus = Object.freeze({ 'ONLINE': 'online', 'OFFLINE': 'offline'});

export const MotionStatus = Object.freeze({ 
    'SMARTPHONE_ON': 'SMARTPHONE_ON', 
    'SMARTPHONE_OFF': 'SMARTPHONE_OFF', 
    'SMARTPHONE_UNKNOWN': 'SMARTPHONE_UNKNOWN',
    'VEHICLE_ON': 'VEHICLE_ON', 
    'VEHICLE_OFF': 'VEHICLE_OFF', 
    'VEHICLE_UNKNOWN': 'VEHICLE_UNKNOWN',
    'CONSTRUCTION_MACHINE_ON': 'CONSTRUCTION_MACHINE_ON', 
    'CONSTRUCTION_MACHINE_OFF': 'CONSTRUCTION_MACHINE_OFF', 
    'CONSTRUCTION_MACHINE_UNKNOWN': 'CONSTRUCTION_MACHINE_UNKNOWN', 
    'TOOL_ON': 'TOOL_ON',
    'TOOL_OFF': 'TOOL_OFF',
    'TOOL_UNKNOWN': 'TOOL_UNKNOWN',
    'TRAILER_ON': 'TRAILER_ON',
    'TRAILER_OFF': 'TRAILER_OFF',
    'TRAILER_UNKNOWN': 'TRAILER_UNKNOWN',
    'WAREHOUSE_OFF': 'WAREHOUSE_OFF',
    'WAREHOUSE_UNKNOWN': 'WAREHOUSE_UNKNOWN',
})

export const ConstructionMachineType = [
    { name: 'signageMiniExcavator', id: 'MINI_EXCAVATOR' },
    { name: 'signageExcavator', id: 'EXCAVATOR' },
    { name: 'signageDumper', id: 'DUMPER' },
    { name: 'signageLoader', id: 'LOADER' },
    { name: 'signageCompactor', id: 'COMPACTOR' },
    { name: 'signageElevator', id: 'ELEVATOR' },
];

export const EmissionStandard = [ 
    { name: 'signageEuro1', id: 'EURO_1' },
    { name: 'signageEuro2', id: 'EURO_2' },
    { name: 'signageEuro3', id: 'EURO_3' },
    { name: 'signageEuro4', id: 'EURO_4' },
    { name: 'signageEuro5', id: 'EURO_5' },
    { name: 'signageEuro6', id: 'EURO_6' },
];

export const motionLocationIcon = Object.freeze({
    'SMARTPHONE_LOCATION_ON': 'smartphone_location_on',
    'SMARTPHONE_LOCATION_ON_SELECTED': 'smartphone_location_on_selected',
    'SMARTPHONE_LOCATION_OFF': 'smartphone_location_off',
    'SMARTPHONE_LOCATION_OFF_SELECTED': 'smartphone_location_off_selected',
    'SMARTPHONE_LOCATION_UNKNOWN': 'smartphone_location_unknown',
    'SMARTPHONE_LOCATION_UNKNOWN_SELECTED': 'smartphone_location_unknown_selected',
    'CAR_LOCATION_ON': 'car_location_on',
    'CAR_LOCATION_ON_SELECTED': 'car_location_on_selected',
    'CAR_LOCATION_OFF': 'car_location_off',
    'CAR_LOCATION_OFF_SELECTED': 'car_location_off_selected',
    'CAR_LOCATION_UNKNOWN': 'car_location_unknown',
    'CAR_LOCATION_UNKNOWN_SELECTED': 'car_location_unknown_selected',
    'CONSTRUCTION_MACHINE_LOCATION_ON': 'construction_machine_location_on',
    'CONSTRUCTION_MACHINE_LOCATION_ON_SELECTED': 'construction_machine_location_on_selected',
    'CONSTRUCTION_MACHINE_LOCATION_OFF': 'construction_machine_location_off',
    'CONSTRUCTION_MACHINE_LOCATION_OFF_SELECTED': 'construction_machine_location_off_selected',
    'CONSTRUCTION_MACHINE_LOCATION_UNKNOWN': 'construction_machine_location_unknown',
    'CONSTRUCTION_MACHINE_LOCATION_UNKNOWN_SELECTED': 'construction_machine_location_unknown_selected',
    'TOOL_LOCATION_ON': 'tool_location_on',
    'TOOL_LOCATION_ON_SELECTED': 'tool_location_on_selected',
    'TOOL_LOCATION_OFF': 'tool_location_off',
    'TOOL_LOCATION_OFF_SELECTED': 'tool_location_off_selected',
    'TOOL_LOCATION_UNKNOWN': 'tool_location_unknown',
    'TOOL_LOCATION_UNKNOWN_SELECTED': 'tool_location_unknown_selected',
    'TRAILER_LOCATION_ON': 'trailer_location_on',
    'TRAILER_LOCATION_ON_SELECTED': 'trailer_location_on_selected',
    'TRAILER_LOCATION_OFF': 'trailer_location_off',
    'TRAILER_LOCATION_OFF_SELECTED': 'trailer_location_off_selected',
    'TRAILER_LOCATION_UNKNOWN': 'trailer_location_unknown',
    'TRAILER_LOCATION_UNKNOWN_SELECTED': 'trailer_location_unknown_selected',
    'WAREHOUSE_LOCATION_OFF': 'warehouse_location_off',
    'WAREHOUSE_LOCATION_OFF_SELECTED': 'warehouse_location_off_selected',
    'WAREHOUSE_LOCATION_UNKNOWN': 'warehouse_location_unknown',
    'WAREHOUSE_LOCATION_UNKNOWN_SELECTED': 'warehouse_location_unknown_selected',
    'DIRECTION': 'direction',
    'POINTER': 'pointer',
});

export const AlarmType = Object.freeze({
    general: { key: 'general', translatorKey: "alarmGeneral" },
    sos: { key: 'sos', translatorKey: "alarmSos" },
    vibration: { key: 'vibration', translatorKey: "alarmVibration" },
    movement: { key: 'movement', translatorKey: "alarmMovement" },
    lowspeed: { key: 'lowspeed', translatorKey: "alarmLowspeed" },
    overspeed: { key: 'overspeed', translatorKey: "alarmOverspeed" },
    fallDown: { key: 'fallDown', translatorKey: "alarmFallDown" },
    lowPower: { key: 'lowPower', translatorKey: "alarmLowPower" },
    lowBattery: { key: 'lowBattery', translatorKey: "alarmLowBattery" },
    criticalBattery: { key: 'criticalBattery', translatorKey: "alarmCriticalBattery" },
    fault: { key: 'fault', translatorKey: "alarmFault" },
    powerOff: { key: 'powerOff', translatorKey: "alarmPowerOff" },
    powerOn: { key: 'powerOn', translatorKey: "alarmPowerOn" },
    door: { key: 'door', translatorKey: "alarmDoor" },
    lock: { key: 'lock', translatorKey: "alarmLock" },
    unlock: { key: 'unlock', translatorKey: "alarmUnlock" },
    geofence: { key: 'geofence', translatorKey: "alarmGeofence" },
    geofenceEnter: { key: 'geofenceEnter', translatorKey: "alarmGeofenceEnter" },
    geofenceExit: { key: 'geofenceExit', translatorKey: "alarmGeofenceExit" },
    gpsAntennaCut: { key: 'gpsAntennaCut', translatorKey: "alarmGpsAntennaCut" },
    accident: { key: 'accident', translatorKey: "alarmAccident" },
    tow: { key: 'tow', translatorKey: "alarmTow" },
    idle: { key: 'idle', translatorKey: "alarmIdle" },
    highRpm: { key: 'highRpm', translatorKey: "alarmHighRpm" },
    hardAcceleration: { key: 'hardAcceleration', translatorKey: "alarmHardAcceleration" },
    hardBraking: { key: 'hardBraking', translatorKey: "alarmHardBraking" },
    hardCornering: { key: 'hardCornering', translatorKey: "alarmHardCornering" },
    laneChange: { key: 'laneChange', translatorKey: "alarmLaneChange" },
    fatigueDriving: { key: 'fatigueDriving', translatorKey: "alarmFatigueDriving" },
    powerCut: { key: 'powerCut', translatorKey: "alarmPowerCut" },
    powerRestored: { key: 'powerRestored', translatorKey: "alarmPowerRestored" },
    jamming: { key: 'jamming', translatorKey: "alarmJamming" },
    temperature: { key: 'temperature', translatorKey: "alarmTemperature" },
    parking: { key: 'parking', translatorKey: "alarmParking" },
    shock: { key: 'shock', translatorKey: "alarmShock" },
    bonnet: { key: 'bonnet', translatorKey: "alarmBonnet" },
    footBrake: { key: 'footBrake', translatorKey: "alarmFootBrake" },
    fuelLeak: { key: 'fuelLeak', translatorKey: "alarmFuelLeak" },
    tampering: { key: 'tampering', translatorKey: "alarmTampering" },
    unplugged: { key: 'unplugged', translatorKey: "alarmUnplugged" },
    plugged: { key: 'plugged', translatorKey: "alarmPlugged" },
});

export const BatteryState = Object.freeze({
    'OK': 'OK', 
    'WARNING': 'WARNING',
    'CRITICAL': 'CRITICAL',
});

export const EventType = Object.freeze({
    'ALL_EVENTS': 'allEvents',
    'COMMAND_RESULT': 'commandResult',
    'DEVICE_ONLINE': 'deviceOnline',
    'DEVICE_OFFLINE': 'deviceOffline',
    'DEVICE_INACTIVE': 'deviceInactive',
    'DEVICE_MOVING': 'deviceMoving',
    'DEVICE_STOPPED': 'deviceStopped',
    'DEVICE_OVERSPEED': 'deviceOverspeed',
    'DEVICE_FUEL_DROP': 'deviceFuelDrop',
    'GEOFENCE_ENTER': 'geofenceEnter',
    'GEOFENCE_EXIT': 'geofenceExit',
    'ALARM': 'alarm',
    'IGNITION_ON': 'ignitionOn',
    'IGNITION_OFF': 'ignitionOff',
    'MAINTENANCE': 'maintenance',
    'TEXT_MESSAGE': 'textMessage',
    'STAFFER_CHANGED': 'stafferChanged',
    'BEACON_IN': 'beaconIn',
    'BEACON_OUT': 'beaconOut',
});

export const AsyncServerTasksType = Object.freeze({
    'ROUTE_REPORT_EMAIL':  'routeReportEmail',
    'EVENTS_REPORT_EMAIL':  'eventsReportEmail',
    'TRIPS_REPORT_EMAIL':  'tripsReportEmail',
    'STOPS_REPORT_EMAIL':  'stopsReportEmail',
    'SUMMARY_REPORT_EMAIL':  'summaryReportEmail',
    'TIMESHEET_REPORT_EMAIL':  'timesheetReportEmail',
});

export const DevicesViewColumns = Object.freeze({
    'LABEL': 'LABEL',
    'TRACKER_IDENTIFIER': 'TRACKER_IDENTIFIER',
    'BRAND': 'BRAND',
    'MODEL': 'MODEL',
    'GROUP': 'GROUP',
});

export const ReportFormat = Object.freeze({
    'JSON': 'json',
    'PDF': 'pdf',
    'EXCEL': 'excel',
    'MAIL': 'mail',
});

export const AlertMessageSeverity = Object.freeze({
    'ERROR': 'error',
    'WARNING': 'warning',
    'SUCCESS': 'success',
    'INFO': 'info',
});

export const LoginFailedCode = Object.freeze({
    'ACCOUNT_DISABLED': 'ACCOUNT_DISABLED',
    'ACCOUNT_EXPIRED': 'ACCOUNT_EXPIRED', 
    'INVALID_AUTHENTICATION_TOKENS': 'INVALID_AUTHENTICATION_TOKENS', 
    'USER_COMPANY_DISABLED': 'USER_COMPANY_DISABLED', 
    'USER_COMPANY_EXPIRED': 'USER_COMPANY_EXPIRED',
});

export const GeometryType = Object.freeze({
    'POLYGON': 'Polygon',
    'CIRCLE': 'Circle',
});

export const Sex = Object.freeze({
    'FEMALE': 'f',
    'MALE': 'm',
});

export const Command = Object.freeze({
    'IMMOBILIZATION_ON': 'IMMOBILIZATION_ON',
    'IMMOBILIZATION_OFF': 'IMMOBILIZATION_OFF', 
    'ANTIHACKING_ON': 'ANTIHACKING_ON',
    'ANTIHACKING_OFF': 'ANTIHACKING_OFF',
});

export const TimesheetErrorCode = Object.freeze({
    'NO_ERROR': 'NO_ERROR',
    'ODD_CHECKING': 'ODD_CHECKING',
    'DUPLICATE' : 'DUPLICATE',
});

export const CRUDAction = Object.freeze({
    READ: 'READ',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
});

export const ColorMode = Object.freeze({
    'GREEN': 'green',
    'BLUE': 'blue',
    'DARK': 'dark',
});

export const AssetColumns = Object.freeze({
    ITEM_STATUS: 'itemStatus',
    ITEM_CATEGORY: 'itemCategory',
    ITEM_LICENSE_PLATE: 'itemLicensePlate',
    ITEM_LABEL: 'itemLabel',
    ITEM_ADDRESS: 'itemAddress',
    ITEM_GROUP: 'itemGroup',
    ITEM_ODOMETER_OR_HOUR: 'itemOdometerOrHour',
    ITEM_LAST_DATA: 'itemLastData',
});

export const BeaconsColumns = Object.freeze({
    ITEM_STATUS: 'itemStatus',
    ITEM_CATEGORY: 'itemCategory',
    ITEM_LABEL: 'itemLabel',
    ITEM_TOOL_DEVICE_ASSOCIATED: 'toolDeviceAssociated',
    ITEM_ADDRESS: 'itemAddress',
    ITEM_GROUP: 'itemGroup',
    ITEM_LAST_DATA: 'itemLastData',
});

export const Parameters = Object.freeze({
    ASSET_FILTERS: 'ASSET_FILTERS',
    TOOL_FILTERS: 'TOOL_FILTERS',
    ASSET_PAGINATION: 'ASSET_PAGINATION',
    TOOL_PAGINATION: 'TOOL_PAGINATION',
});

export const Period = Object.freeze({
    TODAY: 'today',
    YESTERDAY: 'yesterday',
    THIS_WEEK: 'thisWeek',
    PREVIOUS_WEEK: 'previousWeek',
    THIS_MONTH: 'thisMonth',
    PREVIOUS_MONTH: 'previousMonth',
});