import Address from 'components/common/Address';
import BasicValue from 'components/common/BasicValue';
import None from 'components/common/None';
import useFormat from 'hooks/useFormat';
import useTranslation from 'hooks/useTranslation';
import React from 'react';

const StopPopupContent = ({ position }) => {
    const { t } = useTranslation();
    const { formatLocalDate } = useFormat();

    return (
        <div>
            <BasicValue
                label={t('date')}
                value={formatLocalDate(position.fixTime)}
            />
            <BasicValue
                label={t('itemAddress')}
                value={position?.address
                    ? <Address address={position.address} placement="start" />
                    : <None text={t('itemAddressNone')}/>
                }
            />
        </div>
        
    );
};

export default StopPopupContent;