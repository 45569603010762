import _ from 'lodash';
import React from "react";
import { Button, Tooltip, } from "@mui/material";
import { isValidDate } from "utils/date";
import { Check, DeleteForever, Edit, RestartAlt } from '@mui/icons-material';
import { timesheetReportActions } from 'store';
import BreakTimeField from '../BreakTimeField';
import TimePickerCell from '../TimePickerCell';

export const getColumns = ({ t, classes, globalClasses, formatLocalDate, formatDuration, }) => [ 
    {
        accessor: 'stafferName',
        Header: t('stafferName'),
        props: {
            className: globalClasses.tableCellFirst,
            style: {
                width: '16%',
            }
        },
    },
    {
        accessor: 'date',
        id: 'day',
        Header: t('day'),
        Cell: ({ cell: { value: date } }) => {
            if(isValidDate(new Date(date))) {
                return _.capitalize(formatLocalDate(date, 'iiii'));
            }
            return null;
        },
        props: {
            style: {
                width: '6%',
            }
        },
    },
    {
        accessor: 'date',
        id: 'date',
        Header: t('date'),
        Cell: ({ cell: { value: date, }}) => {
            if(isValidDate(new Date(date))) {
                return formatLocalDate(date, 'PPP');
            }
            return null;
        },
       props: {
            style: {
                width: '8%',
            }
        },
    },
    {
        accessor: 'startTime',
        Header: t('timesheetStartTime'),
        Cell: ({ cell: { value, row: { id, original: { isEditable } }, column: { id: prop } }, meta: { updateTimesheet, }}) => {
            if(isEditable) {
                return (
                    <TimePickerCell
                        initialValue={value}
                        id={id}
                        prop={prop}
                        updateTimesheet={updateTimesheet}
                    />
                );
            }
            return formatLocalDate(new Date(value));
        },
        props: {
            className: globalClasses.tableCellFixedSize,
            style: {
                width: '14%',
            }
        },
    },
    {
        accessor: 'endTime',
        Header: t('timesheetEndTime'),
        Cell: ({ cell: { value, row: { id, original: { isEditable } }, column: { id: prop } }, meta: { updateTimesheet, }}) => {
            if(isEditable) {
                return (
                    <TimePickerCell
                        initialValue={value}
                        id={id}
                        prop={prop}
                        updateTimesheet={updateTimesheet}
                    />
                );
            }
            return formatLocalDate(new Date(value));
        },
        props: {
            className: globalClasses.tableCellFixedSize,
            style: {
                width: '14%',
            }
        },
    },
    {
        accessor: 'breakTime',
        Header: t('timesheetBreakTime'),
        Cell: ({ cell: { value, row: { id, original: { isEditable } }, column: { id: prop } }, meta: { updateTimesheet, } }) => {
            if(isEditable) {
                return (
                    <BreakTimeField
                        initialValue={value}
                        id={id}
                        prop={prop}
                        updateTimesheet={updateTimesheet}
                        t={t}
                    />
                );
            }
            return formatDuration(value*1000);
        },
        props: {
            className: globalClasses.tableCellFixedSize,
            style: {
                width: '14%',
            }
        },
    },
    {
        accessor: 'timesheetWorkingTime',
        Header: t('timesheetWorkingTime'),
        Cell: ({ cell: { value }}) => formatDuration(value),
        props: {
            className: globalClasses.tableCellNumber,
            style: {
                width: '10%',
            }
        },
    },
    {
        Header: () => null, 
        accessor: 'id',
        id: 'edition',
        Cell: ({ cell: { value: id, row: { original: { isEditable } } }, meta: { removeTimesheet, validateTimesheet, dispatch } }) => {
            return isEditable 
                ? (
                    <div className={globalClasses.timesheetEditionContainer}>
                        <Tooltip
                            title={t('timesheetReset')}
                            placement='top'
                        >
                            <Button onClick={() => dispatch(timesheetReportActions.resetItem(id))}>
                                <RestartAlt/>
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title={t('timesheetSave')}
                            placement='top'
                        >
                            <Button 
                                onClick={() => validateTimesheet(id)}
                            >  
                                <Check/>
                            </Button>
                        </Tooltip>
                        <Tooltip 
                            title={t('timesheetRemove')} 
                            placement='top'
                        >
                            <Button 
                                onClick={() => removeTimesheet(id)}
                            >
                                <DeleteForever/>
                            </Button>
                        </Tooltip>
                    </div>
                )
                : (
                    <Tooltip
                        title={t('timesheetEdit')}
                        placement='top'
                    >
                        <Button 
                            className={classes.editButton}
                            onClick={() => dispatch(timesheetReportActions.enableEdition(id))}
                        >
                            <Edit/>
                        </Button>
                    </Tooltip>
                );
        },
        disableSortBy: true,
        props: {
            style: {
                width: '18%',
            }
        },
    }
];