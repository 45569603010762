import _ from 'lodash';
import { magellanV1 } from "api/magellan";
import { useSelector } from "react-redux";
import { useEffect } from 'react';

const useFilters = ({ filters, filtersId, setTableFilters, setFiltersInCache, resetPage  }) => {
    const userId = useSelector(state => state.session.user.id);

    useEffect(() => {
        if (setTableFilters) {
            setTableFilters(filters);
        }
    }, [JSON.stringify(filters)]);

    const getNewFilters = (filterKey, value) => {
        let result = _.cloneDeep(filters);
        let filterIndex = result.findIndex(o => o.filterKey === filterKey);
        if (filterIndex > -1) {
            let valueIndex = result[filterIndex].values.findIndex(v => v === value);
            if (valueIndex > -1) {
                if (result[filterIndex].values.length > 1) {
                    result[filterIndex].values.splice(valueIndex, 1);
                } else {
                    result.splice(filterIndex, 1);
                }
            } else {
                result[filterIndex].values.push(value);
            }
        } else {
            result = [...result, { filterKey, values: [value]}];
        }
        return result;
    }

    const onFiltersChange = (newFilters) => {
        magellanV1.post(`api/users/${userId}/parameters/${filtersId}`, newFilters).catch();
        if (resetPage) {
            resetPage();
        }
    };

    const handleFilterChange = (filterKey, value) => {
        let newFilters = getNewFilters(filterKey, value);
        setFiltersInCache(newFilters);
        onFiltersChange(newFilters);
    }

    const handleGlobalSearchChange = (getFilters) => {
        const newFilters = getFilters(filters);
        setFiltersInCache(newFilters);
        onFiltersChange(newFilters)
    };

    return { handleFilterChange, handleGlobalSearchChange }
};

export default useFilters;