import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import VehiclesPage from '../components/signage/vehicle/VehiclesPage';
import VehiclePage from '../components/signage/vehicle/VehiclePage';
import ConstructionMachinesPage from '../components/signage/construction_machine/ConstructionMachinesPage';
import ConstructionMachinePage from '../components/signage/construction_machine/ConstructionMachinePage';
import SmartphonesPage from '../components/signage/smartphone/SmartphonesPage';
import SmartphonePage from '../components/signage/smartphone/SmartphonePage';
import WarehousesPage from '../components/signage/warehouse/WarehousesPage';
import WarehousePage from '../components/signage/warehouse/WarehousePage';
import ToolsPage from '../components/signage/tool/ToolsPage';
import ToolPage from '../components/signage/tool/ToolPage';
import TrailersPage from '../components/signage/trailer/TrailersPage';
import TrailerPage from '../components/signage/trailer/TrailerPage';
import GeofencesPage from '../components/signage/geofence/GeofencesPage';
import GeofencePage from '../components/signage/geofence/GeofencePage';
import StaffersPage from 'components/signage/staffer/StaffersPage';
import StafferPage from 'components/signage/staffer/StafferPage';
import { UserAndManagerEnabled } from '../authorization';

const SignageRoutes = () => {
    const VehiclesPageForUserAndManager = UserAndManagerEnabled(VehiclesPage);
    const VehiclePageForUserAndManager = UserAndManagerEnabled(VehiclePage);
    const ConstructionMachinesPageForUserAndManager = UserAndManagerEnabled(ConstructionMachinesPage);
    const ConstructionMachinePageForUserAndManager = UserAndManagerEnabled(ConstructionMachinePage);
    const SmartphonesPageForUserAndManager = UserAndManagerEnabled(SmartphonesPage);
    const SmartphonePageForUserAndManager = UserAndManagerEnabled(SmartphonePage);
    const WarehousesPageForUserAndManager = UserAndManagerEnabled(WarehousesPage);
    const WarehousePageForUserAndManager = UserAndManagerEnabled(WarehousePage);
    const ToolsPageForUserAndManager = UserAndManagerEnabled(ToolsPage);
    const ToolPageForUserAndManager = UserAndManagerEnabled(ToolPage);
    const TrailersPageForUserAndManager = UserAndManagerEnabled(TrailersPage);
    const TrailerPageForUserAndManager = UserAndManagerEnabled(TrailerPage);
    const GeofencesPageForUserAndManager = UserAndManagerEnabled(GeofencesPage);
    const GeofencePageForUserAndManager = UserAndManagerEnabled(GeofencePage);
    const StaffersPageForUserAndManager = UserAndManagerEnabled(StaffersPage);
    const StafferPageForUserAndManager = UserAndManagerEnabled(StafferPage);

    return (
        <Routes>
            <Route exact path="vehicles" element={<VehiclesPageForUserAndManager/>}/>
            <Route exact path="vehicle">
                <Route exact path="" element={<VehiclePageForUserAndManager/>}/>
                <Route exact path=":id" element={<VehiclePageForUserAndManager/>}/>
            </Route>
            <Route exact path="constructionmachines" element={<ConstructionMachinesPageForUserAndManager/>}/>
            <Route exact path="constructionmachine">
                <Route exact path="" element={<ConstructionMachinePageForUserAndManager/>}/>
                <Route exact path=":id" element={<ConstructionMachinePageForUserAndManager/>}/>
            </Route>
            <Route exact path="smartphones" element={<SmartphonesPageForUserAndManager/>}/>
            <Route exact path="smartphone">
                <Route exact path="" element={<SmartphonePageForUserAndManager/>}/>
                <Route exact path=":id" element={<SmartphonePageForUserAndManager/>}/>
            </Route>
            <Route exact path="warehouses" element={<WarehousesPageForUserAndManager/>}/>
            <Route exact path="warehouse">
                <Route exact path="" element={<WarehousePageForUserAndManager/>}/>
                <Route exact path=":id" element={<WarehousePageForUserAndManager/>}/>
            </Route>
            <Route exact path="tools" element={<ToolsPageForUserAndManager/>}/>
            <Route exact path="tool">
                <Route exact path="" element={<ToolPageForUserAndManager/>}/>
                <Route exact path=":id" element={<ToolPageForUserAndManager/>}/>
            </Route>
            <Route exact path="trailers" element={<TrailersPageForUserAndManager/>}/>
            <Route exact path="trailer">
                <Route exact path="" element={<TrailerPageForUserAndManager/>}/>
                <Route exact path=":id" element={<TrailerPageForUserAndManager/>}/>
            </Route>
            <Route exact path="geofences" element={<GeofencesPageForUserAndManager/>}/>
            <Route exact path="geofence">
                <Route exact path="" element={<GeofencePageForUserAndManager/>}/>
                <Route exact path=":id" element={<GeofencePageForUserAndManager/>}/>
            </Route>
            <Route exact path="staffers" element={<StaffersPageForUserAndManager/>}/>
            <Route exact path="staffer">
            <Route exact path="" element={<StafferPageForUserAndManager/>}/>
                <Route exact path=":id" element={<StafferPageForUserAndManager/>}/>
            </Route>
            
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    )
}

export default SignageRoutes;