import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useParams, Link } from "react-router-dom";
import { makeStyles } from 'tss-react/mui';
import { Button, TextField, Typography, CircularProgress } from '@mui/material';
import * as yup from 'yup';

import { magellanV0NotIntercept401 } from 'api/magellan';
import { useGlobalStyles } from 'context/StylesContext';
import useTranslation from 'hooks/useTranslation';

const useStyles = makeStyles()(theme => ({
    title: {
        color: theme.palette.secondary.main,
        fontSize: '1.4rem',
    },
    formControl: {
        justifyContent: 'flex-end',
        '& > *': {
            flexBasis: 'auto',
        }
    },
    loadingContainer: {
        height: '20vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));

const ResetPassword = () => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const { classes: globalClasses, cx } = useGlobalStyles();
    const {token} = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [passwordUpdated, setPasswordUpdated] = useState(false);

    const handleErrorResponseStatus = (status) => {
        switch(status) {
            case 404:
                setError(t('errorInvalidLink'));
                break;
            case 410:
                setError(t('errorExpiredLink'));
                break;
            default: 
            setError(t('errorOccured'));
        }
    }

    const onSubmit = ({newPassword}) => {
        setLoading(true);
        magellanV0NotIntercept401.put('/api/password/update', new URLSearchParams(`token=${token}&password=${newPassword}`))
            .then(() => {
                setPasswordUpdated(true);
            })
            .catch(e => {
                handleErrorResponseStatus(e.response.status);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const formik = useFormik({
        initialValues: {
            newPassword: '',
            confirmNewPassword: '',
        },
        validationSchema: yup.object().shape({
            newPassword: yup.string()
                .trim()
                .required(t('validationRequired'))
                .min(8, `\${min} ${t('validationLengthMin')}`),
            confirmNewPassword: yup.string()
                .trim()
                .required(t('validationRequired'))
                .oneOf([yup.ref('newPassword')], t('validationMustBeSameAsNewPassword')),
        }), 
        onSubmit,
    });

    useEffect(() => {
        magellanV0NotIntercept401.post('/api/password/checkTokenValidity', new URLSearchParams(`token=${token}`))
            .then(() => {})
            .catch(e=> {
                handleErrorResponseStatus(e.response.status);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const renderResetPassword = () => {
        return (
            <>
                <Typography className={classes.title}>{t('loginReset')}</Typography>
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        margin='normal'
                        required
                        fullWidth
                        label={t('loginNewPassword')}
                        name='newPassword'
                        value={formik.values.newPassword}
                        autoFocus
                        type='password'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                        helperText={formik.touched.newPassword && formik.errors.newPassword}
                    />
                    <TextField
                        margin='normal'
                        required
                        fullWidth
                        label={t('loginNewPasswordConfirmation')}
                        name='confirmNewPassword'
                        value={formik.values.confirmNewPassword}
                        type='password'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
                        helperText={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}
                    />
    
                    <div className={cx(globalClasses.loginFormControl, classes.formControl)}>
                        <Button 
                            type="submit" 
                            variant="contained" 
                            color="secondary" 
                            disabled={formik.errors.newPassword || formik.errors.confirmNewPassword}
                        >
                            {t('loginResetPassword')}
                        </Button>
                    </div>
                </form>
            </>
        );
    };

    const render = () => {
        if(loading) {
            return (
                <div className={classes.loadingContainer}>
                    <CircularProgress color="secondary"/>
                </div>
            );
        } else {
            if(error) {
                return (
                    <>
                        <Typography>{error}</Typography>
                        <Link className={globalClasses.link} to="/auth">{t('loginBackToLoginPage')}</Link>
                    </>
                );
            } else {
                if(passwordUpdated) {
                    return (
                        <>
                            <Typography>{t('loginPasswordUpdatedSuccesfully')}</Typography>
                            <Link className={globalClasses.link} to="/auth">{t('loginBackToLoginPage')}</Link>
                        </>
                    );
                }
                return renderResetPassword();
            }
        }
    };

    return render();
};

export default ResetPassword;
