import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';

import {magellanV0} from 'api/magellan';
import useTranslation from 'hooks/useTranslation';
import useFormat from 'hooks/useFormat';
import { DeviceType } from 'utils/constant';
import BasicValue from './BasicValue';
import { isToday, isYesterday } from 'date-fns';
import None from './None';

const useStyles = makeStyles()(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
    },
    summaryContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
    },
    lastJourneyContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    dayContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
}));

const MotionsSummaryText = ({ currentPosition, device, motions }) => {
    const { t } = useTranslation();
    const { formatTripDistance, formatDuration, formatTime, formatDayName } = useFormat();
    const [positionsOfToday, setPositionsOfToday] = useState([]);
    const [startHourCurrentMotion, setStartHourCurrentMotion] = useState(null);
    const [distanceCurrentMotion, setDistanceCurrentMotion] = useState(null);
    const [durationCurrentMotion, setDurationCurrentMotion] = useState(null);
    const { classes } = useStyles();

    useEffect(() => {
        const inMotion = currentPosition?.attributes?.motion;
        if(inMotion && motions.stops.length === 0 && positionsOfToday.length === 0){
            let from = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
            let to = new Date(new Date().setHours(24, 0, 0, 0)).toISOString();
            magellanV0.get(`/api/reports/route?deviceId=${device.id}&from=${from}&to=${to}`)
                .then(response => {
                    setPositionsOfToday(response.data);
                })
                .catch(error => {

                });
        } else {
            updateInfosCurrentMotion();
        }
    }, [motions, currentPosition?.attributes?.motion]);

    useEffect(() => {
        updateInfosCurrentMotion();
    }, [positionsOfToday, currentPosition?.attributes?.totalDistance, currentPosition?.attributes?.odometer]);

    const updateInfosCurrentMotion = () => {
        let lastStop = motions.stops.at(-1);
        if(currentPosition?.attributes?.motion) {
            let firstPosition = positionsOfToday.at(0);
            if(lastStop || firstPosition) {
                let startHour;
                let firstOdometer;
                let lastOdometer = (device.category === DeviceType.SMARTPHONE) ? currentPosition.attributes.totalDistance : currentPosition.attributes.odometer;   
                if(lastStop) {
                    startHour = new Date(lastStop.endTime).valueOf() + 60 * 1000;
                    firstOdometer = lastStop.endOdometer;
                } else {
                    startHour = new Date(firstPosition.fixTime);
                    firstOdometer = (device.category === DeviceType.SMARTPHONE) ? firstPosition.attributes.totalDistance : firstPosition.attributes.odometer;
                } 
                setStartHourCurrentMotion(startHour);
                setDurationCurrentMotion((new Date(currentPosition?.fixTime)).valueOf() - startHour);
                setDistanceCurrentMotion(lastOdometer - firstOdometer);
            }
        } else {
            if(lastStop) {
                setStartHourCurrentMotion(new Date(lastStop.startTime).valueOf());
                setDistanceCurrentMotion(null);
            }
        }
    }

    const renderCurrentMotion = () => {
        if(!startHourCurrentMotion || (!currentPosition?.attributes?.motion && !isToday(startHourCurrentMotion))) {
            return <None text={t('noJourneyInProgress')}/>;
        }
        const startHourFormatted = isYesterday(startHourCurrentMotion) ? formatDayName(startHourCurrentMotion) : `${formatTime(startHourCurrentMotion, 'hour')}`;
        if(distanceCurrentMotion && durationCurrentMotion) {
            const currentMotionDistanceFormatted = formatTripDistance(distanceCurrentMotion);
            const currentMotionDurationFormatted = formatDuration(durationCurrentMotion);
            
            return (
                <>
                    <span>{`${t('onTheRoadSince')} ${startHourFormatted}`}</span>
                    <span>{`${currentMotionDistanceFormatted} ${t('travelledIn')} ${currentMotionDurationFormatted}`}</span>
                </>
            );
        } 
        return <span>{`${t('atAStandstillSince')} ${startHourFormatted}`}</span>;
    };

    const renderDaySummary = () => {
        let nbJourneys = motions.trips.length;
        let totalDistance = (motions.trips.map(trip => trip.distance)).reduce((a, b) => a + b, 0);
        let totalDuration = (motions.trips.map(trip => trip.duration)).reduce((a, b) => a + b, 0);
        if(startHourCurrentMotion && durationCurrentMotion && distanceCurrentMotion) {
            nbJourneys++;
            totalDistance += distanceCurrentMotion;
            totalDuration += durationCurrentMotion;          
        }
        if(nbJourneys > 0) {
            return (
                <>
                    <span>{`${nbJourneys} ${nbJourneys > 1 ? t('journeysMade') : t('journeyMade')}`}</span>
                    <span>{`${formatTripDistance(totalDistance)} ${t('travelledIn')} ${formatDuration(totalDuration)}`}</span>
                </>
            );
        }
        return <None text={t('noJourneyToday')}/>;
    };

    return (
        <div className={classes.root}>
           <div className={classes.summaryContainer}>
                <BasicValue label={t('assetLastMotion')} value={
                    <div className={classes.lastJourneyContainer}>
                        {renderCurrentMotion()}
                    </div>
                } />
                <BasicValue alignment='right' label={t('assetDayMotion')} value={
                    <div className={classes.dayContainer}>
                        {renderDaySummary()}
                    </div>
                }/>
            </div>
        </div>
    );
}

export default MotionsSummaryText;