
import React, { useEffect, useRef, useState } from 'react';
import { 
    Button, 
    Card, 
    CardHeader, 
    CircularProgress, 
    useTheme, } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { capitalize } from 'lodash';
import useTranslation from 'hooks/useTranslation';
import useAlertMessage from 'hooks/useAlertMessage';
import useConfirmationDialog from 'hooks/useConfirmationDialog';
import ConfirmationDialog from 'components/common/ConfirmationDialog';

const useStyles = makeStyles()(theme => ({
    icon: {
        height: 20,
        width: 20,
    },
    iconSafe: {
        color: theme.palette.safe.main,
    },
    iconUnsafe: {
        color: theme.palette.unsafe.main,
    },
    commandCard: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 8
    },
    buttonContainer: {
        display: 'flex',
        gap: 5
    },
    buttonReversed: {
        flexDirection: 'row-reverse'
    },
}));

const BinaryChoiceCommand = ({ sendCommand, currentStatus, commandON, commandOFF, reverseButton = false, title, statusONMessage, statusOFFMessage, 
    statusONIcon: StatusONIcon, statusOFFIcon: StatusOFFIcon, statusONLoadingMessage, statusOFFLoadingMessage, sendCommandONWarningMessage, sendCommandOFFWarningMessage, 
    buttonONLabel, buttonOFFLabel }) => {
    const { t } = useTranslation();
    const { classes, cx } = useStyles();
    const theme = useTheme();
    const { setAlertMessage, sendCommandDelayInfoAlertMessage, sendCommandErrorAlertMessage } = useAlertMessage();
    const [loading, setLoading] = useState(false);
    const { open, setOpen, title: dialogTitle, message, callback, openConfirmationDialog } = useConfirmationDialog();
    let timeoutId = useRef();

    useEffect(() => {
        if(loading) {
            timeoutId.current = setTimeout(() => {
                setLoading(false);
            }, 60000);
            return () => clearTimeout(timeoutId.current);
        }
    }, [loading]);

    useEffect(() => {
        setLoading(false);
        clearTimeout(timeoutId.current);
    }, [currentStatus]);

    const sendCommandON = async () => {
        setOpen(false);
        try {
            setLoading(true);
            await sendCommand(commandON.id);
            setAlertMessage(sendCommandDelayInfoAlertMessage);
        } catch(error) {
            setAlertMessage(sendCommandErrorAlertMessage);
           setLoading(false);
        } 
    };

    const sendCommandOFF = async () => {
        setOpen(false);
        try {
            setLoading(true);
            await sendCommand(commandOFF.id);
            setAlertMessage(sendCommandDelayInfoAlertMessage);
            
        } catch(error) {
            setAlertMessage(sendCommandErrorAlertMessage);
            setLoading(false);
        }
    }

    const renderStatus = () => {
        if(loading) {
            if(currentStatus) {
                return (
                    <>
                        <span>{statusOFFLoadingMessage}</span>
                        <CircularProgress style={{height: 20, width: 20}} color='secondary'/>
                    </>
                );
            } else {
                return (
                    <>
                        <span>{statusONLoadingMessage}</span>
                        <CircularProgress style={{height: 20, width: 20}} color='secondary'/>
                    </>
                );
            }
        } else if(currentStatus){
            return (
                <>
                    <span>{statusONMessage}</span>
                    <StatusONIcon className={cx(classes.icon, classes.iconSafe)} fill={theme.palette.safe.main} stroke={theme.palette.safe.main}/>
                </>
            );
        } else {
            return (
                <>
                    <span>{statusOFFMessage}</span>
                    <StatusOFFIcon className={cx(classes.icon, classes.iconUnsafe)} fill={theme.palette.unsafe.main} stroke={theme.palette.unsafe.main}/>
                </>
            );
        }
    };

    return (
        <>
            <CardHeader title={capitalize(title)}/>
            <Card className={classes.commandCard}>
                <div className={classes.statusContainer}>
                    { renderStatus() }    
                </div>
                <div className={reverseButton ?  cx(classes.buttonContainer, classes.buttonReversed) : classes.buttonContainer}>
                    <Button 
                        color='secondary'
                        variant='contained'
                        disabled={loading}
                        onClick={() => openConfirmationDialog(t('warning'), sendCommandOFFWarningMessage, sendCommandOFF)}
                    >
                        {buttonOFFLabel}
                    </Button>
                    <Button 
                        color='secondary'
                        variant='contained'
                        disabled={loading}
                        onClick={() => openConfirmationDialog(t('warning'), sendCommandONWarningMessage, sendCommandON)}
                    >
                        {buttonONLabel}
                    </Button>
                </div>
            </Card>
            <ConfirmationDialog
                open={open}
                setOpen={setOpen}
                title={dialogTitle}
                message={message}
                callback={callback}
            />
        </>
    );
};

export default BinaryChoiceCommand;