import { useLayoutEffect, useContext } from 'react';
import { createRoot } from 'react-dom/client';
import { useSelector } from 'react-redux';
import mapboxgl from 'mapbox-gl';

import { useGeofencePointerVisibility } from 'context/GeofencePointerContext';
import { MapContext, useMapIcon } from 'context/MapContext';
import { motionLocationIcon } from 'utils/constant';
import { geofenceToFeature } from './mapUtil';
import { useTheme } from '@emotion/react';

const GeofenceMap = () => {
    const theme = useTheme();
    const sourceId = 'geofences';
    const geofencesFillLayer = 'geofences-fill';
    const geofencesLineLayer = 'geofences-line';
    const geofencesTitleLayer = 'geofences-title';
    const geofencesPointerLayer = 'geofences-pointer';
    const { map, mapReady } = useContext(MapContext);
    const geofences = useSelector(state => Object.values(state.geofences.items));
    const [geofencePointerVisible, setGeofencePointerVisible] = useGeofencePointerVisibility();
    let popup;
    const { getMapIcon } = useMapIcon();

    const onEnterGeofence = event => {
        const feature = event.features[0];
        let coordinates = feature.geometry.coordinates.slice();
        while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        const placeholder = document.createElement('div');
        const placeholderRoot = createRoot(placeholder);
        placeholderRoot.render(feature.properties.name);

        popup = new mapboxgl.Popup({
            offset: 25,
            anchor: 'bottom',
            closeButton: false,
        })
            .setDOMContent(placeholder)
            .setLngLat(coordinates)
            .addTo(map);
            popup.getElement().childNodes[0].style.borderTopColor = theme.palette.primary.main;
            popup.getElement().childNodes[1].style.backgroundColor = theme.palette.primary.main;
    };

    const onLeaveGeofence = (event) => {
        if(popup){
            popup.remove();
        }
    }

    useLayoutEffect(() => {
        if(mapReady) {
            map.addSource(sourceId, {
                'type': 'geojson',
                'data': {
                    type: 'FeatureCollection',
                    features: geofences
                }
            });
            
            map.addLayer({
                'source': sourceId,
                'id': geofencesFillLayer,
                'type': 'fill',
                'minzoom': 15,
                'filter': [
                    'all',
                    ['==', '$type', 'Polygon'],
                ],
                'paint': {
                    'fill-color':'#3bb2d0',
                    'fill-outline-color':'#3bb2d0',
                    'fill-opacity':0.1,
                },
                
            });

            map.addLayer({
                'source': sourceId,
                'id': geofencesLineLayer,
                'type': 'line',
                'minzoom': 15,
                'paint': {
                    'line-color': '#3bb2d0',
                    'line-width': 2,
                },
            });
            map.addLayer({
                'source': sourceId,
                'id': geofencesTitleLayer,
                'type': 'symbol',
                'layout': {
                    'text-field': '{name}',
                    'text-font': ['Roboto Regular'],
                    'text-size': 12,
                },
                'paint': {
                    'text-halo-color': 'white',
                    'text-halo-width': 1,
                },
            });
        
            return () => {  
                if(map.getLayer(geofencesFillLayer)){
                    map.removeLayer(geofencesFillLayer);
                }
                if(map.getLayer(geofencesLineLayer)){
                    map.removeLayer(geofencesLineLayer);
                }
                if(map.getLayer(geofencesTitleLayer)){
                    map.removeLayer(geofencesTitleLayer);
                }
                if(map.getLayer(geofencesPointerLayer)){
                    map.off('mouseenter', geofencesPointerLayer, onEnterGeofence);
                    map.off('mouseleave', geofencesPointerLayer, onLeaveGeofence);
                    map.removeLayer(geofencesPointerLayer);
                }
                if(map.getSource(sourceId)){
                    map.removeSource(sourceId);
                }
            };
        }
    },[mapReady]); 

    useLayoutEffect(() => {
        if(mapReady && geofencePointerVisible) {
            map.addLayer({
                'source': sourceId,
                'id': geofencesPointerLayer,
                'type': 'symbol',
                'maxzoom': 15,
                'filter': [
                    'all',
                    ['==', '$type', 'Point'],
                ],
                'layout': {
                    'icon-image': getMapIcon(motionLocationIcon.POINTER),
                    'icon-anchor': 'bottom',
                    'icon-allow-overlap': true,
                }
            }); 
            
            map.on('mouseenter', geofencesPointerLayer, onEnterGeofence);
            map.on('mouseleave', geofencesPointerLayer, onLeaveGeofence);
            
            return () => {
                if(map.getLayer(geofencesPointerLayer)){
                    map.off('mouseenter', geofencesPointerLayer, onEnterGeofence);
                    map.off('mouseleave', geofencesPointerLayer, onLeaveGeofence);
                    map.removeLayer(geofencesPointerLayer);
                }
            }
        }        
    }, [geofencePointerVisible, mapReady]);
    
    useLayoutEffect(() => {
        if(mapReady && map?.getSource(sourceId)){
            let features = [];
            geofences.forEach(g => {
                let [area, pointer] = geofenceToFeature(g);
                features.push(area, pointer);
            });
            map.getSource(sourceId).setData({
                type: 'FeatureCollection',
                features,
            });     
        }   
    }, [geofences, mapReady]);


    return null;
}

export default GeofenceMap;
