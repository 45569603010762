import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Button, IconButton, Icon, MenuItem, Toolbar, List, ListItemButton, ListItemIcon, ListItemText, Collapse, Divider, } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import MenuIcon from '@mui/icons-material/Menu';

import useTranslation from 'hooks/useTranslation';
import DropdownMenu from './DropdownMenu';
import SupportPopup from './SupportPopup';
import { useSelector } from 'react-redux';
import { useColorMode } from 'context/ColorModeContext';
import { ColorMode } from 'utils/constant';
import { ArrowDropDown, ArrowDropUp, Check, ExpandLess, ExpandMore } from '@mui/icons-material';
import useMenuAnchor from 'hooks/useMenuAnchor';
import { useGlobalStyles } from 'context/StylesContext';

const useStyles = makeStyles()(theme => ({
    appBar: {
        position: 'relative',
        height: theme.fixedSize.toolbar.height,
        background: `linear-gradient(to right, ${theme.palette.tertiary.main}, ${theme.palette.quaternary.main})`,
        zIndex: theme.zIndex.appBar,
    },
    menuButton: {
        marginRight: 18,
        backgroundColor: 'transparent',
        "&:hover": {
            backgroundColor: 'transparent',
        }
    },
    menuButtonIcon: {
        color: theme.colorMode === ColorMode.BLUE ? theme.palette.primary.main : theme.palette.secondary.main,
    },
    iconRoot: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%'
    },
    imageIcon: {
        maxHeight: '50%',
    },
    dropdownMenu: {
        color: 'white',
        marginRight: theme.spacing(5),
        cursor: 'pointer',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
    },
    arrow: {
        color: 'white',
    },
    divider: {
        background: theme.palette.selected.main,
    }
}));


const ApplicationBar = ({toggleDrawer, handleLogout, adminEnabled, user}) => {
    const { t, handleChangeLanguage } = useTranslation();
    const { classes } = useStyles();
    const { classes: globalClasses } = useGlobalStyles();
    const navigate = useNavigate();
    const { menuAnchor, setMenuAnchor, } = useMenuAnchor();
    const [supportModalOpen, setSupportModalOpen] = useState(false);
    const [colorModesDisplay, setColorModesDisplay] = useState(false);
    const [languagesDisplay, setLanguagesDisplay] = useState(false);
    const { availableLanguages, selectedLanguageId } = useSelector(state => 
        ({ availableLanguages: state.languages.items, selectedLanguageId: state.languages.selectedId }));
    const { colorMode: selectedColorMode, setColorMode, MagellanLogo } = useColorMode();

    const handleMenuAnchor = (newAnchor) => {
        if(newAnchor === null) {
            setLanguagesDisplay(false);
        } 
        setMenuAnchor(newAnchor);
    };

    useEffect(() => {}, [MagellanLogo]);
    
    
    return (
        <>
            <AppBar  className={classes.appBar}>
                <Toolbar style={{ paddingLeft: 4 }}>
                    <IconButton
                        className={classes.menuButton}
                        onClick={toggleDrawer}
                        size="large">
                        <MenuIcon className={classes.menuButtonIcon}/>
                    </IconButton>
                    <Icon className={classes.iconRoot}>
                        <img alt={t('magellanLogo')} className ={classes.imageIcon} src={MagellanLogo}/>
                    </Icon>
                    {adminEnabled && <Button href="https://demo.traccar.org/" target="_blank" rel="noopener noreferrer">{t("oldInterface")}</Button>}
                    <div className={classes.dropdownMenu}>
                        <DropdownMenu 
                            component={
                                <>
                                    {user && user.name}
                                    {menuAnchor !== null ? <ArrowDropUp className={classes.arrow}/> : <ArrowDropDown className={classes.arrow}/>}
                                </>
                            } 
                            anchor={menuAnchor} 
                            setAnchor={handleMenuAnchor}
                        >
                            <MenuItem onClick={() =>navigate(`settings/account/${user && user.id}`)}><span>{t('settingsUser')}</span></MenuItem>
                            <List disablePadding>
                                <ListItemButton
                                    dense
                                    onClick={() => setColorModesDisplay(old => !old)}
                                >
                                    <ListItemText primary={t('color')} />
                                    {colorModesDisplay ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={colorModesDisplay} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        { Object.values(ColorMode)?.map(colorMode => (
                                            <ListItemButton
                                                className={globalClasses.listCheckableItemItem}
                                                key={colorMode}
                                                dense
                                                onClick={() => {setColorMode(colorMode)}}
                                            >
                                                <ListItemIcon className={globalClasses.listCheckableItemCheck}>
                                                    { colorMode === selectedColorMode 
                                                        ? <Check/>
                                                        : <Check className={globalClasses.deselectedIcon}/>
                                                    }
                                                </ListItemIcon>
                                                <span className={globalClasses.listCheckableItemText}>{t(colorMode)}</span>
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Collapse>
                            </List>
                            <List disablePadding>
                                <ListItemButton
                                    dense
                                    onClick={() => setLanguagesDisplay(!languagesDisplay)}
                                >
                                    <ListItemText primary={t('language')} />
                                    {languagesDisplay ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={languagesDisplay} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        { Object.values(availableLanguages)?.map(lang => (
                                            <ListItemButton
                                                className={globalClasses.listCheckableItemItem}
                                                key={lang?.id}
                                                dense
                                                onClick={() => {handleChangeLanguage(lang.id)}}
                                            >
                                                <ListItemIcon className={globalClasses.listCheckableItemCheck}>
                                                    { lang.id === selectedLanguageId 
                                                        ? <Check/>
                                                        : <Check className={globalClasses.deselectedIcon}/>
                                                    }
                                                </ListItemIcon>
                                                <span className={globalClasses.listCheckableItemText}>{t(lang.name.toLowerCase())}</span>
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Collapse>
                            </List>
                            <MenuItem onClick={() => setSupportModalOpen(true)}>{t('supportTitle')}</MenuItem>
                            <MenuItem onClick={handleLogout}>{t('loginLogout')}</MenuItem>
                        </DropdownMenu>
                    </div>
                </Toolbar>
                <Divider className={classes.divider}/>
            </AppBar>
            <SupportPopup open={supportModalOpen} onClose={() => setSupportModalOpen(false)} />
        </>
        
    );
};

export default ApplicationBar;