import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useParams } from 'react-router-dom';

import { MANAGER, USER } from 'authorization/role';
import useTranslation from 'hooks/useTranslation';
import CompanyNotFound from 'resources/company_not_found.png';
import UpdateCompany from './UpdateCompany';
import CreateCompany from './CreateCompany';

const filterDataForServer = (company) => {
    const dateToTime = (dateString) => {
        if(dateString){ 
            const date = new Date(dateString);
            return date.toTimeString().split(' ')[0];
        }
        return null;
    }

    const companyApi = {
        name: company.name,
        legalStatus: company.legalStatus || null,
        phoneNumber: company.phoneNumber || null,
        mailAddress: company.mailAddress || null,
        logo: company.logo || null,
        isPrivateOutsideWorkingHours: company.isPrivateOutsideWorkingHours ?? false,
        address:{
            street: company.address.street,
            houseNumber: company.address.houseNumber,
            localityId: company.address.locality.id,
            latitude: 0,
            longitude: 0,
        },
        mondayStartHour : dateToTime(company.calendar.mondayStartHour),
        mondayEndHour: dateToTime(company.calendar.mondayEndHour),
        tuesdayStartHour: dateToTime(company.calendar.tuesdayStartHour),
        tuesdayEndHour: dateToTime(company.calendar.tuesdayEndHour),
        wednesdayStartHour: dateToTime(company.calendar.wednesdayStartHour),
        wednesdayEndHour: dateToTime(company.calendar.wednesdayEndHour),
        thursdayStartHour: dateToTime(company.calendar.thursdayStartHour),
        thursdayEndHour: dateToTime(company.calendar.thursdayEndHour),
        fridayStartHour: dateToTime(company.calendar.fridayStartHour),
        fridayEndHour: dateToTime(company.calendar.fridayEndHour),
        saturdayStartHour : dateToTime(company.calendar.saturdayStartHour),
        saturdayEndHour: dateToTime(company.calendar.saturdayEndHour),
        sundayStartHour : dateToTime(company.calendar.sundayStartHour),
        sundayEndHour: dateToTime(company.calendar.sundayEndHour),
    }
    return companyApi;
};

const useStyles = makeStyles()(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    companyNotFoundLogo: {
        height: '40vh',
    }
}));
    
const CompanyPage = ({ role }) => {
    const { id } = useParams();
    const { classes } =  useStyles();
    const { t } = useTranslation();

    if(role === MANAGER){
        return (id) 
            ? <UpdateCompany filterDataForServer={filterDataForServer} role={MANAGER}/> 
            : <CreateCompany filterDataForServer={filterDataForServer}/>;
    }else {
        return (id) 
            ? <UpdateCompany role={USER}/> 
            : (
                <div className={classes.root}>
                    <img alt={t('companyNotFoundLogo')} className={classes.companyNotFoundLogo} src={CompanyNotFound}/>
                    <h1>{t('companyNotFoundMessage')}</h1>
                </div>
            );
    }
};


export default CompanyPage;