import React from 'react';
import { makeStyles } from 'tss-react/mui';

import useFormat from 'hooks/useFormat';
import TruncableText from './TruncableText';

const useStyles = makeStyles()(theme => ({
    addressContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

const Address = ({address, placement, oneLiner = false, maxLength }) => {
    const { classes } = useStyles();
    const { formatAddress, formatAddressOneLine } = useFormat();
    const {streetAndNumber, postalCodeAndLocality} = formatAddress(address);

    const translatePlacement = () => {
        switch(placement) {
            case "start": return 'flex-start';
            case "center": return 'center';
            case "end": return 'flex-end';  
            default: return 'flex-start';
        }
    }

    if(address){
        if(typeof address === 'object') {  
            return (oneLiner 
                ? <TruncableText texts={[formatAddressOneLine(address)]} maxLength={maxLength} className={classes.addressContainer} style={placement ? {alignItems: translatePlacement()} : {}} />
                : <TruncableText texts={[streetAndNumber, postalCodeAndLocality]} maxLength={maxLength} className={classes.addressContainer} style={placement ? {alignItems: translatePlacement()} : {}} />
            );
        }
    }
    return null;
}

export default Address;