import { DeviceType } from 'utils/constant';

export const categoryOrder = {
    [DeviceType.VEHICLE]: 1,
    [DeviceType.CONSTRUCTION_MACHINE]: 2,
    [DeviceType.SMARTPHONE]: 3,
    [DeviceType.TRAILER]: 4,
};

export const comparatorByCategoryThenByName = (categoryA, nameA, categoryB, nameB) => {
    let comparatorResult = 0;
    
    if(categoryOrder[categoryA] - categoryOrder[categoryB] !== 0){
        comparatorResult = categoryOrder[categoryA] - categoryOrder[categoryB];
    } else if(nameA < nameB) {
        comparatorResult = -1;
    } else {
        comparatorResult = 1;
    }
    return comparatorResult;
};

export const sortByThenBy = (sortByItemA, sortThenByItemA, sortByItemB, sortThenByItemB) => {
    let comparatorResult = 0;

    if(sortByItemA !== sortByItemB) {
        if(sortByItemA < sortByItemB) {
            comparatorResult = -1;
        }else {
            comparatorResult = 1;
        }
    } else if(sortThenByItemA < sortThenByItemB) {
        comparatorResult = -1;
    } else {
        comparatorResult = 1;
    }
    return comparatorResult;
}

export const groupByCategory = (items) => {     
    const groupedItems = items.reduce((previous, current) => {
        (previous[current['category']] = previous[current['category']] || []).push(current);
        return previous;
    }, {});

    return groupedItems;
}