import React from 'react';
import { TextField, Autocomplete, TablePagination, } from '@mui/material';

import useRouteReport from './useRouteReport';
import PeriodSelector from 'components/common/PeriodSelector';
import ReportLayoutPage from '../ReportLayoutPage';
import ReportShowButton from '../ReportShowButton';
import FileExportReportButtons from '../FileExportReportButtons';
import { sortByThenBy } from 'utils/sort';
import ItemTable from 'components/home/ItemTable';
import ItemRow from 'components/common/ItemRow';


const RouteReportPage = () => {
    const {  
        t, 
        formatDeviceCategory,
        globalClasses,
        loading, 
        deviceId, 
        setDeviceId, 
        period, 
        setPeriod, 
        from, 
        setFrom, 
        to, 
        setTo, 
        devices, 
        getFiltersKey,
        handleSubmit, 
        tasks,
        displayDisabled,
        pdfDisabled,
        excelDisabled,
        emailDisabled,
        setPageSize,
        gotoPage,
        state: {
            pageIndex,
            pageSize,
        },
        rows, 
        ...rest
    } = useRouteReport();

    return (
        <ReportLayoutPage 
            loading={loading}
            filter={
                <div className={globalClasses.rootReportEngineContainer}>
                    <div className={globalClasses.reportFiltersContainer}>
                        <Autocomplete 
                            options={devices.sort((deviceA, deviceB) => sortByThenBy(deviceA.category, deviceA.name.toLowerCase(), deviceB.category, deviceB.name.toLowerCase()))}
                            groupBy={d => formatDeviceCategory(d?.category)}
                            getOptionLabel={d => d?.name || ''}
                            value={devices.find(d => d.id === deviceId)}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            onChange={(_, value) => setDeviceId(value?.id || null)}
                            renderInput={(params) => <TextField {...params} label={t('reportDevice')} variant='outlined'/>}
                        />
                        <PeriodSelector 
                            period={period} 
                            setPeriod={setPeriod} 
                            from={from}
                            setFrom={setFrom}
                            to={to}
                            setTo={setTo}
                        />
                        <ReportShowButton
                            disabled={displayDisabled}
                            handleSubmit={handleSubmit}
                        />
                    </div>
                    <div className={globalClasses.reportButtonsContainer}>
                        <FileExportReportButtons
                            pdfDisabled={pdfDisabled}
                            excelDisabled={excelDisabled}
                            emailDisabled={emailDisabled}
                            tasks={tasks}
                            handleSubmit={handleSubmit}
                            getFiltersKey={getFiltersKey}
                        />
                    </div>
                </div>
            }
        >
            <ItemTable 
                {...rest}
                rows={rows}
            >
                { ({ itemRow, ...rest }) => (
                    <ItemRow 
                        key={itemRow.id} 
                        itemRow={itemRow}
                        {...rest}
                    />
                )}
            </ItemTable>
            <TablePagination
                page={pageIndex}
                rowsPerPage={pageSize}
                count={rows.length}
                onPageChange={(_, newPageIndex) => gotoPage(newPageIndex)}
                onRowsPerPageChange={(event) => setPageSize(event.target.value)}
                component="div"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('paginationOf')} ${count !== -1 ? count : `${t('paginationMoreThan')} ${to}`}`}
                labelRowsPerPage={t('paginationRowsPerPage')}
            />
        </ReportLayoutPage>
    );
};

export default RouteReportPage;
