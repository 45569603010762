import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import {magellanV0} from 'api/magellan';
import withSignageLayout from 'hoc/withSignageLayout';
import {useSignage} from 'hooks/useSignage';
import useAlertMessage from 'hooks/useAlertMessage';
import Loading from '../../common/Loading';
import SignageHeader from '../../common/SignageHeader';
import NotificationForm from './NotificationForm';
import { getInitialTimetable } from 'utils/timetable';
import { CRUDAction } from 'utils/constant';

const initialValues = {
    name: '',
    type: '',
    active: true,
    notificators: [],
    currentDevicesSelector: null,
    groups: {allItems: [], initialItems: [], finalItems: []},
    devices: {allItems: [], initialItems: [], finalItems: []},
    calendar: getInitialTimetable(),
};

const CreateNotification = ({filterDataForServer}) => {
    const {editionMode, setEditionMode, loading, setLoading, id} = useSignage({ readApi: magellanV0, createApi: magellanV0, updateApi: magellanV0 }, 'notifications', true, false);
    const { setAlertMessage, creationErrorAlertMessage, savedAlertMessage } = useAlertMessage();
    const navigate = useNavigate();

    const onSubmit = async (formValues, initialValues) => {
        setLoading(true);
        const {calendar, calendarAction, notification, groupsToLink, devicesToLink} = await filterDataForServer(_.cloneDeep(formValues), initialValues);

        try{
            if (calendarAction === CRUDAction.CREATE) {
                let calendarResponse = await magellanV0.post('/api/calendars', calendar, { headers: {'Content-Type': 'application/json'} });
                notification.calendarId = calendarResponse.data.id;
            }
            let notificationResponse = await magellanV0.post('/api/notifications', notification);
            if(formValues.currentDevicesSelector !== 'always'){
                let permission = {notificationId: notificationResponse.data.id};
                let permissionSubmission;
                Promise.all([
                    groupsToLink.map(groupId => {
                        permissionSubmission = Object.assign({groupId}, _.cloneDeep(permission));
                        return magellanV0.post('/api/permissions', permissionSubmission);
                    }),
                    devicesToLink.map(deviceId => {
                        permissionSubmission = Object.assign({deviceId}, _.cloneDeep(permission));
                        return magellanV0.post('/api/permissions', permissionSubmission);
                    }),
                ]);
            }
            setAlertMessage(savedAlertMessage);
            setEditionMode(false);
            navigate(`${notificationResponse.data.id}`, { replace: true });     
        } catch(error) {
            setAlertMessage(creationErrorAlertMessage);
            setLoading(false);
        }
    };

    return (
        <>
            {loading
                ? <Loading/>
                : <>
                    <SignageHeader editionMode={editionMode} setEditionMode={setEditionMode} editionEnabled={true}/>
                    <NotificationForm editionMode={editionMode} initialValues={initialValues} onSubmit={onSubmit} id={id}/>
                </>
            }
        </>
    );
};

export default withSignageLayout()(CreateNotification);