import { createAsyncThunk } from "@reduxjs/toolkit";

import { timesheetValidationActions } from "store";
import { fetchItems, checkAndUpdate, validateAndHideItem, validateAndUpdateItem, removeItem } from '../asyncTimesheetActions';

const SLICE_NAME = 'timesheetValidation';
const sliceUtils = { accessor: SLICE_NAME, actions: timesheetValidationActions };

export const fetchItemsAction = createAsyncThunk(`${SLICE_NAME}/fetch`, (payloadCreator, thunkAPI) => fetchItems(payloadCreator, sliceUtils, thunkAPI));
export const checkAndUpdateAction = createAsyncThunk(`${SLICE_NAME}/checkAndUpdate`, (payloadCreator, thunkAPI) => checkAndUpdate(payloadCreator, sliceUtils, thunkAPI));
export const validateAndHideItemAction = createAsyncThunk(`${SLICE_NAME}/validateAndHide`, (payloadCreator, thunkAPI) => validateAndHideItem(payloadCreator, sliceUtils, thunkAPI)); 
export const validateAndUpdateItemAction = createAsyncThunk(`${SLICE_NAME}/validateAndUpdate`, (payloadCreator, thunkAPI) => validateAndUpdateItem(payloadCreator, sliceUtils, thunkAPI)); 
export const removeItemAction = createAsyncThunk(`${SLICE_NAME}/remove`, (payloadCreator, thunkAPI) => removeItem(payloadCreator, sliceUtils, thunkAPI));