import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useGlobalStyles } from 'context/StylesContext';
import useFormat from 'hooks/useFormat';
import useTranslation from 'hooks/useTranslation';
import { checkingStore } from './checkingStore';
import { fetchItems } from './asyncCheckingActions';
import ItemTable from 'components/home/ItemTable';
import useAlertMessage from 'hooks/useAlertMessage';
import DetailPresenceRow from './DetailPresenceRow';
import ItemRow from 'components/common/ItemRow';
import { getColumns, getData } from '.';
import { useExpanded, useSortBy, useTable } from 'react-table';

const PresencePage = () => {
    const { t } = useTranslation();
    const { formatAddressOneLine, formatTime } = useFormat();
    const { classes: globalClasses, cx, theme } = useGlobalStyles();
    const [loading, setLoading] = useState(false);
    const [checkingsByStaffer, setCheckingsByStaffer] = useState(null); 
    const [lastCheckingByStaffer, setLastCheckingByStaffer] = useState(null);
    const staffers = useSelector(state => state.staffers.items);
    const devices = useSelector(state => state.devices.items);
    const geofences = useSelector(state => state.geofences.items);
    const { setAlertMessage, dataLoadingErrorAlertMessage } = useAlertMessage();

    useEffect(() => {
        const unsubscribeStore = checkingStore.subscribe(() => {
            setLoading(checkingStore.getState().loading);
            setCheckingsByStaffer(checkingStore.getState().items);
            setLastCheckingByStaffer(checkingStore.getState().lastItems)
        });

        return () => unsubscribeStore();
    }, []);

    useEffect(() => {
        checkingStore.dispatch(fetchItems({ setAlertMessage, dataLoadingErrorAlertMessage }));
    }, []);

    const columns = useMemo(() => getColumns(t, theme, globalClasses, formatTime), []);
    const data = useMemo(() => getData(staffers, checkingsByStaffer, lastCheckingByStaffer, devices, geofences, formatAddressOneLine), [JSON.stringify(staffers), JSON.stringify(lastCheckingByStaffer), JSON.stringify(checkingsByStaffer)]);
    const { 
        toggleAllRowsExpanded,
        visibleColumns, 
        ...tableRest
    } = useTable(
        { 
            columns, 
            data, 
            autoResetExpanded: false, 
            autoResetSortBy: false,
            getRowId: useCallback(row => row.allData.item.id, []),
        }, 
        useSortBy,
        useExpanded,
    );

    return (!loading &&
        <div className={globalClasses.tablePageLayout}>
            <ItemTable 
                {...tableRest}
            >
                { ({ itemRow, }) => (
                    <ItemRow 
                        key={itemRow.id} 
                        itemRow={itemRow} 
                        visibleColumns={visibleColumns} 
                        toggleAllRowsExpanded={toggleAllRowsExpanded} 
                    >
                        {({}) => (
                            <DetailPresenceRow 
                                geofences={geofences}
                                devices={devices}
                                formatAddressOneLine={formatAddressOneLine}
                                formatTime={formatTime}
                                visibleColumns={visibleColumns}
                                itemRow={itemRow}
                            />
                        )}
                    </ItemRow>
                )}
            </ItemTable>
        </div>
    );
};

export default PresencePage;