import React from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { SiMicrosoftexcel } from 'react-icons/si';
import { VscFilePdf } from 'react-icons/vsc';

import useTranslation from 'hooks/useTranslation';
import { asyncServerTasksActions } from 'store';
import { ReportFormat } from 'utils/constant';
import { makeStyles } from 'tss-react/mui';
import { AttachEmailOutlined } from '@mui/icons-material';

const useStyles = makeStyles()(theme => ({
    icon: {
        fontSize: 25,
        color: theme.palette.on.main,
    },
    iconDisabled: {
        color: theme.palette.off.main,
    },
}));

const FileExportReportButtons = ({ tasks, handleSubmit, getFiltersKey, pdfDisabled, excelDisabled, emailDisabled }) => {
    const { t } = useTranslation();
    const { classes, cx } = useStyles();
    const dispatch = useDispatch();

    const renderMailReportButton = () => {
        const filtersKey = getFiltersKey()
        if(tasks?.initiatedTask?.[filtersKey]) {
            return <IconButton disabled><CircularProgress/></IconButton>;
        } else if(tasks?.ongoingTask?.[filtersKey]) {
            if(tasks?.ongoingTask?.[filtersKey]?.message) {
                return <IconButton disabled endIcon={<CircularProgress/>}>{tasks?.ongoingTask?.[filtersKey]?.message}</IconButton>;
            } else {
                return <IconButton disabled><CircularProgress/></IconButton>;
            }
        } else if (tasks?.completedTask?.[filtersKey] && tasks?.completedTask?.[filtersKey]?.succeeded) {
            return <IconButton disabled ><CheckCircleOutlineIcon/></IconButton>
        } else if (tasks?.archivedTask?.[filtersKey] && tasks?.archivedTask?.[filtersKey]?.succeeded) {
            return <IconButton 
                    variant="outlined" 
                    color="primary"
                    onClick={() => dispatch(asyncServerTasksActions?.removeAsyncTask(tasks?.archivedTask?.[filtersKey]))}
                >
                    <CheckCircleOutlineIcon/>
                </IconButton>
        }
        return (
            <Tooltip
                disableHoverListener={pdfDisabled}
                disableFocusListener={pdfDisabled}
                disableInteractive={pdfDisabled}
                disableTouchListener={pdfDisabled}
                title={t('reportEmail')}
            >
                <span>
                    <IconButton 
                        disabled={emailDisabled} 
                        onClick={() => handleSubmit(ReportFormat.MAIL)}
                    >
                        <AttachEmailOutlined className={emailDisabled ? cx(classes.icon, classes.iconDisabled) : classes.icon}/>
                    </IconButton>
                </span>
            </Tooltip>
        );
    }

    return (
        <>
            <Tooltip
                disableHoverListener={pdfDisabled}
                disableFocusListener={pdfDisabled}
                disableInteractive={pdfDisabled}
                disableTouchListener={pdfDisabled}
                title={t('reportExportPDF')}
            >   
                <span>
                    <IconButton 
                        disabled={pdfDisabled} 
                        onClick={() => handleSubmit(ReportFormat.PDF)}
                    >
                        <VscFilePdf className={pdfDisabled ? cx(classes.icon, classes.iconDisabled) : classes.icon}/>
                    </IconButton>
                </span>
                
            </Tooltip>
            <Tooltip
                disableHoverListener={pdfDisabled}
                disableFocusListener={pdfDisabled}
                disableInteractive={pdfDisabled}
                disableTouchListener={pdfDisabled}
                title={t('reportExportXLSX')}
            >
                <span>
                    <IconButton 
                        disabled={excelDisabled} 
                        onClick={() => handleSubmit(ReportFormat.EXCEL)}
                    >
                        <SiMicrosoftexcel className={excelDisabled ? cx(classes.icon, classes.iconDisabled) : classes.icon}/>
                    </IconButton>
                </span>
            </Tooltip>
            
            {renderMailReportButton()}
        </>
    );
};

export default FileExportReportButtons;