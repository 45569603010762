import React from 'react';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { Collapse, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';

import EventItem from './EventItem';
import { alertsActions } from 'store';
import { magellanV1 } from 'api/magellan';

const useStyles = makeStyles()(theme => ({
    root: {
        height: '100%', 
        overflow: 'auto',
    },
    divider: {
        height: '0.1px',
        opacity: 0.1
    },
}));

const AlertsHistory = () => {
    const { classes } = useStyles();
    const alerts = useSelector(state => state.alerts.items, (prev, next) => JSON.stringify(prev) === JSON.stringify(next));
    const selectedId = useSelector(state => state.alerts.selectedId);
    const dispatch = useDispatch();

    const onClose = async (alertId) => {
        let alert = _.cloneDeep(alerts?.[alertId]);
        dispatch(alertsActions.removeFromHistory(alertId));
        try {
            await magellanV1.put(`api/events/${alertId}/acked`);
        } catch(error) {
            dispatch(alertsActions.addToHistory([alert]));
        }
    };

    return (
        <TransitionGroup className={classes.root}>
            {_.cloneDeep(Object.values(alerts))
                .sort((alertA, alertB) => (new Date(alertB.eventTime)).getTime() - (new Date(alertA.eventTime)).getTime())
                .map((alert, iAlert) => (
                    <Collapse key={alert.id}>
                        { iAlert > 0  && <Divider className={classes.divider}/>}
                        <EventItem
                            event={alert}
                            isSelected={selectedId === alert.id}
                            onClose={() => onClose(alert.id)}
                            selectItem={(newSelectedId) => dispatch(alertsActions.select(newSelectedId))}
                            deselectItem={() => dispatch(alertsActions.deselect())}
                        />
                    </Collapse>
                ))
            }
        </TransitionGroup>
    );
};

export default AlertsHistory;