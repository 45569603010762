import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { CircularProgress, Grid, Paper, Typography } from '@mui/material';

const useStyles = makeStyles()(theme => ({
    root: {
        overflow: 'hidden',
        height: '100%',
    },
    container: {
        overflow: 'auto',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    loadingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 150,
    },
}));

const ReportLayoutPage = ({ children, filter, loading }) => {
    const { classes, cx } = useStyles();
    return (
        <div className={cx(classes.container, classes.root)}>
            {filter}
            <Paper className={classes.container}>
                { loading ? <div className={classes.loadingContainer}><CircularProgress color='secondary'/></div> : children }
            </Paper>            
        </div>
    );
}

export default ReportLayoutPage;
