import React, { useEffect, useRef, useState, } from 'react';
import { useFormik } from 'formik';
import { Autocomplete, Card, CardHeader, CircularProgress, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import useTranslation from 'hooks/useTranslation';
import ItemsLinked from '../../common/ItemsLinked';
import DrawGeofenceMap from './DrawGeofenceMap';
import useGroupManager from 'hooks/useGroupManager';
import useAlertMessage from 'hooks/useAlertMessage';
import { AlertMessageSeverity, GeometryType } from 'utils/constant';
import useGeocoding from 'hooks/useGeocoding';
import useFormat from 'hooks/useFormat';

const useStyles = makeStyles()(theme => ({
    root:{
        margin: '32px 0px 0px 0px',
    },
    propertyCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    groupCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
    },
    addressQueryTextfield: {
        margin: theme.spacing(4),
    },
    mapCard: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        rowGap: 0,
    }
}));

const GeofenceForm = ({ onSubmit, initialValues, editionMode, id }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const formik = useFormik({
        initialValues,
        validationSchema: yup.object().shape({
            name: yup.string()
                .trim()
                .required(t('validationRequired'))
                .max(128, `\${max} ${t('validationLengthMax')}`),
            description: yup.string()
                .trim()
                .nullable()
                .max(4000, `\${max} ${t('validationLengthMax')}`),
            area: yup.string()
                .trim()
                .required(t('validationRequired'))
        }),
        onSubmit,
    });
    const { groups, handleChangeGroups } = useGroupManager({ 
        id,
        endpoint: 'geofence',
        formik,
        allCheckedByDefault: true
    });
    const { setAlertMessage } = useAlertMessage();
    const geofenceNotDrawnWarningAlertMessage = {
        show: true,
        severity: AlertMessageSeverity.WARNING,
        message: t('geofenceNotDrawnWarning')
    };
    
    const [foundCoordinates, setFoundCoordinates] = useState({ latitude: 50.641111, longitude:  4.668889});
    const { 
        addressLoading, 
        addressQuery, 
        setAddressQuery, 
        suggestions, 
        onAddressChosen,
    } = useGeocoding({ setCoordinates: setFoundCoordinates });
    const { formatAddressOneLine } = useFormat();
    const loadingParentRef = useRef(null);
    const [loadingParentSize, setLoadingParentSize] = useState(0);

    useEffect(() => {
        const { clientHeight, clientWidth } = loadingParentRef.current;
    
        setLoadingParentSize(Math.min(clientHeight, clientWidth));
    }, []);

    return (
        <form id="form" onSubmit={e => {
            e.preventDefault();
            if(formik.errors.area) {
                setAlertMessage(geofenceNotDrawnWarningAlertMessage);
            } else {
                formik.submitForm();
            }
        }}>
            <CardHeader title={t('signageProperties')} />
            <Card className={classes.propertyCard}>
                <TextField 
                    required
                    id="name"
                    name="name"
                    label={t('signageLabel')}
                    disabled={!editionMode}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
                <TextField 
                    id="description"
                    name="description"
                    label={t('signageDescription')}
                    disabled={!editionMode}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                    multiline
                    rows={10}
                    maxRows={35}
                    fullWidth={true}
                />
            </Card>
            <Card className={classes.mapCard}>
                <Autocomplete 
                    className={classes.addressQueryTextfield}
                    disableClearable
                    filterOptions={x => x}
                    autoComplete
                    onChange={(_, option) => onAddressChosen(option)}
                    options={suggestions}
                    noOptionsText={t('addressNotFound')}
                    onInputChange={(_, newInputValue) => setAddressQuery(newInputValue)}
                    getOptionLabel={option => {
                        let address = option?.address;
                        let label = formatAddressOneLine({
                            street: address?.road,
                            locality: {
                                name: address?.village || address?.city_district || address?.town || address?.city || address?.suburb || address?.county || address?.state,
                            }
                        });
                        if (label && address?.country) {
                            label += ` (${option.address.country})`;
                        }
                        return label || '';
                    }}
                    renderInput={(params) => (
                        <TextField 
                            {...params} 
                            variant='outlined'
                            placeholder={t('searchAddressOnMap')} 
                            inputRef={loadingParentRef}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: addressLoading && <CircularProgress color="secondary" size={0.8 * loadingParentSize}/>
                            }}
                        />
                    )}
                />
                <DrawGeofenceMap 
                    editionMode={editionMode}
                    editable={formik.values.area.indexOf(GeometryType.CIRCLE.toUpperCase()) === -1}
                    loading={false}
                    coordinates={foundCoordinates}
                    setArea={(newArea) => formik.setFieldValue('area', newArea)}
                    geofence={formik.values}
                />
            </Card>
            <CardHeader title={t('signageGroups')} />
            <Card className={classes.groupCard}>
                <ItemsLinked
                    disabled={!editionMode}
                    items={groups}
                    handleChange={handleChangeGroups}
                />
            </Card>
        </form>
    );




};


export default GeofenceForm;