import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { sessionReducer as session } from './session';
import { devicesReducer as devices } from './devices';
import { staffersReducer as staffers } from './staffers';
import { positionsReducer as positions } from './positions';
import { geofencesReducer as geofences } from './geofences';
import { groupsReducer as groups } from './groups';
import { notificationsReducer as notifications } from './notifications';
import { alertsReducer as alerts } from './alerts';
import { asyncServerTasksReducer as asyncServerTasks } from './asyncServerTasks';
import { languagesReducer as languages } from './languages';
import { companyReducer as company } from './company';
import { timesheetValidationReducer as timesheetValidation } from './timesheetValidation';
import { timesheetReportReducer as timesheetReport } from './timesheetReport';

const reducer = combineReducers({
    session,
    devices,
    staffers,
    positions,
    geofences,
    groups,
    notifications,
    alerts,
    asyncServerTasks,
    languages,
    company,
    timesheetValidation,
    timesheetReport,
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        localStorage.removeItem('session');
        localStorage.removeItem('notifications');
        state = undefined;
    }
    return reducer(state, action)
}

const store = configureStore({
    reducer: rootReducer,
});

export { sessionActions } from './session';
export { devicesActions } from './devices';
export { staffersActions } from './staffers';
export { positionsActions } from './positions';
export { geofencesActions } from './geofences';
export { groupsActions } from './groups';
export { notificationsActions } from './notifications';
export { alertsActions } from './alerts';
export { asyncServerTasksActions } from './asyncServerTasks';
export { languagesActions } from './languages';
export { companyActions } from './company';
export { timesheetValidationActions } from './timesheetValidation';
export { timesheetReportActions } from './timesheetReport';

export {store};
