import { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MapContext } from 'context/MapContext';
import { devicesActions } from 'store';

const SelectedDeviceMap = () => {
    const dispatch = useDispatch();
    const { map, mapReady } = useContext(MapContext);
    const [mapCenter, selectedId, mustZoom] = useSelector(state => {
        let positionDeviceAssociated;
        const devices = state.devices.items;
        const mustZoom = state.devices.mustZoom;
        let selectedId = state.devices.selectedId;
        if (selectedId) {
            const device = devices[selectedId];
            const position = state.positions.items[selectedId] || null;
            if(device.beacon && device.detectedBy != null) {
                positionDeviceAssociated = state.positions.items[device.detectedBy];
                if(positionDeviceAssociated) {
                    return [[positionDeviceAssociated.longitude, positionDeviceAssociated.latitude], selectedId, mustZoom];
                }
            }
            if (position) {
                
                return [[position.longitude, position.latitude], selectedId, mustZoom];
            }
        }
        return [null, selectedId, mustZoom];
    });

    useEffect(() => {
        if(mapReady && !mustZoom) {
            map.easeTo({ center: mapCenter });
        }
        
    }, [mapReady && JSON.stringify(mapCenter)]);

    useEffect(() => {
        if(mapReady && selectedId && mapCenter && mustZoom){
            map.flyTo({
                center: mapCenter,
                zoom: 17,
                speed: 2.3
            });
            dispatch(devicesActions.endZoom());
        }
    }, [mapReady && selectedId, mustZoom]);

    return null;
}

export default SelectedDeviceMap;
