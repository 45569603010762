import React, { useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { 
    FormControlLabel,
    Switch,
    Tooltip,
    TextField
} from '@mui/material';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';


const ExpirationDateInput = ({ name, label, tooltip, disabled, formik }) => {
    const [active, setActive] = useState(Boolean(formik.values[name]));

    const getDefaultExpirationDate = () => {
        var date = new Date();
        date.setDate(date.getDate() + 10);
        return date.toISOString();
    }

    const handleDateChange = (date) => {
        formik.setFieldValue(name, (date && date.toString() !== 'Invalid Date') ? date.toISOString() : "Invalid Date");
    };


    return (
        <Tooltip  title={tooltip} placement="left" arrow>
            <FormControlLabel
                style={{
                    display: "flex",
                    width: '100%',
                    justifyContent: "space-between",
                    marginLeft: '0px',
                }}
                disabled={disabled}
                control={<Switch checked={active} onChange={(event) => {
                    setActive(event.target.checked);
                    if(event.target.checked){
                        let expirationDate = getDefaultExpirationDate();
                        formik.setFieldValue(name, expirationDate);
                    }else{
                        formik.setFieldValue(name, null);
                    }
                }
                } />}
                label={active
                    ? <>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker
                                ampm={false}
                                label={label}
                                disabled={disabled || !active}
                                margin="normal"
                                keyboardIcon={<QueryBuilderIcon/>}
                                disablePast
                                value={new Date(formik.values[name] ? formik.values[name] : getDefaultExpirationDate())}
                                onChange={handleDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </>
                    : label
                }
                labelPlacement="start"
            />
        </Tooltip>
    );
};

export default ExpirationDateInput;