import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { getIn } from 'formik';

import { magellanV0 } from 'api/magellan';
import useTranslation from 'hooks/useTranslation';
import useAlertMessage from 'hooks/useAlertMessage';

const AutocompleteLocality = ({label, formik, endpoint, required = false, ...custom}) => {
    const { t } = useTranslation();
    const [items, setItems] = useState();
    const { setAlertMessage, dataLoadingErrorAlertMessage } = useAlertMessage();

    useEffect(() => {
        if (endpoint) {
            magellanV0.get(endpoint)
                .then(response => {
                    setItems(response.data);
                })
                .catch(err => {
                    setAlertMessage(dataLoadingErrorAlertMessage);
                });
        }
    }, []);
    
    const getItem = () => {
        const localityId = formik.values.address.locality?.id || formik.values.address.localityId;
        return localityId ? items.find(item => item.id === localityId) : null;
    }
    
    if(items) {
        return (
            <Autocomplete 
                onClose={formik.handleBlur}
                value={getItem()}
                onChange={(e, value) => {
                    formik.setFieldValue('address.locality', value, true)
                }}
                options={items.sort((a, b) => -b.province.localeCompare(a.province))}
                groupBy={(item) => item.province}
                getOptionLabel={(option) =>` ${option.name} (${option.postalCode})`}
                renderInput={(params) => (
                    <TextField 
                        required={required} 
                        error={getIn(formik.touched, 'address.locality') && formik.values.address.locality === null}
                        helperText={getIn(formik.touched, 'address.locality') && formik.values.address.locality === null && t('validationRequired')}
                        {...params} 
                        label={t('companyLocality')} 
                    />
                )}
                {...custom}
            />
        );
    } else {
        return null;
    }
    
};

export default React.memo(AutocompleteLocality);