import { 
    Button,
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    createTheme, 
    useTheme, 
    ThemeProvider, 
    StyledEngineProvider, } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import React from 'react';


const ConfirmationDialog = ({ open, setOpen, title, message, callback, }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const buttonDialogTheme = createTheme({
        MuiButton: {
            styleOverrides: {
                label : {
                    color: theme.palette.common.black,
                    textTransform: 'none',
                    '&:hover':{
                        color: theme.palette.secondary.main,
                    },
                },
            }
        },
    });

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{ style: { backgroundColor: theme.palette.primary.main } }}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={buttonDialogTheme}>
                        <Button color="primary" onClick={() => setOpen(false)} >{t('sharedCancel')}</Button>
                        <Button 
                            style={{ 
                                color: 'black',
                                '&:hover':{
                                    color: theme.palette.secondary.main,
                                } 
                            }}  
                            onClick={callback}
                        >
                            {t('ok')}
                        </Button>
                    </ThemeProvider>
                </StyledEngineProvider>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;