import React from 'react';
import { useParams } from 'react-router-dom';

import CreateUser from './CreateUser';
import UpdateUser from './UpdateUser';

const filterDataForServer = item => {
    item.deviceReadonly = true;
    if(!item.password){
        delete item.password;
    }
    delete item.groups;
    return item;
}

const UserPage = ({ role }) => {
    const { id } = useParams();

    

    return (id) ? <UpdateUser id={ id } filterDataForServer={filterDataForServer} role={role}/> : <CreateUser filterDataForServer={filterDataForServer}/>;

}

export default UserPage;
