import axios from "axios";

import { decodeStringInJson } from "utils/urlDecode";

const transformResponse  = res => {
    try{
        return decodeStringInJson(JSON.parse(res));
    }catch(error){
        return res;
    }
}

const paramsSerializer = {
    serialize: (params) => Object.entries(params)
        .map(([key, value]) =>{
            let result = "";
            if(Array.isArray(value)) {
                result = value.map(item => `${key}=${item}`).join('&');
            } else {
                result = `${key}=${value}`;
            }
            return result;
        }).join('&')
}

const magellanV0Config = {
    baseURL: window.location.origin,
    transformResponse,
};

const magellanV1Config = {
    baseURL: window.location.origin,
    transformResponse,
    headers: {
        'Accept': 'application/vnd.magellan.v1+json',
    },
    paramsSerializer,
};

const magellanV0NotIntercept401 = axios.create(magellanV0Config);

const magellanV1NotIntercept401 = axios.create(magellanV1Config);

const magellanV0 = axios.create(magellanV0Config);

const magellanV1 = axios.create(magellanV1Config);

magellanV0.defaults.headers.common['Content-Type'] = 'application/json';
magellanV1.defaults.headers.common['Content-Type'] = 'application/json';

const magellanV0Interceptor401Id = magellanV0.interceptors.response.use(response => response, error => {
    if(error.response.status === 401) {
        window.location = window.location.origin;
    } else {
        return Promise.reject(error);
    }
});

const magellanV1Interceptor401Id = magellanV1.interceptors.response.use(response => response, error => {
    if(error.response.status === 401) {
        window.location = window.location.origin;
    } else {
        return Promise.reject(error);
    }
});

export { magellanV0NotIntercept401, magellanV0, magellanV1NotIntercept401, magellanV1 };