import _ from 'lodash';

const containsText = (rows, filterValues, extractValue) => {
    return rows.filter(r => filterValues.some(filterValue => extractValue(r)?.toLowerCase().includes(filterValue?.toLowerCase())));
};
containsText.autoRemove = val => !val;

const matchExactText = (rows, filterValues, extractValue) => {
    return rows.filter(r => filterValues.some(filterValue => extractValue(r)?.toLowerCase() === filterValue?.toLowerCase()));
};
matchExactText.autoRemove = val => !val;

export { containsText, matchExactText };

export const globalFilterFunction = (rows, columnIds, globalFilterValues, columns) => {
    let result = [];
    if (rows.length > 0 && columns) {
        for (let column of columns) {
            if (column.filter) {
                result = _.unionBy(result, column?.filter(rows, column?.accessor, globalFilterValues), 'id');
            }
        }
    } 

    return result;
};

export const setTableFilters = (filters, setGlobalTableFilter, setOtherTableFilters) => {
    let newGlobalFilter = filters.find(f => f.filterKey === 'all');
    let newFilters = filters
        .filter(f => f.filterKey !== 'all')
        .map(f => ({ id: f.filterKey, value: f.values }));
    setGlobalTableFilter(newGlobalFilter?.values);
    setOtherTableFilters(newFilters);
}