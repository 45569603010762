import { createSlice } from '@reduxjs/toolkit';


let initialState = {server: null, user: null};
let savedState = localStorage.getItem('session');
if(savedState){
    initialState = JSON.parse(savedState).session;
}

const { reducer, actions } = createSlice({
    name: 'session',
    initialState,
    reducers: {
        updateServer(state, action) {
            state.server = action.payload;
        },
        updateUser(state, action) {
            state.user = action.payload;
        },
        updateColorMode(state, action) {
            state.user.attributes.colorMode = action.payload;
        }
    },
});

export { actions as sessionActions };
export { reducer as sessionReducer };
