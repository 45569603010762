import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { IconButton } from "@mui/material";
import CreateIcon from '@mui/icons-material/Create';
import CheckIcon from '@mui/icons-material/Check';
import { ArrowBack } from  '@mui/icons-material';

const useStyles = makeStyles()(theme => ({
    header: {
        verticalAlign: 'center',
        height: theme.spacing(5),
    },
    goBackButton: {
        display: 'inline-block',
        cursor: 'pointer',
        marginBottom: theme.spacing(2),

    },
    goBackIcon: {
        verticalAlign: 'middle'
    },
    goBackText: {
        verticalAlign: 'middle',
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
        marginLeft: theme.spacing(1),
    },
    modifyButton: {
        float: 'right',
    },
}));

const SignageHeader = ({ editionMode, setEditionMode, editionEnabled, showGoBack = true, title }) =>{ 
    const { classes } = useStyles(); 
    const navigate = useNavigate();

    return <>
        <div className={classes.header}>
            {showGoBack && (
                    <div  className={classes.goBackButton} onClick={() => navigate(-1)}>
                        <ArrowBack className={classes.goBackIcon} />
                        <span className={classes.goBackText}>{ title }</span>
                    </div>
                )
            }
            { editionEnabled &&
                <div className={classes.modifyButton}>
                    {!editionMode && 
                        <IconButton color='primary' onClick={() => setEditionMode(true)} size="large">
                            <CreateIcon/>
                        </IconButton>
                    }
                    {editionMode && 
                        <IconButton color='primary' form="form" type="submit" size="large">
                            <CheckIcon/>
                        </IconButton>
                    }
                </div>
            }
            
        </div>
    </>;
}

export default SignageHeader;