import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Menu from '@mui/material/Menu';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    root: { 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
    },
    menu: {
        zIndex: theme.zIndex.appBar + 1,
    }
}));

const DropdownMenu = ({ children, component, anchor, setAnchor }) => {
    const { classes } = useStyles();

    const handleClick = (event) => {
        setAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setAnchor(null);
    };

    return (
        <>
            <div className={classes.root}  onClick={handleClick}>
                {component}
            </div>
            <Menu
                className={classes.menu}
                anchorEl={anchor}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={Boolean(anchor)}
                onClose={handleClose}
            >
                {children}
            </Menu>
        </>
    );
}

export default DropdownMenu;