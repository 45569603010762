import { makeStyles } from 'tss-react/mui';
import { Typography, useTheme } from '@mui/material';
import React from 'react';

const useStyles = makeStyles()(theme => ({
    root: {
        position: 'relative',
        height: 100,
    },
    title: {
        position: 'absolute',
        zIndex: 5,
        top: 10,
        textAlign: 'center',
        left: 0,
        right: 0,
        color: 'black',
        textShadow: '2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;'
    },
}));

const MapChoiceItem = ({item, onClick}) => {
    const theme = useTheme();
    const { classes } = useStyles();

    return (
        <div onClick={onClick} className={classes.root}>
            <Typography className={classes.title}>{item.title}</Typography>
            <img src={item.img} width={100} height={100}/>
        </div>
    );
}

export default MapChoiceItem;