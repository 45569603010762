import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import SafeDrivingPage from '../components/reports/safeDriving/SafeDrivingPage';
import ToolsAndTrailersEventsPage from 'components/reports/toolAndTrailer/ToolsAndTrailersEventsPage';
import RouteReportPage from '../components/reports/route/RouteReportPage';
import EventReportPage from '../components/reports/event/EventReportPage';
import TripReportPage from '../components/reports/trip/TripReportPage';
import StopReportPage from '../components/reports/stop/StopReportPage';
import SummaryReportPage from '../components/reports/summary/SummaryReportPage';
import ChartReportPage from '../components/reports/chart/ChartReportPage';
import { UserAndManagerEnabled } from '../authorization';


const ReportsRoutes = () => {
    const SafeDrivingPageForUserAndManager = UserAndManagerEnabled(SafeDrivingPage);
    const ToolAndTrailerEventsPageForUserAndManager = UserAndManagerEnabled(ToolsAndTrailersEventsPage);
    const RouteReportPageForUserAndManager = UserAndManagerEnabled(RouteReportPage);
    const EventReportPageForUserAndManager = UserAndManagerEnabled(EventReportPage);
    const TripReportPageForUserAndManager = UserAndManagerEnabled(TripReportPage);
    const StopReportPageForUserAndManager = UserAndManagerEnabled(StopReportPage);
    const SummaryReportPageForUserAndManager = UserAndManagerEnabled(SummaryReportPage);
    const ChartReportPageForUserAndManager = UserAndManagerEnabled(ChartReportPage);
    
    return (
        <Routes>
            <Route exact path="safeDriving" element={<SafeDrivingPageForUserAndManager/>}/>
            <Route exact path="toolsAndTrailersEvents" element={<ToolAndTrailerEventsPageForUserAndManager/>}/>
            <Route exact path="route" element={<RouteReportPageForUserAndManager/>}/>              
            <Route exact path="event" element={<EventReportPageForUserAndManager/>}/>
            <Route exact path="trip" element={<TripReportPageForUserAndManager/>}/>
            <Route exact path="stop" element={<StopReportPageForUserAndManager/>}/>
            <Route exact path="summary" element={<SummaryReportPageForUserAndManager/>}/>
            <Route exact path="chart" element={<ChartReportPageForUserAndManager/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    )
}

export default ReportsRoutes;