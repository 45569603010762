import React from 'react';
import { Card, CardHeader } from '@mui/material';

import useTranslation from 'hooks/useTranslation';
import DayInput from './DayInput';

const Timetable = ({editionMode, formik, offMessage}) => {
    const { t } = useTranslation();

    return (
        <table>
            <tbody>
                <DayInput disabled={!editionMode} day={t('monday')} names={['mondayStartHour', 'mondayEndHour']} formik={formik} offMessage={offMessage}/>
                <DayInput disabled={!editionMode} day={t('tuesday')} names={['tuesdayStartHour', 'tuesdayEndHour']} formik={formik} offMessage={offMessage}/>
                <DayInput disabled={!editionMode} day={t('wednesday')} names={['wednesdayStartHour', 'wednesdayEndHour']} formik={formik} offMessage={offMessage}/>
                <DayInput disabled={!editionMode} day={t('thursday')} names={['thursdayStartHour', 'thursdayEndHour']} formik={formik} offMessage={offMessage}/>
                <DayInput disabled={!editionMode} day={t('friday')} names={['fridayStartHour', 'fridayEndHour']} formik={formik} offMessage={offMessage}/>
                <DayInput disabled={!editionMode} day={t('saturday')} names={['saturdayStartHour', 'saturdayEndHour']} formik={formik} offMessage={offMessage}/>
                <DayInput disabled={!editionMode} day={t('sunday')} names={['sundayStartHour', 'sundayEndHour']} formik={formik} offMessage={offMessage}/>
            </tbody>
        </table>
    );
}

export default Timetable;