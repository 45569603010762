import React, { useEffect, useRef, useState, useContext, useCallback } from 'react';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { makeStyles } from 'tss-react/mui';
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import mapboxgl from 'mapbox-gl';

import { MapContext } from 'context/MapContext';
import Pointer from 'resources/default_point.svg';
import Loading from '../../common/Loading';
import { coordinatesToPolygonWkt, fitBounds, geofenceToFeature, } from '../../home/map/mapUtil';
import Map from '../../home/map/Map';
import { GeometryType } from 'utils/constant';
import useTranslation from 'hooks/useTranslation';
import { bbox, bboxPolygon, circle } from '@turf/turf';

const useStyles = makeStyles()(theme => ({
    mapContainer: {
        flexGrow: 1,
        height: '50vh',
    },
    editionNotPossible: {
        backgroundColor: 'black',
        color: 'white',
        opacity: 0.6,
        padding: 8
    }
}));

let modes = MapboxDraw.modes;
modes.static = StaticMode;


const DrawGeofenceMap = ({ editionMode, editable, loading, coordinates, setArea, geofence }) => {
    const { t } = useTranslation();
    const { map, mapReady } = useContext(MapContext);
    const { classes } = useStyles();
    const [marker, setMarker] = useState();
    const firstRender = useRef(true);
    const markerRef = useRef();
    const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
            polygon: editionMode && editable,
            trash: editionMode && editable
        },
        modes,
    });

    useEffect(() => {
        if(mapReady) {
            map.addControl(draw);
            map._drawControl = draw;
            if(geofence.area){    
                draw.deleteAll();
                let geometry = geofenceToFeature(geofence)[0].geometry;
                draw.add({
                    id: 'unique-id',
                    type: 'Feature',
                    properties: {},
                    geometry: geometry,
                });
            }

            if(editionMode && editable){
                draw.changeMode('simple_select');
            }else{
                draw.changeMode('static');
            }

            map.on('draw.create', drawn);
            map.on('draw.update', drawn);
            map.on('draw.delete', deleted);
            map.on('draw.modechange', removePolygon);

            return () => {
                map.off('draw.create', drawn);
                map.off('draw.update', drawn);
                map.off('draw.delete', deleted);
                map.off('draw.modechange', removePolygon);
                map.removeControl(draw);
            }
        }
    }, [editionMode, mapReady]);

    const placeCoordinatesOnMap = async () => {
        let newMarker;
        const el = document.createElement('div');
        const width = 27;
        const height = 41;
        el.style.backgroundImage = `url(${Pointer})`;
        el.style.width = `${width}px`;
        el.style.height = `${height}px`;
        el.style.backgroundSize = '100%';
        if(marker){
            marker.remove();
        }
        newMarker = new mapboxgl.Marker({element: el, anchor: 'bottom'}).setLngLat([coordinates.longitude, coordinates.latitude]).addTo(map)
        setMarker(newMarker);
        markerRef.current = newMarker;
        map.jumpTo({center: [coordinates.longitude, coordinates.latitude], zoom: 15});            
        if(!geofence.area) {
            let feature = bboxPolygon(bbox(circle([coordinates.longitude, coordinates.latitude], 0.1)));
            map._drawControl.add({
                id: 'unique-id',
                ...feature,
            });
            const data = map._drawControl.getAll();
            setArea(coordinatesToPolygonWkt(data.features[0].geometry.coordinates[0]));
        }
    };
    
    useEffect(() => {
        if(mapReady) {
            if(firstRender.current){
                firstRender.current = false;
                if(geofence.area){
                    let areaCoordinates = geofenceToFeature(geofence)[0].geometry.coordinates[0];
                    fitBounds(map, areaCoordinates);
                } else {
                    map.jumpTo({zoom: 7, center : [coordinates.longitude, coordinates.latitude]});
                }
            } else if(coordinates.latitude && coordinates.longitude){
                placeCoordinatesOnMap();
            }
        }
    }, [JSON.stringify(coordinates), mapReady]);


    useEffect(() => {
        if(mapReady) {
            map.setMinZoom(1);

            return () => {
                if(markerRef.current){
                    markerRef.current.remove();
                }
            }   
        }
             
    }, [mapReady]);

    const removePolygon = (e) => {
        const data = draw.getAll();
        if (draw.getMode() === 'draw_polygon') {
            var pids = [];
        
            // ID of the added template empty feature
            const lid = data.features[data.features.length - 1].id
        
            data.features.forEach((f) => {
                if (f.geometry.type === GeometryType.POLYGON && f.id !== lid) {
                    pids.push(f.id)
                }
            })
            draw.delete(pids);
            deleted();
        }
    };
    
    const drawn = () => {
        const data = draw.getAll();
        setArea(coordinatesToPolygonWkt(data.features[0].geometry.coordinates[0]));
    };

    const deleted = () => {
        setArea('');
    }


    return (
        <>
            {!editable && <div className={classes.editionNotPossible}>{t('editionGeofenceCreatedByMobileAppNotPossible')}</div>}
            <div className={classes.mapContainer} style={editionMode && editable ? {} :{pointerEvents:'none'}}>
                { loading 
                    ? <Loading />
                    : <Map/>
                }
            </div>
        </>
    );
};

export default React.memo(DrawGeofenceMap);