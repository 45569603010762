import React from 'react';
import { Switch, FormControlLabel } from '@mui/material';

const ItemsLinker = ({ disabled, items, handleChange }) => {

    return (
        <>
            {items.allItems && 
                items.allItems.map(item => {
                    return (
                        <FormControlLabel
                            key={item.id}
                            style={{
                                display: "flex",
                                width: '100%',
                                justifyContent: "space-between",
                                marginLeft: '0px',
                            }}
                            disabled={disabled}
                            control={<Switch 
                                value={item.id} 
                                checked={items?.finalItems ? items?.finalItems?.some(id => id === item.id) : false} 
                                onChange={handleChange}
                            />}
                            label={item.name}
                            labelPlacement="start"
                        />
                    );
                })
            }
        </>
    );
};

export default ItemsLinker;