import React from 'react';
import { useParams } from 'react-router-dom';

import CreateStaffer from './CreateStaffer';
import UpdateStaffer from './UpdateStaffer';

const StafferPage = ({ role }) => {
    const { id } = useParams();

    const filterDataForServer = data => {
        let item = {...data};
        item.address.localityId = item?.address?.locality?.id;
        delete item.address.locality;
        delete item.address.id;
        item.id = id;   
        item.attributes = item.attributes || {}; 
        return item;   
    }

    return (id) 
        ? <UpdateStaffer id={ id } filterDataForServer={filterDataForServer} role={role} /> 
        : <CreateStaffer filterDataForServer={filterDataForServer}/>;
};

export default StafferPage;