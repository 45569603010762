import React, { useMemo, useCallback, useState, useEffect, } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { useGlobalStyles } from 'context/StylesContext';
import useFormat from 'hooks/useFormat';
import useTranslation from 'hooks/useTranslation';
import ItemTable from '../ItemTable';
import { Button, Divider, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Menu, TablePagination } from '@mui/material';
import { AssetColumns, DeviceStatus, DeviceType, Filters, Parameters } from 'utils/constant';
import { makeStyles } from 'tss-react/mui';
import useBeaconData from 'hooks/useBeaconData';
import ItemRow from 'components/common/ItemRow';
import DetailAssetRow from './DetailAssetRow';
import { devicesActions } from 'store';
import { getColumns, getData } from '.';
import { useTable, useExpanded, useSortBy, usePagination, useFilters as useTableFilters, useGlobalFilter, } from 'react-table';
import GlobalSearch from 'components/common/GlobalSearch';
import { globalFilterFunction, setTableFilters } from 'utils/filters';
import { Check, FilterAlt } from '@mui/icons-material';
import useFilters from 'hooks/useFilters';
import useMenuAnchor from 'hooks/useMenuAnchor';
import useMemoizePagination from 'hooks/useMemoizePagination';


const useStyles = makeStyles()(theme => ({
    iconStatusContainer: {
        justifyContent: 'flex-start'
    },
}));

const deviceTypesTakenIntoAccount = [
    DeviceType.VEHICLE,
    DeviceType.CONSTRUCTION_MACHINE,
    DeviceType.SMARTPHONE,
    DeviceType.TRAILER,
];

const AssetsPage = () => {
    const { t } = useTranslation();
    const { formatAddressOneLine, formatDeviceCategory, formatDistance, formatDuration } = useFormat();
    const { classes: globalClasses, cx } = useGlobalStyles();
    const { classes } = useStyles();
    const { menuAnchor, handleMenuOpen, handleMenuClose } = useMenuAnchor();
    const { devices, geofences, positions, groups } = useSelector(state => ({ 
        devices: Object.values(state.devices.items),
        geofences: state.geofences.items, 
        positions: state.positions.items, 
        groups: state.groups.items 
    }));
    const filters = useSelector(state => state.devices.assets.filters);
    const pagination = useSelector(state => state.devices.assets.pagination);
    const dispatch = useDispatch();
    const items = devices.filter(device => deviceTypesTakenIntoAccount.includes(device.category));
    const { extractBeaconData } = useBeaconData(devices, positions, geofences, groups);
    const columns = useMemo(() => getColumns(t, cx, globalClasses, classes), []);
    const data = useMemo(() => getData(items, extractBeaconData, positions, geofences, formatAddressOneLine, formatDeviceCategory, groups, formatDistance, formatDuration), [JSON.stringify(items), JSON.stringify(geofences)]);
    const defaultColumn = useMemo(() => ({
        Filter: () => null
    }), []);
    const { 
        toggleAllRowsExpanded,
        visibleColumns, 
        setPageSize,
        gotoPage,
        rows,
        setGlobalFilter: setGlobalTableFilter,
        setAllFilters: setOtherTableFilters,
        state: {
            pageIndex,
            pageSize,
        }, 
        ...tableRest
    } = useTable(
        { 
            columns, 
            data, 
            defaultColumn,
            globalFilter: (rowsFromFn, columnIds, globalFilterValues) => globalFilterFunction(rowsFromFn, columnIds, globalFilterValues, columns),
            autoResetExpanded: false, 
            autoResetSortBy: false,
            autoResetPage: false,
            autoResetFilters: false,
            autoResetGlobalFilter: false,
            getRowId: useCallback(row => row.allData.item.id, []),
            initialState: {
                pageSize: pagination?.limit ?? 50,
                pageIndex: pagination?.page ?? 0,
            }
        }, 
        useTableFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination
    );
    const { handleFilterChange, handleGlobalSearchChange } = useFilters({ 
        filters, 
        filtersId: Parameters.ASSET_FILTERS, 
        setTableFilters: (newFilters) => setTableFilters(newFilters, setGlobalTableFilter, setOtherTableFilters), 
        setFiltersInCache: (newFilters) => dispatch(devicesActions.refreshAssetsFilters(newFilters)),
        resetPage: () => dispatch(devicesActions.updateAssetsPaginationPage(0)),
    });
    const { handleChangePage, handleChangeLimit } = useMemoizePagination({ 
        parameterKey: Parameters.ASSET_PAGINATION,
        gotoPage,
        setPageSize,
        savedPage: pagination.page,
        savedLimit: pagination.limit,
        savePage: (newPage) => dispatch(devicesActions.updateAssetsPaginationPage(newPage)),
        saveLimit: (newLimit) => dispatch(devicesActions.updateAssetsPaginationLimit(newLimit)),
    }); 
            
    const renderTable = () => {
        return (
            <ItemTable 
                rows={rows}
                {...tableRest}
            >
                { ({ itemRow, ...rest }) => (
                    <ItemRow 
                        key={itemRow.id} 
                        itemRow={itemRow} 
                        onClick={() => {
                            if(itemRow.isExpanded) {
                                itemRow.toggleRowExpanded(false);
                                dispatch(devicesActions.deselect());
                            } else {
                                toggleAllRowsExpanded(false);
                                itemRow.toggleRowExpanded(true);
                                dispatch(devicesActions.select(itemRow?.id));
                            }
                        }}
                        {...rest} 
                    >
                        {({ }) => (
                            <DetailAssetRow
                                itemRow={itemRow}
                                visibleColumns={visibleColumns}
                            />
                        )}
                    </ItemRow>
                )}
            </ItemTable>
        );
    };

    return (
        <div className={globalClasses.tablePageLayout}>
            <div className={globalClasses.tableEngineContainer}>
                <GlobalSearch
                    filters={filters}
                    setFilters={(getFilters) => handleGlobalSearchChange(getFilters)}
                    availableFilters={[
                        'all', 
                        AssetColumns.ITEM_LABEL, 
                        AssetColumns.ITEM_LICENSE_PLATE, 
                        AssetColumns.ITEM_GROUP, 
                        AssetColumns.ITEM_CATEGORY
                    ]}
                />
                <div className={globalClasses.tableEngineFilterAndPagination} style={{overflow: 'auto'}}>
                    <div onClick={handleMenuOpen} className={globalClasses.tableEngineFilterButton}>
                        <span>{t('filters')}</span>
                        <FilterAlt className={globalClasses.actionIcon}/>
                    </div>
                    <Menu
                        MenuListProps={{
                            className: globalClasses.listCheckableItemList
                        }}
                        anchorEl={menuAnchor}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={Boolean(menuAnchor)}
                        onClose={handleMenuClose}
                    >
                        <List 
                            // subheader={
                            //     <ListSubheader className={classes.filterCategory}>
                            //         <ListItemIcon className={globalClasses.listCheckableItemCheck}>
                            //             <Check className={globalClasses.deselectedIcon}/>
                            //         </ListItemIcon>
                            //         {t(AssetColumns.ITEM_CATEGORY)}
                            //     </ListSubheader>
                            // }
                            disablePadding
                        >
                            { [DeviceType.VEHICLE, DeviceType.CONSTRUCTION_MACHINE, DeviceType.SMARTPHONE, DeviceType.TRAILER, DeviceType.WAREHOUSE].map(value => (
                                <ListItemButton
                                    className={globalClasses.listCheckableItemItem}
                                    key={value}
                                    dense
                                    onClick={(event) => handleFilterChange(AssetColumns.ITEM_CATEGORY, event.currentTarget.textContent)}
                                >
                                    <ListItemIcon className={globalClasses.listCheckableItemCheck}>
                                        { filters.find(f => f.filterKey === AssetColumns.ITEM_CATEGORY)?.values?.includes(formatDeviceCategory(value))
                                            ? <Check/>
                                            : <Check className={globalClasses.deselectedIcon}/>
                                        }
                                    </ListItemIcon>
                                    <span className={globalClasses.listCheckableItemText}>{formatDeviceCategory(value)}</span>    
                                </ListItemButton>
                            ))}
                        </List>
                        <Divider className={globalClasses.transparentDivider}/>
                        <List 
                            // subheader={
                            //     <ListSubheader className={classes.filterCategory}>
                            //         <ListItemIcon className={globalClasses.listCheckableItemCheck}>
                            //             <Check className={globalClasses.deselectedIcon}/>
                            //         </ListItemIcon>
                            //         {t('itemStatusTitle')}
                            //     </ListSubheader>
                            // }
                            disablePadding
                        >
                            { [DeviceStatus.ONLINE, DeviceStatus.OFFLINE].map(value => (
                                <ListItemButton
                                    className={globalClasses.listCheckableItemItem}
                                    key={value}
                                    dense
                                    onClick={(event) => handleFilterChange(AssetColumns.ITEM_STATUS, event.currentTarget.textContent)}
                                >
                                    <ListItemIcon className={globalClasses.listCheckableItemCheck}>
                                        { filters.find(f => f.filterKey === AssetColumns.ITEM_STATUS)?.values?.includes(t(value))
                                            ? <Check/>
                                            : <Check className={globalClasses.deselectedIcon}/>
                                        }
                                    </ListItemIcon>
                                    <span className={globalClasses.listCheckableItemText}>{t(value)}</span>
                                </ListItemButton>
                            ))}
                        </List>
                    </Menu>
                    <TablePagination
                        style={{overflow: 'unset'}}
                        page={pageIndex}
                        rowsPerPage={pageSize}
                        count={rows.length}
                        onPageChange={(_, newPageIndex) => handleChangePage(newPageIndex)}
                        onRowsPerPageChange={(event) => handleChangeLimit(event.target.value)}
                        component="div"
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('paginationOf')} ${count !== -1 ? count : `${t('paginationMoreThan')} ${to}`}`}
                        labelRowsPerPage={t('paginationRowsPerPage')}
                    />
                </div>
            </div>
            {renderTable()}
        </div>

    );
        
};

export default AssetsPage;