import React from 'react';
import { Card, CardHeader, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { getIn } from 'formik';

import useTranslation from 'hooks/useTranslation';
import AutocompleteLocality from './AutocompleteLocality';


const useStyles = makeStyles()(theme => ({
    root:{
        margin: '32px 0px 0px 0px',
    },  
}));


const AddressInput = ({ editionMode, formik }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();

    return (
        <div className={classes.root}>
            <CardHeader title={t('sharedAddress')}/>
            <Card className={classes.card}>
                <TextField 
                    required
                    id="address.street"
                    name="address.street"
                    label={t('companyStreet')}
                    disabled={!editionMode}
                    value={formik.values.address.street}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={getIn(formik.touched, 'address.street') && Boolean(getIn(formik.errors, 'address.street'))}
                    helperText={getIn(formik.touched, 'address.street') && getIn(formik.errors, 'address.street')}
                />
                <TextField 
                    required
                    id="address.houseNumber"
                    name="address.houseNumber"
                    label={t('companyHouseNumber')}
                    disabled={!editionMode}
                    value={formik.values.address.houseNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={getIn(formik.touched, 'address.houseNumber') && Boolean(getIn(formik.errors, 'address.houseNumber'))}
                    helperText={getIn(formik.touched, 'address.houseNumber') && getIn(formik.errors, 'address.houseNumber')}
                />
                <AutocompleteLocality
                    required
                    id="address.locality"
                    name="address.locality"
                    disabled={!editionMode}
                    endpoint="/api/localities"
                    formik={formik}
                />
                <TextField 
                    required
                    label={t('companyCountry')}
                    disabled
                    value={t('belgium')}
                />
            </Card>
        </div>
    );
};

export default React.memo(AddressInput);