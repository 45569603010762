import React from 'react';
import { useSelector } from 'react-redux';

import AssetLive from '../assets/AssetLive';
import { useGlobalStyles } from 'context/StylesContext';
import ContactStatus from 'components/common/ContactStatus';
import MovementStatus from 'components/common/MovementStatus';
import SignalStatus from 'components/common/SignalStatus';
import { DeviceType } from 'utils/constant';

const StatusView = ({ deviceId }) => {
    const device = useSelector(state => state.devices.items[deviceId]);
    const position = useSelector(state => state.positions.items[deviceId]);
    const geofence = useSelector(state => state.geofences.items[device.geofenceIds[0]]);
    const { classes: globalClasses } = useGlobalStyles();

    return (
        <>
            { device && position ? <>
                <div className={globalClasses.iconStatusContainer}>
                    { device.category !== DeviceType.TRAILER && <ContactStatus device={device} position={position} />}
                    <MovementStatus device={device} position={position} />
                    <SignalStatus device={device} />
                </div>
                <AssetLive 
                    item={device}
                    position={position}
                    geofence={geofence}    
                />                    
                </>
            : null}
        </>
    );
};

export default StatusView;
