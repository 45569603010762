import React, { memo, useRef, } from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    useTheme,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';
import { ColorMode } from 'utils/constant';

const useStyles = makeStyles()(theme => ({
    label: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
    }, 
    active: {
        color: theme.palette.on.main
    }, 
    inactive: {
        color: (theme.colorMode === ColorMode.BLUE) ? theme.palette.primary.main : theme.palette.off.main,
    },
}));

const ItemTable = ({ 
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows: allRows,
    prepareRow,
    children, 
}) => {
    const { classes } = useStyles();
    const rows = page ? page : allRows;

    const renderSortedDirection = (column) => {
        if(column.isSorted) {
            if(column.isSortedDesc) {
                return <ArrowDownwardIcon fontSize='small' className={classes.active} />
            } else {
                return <ArrowUpwardIcon fontSize='small' className={classes.active} />
            }
        }
        return <ArrowUpwardIcon fontSize='small' className={classes.inactive} />
    }
       
    return (
        <TableContainer style={{ flex: 1, height: '100%' }}>
            <Table stickyHeader {...getTableProps()}>
                <TableHead>
                    {headerGroups.map(headerGroup => 
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => {
                                return (
                                <TableCell
                                    {...column.getHeaderProps([
                                        column.getSortByToggleProps(),
                                        column.props || {},
                                        column.headerProps || {},
                                    ])}
                                >
                                    {column.disableSortBy 
                                    ? column.render('Header')
                                    : (<span className={classes.label} >
                                            {column.render('Header')}
                                            {renderSortedDirection(column)}
                                        </span>
                                    )}
                                </TableCell>
                            )})}
                        </TableRow>
                    )}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    { rows
                        .map((itemRow, i) => {
                            prepareRow(itemRow);
                            return children({ itemRow });
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>   
    );
};

export default memo(ItemTable, (prev, next) => {_.isEqual(prev, next)});;