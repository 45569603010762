import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { magellanV1 } from 'api/magellan';
import useTranslation from 'hooks/useTranslation';
import useAlertMessage from 'hooks/useAlertMessage';
import EditCollectionView from '../../common/EditCollectionView';
import Loading from '../../common/Loading';

const useStyles = makeStyles()(theme => ({
    columnAction: {
        width: theme.spacing(1),
        padding: theme.spacing(0, 1),
    },
}));

const StaffersView = ({ updateTimestamp, onMenuClick, displayAddButton }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);  
    const { setAlertMessage, dataLoadingErrorAlertMessage } = useAlertMessage();

    useEffect(() => {
        setLoading(true);
        magellanV1.get('/api/staffers')
            .then(response => {
                setItems(response.data);
            })
            .catch(error => {
                if(error.response.status !== 404){
                    setAlertMessage(dataLoadingErrorAlertMessage);
                }
                
            })
            .finally(() => {
                setLoading(false);
            });
    }, [updateTimestamp]);

    return <>
    <TableContainer>
    <Table>
        <TableHead>
            <TableRow>
                <TableCell>{t('sharedName')}</TableCell>
                <TableCell>{t('signageStafferIdentifier')}</TableCell>
                <TableCell>{t('signageFunction')}</TableCell>
                <TableCell className={classes.columnAction} />
            </TableRow>
        </TableHead>
        <TableBody>
            {loading 
                ? <TableRow><TableCell colSpan="100%"><Loading/></TableCell></TableRow>
                : items
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{`${item.firstname} ${item.name}`}</TableCell>
                                <TableCell>{item.uniqueId}</TableCell>
                                <TableCell>{item.function}</TableCell>
                                <TableCell className={classes.columnAction} padding="none">
                                    <IconButton
                                        color='primary'
                                        onClick={(event) => onMenuClick(event.currentTarget, item.id)}
                                        size="large">
                                        <MoreVertIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))
            }
        </TableBody>
    </Table>
    </TableContainer>
    {!loading && displayAddButton(-1, -1, true)}
    </>;
}

const StaffersPage = () => {

    return (
        <EditCollectionView content={StaffersView} forceNoLimit={true} editPath="staffer" endpoint="staffers" />
    );
}

export default StaffersPage;
