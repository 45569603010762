import React from 'react';
import { 
    Snackbar, 
} from '@mui/material';
import Alert from '@mui/material/Alert';

import useAlertMessage from 'hooks/useAlertMessage';

const AlertMessageSnackbar = () => {
    const { message, setAlertMessage } = useAlertMessage();

    return (
        <Snackbar
          open={message?.show}
          autoHideDuration={5000}
          onClose={() => {
              setAlertMessage({ 
                  show: false,
                  severity: message.severity, 
                  message: '' 
              });
          }}>
          <Alert variant="filled" severity={message.severity}>{message.message}</Alert>
        </Snackbar>
    );
};

export default AlertMessageSnackbar;