import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, IconButton, Switch } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { magellanV0, magellanV1 } from 'api/magellan';
import useTranslation from 'hooks/useTranslation';
import { prefixString } from 'utils/stringUtils';
import useAlertMessage from 'hooks/useAlertMessage';
import EditCollectionView from '../../common/EditCollectionView';
import _ from 'lodash';
import useFormat from 'hooks/useFormat';
import { useGlobalStyles } from 'context/StylesContext';
import StateInfo from 'components/common/StateInfo';
import { NotificationAdd } from '@mui/icons-material';

const useStyles = makeStyles()(theme => ({
    columnAction: {
        width: theme.spacing(1),
        padding: theme.spacing(0, 1),
    },
    contentContainer: {
        height: '75%',
    },
}));

const NotificationsView = ({ updateTimestamp, onMenuClick, displayAddButton }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const { formatEventType } = useFormat();
    const { classes: globalClasses, cx } = useGlobalStyles();
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const { setAlertMessage, dataLoadingErrorAlertMessage } = useAlertMessage();

    useEffect(() => {
        setLoading(true);
        magellanV0.get('/api/notifications')
            .then(response => {
                setItems(response.data);
            })
            .catch(error => {
                setAlertMessage(dataLoadingErrorAlertMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [updateTimestamp]);

    const formatList = (prefix, value) => {
        if (value) {
            return value
                .split(/[,]+/)
                .filter(Boolean)
                .map(it => t(prefixString(prefix, it)))
                .join(', ');
        }
        return '';
    };

    const onToggleNotification = (id, isActive) => {
        toggleSwitch(id, isActive);
        magellanV1.put(`api/notifications/${id}/toggleNotification?isActive=${isActive}`)
            .then(() => {})
            .catch(e => {
                setAlertMessage({
                    show: true,
                    severity: 'error',
                    message: t('notificationToggleError')
                });
                toggleSwitch(id, !isActive);
            });
    }

    const toggleSwitch = (id, isActive) => {
        const notifications = _.cloneDeep(items);
        const notificationIndex = notifications.findIndex(item => item.id === id);
        const notification = notifications.find(item => item.id === id);
        if(notificationIndex != -1 && notification) {
            notification.active = isActive;
            notifications[notificationIndex] = notification;
            setItems(notifications);
        }
    }

    const renderCreateFirstNotification = () => {
        if (!loading && items.length === 0) {
            return (
                <div className={cx(globalClasses.centerOfPageContainer, classes.contentContainer)}>
                    <StateInfo
                        title={t('createFirstNotificationTitle')}
                        message={t('createFirstNotificationMessage')}
                        logo={NotificationAdd}
                    />
                </div>
            );
        }
    };

    return <>
    <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>{t('signageLabel')}</TableCell>
                    <TableCell>{t('notificationType')}</TableCell>
                    <TableCell>{t('notificationNotificators')}</TableCell>
                    <TableCell>{t('notificationActive')}</TableCell>
                    <TableCell className={classes.columnAction} />
                </TableRow>
            </TableHead>
            <TableBody>
                {items
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(item => (
                        <TableRow key={item.id}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{formatEventType(item)}</TableCell>
                            <TableCell>{formatList('notificator', item.notificators)}</TableCell>
                            <TableCell><Switch checked={item.active} onChange={(e) => onToggleNotification(item.id, e.target.checked)} /></TableCell>
                            <TableCell className={classes.columnAction} padding="none">
                                <IconButton
                                    color='primary'
                                    onClick={(event) => onMenuClick(event.currentTarget, item.id)}
                                    size="large">
                                    <MoreVertIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
    { renderCreateFirstNotification() }
    {!loading && displayAddButton(-1, items && items.length, false)}
    </>;
}

const NotificationsPage = () => {
    return (
        <EditCollectionView content={NotificationsView} editPath="notification" endpoint="notifications" />
    );
}

export default NotificationsPage;
