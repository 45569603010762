import React from 'react';

import { magellanV0 } from 'api/magellan';
import withSignageLayout from 'hoc/withSignageLayout';
import { useSignage } from 'hooks/useSignage';
import SignageHeader from '../../common/SignageHeader';
import Loading from '../../common/Loading';
import GroupForm from './GroupForm';

const initialValues = {
    name: '',
    description: '',
}

const CreateGroupe = () => {
    const {editionMode, setEditionMode, createData, loading} = useSignage({ readApi: magellanV0, createApi: magellanV0, updateApi: magellanV0 }, 'groups', true, false);

    const onSubmit = (formValues) => {
        createData(formValues);
    };

    return (
        <>
            {loading
                ? <Loading/>
                : <>
                    <SignageHeader editionMode={editionMode} setEditionMode={setEditionMode} editionEnabled={true}/>
                    <GroupForm editionMode={editionMode} initialValues={initialValues} onSubmit={onSubmit}/>
                </>
            }
        </>
    );
}

export default withSignageLayout()(CreateGroupe);