import { useCallback, useMemo } from 'react';
import useTranslation from 'hooks/useTranslation';

import { DeviceType, EventType, ReportFormat } from 'utils/constant';
import { magellanV1 } from 'api/magellan';
import useReport from 'hooks/useReport';
import { useGlobalStyles } from 'context/StylesContext';
import useFormat from 'hooks/useFormat';
import useDeviceReport from 'hooks/useDeviceReport';
import { getColumns, getData } from '.';
import { useExpanded, useSortBy, useTable } from 'react-table';

const useToolsAndTrailerEventsReport = () => {
    const { t } = useTranslation();
    const { classes: globalClasses, cx } = useGlobalStyles();
    const { formatDate, formatAddressOneLine, formatDeviceCategory } = useFormat();
    const eventTypes = [EventType.BEACON_IN, EventType.BEACON_OUT];
    const availableReportActions = [ReportFormat.JSON];
    const { devices: toolsAndTrailers, query, getFiltersKey, items, setItems, ...deviceReportRest } = useDeviceReport({ devicesTakenIntoAccount: [DeviceType.TOOL, DeviceType.TRAILER], availableReportActions });
    const { ...reportRest } = useReport({
        api: magellanV1, 
        report: 'reports/events', 
        query,
        addExtraQueryParams: (query) => {eventTypes.forEach(it => query.append('type', it))}, 
        getFiltersKey,
        setItems,
    });
    const columns = useMemo(() => getColumns(globalClasses, t, cx, formatDate), []);
    const data = useMemo(() => getData(items, formatAddressOneLine, formatDeviceCategory), [JSON.stringify(items)]);
    const { 
        toggleAllRowsExpanded,
        visibleColumns, 
        ...tableRest
    } = useTable(
        { 
            columns, 
            data, 
            autoResetExpanded: false, 
            autoResetSortBy: false,
            getRowId: useCallback(row => row.allData.item.start.id, []),
        }, 
        useSortBy,
        useExpanded,
    );

    return { 
        t, 
        globalClasses, 
        cx, 
        formatDate, 
        formatAddressOneLine, 
        formatDeviceCategory, 
        toolsAndTrailers, 
        getFiltersKey, 
        availableReportActions, 
        setItems, 
        ...deviceReportRest, 
        ...reportRest, 
        ...tableRest
    };
};

export default useToolsAndTrailerEventsReport;