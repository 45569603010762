import wellknown from 'wellknown';
import circle from '@turf/circle';
import center from '@turf/center';

export const loadImage = (url) => {
    return new Promise(imageLoaded => {
        const image = new Image();
        image.onload = () => imageLoaded(image);
        image.src = url;
    });
};

export const loadIcon = async (url, size) => {
    const image = await loadImage(url);
    const canvas = document.createElement('canvas');
    canvas.width = size;
    canvas.height = canvas.width * (image.height/image.width);
    
    const context = canvas.getContext('2d');
    context.drawImage(image, 0, 0, canvas.width, canvas.height);
    return context.getImageData(0, 0, canvas.width, canvas.height);
};

export const reverseCoordinates = it => {
    if (!it) {
        return it;
    } else if (Array.isArray(it)) {
        if (it.length === 2 && !Number.isNaN(it[0]) && !Number.isNaN(it[1])) {
            return [it[1], it[0]];
        } else {
            return it.map(it => reverseCoordinates(it));
        }
    } else {
        return {
            ...it,
            coordinates: reverseCoordinates(it.coordinates),
        }
    }
}

export const polygonWktToCoordinates = (wkt) => {
    return reverseCoordinates(wkt.replace('POLYGON((', '').replace('))', '').split(',').map(c => c.split(' ').map(l => Number(l))));
}

export const coordinatesToPolygonWkt = (coordinates) => {
    coordinates = reverseCoordinates(coordinates);
    return `POLYGON((${coordinates.map(coord => coord.join(' ')).join()}))`;
}

export const geofenceToFeature = (item) => {
    if (item.area.indexOf('CIRCLE') > -1) {
        let coordinates = item.area.replace(/CIRCLE|\(|\)|,/g, " ").trim().split(/ +/);
        var options = { steps: 32, units: 'meters' };
        let polygon = circle([Number(coordinates[1]), Number(coordinates[0])], Number(coordinates[2]), options);
        return [
            { 
                type: 'Feature',
                geometry: polygon.geometry,
                properties: { name: item.name }
            },
            {
                type: 'Feature',
                geometry: center(polygon).geometry,
                properties: { name: item.name }
            }
        ];
    } else {
        let geometry = reverseCoordinates(wellknown(item.area))
        return [
            {
                type: 'Feature',
                geometry: geometry,
                properties: { name: item.name },
            },
            {
                type: 'Feature',
                geometry: center(geometry).geometry,
                properties: { name: item.name },
            }
        ];
    }
}

export const geofenceToPointer = (item) => {

}

const findBounds = (positions) => {
    const bounds = {minLon: 181, minLat: 91, maxLon: -181, maxLat: -91};
    for(const position of positions){
        if(position[0] < bounds.minLon) {
            bounds.minLon = position[0];
        }
        if(position[0] > bounds.maxLon) {
            bounds.maxLon = position[0];
        }
        if(position[1] < bounds.minLat) {
            bounds.minLat = position[1];
        }
        if(position[1] > bounds.maxLat) {
            bounds.maxLat = position[1];
        }
    }
    return [[bounds.minLon, bounds.minLat],[bounds.maxLon, bounds.maxLat]];
}

export const fitBounds = (map, positions, maxZoom = 19) => {
    let coordinates;
    if(positions.length !== 0) {
        coordinates = (positions[0].longitude !== undefined) ? positions.map(p => [p.longitude, p.latitude]): positions;
        const coordinatesToFit = findBounds(coordinates);
        map.fitBounds(coordinatesToFit, {animate: false, maxZoom, padding: {top: 100, bottom:50, left: 100, right: 100}});
    }
}

export const changeCursorToPointer = (e) => e.target.getCanvas().style.cursor = 'pointer';
export const changeCursorToNormal = (e) => e.target.getCanvas().style.cursor = '';