import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import useTranslation from 'hooks/useTranslation';
import useAlertMessage from 'hooks/useAlertMessage';
import Timetable from '../../common/Timetable';
import AddressInput from '../../common/AddressInput';
import CompanyDetails from './CompanyDetails';
import { AlertMessageSeverity } from 'utils/constant';
import { Card, CardHeader, FormControlLabel, Switch } from '@mui/material';
import { useGlobalStyles } from 'context/StylesContext';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    privacyCardHeader:{
        margin: '32px 0px 0px 0px',
    },  
}));

const CompanyForm = ({ onSubmit, initialValues, editionMode }) => {
    const { t } = useTranslation();
    const { classes, } = useStyles();
    const { classes: globalClasses } = useGlobalStyles();
    const { setAlertMessage } = useAlertMessage();
    const formik = useFormik({
        initialValues,
        validationSchema: yup.object().shape({
            name: yup.string()
                .trim()
                .required(t('validationRequired'))
                .max(128, `\${max} ${t('validationLengthMax')}`),
            legalStatus: yup.string()
                .trim()
                .max(64, `\${max} ${t('validationLengthMax')}`),
            mailAddress: yup.string()
                .trim()
                .email(t('validationEmailAddress'))
                .max(128, `\${max} ${t('validationLengthMax')}`),
            phoneNumber: yup.string()
                .trim()
                .max(32, `\${max} ${t('validationLengthMax')}`)
                .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g, t('validationPhoneNumber')),
            isPrivateOutsideWorkingHours: yup.boolean()
                .required(t('validationRequired')),
            address: yup.object().shape({
                street: yup.string().trim().required(t('validationRequired')).max(128, `\${max} ${t('validationLengthMax')}`),
                houseNumber: yup.string().trim().required(t('validationRequired')).max(16, `\${max} ${t('validationLengthMax')}`),
            }),
            calendar: yup.object().shape({
                mondayStartHour : yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate')),
                mondayEndHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate')),
                tuesdayStartHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate')),
                tuesdayEndHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate')),
                wednesdayStartHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate')),
                wednesdayEndHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate')),
                thursdayStartHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate')),
                thursdayEndHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate')),
                fridayStartHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate')),
                fridayEndHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate')),
                saturdayStartHour : yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate')),
                saturdayEndHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate')),
                sundayStartHour : yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate')),
                sundayEndHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate')),
            })
        }),
        onSubmit
    });
    
    return(
        <form id="form" onSubmit={e => {
            e.preventDefault();
            formik.submitForm();
        }}>
            <CompanyDetails 
                formik={formik} 
                editionMode={editionMode} 
                setWarn={(message) => setAlertMessage({show: true, severity: AlertMessageSeverity.WARNING, message})}
            />
            <CardHeader className={classes.privacyCardHeader} title={t('companyPrivacy')} />
            <Card className={globalClasses.switchCard} >
                <FormControlLabel
                    label={t('companyIsPrivateOutsideWorkingHours')} 
                    disabled={!editionMode}
                    style={{
                        display: "flex",
                        width: '100%',
                        justifyContent: "space-between",
                        marginLeft: '0px',
                    }}
                    control={<Switch name='isPrivateOutsideWorkingHours' checked={formik.values.isPrivateOutsideWorkingHours} onChange={formik.handleChange} />}
                    labelPlacement="start"
                />
            </Card>
            <AddressInput 
                editionMode={editionMode} 
                formik={formik}
            />
            <CardHeader title={t('timetable')}/>
            <Card className={globalClasses.timetableCard}>
                <Timetable 
                    formik={formik}
                    editionMode={editionMode}
                    offMessage={t('companyClosed')}
                />
            </Card>
        </form>
    );
  };

  export default CompanyForm;