import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import ValidationTimesheetPage from '../components/timesheet/validation/TimesheetValidationPage';
import TimesheetReportPage from 'components/timesheet/history/TimesheetReportPage';
import PresencePage from 'components/timesheet/presence/PresencePage';
import { UserAndManagerEnabled } from '../authorization';

const ReportsRoutes = () => {
    const ValidationTimesheetPageForUserAndManager = UserAndManagerEnabled(ValidationTimesheetPage);
    const TimesheetReportPageForUserAndManager = UserAndManagerEnabled(TimesheetReportPage);
    const PresencePageForUserAndManager = UserAndManagerEnabled(PresencePage);
    
    return (
        <Routes>
            <Route exact path="validation" element={<ValidationTimesheetPageForUserAndManager/>}/>
            <Route exact path="history" element={<TimesheetReportPageForUserAndManager/>}/>
            <Route exact path="presence" element={<PresencePageForUserAndManager/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    )
}

export default ReportsRoutes;