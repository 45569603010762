import { Text } from '@react-pdf/renderer';
import React from 'react';

import { styles } from './sharedPdfReportStyles';

const NonePdf = ({ text, t }) => {
    let none = text || t('none');

    return <Text style={styles.none}>{none}</Text>;
};

export default NonePdf;