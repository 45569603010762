import React from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Card, CardHeader, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as EngineIcon } from 'resources/engine.svg'; 
import useTranslation from 'hooks/useTranslation';
import { ConstructionMachineType, DeviceType, EmissionStandard } from 'utils/constant';
import SelectTextField from '../common/SelectTextField';
import AccumulatorDialog from './AccumulatorDialog';
import BinaryChoiceCommand from './BinaryChoiceCommand';
import useCommands from 'hooks/useCommands';
import { HealthAndSafety as SafeIcon } from '@mui/icons-material';


const useStyles = makeStyles()(theme => ({  
    commentCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
}));

const DeviceForm = ({ onSubmit, initialValues, validationSchema, deviceType, editionMode }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    });
    const { startOFFCommand, startONCommand, antiHackingONCommand, antiHackingOFFCommand, sendCommand } = useCommands(initialValues?.id);

    const position = useSelector(state => (initialValues && initialValues.id) ? state.positions.items[initialValues.id] : null,
        (prevPosition, nextPosition) => prevPosition && nextPosition && prevPosition.id === nextPosition.id);

    return <>
        <form id="form" onSubmit={e => {
            e.preventDefault();
            formik.submitForm();
        }}>
            <CardHeader title={t('signageProperties')} />
            <Card>
                <TextField 
                    required
                    id="name"
                    name="name"
                    label={t('signageLabel')}
                    disabled={!editionMode}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
                <TextField 
                    required
                    id="uniqueId"
                    name="uniqueId"
                    label={t('signageTrackerIdentifier')}
                    disabled={!editionMode}
                    value={formik.values.uniqueId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.uniqueId && Boolean(formik.errors.uniqueId)}
                    helperText={formik.touched.uniqueId && formik.errors.uniqueId}
                />
                { (deviceType === DeviceType.VEHICLE || deviceType === DeviceType.CONSTRUCTION_MACHINE || deviceType === DeviceType.SMARTPHONE) &&
                    <TextField 
                        id="licensePlate"
                        name="licensePlate"
                        label={t('signageLicensePlate')}
                        disabled={!editionMode}
                        value={formik.values.licensePlate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.licensePlate && Boolean(formik.errors.licensePlate)}
                        helperText={formik.touched.licensePlate && formik.errors.licensePlate}
                    />
                }
            </Card>
            <CardHeader title={t('signageGroup')} />
            <Card>
                <SelectTextField 
                    id="groupId" 
                    name="groupId" 
                    classes={{width: '45%'}}
                    label={t('signageGroup')} 
                    value={formik.values.groupId}
                    disabled={!editionMode} 
                    endpoint="/api/groups"
                    valuePropertyName="id"
                    namePropertyLabel="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.groupId && Boolean(formik.errors.groupId)}
                    helperText={formik.touched.groupId && formik.errors.groupId}
                />   
            </Card>
            <CardHeader title={t('signageCharacteristics')} />
            <Card>
                { deviceType === DeviceType.CONSTRUCTION_MACHINE &&
                    <SelectTextField 
                        id="constructionMachineType" 
                        name="constructionMachineType" 
                        label={t('signageConstructionMachineType')} 
                        value={formik.values.constructionMachineType}
                        disabled={!editionMode} 
                        options={ConstructionMachineType}
                        formatLabel={l => t(l)}
                        valuePropertyName="id"
                        namePropertyLabel="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.groupId && Boolean(formik.errors.groupId)}
                        helperText={formik.touched.groupId && formik.errors.groupId}
                    />
                }
                <TextField 
                    id="brand"
                    name="brand"
                    label={t('signageBrand')}
                    disabled={!editionMode}
                    value={formik.values.brand}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.brand && Boolean(formik.errors.brand)}
                    helperText={formik.touched.brand && formik.errors.brand}
                />
                <TextField 
                    id="model"
                    name="model"
                    label={t('signageModel')}
                    disabled={!editionMode}
                    value={formik.values.model}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.model && Boolean(formik.errors.model)}
                    helperText={formik.touched.model && formik.errors.model}
                />
                { (deviceType === DeviceType.VEHICLE || deviceType === DeviceType.CONSTRUCTION_MACHINE) &&
                    <TextField 
                        id="fuelType"
                        name="fuelType"
                        label={t('signageFuelType')}
                        disabled={!editionMode}
                        value={formik.values.fuelType}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.fuelType && Boolean(formik.errors.fuelType)}
                        helperText={formik.touched.fuelType && formik.errors.fuelType}
                    />
                }
                { (deviceType === DeviceType.VEHICLE || deviceType === DeviceType.CONSTRUCTION_MACHINE) &&
                    <TextField 
                        id="chassisNumber"
                        name="chassisNumber"
                        label={t('signageChassisNumber')}
                        disabled={!editionMode}
                        value={formik.values.chassisNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.chassisNumber && Boolean(formik.errors.chassisNumber)}
                        helperText={formik.touched.chassisNumber && formik.errors.chassisNumber}
                    />
                }
                { deviceType === DeviceType.VEHICLE &&
                    <SelectTextField 
                        id="emissionStandard" 
                        name="emissionStandard" 
                        label={t('signageEmissionStandard')} 
                        value={formik.values.emissionStandard}
                        disabled={!editionMode} 
                        options={EmissionStandard}
                        formatLabel={l => t(l)}
                        valuePropertyName="id"
                        namePropertyLabel="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.emissionStandard && Boolean(formik.errors.emissionStandard)}
                        helperText={formik.touched.emissionStandard && formik.errors.emissionStandard}
                    />
                }
                {/* { (deviceType === DeviceType.VEHICLE || deviceType === DeviceType.CONSTRUCTION_MACHINE) &&
                    <TextField 
                        id="maxSpeed"
                        name="maxSpeed"
                        label={t('signageMaxSpeed')}
                        disabled={!editionMode}
                        value={formik.values.maxSpeed}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.maxSpeed && Boolean(formik.errors.maxSpeed)}
                        helperText={formik.touched.maxSpeed && formik.errors.maxSpeed}
                    />
                } */}
                { (deviceType === DeviceType.VEHICLE || deviceType === DeviceType.CONSTRUCTION_MACHINE) && position &&
                    <AccumulatorDialog 
                        id={initialValues.id} 
                        position={position}
                        disabled={!editionMode}
                        deviceType={deviceType}
                    />
                }  
            </Card>
            <CardHeader title={t('signageComment')} />
            <Card className={classes.commentCard}>
                    <TextField 
                        id="comment"
                        name="comment"
                        label={t('signageComment')}
                        multiline
                        rows={10}
                        maxRows={35}
                        fullWidth={true} 
                        disabled={!editionMode}
                        value={formik.values.comment}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.comment && Boolean(formik.errors.comment)}
                        helperText={formik.touched.comment && formik.errors.comment}
                    />         
            </Card>
            { (deviceType === DeviceType.VEHICLE || deviceType === DeviceType.CONSTRUCTION_MACHINE) && 
                    initialValues.id && 
                    position?.attributes?.[startONCommand?.positionAttribute] !== undefined && 
                    startONCommand !== undefined &&
                    startOFFCommand !== undefined &&
                <BinaryChoiceCommand 
                    sendCommand={sendCommand} 
                    commandON={startONCommand} 
                    commandOFF={startOFFCommand}
                    reverseButton={true} 
                    title={t('commandStartTitle')}
                    currentStatus={!position.attributes?.[startONCommand.positionAttribute]}
                    statusONMessage={t('commandStartONMessage')}
                    statusOFFMessage={t('commandStartOFFMessage')}
                    statusONIcon={EngineIcon}
                    statusOFFIcon={EngineIcon}
                    statusONLoadingMessage={t('commandStartONLoading')}
                    statusOFFLoadingMessage={t('commandStartOFFLoading')}
                    sendCommandONWarningMessage={t('sendCommandStartONWarningMessage')}
                    sendCommandOFFWarningMessage={t('sendCommandStartOFFWarningMessage')}
                    buttonONLabel={t('commandStartON')}
                    buttonOFFLabel={t('commandStartOFF')}
                />
            }
            { (deviceType === DeviceType.VEHICLE || deviceType === DeviceType.CONSTRUCTION_MACHINE) && 
                    initialValues.id && 
                    position?.attributes?.[antiHackingONCommand?.positionAttribute] !== undefined && 
                    antiHackingONCommand !== undefined &&
                    antiHackingOFFCommand !== undefined &&
                <BinaryChoiceCommand 
                    sendCommand={sendCommand} 
                    commandON={antiHackingONCommand} 
                    commandOFF={antiHackingOFFCommand} 
                    title={t('commandAntiHackingTitle')}
                    currentStatus={!position.attributes?.[antiHackingONCommand.positionAttribute]}
                    statusONMessage={t('commandAntiHackingONMessage')}
                    statusOFFMessage={t('commandAntiHackingOFFMessage')}
                    statusONIcon={SafeIcon}
                    statusOFFIcon={SafeIcon}
                    statusONLoadingMessage={t('commandAntiHackingONLoading')}
                    statusOFFLoadingMessage={t('commandAntiHackingOFFLoading')}
                    sendCommandONWarningMessage={t('sendCommandAntiHackingONWarningMessage')}
                    sendCommandOFFWarningMessage={t('sendCommandAntiHackingOFFWarningMessage')}
                    buttonONLabel={t('commandAntiHackingON')}
                    buttonOFFLabel={t('commandAntiHackingOFF')}
                />
            }
        </form>
    </>;
};

export default DeviceForm;