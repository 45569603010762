import React, { useEffect, useState, useRef } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import { Card, CardHeader, FormControlLabel, Switch, Tooltip, TextField, CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { makeStyles } from 'tss-react/mui';

import { magellanV1 } from 'api/magellan';
import useTranslation from 'hooks/useTranslation';
import useValidateField from 'hooks/useValidateField';
import ExpirationDateInput from './ExpirationDateInput';
import GroupManager from './ItemsLinked';
import useGroupManager from 'hooks/useGroupManager';
import { useGlobalStyles } from 'context/StylesContext';

const useStyles = makeStyles()(theme => ({
    root:{
        margin: '32px 0px 0px 0px',
    },
}));

const UserForm = ({ onSubmit, initialValues, managerEnabled, isCreation, editionMode, id, currentUserId, validationPassword }) => {
    const { t } = useTranslation();
    const { classes, theme } = useStyles();
    const { classes: globalClasses } = useGlobalStyles();
    const validationSchemaBase = yup.object().shape({
        name: yup.string()
            .trim()
            .required(t('validationRequired'))
            .max(128, `\${max} ${t('validationLengthMax')}`),
        email: yup.string()
            .trim()
            .required(t('validationRequired'))
            .email(t('validationEmailAddress'))
            .max(128, `\${max} ${t('validationLengthMax')}`),
        expirationTime: yup.string()
            .notOneOf(['Invalid Date'], t('validationDate'))
            .nullable(),
    });
    const validationSchema = validationSchemaBase.shape(validationPassword);
    const [loadingParentSize, setLoadingParentSize] = useState(0);
    const loadingParentRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema,
        onSubmit: (values) => {
            if(values.email !== formik.initialValues.email) {
                setLoading(true);
                magellanV1.get('/api/users/checkEmailAlreadyInUse', { params: { email: values.email }})
                        .then(() => {
                            onSubmit(values);
                        })
                        .catch(duplicateEmailError => {
                            if(duplicateEmailError.response.status === 409) {
                                formik.setFieldError('email', t('validationEmailAddressAlreadyUsed'));
                            }
                        });
            } else {
                onSubmit(values);
            }
        },
    });
    const { groups, handleChangeGroups } = useGroupManager({ 
        id,
        endpoint:'user',
        formik,
        allCheckedByDefault: true,
    });
    useValidateField(formik, 'name', validationSchema);
    useValidateField(formik, 'password', validationSchema);
    
    useEffect(() => {
        const { clientHeight, clientWidth } = loadingParentRef.current;
    
        setLoadingParentSize(Math.min(clientHeight, clientWidth));
    }, []);

    useEffect(() => {
        let unmounted = false;
        let timeoutId;
        let source = axios.CancelToken.source();
        validationSchema.validateAt('email', formik.values)
            .then(() => {
                if(formik.values.email !== formik.initialValues.email) {
                    setLoading(true);
                    
                    timeoutId = setTimeout(() =>  {
                        magellanV1.get('/api/users/checkEmailAlreadyInUse', { params: { email: formik.values.email }, cancelToken: source.token})
                            .then(() => {
                                if(!unmounted){
                                    formik.setFieldError('email', undefined);
                                }
                            })
                            .catch(error => {
                                if(!axios.isCancel(error)) {
                                    if(error?.response?.status === 409) {
                                        formik.setFieldError('email', t('validationEmailAddressAlreadyUsed'));
                                    }
                                }
                                
                            })
                            .finally(() => {
                                setLoading(false);
                            });
                    }, 500);                   
                } else {
                    formik.setFieldError('email', undefined);
                    setLoading(false);
                }
            })
            .catch(yupError=>{
                formik.setFieldError('email', yupError.message);
            });


            return () => {
                unmounted = true;
                source.cancel("Cancelling in cleanup");
                if(timeoutId) {
                    clearTimeout(timeoutId);
                }
                setLoading(false);
            }
    }, [formik.values.email]);

    const renderEmailEndAdornment = () => {
        if(editionMode) {
            if(formik.errors.email === t('validationEmailAddressAlreadyUsed')) {
                return <ErrorIcon style={{ color: theme.palette.error.main }}/>
            } else if(loading) {
                return <CircularProgress color="secondary" size={0.8 * loadingParentSize}/>
            } else  if(!formik.errors.email){
                return <CheckCircleIcon/>
            } 
        }
    };

    return (
        <form id="form" onSubmit={e => {
            e.preventDefault();
            formik.submitForm();
        }}>
            <CardHeader title={t('signageProperties')} />
            <Card>
                <TextField 
                    required
                    id="name"
                    name="name"
                    label={t('userName')}
                    disabled={!editionMode}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
                <TextField 
                    required
                    id="email"
                    name="email"
                    label={t('userEmail')}
                    disabled={!editionMode}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                        endAdornment: renderEmailEndAdornment() 
                    }}
                    inputRef={loadingParentRef}
                    error={(formik.touched.email && Boolean(formik.errors.email)) || formik.errors.email === t('validationEmailAddressAlreadyUsed')}
                    helperText={
                        (formik.touched.email && formik.errors.email) || 
                        (formik.errors.email === t('validationEmailAddressAlreadyUsed') && t('validationEmailAddressAlreadyUsed'))}
                />
                <TextField 
                    required={isCreation}
                    id="password"
                    name="password"
                    type="password"
                    label={t('userPassword')}
                    disabled={!editionMode}
                    autoComplete="new-password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                />
            </Card>
            {(managerEnabled || isCreation || id === currentUserId) && 
                <>
                    <CardHeader title={t('signageGroups')} />
                    <Card className={globalClasses.switchCard}>
                        <GroupManager
                            disabled={!editionMode || (!isCreation && id === currentUserId)}
                            items={groups}
                            handleChange={handleChangeGroups}
                        />
                    </Card>
                </>
            }
            {managerEnabled && (id !== currentUserId || isCreation) &&
                <>
                   
                    <CardHeader title={t('signageSecurity')} />
                    <Card className={globalClasses.switchCard}>
                    <Tooltip  title={t('userDisabledExplanation')} placement="left" arrow>
                            <FormControlLabel
                                label={t('sharedDisabled')} 
                                disabled={!editionMode}
                                style={{
                                    display: "flex",
                                    width: '100%',
                                    justifyContent: "space-between",
                                    marginLeft: '0px',
                                }}
                                control={<Switch name='disabled' checked={formik.values.disabled} onChange={formik.handleChange} />}
                                labelPlacement="start"
                            />
                        </Tooltip>
                        <Tooltip  title={t('userReadonlyExplanation')} placement="left" arrow>
                            <FormControlLabel
                                label={t('userReadonly')} 
                                disabled={!editionMode}
                                style={{
                                    display: "flex",
                                    width: '100%',
                                    justifyContent: "space-between",
                                    marginTop: '0px',
                                    marginLeft: '0px',
                                }}
                                control={<Switch name='readonly' checked={formik.values.readonly} onChange={formik.handleChange} />}
                                labelPlacement="start"
                            />
                        </Tooltip>
                        <ExpirationDateInput
                            name="expirationTime" 
                            label={t('userExpirationTime')} 
                            tooltip={t('userExpirationTimeExplanation')}
                            disabled={!editionMode}
                            formik={formik}
                        />
                    </Card>
                </>
            }
        </form>
    );
};

export default UserForm;