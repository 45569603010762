import React, { createContext, useContext, useState } from 'react';

const PinnedContext = createContext(null);

const usePinned = () => {
    const [pinned, setPinned] = useContext(PinnedContext);

    return [pinned, setPinned];
}

const PinnedProvider = ({ children }) => {
    const [pinned, setPinned] = useState(true);
 
    return (
        <PinnedContext.Provider value={[pinned, setPinned]}>
            {children}
        </PinnedContext.Provider>
    );
};

export { PinnedProvider, usePinned };