import React from 'react';
import { Tooltip } from '@mui/material';

import { useGlobalStyles } from 'context/StylesContext';
import useTranslation from 'hooks/useTranslation';
import { MotionStatus } from 'utils/constant';
import { ReactComponent as CarIcon } from 'resources/car.svg';
import { ReactComponent as SmartphoneIcon } from 'resources/smartphone.svg';
import { ReactComponent as ConstructionMachineIcon } from 'resources/construction_machine.svg';
import { ReactComponent as ToolIcon } from 'resources/tool.svg';
import { ReactComponent as TrailerIcon } from 'resources/trailer.svg';
import { ReactComponent as WarehouseIcon } from 'resources/warehouse.svg';
import { defineMotionStatusByType } from 'utils/motionStatus';


const MovementStatus = ({ device, position, ...props }) => {
    const { t } = useTranslation();
    const { classes, theme } = useGlobalStyles();

    if(position && device){
        const motionStatus = defineMotionStatusByType(position, device);
        switch(motionStatus){
            case MotionStatus.SMARTPHONE_ON:  
                return <Tooltip placement="top" title={`${device.name} ${t('deviceIsMovingMessage')}`}><SmartphoneIcon fill={theme.palette.on.main} stroke={theme.palette.on.main} className={classes.iconStatus} {...props} /></Tooltip>;
            case MotionStatus.SMARTPHONE_OFF: 
                return <Tooltip placement="top" title={`${device.name} ${t('deviceStoppedMessage')}`}><SmartphoneIcon fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.iconStatus} {...props} /></Tooltip>;
            case MotionStatus.VEHICLE_ON:
                return <Tooltip placement="top" title={`${device.name} ${t('deviceIsMovingMessage')}`}><CarIcon fill={theme.palette.on.main} stroke={theme.palette.on.main} className={classes.iconStatus} {...props} /></Tooltip>;
            case MotionStatus.VEHICLE_OFF: 
                return <Tooltip placement="top" title={`${device.name} ${t('deviceStoppedMessage')}`}><CarIcon fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.iconStatus} {...props} /></Tooltip>;
            case MotionStatus.VEHICLE_UNKNOWN:
                return <Tooltip placement="top" title={`${device.name} ${t('deviceUnknownMotionMessage')}`}><CarIcon fill={theme.palette.unknown.main} stroke={theme.palette.unknown.main} className={classes.iconStatus} {...props} /></Tooltip>;
            case MotionStatus.CONSTRUCTION_MACHINE_ON:
                return <Tooltip placement="top" title={`${device.name} ${t('deviceIsMovingMessage')}`}><ConstructionMachineIcon fill={theme.palette.on.main} stroke={theme.palette.on.main} className={classes.iconStatus} {...props} /></Tooltip>;
            case MotionStatus.CONSTRUCTION_MACHINE_OFF:
                return <Tooltip placement="top" title={`${device.name} ${t('deviceStoppedMessage')}`}><ConstructionMachineIcon fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.iconStatus} {...props} /></Tooltip>;
            case MotionStatus.CONSTRUCTION_MACHINE_UNKNOWN:
                return <Tooltip placement="top" title={`${device.name} ${t('deviceUnknownMotionMessage')}`}><ConstructionMachineIcon fill={theme.palette.unknown.main} stroke={theme.palette.unknown.main} className={classes.iconStatus} {...props} /></Tooltip>;
            case MotionStatus.TOOL_ON:
                return <Tooltip placement="top" title={`${device.name} ${t('deviceIsMovingMessage')}`}><ToolIcon fill={theme.palette.on.main} stroke={theme.palette.on.main} className={classes.iconStatus} {...props} /></Tooltip>;
            case MotionStatus.TOOL_OFF:
                return <Tooltip placement="top" title={`${device.name} ${t('deviceStoppedMessage')}`}><ToolIcon fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.iconStatus} {...props} /></Tooltip>;
            case MotionStatus.TOOL_UNKNOWN:
                return <Tooltip placement="top" title={`${device.name} ${t('deviceUnknownMotionMessage')}`}><ToolIcon fill={theme.palette.unknown.main} stroke={theme.palette.unknown.main} className={classes.iconStatus} {...props} /></Tooltip>;
            case MotionStatus.TRAILER_ON:
                return <Tooltip placement="top" title={`${device.name} ${t('deviceIsMovingMessage')}`}><TrailerIcon fill={theme.palette.on.main} stroke={theme.palette.on.main} className={classes.iconStatus} {...props} /></Tooltip>;
            case MotionStatus.TRAILER_OFF:
                return <Tooltip placement="top" title={`${device.name} ${t('deviceStoppedMessage')}`}><TrailerIcon fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.iconStatus} {...props} /></Tooltip>;
            case MotionStatus.TRAILER_UNKNOWN:
                return <Tooltip placement="top" title={`${device.name} ${t('deviceUnknownMotionMessage')}`}><TrailerIcon fill={theme.palette.unknown.main} stroke={theme.palette.unknown.main} className={classes.iconStatus} {...props} /></Tooltip>;
            case MotionStatus.WAREHOUSE_OFF:
                return <Tooltip placement="top" title={`${device.name} ${t('deviceStoppedMessage')}`}><WarehouseIcon fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.iconStatus} {...props} /></Tooltip>;
            case MotionStatus.WAREHOUSE_UNKNOWN:
                return <Tooltip placement="top" title={`${device.name} ${t('deviceUnknownMotionMessage')}`}><WarehouseIcon fill={theme.palette.unknown.main} stroke={theme.palette.unknown.main} className={classes.iconStatus} {...props} /></Tooltip>;
            default:
                return <Tooltip placement="top" title={`${device.name} ${t('deviceUnknownMotionMessage')}`}><CarIcon fill={theme.palette.unknown.main} stroke={theme.palette.unknown.main} className={classes.iconStatus} {...props} /></Tooltip>;
        }
    }
    return <CarIcon fill="transparent" stroke="transparent" className={classes.iconStatus} {...props} />;
};

export default MovementStatus;