import React from 'react';
import axios from 'axios';
import _ from 'lodash';

import {magellanV0, magellanV1} from 'api/magellan';
import withSignageLayout from 'hoc/withSignageLayout';
import { useSignage } from 'hooks/useSignage';
import useAlertMessage from 'hooks/useAlertMessage';
import Loading from '../../common/Loading';
import SignageHeader from '../../common/SignageHeader';
import GeofenceForm from './GeofenceForm';

const filterDataFromServer = (data) => {
    data.groups = {};
    return data;
}

const UpdateGeofence = ({ filterDataForServer, role, showGoBack=true }) => {
    const {editionMode, setEditionMode, item, id, loading, setLoading, isReadonly, setItem} = useSignage({ readApi: magellanV1, createApi: magellanV0, updateApi: magellanV0 }, 'geofences', false, true, filterDataFromServer);
    const { setAlertMessage, savedAlertMessage, updateErrorAlertMessage } = useAlertMessage();

    const onSubmit = (formValues) => {
        setLoading(true);
        Promise.all([
            magellanV1.put(`/api/geofences/${id}`, filterDataForServer(_.cloneDeep(formValues))),
            formValues?.groups?.groupToUnlink?.map(g => magellanV0.delete('/api/permissions', {data:{groupId : g, geofenceId : id}})),
            formValues?.groups?.groupToLink?.map(g => magellanV0.post('/api/permissions', {groupId : g, geofenceId : id}))
        ])
            .then(res => {
                setAlertMessage(savedAlertMessage);
                setItem(formValues);
                setEditionMode(false);
            })
            .catch(error => {
                setAlertMessage(updateErrorAlertMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading
                ? <Loading/>
                : <>
                    <SignageHeader 
                        editionMode={editionMode} 
                        setEditionMode={setEditionMode} 
                        editionEnabled={!isReadonly}
                        showGoBack={showGoBack}
                        title={item && item.name}
                    />
                    <GeofenceForm 
                        editionMode={editionMode} 
                        onSubmit={onSubmit} 
                        initialValues={item} 
                        id={Number(id)} 
                    />
                </>
            } 
        </>
    );
}

export default withSignageLayout()(UpdateGeofence);