import React from 'react';
import {
    TableCell,
    TableRow,
} from '@mui/material';
import { useGlobalStyles } from 'context/StylesContext';

const ItemRow = ({ itemRow, children, onClick = () => {}, rowProps = {}, padding="normal" }) => {
    const { classes: globalClasses, cx } = useGlobalStyles();

    return(
        <>
            <TableRow 
                {...itemRow.getRowProps({ className: cx('summaryRow', itemRow.isExpanded ? globalClasses.selectedItem : null), ...rowProps })}
                onClick={onClick}
            >
                {itemRow.cells.map(cell => {
                        return (
                            <TableCell 
                                {...cell.getCellProps(cell.column.props || {}, cell.column.cellProps || {})}
                                padding={padding}
                            >
                                {cell.render('Cell')}
                            </TableCell>
                        );
                    })
                }
            </TableRow>
            { itemRow.isExpanded && children && children({ }) }
        </>
    );
};

export default ItemRow;