import React from 'react';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { Collapse, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';

import { notificationsActions } from 'store';
import EventItem from './EventItem';

const useStyles = makeStyles()(theme => ({
    root: {
        height: '100%', 
        overflow: 'auto',
    },
    divider: {
        height: '0.1px',
        opacity: 0.1
    },
}));

const NotificationsHistory = () => {
    const { classes } = useStyles();
    const notifications = useSelector(state => Object.values(state.notifications.items), (prev, next) => JSON.stringify(prev) === JSON.stringify(next));
    const selectedId = useSelector(state => state.notifications.selectedId);
    const dispatch = useDispatch();

    return (
        <TransitionGroup className={classes.root}>
            {_.cloneDeep(notifications).reverse().map((notification, iNotification) => (
                <Collapse key={notification.id}>
                    { iNotification > 0  && <Divider className={classes.divider}/>}
                    <EventItem
                        event={notification}
                        isSelected={selectedId === notification.id}
                        onClose={() => dispatch(notificationsActions.removeFromHistory(notification.id))}
                        selectItem={(newSelectedId) => dispatch(notificationsActions.select(newSelectedId))}
                        deselectItem={() => dispatch(notificationsActions.deselect())}
                    />
                </Collapse>
            ))}
        </TransitionGroup>
    );
};

export default NotificationsHistory;