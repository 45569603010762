import { useState } from "react";

const useMenuAnchor = () => {
    const [menuAnchor, setMenuAnchor] = useState(null);

    const handleClick = (event) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchor(null);
    };

    return { menuAnchor, setMenuAnchor, handleMenuOpen: handleClick, handleMenuClose: handleClose };
};

export default useMenuAnchor;