import React from 'react';
import { useFormik } from 'formik';
import { Card, CardHeader, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import useTranslation from 'hooks/useTranslation';
import SelectTextField from 'components/common/SelectTextField';
import AddressInput from 'components/common/AddressInput';
import PickCoordinatesOnMap from '../PickCoordinatesOnMap';
import useGeocoding from 'hooks/useGeocoding';

const useStyles = makeStyles()(theme => ({  
    commentCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
}));

const WarehouseForm = ({ onSubmit, editionMode, initialValues }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const formik = useFormik({
        initialValues,
        validationSchema: yup.object().shape({
            name: yup.string()
                .trim()
                .required(t('validationRequired'))
                .max(128, `\${max} ${t('validationLengthMax')}`),
            uniqueId: yup.string()
                .trim()
                    .required(t('validationRequired'))
                    .max(128, `\${max} ${t('validationLengthMax')}`),
            comment: yup.string()
                .trim()
                .max(4000, `\${max} ${t('validationLengthMax')}`),
            address: yup.object().shape({
                street: yup.string().trim().required(t('validationRequired')).max(128, `\${max} ${t('validationLengthMax')}`),
                houseNumber: yup.string().trim().required(t('validationRequired')).max(16, `\${max} ${t('validationLengthMax')}`),
            }),
        }),
        onSubmit
    });
    const { addressLoading, } = useGeocoding({
        street: formik?.values?.address?.street,
        locality: formik?.values?.address?.locality?.name,
        houseNumber: formik?.values?.address?.houseNumber,
        setCoordinates: ({ latitude, longitude }) => {
            formik.setFieldValue('address.latitude', latitude);
            formik.setFieldValue('address.longitude', longitude);
        },
    });

    return <>
        <form id="form" onSubmit={e => {
            e.preventDefault();
            formik.submitForm();
        }}>
            <CardHeader title={t('signageProperties')} />
            <Card>
                <TextField 
                    required
                    id="name"
                    name="name"
                    label={t('signageLabel')}
                    disabled={!editionMode}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
                <TextField 
                    required
                    id="uniqueId"
                    name="uniqueId"
                    label={t('signageTrackerIdentifier')}
                    disabled={!editionMode}
                    value={formik.values.uniqueId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.uniqueId && Boolean(formik.errors.uniqueId)}
                    helperText={formik.touched.uniqueId && formik.errors.uniqueId}
                />
            </Card>
            <CardHeader title={t('signageGroup')} />
            <Card>
                <SelectTextField 
                    id="groupId" 
                    name="groupId" 
                    classes={{width: '45%'}}
                    label={t('signageGroup')} 
                    value={formik.values.groupId}
                    disabled={!editionMode} 
                    endpoint="/api/groups"
                    valuePropertyName="id"
                    namePropertyLabel="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.groupId && Boolean(formik.errors.groupId)}
                    helperText={formik.touched.groupId && formik.errors.groupId}
                />   
            </Card>
            <AddressInput
                editionMode={editionMode}
                formik={formik}
            />
            <PickCoordinatesOnMap 
                editionMode={editionMode}
                loading={addressLoading}
                coordinates={{latitude: formik.values.address.latitude, longitude: formik.values.address.longitude}}
                setCoordinates={({ latitude, longitude }) => {
                    formik.setFieldValue('address.latitude', latitude);
                    formik.setFieldValue('address.longitude', longitude);
                }}
            />
            <CardHeader title={t('signageComment')} />
            <Card className={classes.commentCard}>
                    <TextField 
                        id="comment"
                        name="comment"
                        label={t('signageComment')}
                        multiline
                        rows={10}
                        maxRows={35}
                        fullWidth={true} 
                        disabled={!editionMode}
                        value={formik.values.comment}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.comment && Boolean(formik.errors.comment)}
                        helperText={formik.touched.comment && formik.errors.comment}
                    />         
            </Card>
        </form>
    </>;
};

export default WarehouseForm;