import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Button, TextField, Typography } from '@mui/material';

import { magellanV0NotIntercept401 } from 'api/magellan';
import { AuthenticationContext } from 'context/AuthenticationContext';
import { useGlobalStyles } from 'context/StylesContext';
import useTranslation from 'hooks/useTranslation';
import { sessionActions } from 'store';
import { LoginFailedCode } from 'utils/constant';

const useStyles = makeStyles()(theme => ({
    helperText: {
        color: theme.palette.error.main,
    },
}));

const Login = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { classes } = useStyles();
    const { classes: globalClasses } = useGlobalStyles();
    const navigate = useNavigate();
    const {authenticated, setAuthenticated} = useContext(AuthenticationContext);

    useEffect(() => {
        if(authenticated){
            navigate('../../app/home', { replace: true });
        }
    }, [authenticated, navigate]);
  
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleLogin = async (event) => {
        event.preventDefault();
        const sessionRequest = await magellanV0NotIntercept401.post('/api/session', new URLSearchParams(`email=${email}&password=${password}`))
            .catch(error => {
                if(error.response.status === 401) {
                    switch(error.response.data.code) {
                        case LoginFailedCode.ACCOUNT_DISABLED:
                            setError(t('loginDisabledAccount'));
                            break;
                        case LoginFailedCode.ACCOUNT_EXPIRED:
                            setError(t('loginExpiredAccount'));
                            break;
                        case LoginFailedCode.INVALID_AUTHENTICATION_TOKENS:
                        default: 
                            setError(t('loginIncorrect'));
                    }
                } else if(error.response.status === 402) {
                    if(error.response.data.code === LoginFailedCode.USER_COMPANY_DISABLED) {
                        setError(t('loginDisabledAccount'));
                    } else {
                        setError(t('loginExpiredLicense'));
                    }
                } else {
                    setError('try again later');
                }
                
                setPassword('');
            });
        const serverRequest = await magellanV0NotIntercept401.get('/api/server').catch(error => { });
        if(sessionRequest && serverRequest){
            Promise.all([sessionRequest, serverRequest])
                .then(([sessionResponse, serverResponse]) => {
                    const user = sessionResponse.data;
                    const server = serverResponse.data;
                    dispatch(sessionActions.updateUser(user));
                    dispatch(sessionActions.updateServer(server));
                    localStorage.setItem('session', JSON.stringify({session : {server, user}}));
                    setAuthenticated(true);
                    navigate('../app/home', { replace: true });
                })
                .catch(error => {});
        }
    }

    return (
        <>
            <Typography className={globalClasses.dialogTitle}>{t('connection')}</Typography>
            <form onSubmit={handleLogin}>
                <TextField
                    margin='normal'
                    required
                    fullWidth
                    error={Boolean(error)}
                    label={t('userEmail')}
                    name='email'
                    value={email}
                    autoComplete='email'
                    autoFocus
                    onChange={handleEmailChange}
                />
                <TextField
                    margin='normal'
                    required
                    fullWidth
                    error={Boolean(error)}
                    label={t('userPassword')}
                    name='password'
                    value={password}
                    type='password'
                    autoComplete='current-password'
                    onChange={handlePasswordChange} 
                    helperText={error}
                    FormHelperTextProps={{className: classes.helperText}}
                />

                <div className={globalClasses.loginFormControl}>
                    <Link className={globalClasses.link} to="/auth/accountRecovery">{t('loginForgotPassword')}</Link>
                    <Button 
                        type="submit" 
                        variant="contained" 
                        color="secondary" 
                        disabled={!email || !password}
                    >
                        {t('loginLogin')}
                    </Button>
                </div>
            </form>
        </>
    );
}

export default Login;