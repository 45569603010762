import {createSlice} from '@reduxjs/toolkit';

const {reducer, actions} = createSlice({
    name: 'users',
    initialState: {
        items: {},
        selectedId: null,
    },
    reducers: {
        setUsers(state, action){
            state.item = action.payload;
        },
    },
});

export { actions as usersActions };
export { reducer as usersReducer };