import React, { createContext, useContext, useState } from 'react';

const GeofencePointerContext = createContext(null);

const useGeofencePointerVisibility = () => {
    const [geofencePointerVisible, setGeofencePointerVisible] = useContext(GeofencePointerContext);

    return [geofencePointerVisible, setGeofencePointerVisible];
}

const GeofencePointerProvider = ({ children }) => {
    const [geofencePointerVisible, setGeofencePointerVisible] = useState(true);
 
    return (
        <GeofencePointerContext.Provider value={[geofencePointerVisible, setGeofencePointerVisible]}>
            {children}
        </GeofencePointerContext.Provider>
    );
};

export { GeofencePointerProvider, useGeofencePointerVisibility };