import { useState } from "react";

const useConfirmationDialog = () => {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [callback, setCallback] = useState(() => {});

    const openConfirmationDialog = (title, message, callback) => {
        setOpen(true);
        setTitle(title);
        setMessage(message);
        setCallback(() => callback); // === setState((oldState) => newState)
    }

    return { open, setOpen, title, setTitle, message, setMessage, callback, setCallback, openConfirmationDialog };
};

export default useConfirmationDialog;