import _ from 'lodash';

export const groupPositionsByTrip = (positionsState, tripsState) => {
    let positions = _.cloneDeep(positionsState);
    let trips = _.cloneDeep(tripsState);
    let tripsWithPositions = trips.map(trip => ({ ...trip, positions: positions.filter(position => trip.startTime <= position.fixTime && trip.endTime >= position.fixTime)}));
    return tripsWithPositions.reduce((result, item) => ({ ...result, [item.startPositionId]: item }), {});
};

export const extractCoordinates = position => (position?.attributes?.motion && position?.address?.fixedLatitude && position?.address?.fixedLongitude)
    ? [position?.address?.fixedLongitude, position?.address?.fixedLatitude]
    : [position.longitude, position.latitude];

export const mapPositionsByTripsToLineStringFeatures = (posByTrip, allPositions, selectedTripId, isSatelliteStyle, theme) => {
    let positionsByTrip = _.cloneDeep(Object.values(posByTrip));
    let selectedTripIndex;
    let selectedTrip;
    let offColor = isSatelliteStyle ? theme.palette.common.white : '#333';
    let otherPositions = allPositions.filter(position => position.fixTime > positionsByTrip?.at(-1)?.positions?.at(-1)?.fixTime);
    let features;

    selectedTripIndex = positionsByTrip.findIndex(trip => trip.startPositionId === selectedTripId);
    if (selectedTripIndex >= 0) {
        selectedTrip = positionsByTrip[selectedTripIndex];
        positionsByTrip.splice(selectedTripIndex, 1);
        positionsByTrip.push(selectedTrip);
    }  

    features = positionsByTrip
        .map(trip => ({
            type: 'Feature',
            properties: { 
                color: trip.startPositionId === selectedTripId ? theme.palette.on.main : offColor,
                trip,
                isTrip: true,
            },
            geometry: {
                type: 'LineString',
                coordinates: trip.positions.map(position => extractCoordinates(position)),
            }
        }));
    if (otherPositions.length > 0) {
        features.unshift({
            type: 'Feature',
            properties: { 
                color: offColor,
                positions: otherPositions,
                isTrip: false,
            },
            geometry: {
                type: 'LineString',
                coordinates: otherPositions.map(position =>extractCoordinates(position)),
            }
        });
    }
    return features;
};

export const mapStopPositionsToPointFeatures = (positions) => {
    let stopPositions = _.cloneDeep(positions);

    return stopPositions
        .map(position => ({
            type: 'Feature',
            properties: { 
                position,
            },
            geometry: {
                type: 'Point',
                coordinates: extractCoordinates(position),
            }
        }));
};