import _ from 'lodash';
import { filterNewItems } from "../util";

export const getData = (items, newItems, staffers, filters) => [
        ...Object.values(items).reverse(), 
        ...Object.values(newItems).filter(item => filterNewItems(item, staffers?.[item.stafferId], filters))
    ]
    .map(timesheet => {
        let staffer = staffers?.[timesheet.stafferId];
        let data = {};

        let timesheetWorkingTime = null;
        let endTime = new Date(timesheet.endTime);
        let startTime = new Date(timesheet.startTime);
        if(!isNaN(endTime) && !isNaN(startTime) && !isNaN(timesheet.breakTime)) {
            timesheetWorkingTime = endTime - startTime - (timesheet.breakTime * 1000);
        }

        data =  {
            allData: { timesheet, staffer },
            timesheetWorkingTime,
            stafferName: `${staffer?.firstname} ${staffer?.name}`,
            ...timesheet,
            isNewItem: (timesheet.isNewItem || false),
        };
        
        return data;
        });