import React from 'react';

import ContactStatus from 'components/common/ContactStatus';
import MovementStatus from 'components/common/MovementStatus';
import SignalStatus from 'components/common/SignalStatus';
import TimeCounter from 'components/common/TimeCounter';
import { KeyboardArrowDown, KeyboardArrowUp, LocationOn } from '@mui/icons-material';
import { IconButton, } from '@mui/material';
import None from 'components/common/None';
import { AssetColumns } from 'utils/constant';
import { containsText, matchExactText } from 'utils/filters';

export const getColumns = (t, cx, globalClasses, classes) => [ 
    {
        accessor: AssetColumns.ITEM_STATUS,
        Header: t('itemStatusTitle'),
        Cell: ({ cell: { value: { item, position } }}) => (
            <div className={cx(globalClasses.iconStatusContainer, classes.iconStatusContainer)}>
                <ContactStatus device={item} position={position} />
                <MovementStatus device={item} position={position} />
                <SignalStatus device={item} />
            </div>
        ),
        disableSortBy: true,
        filter: (rows, id, filterValues) => matchExactText(rows, filterValues, (r) => t(r.values[id].item.status)),
        props: {
            className: globalClasses.tableCellFirst,
            style: {
                width: '7%',
            }
        }

    },
    {
        accessor: AssetColumns.ITEM_CATEGORY,
        Header: t('itemCategory'),
        filter: (rows, id, filterValues) => containsText(rows, filterValues, (r) => r.values[id]),
        props: {
            style: {
                width: '6%',
            }
        },
    },
    {
        accessor: AssetColumns.ITEM_LICENSE_PLATE,
        Header: t('itemLicensePlate'),
        Cell: ({ cell: { value } }) => value || <None text={t('itemLicensePlateNone')}/>,
        filter: (rows, id, filterValues) => containsText(rows, filterValues, (r) => r.values[id]),
        props: {
            style: {
                width: '7%',
            },
        },
    },
    {
        accessor: AssetColumns.ITEM_LABEL,
        Header: t('itemLabel'),
        test: 'valeurTest',
        Cell: ({ cell }) => {
            let { value: { item, trailerAssociated, deviceAssociated, position } } = cell;
            return <span>
                {item?.name}
                {trailerAssociated && (
                    <>  
                        <span> - </span>
                        <MovementStatus device={trailerAssociated} position={position} className={globalClasses.decorativeIcon} />
                        {trailerAssociated?.name}
                    </>
                )}
                {deviceAssociated && (
                    <>  
                        <span> - </span>
                        <MovementStatus device={deviceAssociated} position={position} className={globalClasses.decorativeIcon} />
                        {deviceAssociated?.name}
                    </>
                )}
            </span>
        },
        filter: (rows, id, filterValues) => containsText(rows, filterValues, (r) => r.values[id].item.name),
        props: {
            style: {
                width: '21%',
            }
        },
    },
    {
        accessor: AssetColumns.ITEM_ADDRESS,
        Header: t('itemAddress'),
        Cell: ({ cell: { value: { addressValue, isGeofence } } }) => <>
                <LocationOn className={isGeofence ? globalClasses.decorativeIcon : cx(globalClasses.decorativeIcon, globalClasses.invisible)}/>
                {addressValue || <None text={t('itemAddressNone')}/>
                }
            </>,
        filter: (rows, id, filterValues) => containsText(rows, filterValues, (r) => r.values[id].addressValue),
        props: {
            style: {
                width: '23%',
            }
        },
        headerProps: {
            className: globalClasses.tableAddressHeader,
        }
    },
    {
        accessor: AssetColumns.ITEM_GROUP,
        Header: t('itemGroup'),
        Cell: ({ cell: { value } }) => value || <None text={t('itemGroupNone')}/>,
        filter: (rows, id, filterValues) => containsText(rows, filterValues, (r) => r.values[id]),
        props: {
            style: {
                width: '13%',
            }
        },
    },
    {
        accessor: AssetColumns.ITEM_ODOMETER_OR_HOUR,
        Header: t('itemOdometerOrHours'),
        props: {
            className: globalClasses.tableCellNumber,
            style: {
                width: '8%',
            }
        },
    },
    {
        accessor: AssetColumns.ITEM_LAST_DATA,
        Header: t('itemLastData'),
        Cell: ({ cell: { value } }) => <TimeCounter time={new Date(value)} />,
        props: {
            style: {
                width: '11%',
            }
        },
    },
    {
        Header: () => null,
        id: 'expander',
        Cell: (table) => {
            return (
                <IconButton color="primary" size="large" >
                    {table.row.isExpanded 
                        ? <KeyboardArrowUp/>
                        : <KeyboardArrowDown/>
                    }
                </IconButton>
            );
        },    
        disableSortBy: true,   
        props: {
            className: globalClasses.tableCellExpander,
            style: {
                width: '3%',
            }
        }     
    },
];