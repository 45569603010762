import React from 'react';
import { Tooltip } from '@mui/material';

import { useGlobalStyles } from 'context/StylesContext';
import useTranslation from 'hooks/useTranslation';
import { DeviceStatus, DeviceType } from 'utils/constant';
import { ReactComponent as EngineIcon } from 'resources/circled_engine.svg'; 
import { ReactComponent as BatteryIcon } from 'resources/battery.svg';

const ContactStatus = ({ device, position }) => {
    const { t } = useTranslation();
    const { classes, theme } = useGlobalStyles();
    
    switch(device?.category) {
        case DeviceType.SMARTPHONE: 
            if(position?.attributes?.batteryLevel !== undefined && device.status === DeviceStatus.ONLINE){
                return (
                    <Tooltip placement="top" title={`${t('positionBatteryLevel')}: ${position?.attributes?.batteryLevel}%`}>
                        <BatteryIcon fill={theme.palette.on.main} stroke={theme.palette.on.main} className={classes.iconStatus} />
                    </Tooltip>
                );
            } 
            return (
                <Tooltip placement="top" title={`${t('positionBatteryLevel')}: ${t('unknown')}`}>
                    <BatteryIcon fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.iconStatus} />
                </Tooltip>
            );
        case DeviceType.VEHICLE:
        case DeviceType.CONSTRUCTION_MACHINE:
            if(position?.attributes?.ignition !== undefined){
                return position.attributes.ignition 
                    ? <Tooltip placement="top" title={`${device.name} ${t('deviceStartsMessage')}`}><EngineIcon fill={theme.palette.on.main} stroke={theme.palette.on.main} className={classes.iconStatus} /></Tooltip>
                    : <Tooltip placement="top" title={`${device.name} ${t('deviceShutsDownMessage')}`}><EngineIcon fill={theme.palette.off.main} stroke={theme.palette.off.main} className={classes.iconStatus} /></Tooltip>;
            }
            return <Tooltip placement="top" title={`${device.name} ${t('deviceUnknownIgnitionMessage')}`}><EngineIcon fill={theme.palette.unknown.main} stroke={theme.palette.unknown.main} className={classes.iconStatus} /></Tooltip>;
        
        case DeviceType.TOOL:
        case DeviceType.TRAILER:
        case DeviceType.WAREHOUSE:
        default:
            return <EngineIcon fill="transparent" stroke="transparent" className={classes.iconStatus} />;
    }
        
};

export default ContactStatus;