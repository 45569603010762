import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    unknown: {
        padding: '0px',
        height: '5px',
        backgroundColor: theme.palette.unknown.main,
    },
    trip: {
        padding: '0px',
        height: '5px',
        backgroundColor: theme.palette.on.main,
    },
    stop: {
        padding: '0px',
        height: '5px',
        backgroundColor: theme.palette.off.main,
    },
    future: {
        padding: '0px',
        height: '5px',
        backgroundColor: theme.palette.empty.main,
    },
}));

const MotionStatusBar = ({ deviceId, motions }) => {
    const { classes } = useStyles();
    const position = useSelector(state => state.positions.items[deviceId]);  

    const renderMotionBar = () => {
        const numberOfMinutesInADay = 24 * 60;
        const midnight = new Date();
        midnight.setHours(0, 0, 0, 0);
        const now = Math.trunc(((new Date()).getTime() - midnight.getTime())/1000/60);
        const timeTrips = motions.trips.map(trip => ({isTrip: true, startTime: Math.trunc((new Date(trip.startTime) - midnight.getTime())/1000/60), endTime: Math.trunc((new Date(trip.endTime) - midnight.getTime())/1000/60)}));
        const timeStops = motions.stops.map(trip => ({isStop: false, startTime: Math.trunc((new Date(trip.startTime) - midnight.getTime())/1000/60), endTime: Math.trunc((new Date(trip.endTime) - midnight.getTime())/1000/60)}));
        const sortedMotions = [...timeTrips, ...timeStops].sort((a, b) => a.startTime - b.startTime);
        
        const result = [];
        let lastMotion = null;
        let lastMotionEndTime = 0;
        let motionClass;
        for(let motion of sortedMotions) {
            motionClass = motion.isTrip ? classes.trip : classes.stop; 

            if(motion.startTime - lastMotionEndTime > 0){
                result.push(<div key={lastMotion?.startTime || 0} className={classes.unknown} style={{ width: `${(motion.startTime - lastMotionEndTime)*100/numberOfMinutesInADay}%`}}/>);
            } 
            result.push(<div key={motion.startTime} className={motionClass} style={{ width: `${(motion.endTime - motion.startTime)*100/numberOfMinutesInADay}%`}}/>);
            lastMotion = motion;
            lastMotionEndTime = motion.endTime;
        }
        if(now - lastMotionEndTime > 0 && position){
            if(new Date(position.fixTime) > midnight) {
                if(position.attributes.motion) {
                    result.push(<div key={'now'} className={classes.trip} style={{ width: `${(now - lastMotionEndTime)*100/numberOfMinutesInADay}%`}}/>);
                } else{
                    result.push(<div key={'now'} className={classes.stop} style={{ width: `${(now - lastMotionEndTime)*100/numberOfMinutesInADay}%`}}/>);
                }
            } else {
                result.push(<div key={'now'} className={classes.unknown} style={{ width: `${(now - lastMotionEndTime)*100/numberOfMinutesInADay}%`}}/>);
            }
        }
        result.push(<div key={"future"} className={classes.future} style={{ width: `${(numberOfMinutesInADay - now)*100/numberOfMinutesInADay}%`}}/>);

        return result;
    }

    return (motions !== null
        ?   <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>         
                {renderMotionBar()}
            </div>
        : null
    );
};

export default React.memo(MotionStatusBar); 