import React from 'react';
import { useParams } from 'react-router-dom';

import UpdateGroupPage from './UpdateGroup';
import CreateGroupPage from './CreateGroup';

const GroupPage = ({ role }) => {
    const { id } = useParams();

    return (id) ? <UpdateGroupPage id={ id } role={role} /> : <CreateGroupPage/>;
 
};

export default GroupPage;