import React from 'react';
import { Route, useParams } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { MANAGER } from 'authorization/role';
import { DeviceType } from 'utils/constant';
import UpdateWarehouse from './UpdateWarehouse';
import CreateWarehouse from './CreateWarehouse';

const WarehousePage = ({ role }) => {
    const { id } = useParams();
    const type = DeviceType.WAREHOUSE;

    const filterDataForServer = item => {
        item.category = type;
        if(!item.groupId || item.groupId < 1){
            delete item.groupId;
        }
        delete item.ownerIds;
        delete item.lastUpdate;
        delete item.positionId;
        delete item.detectedBy;
        item.address.localityId = item.address.locality.id;
        delete item.address.locality;
        return item;
    }

    if(role === MANAGER){
        return (id) 
            ? <UpdateWarehouse 
                id={ id } 
                filterDataForServer={filterDataForServer} 
                role={role} 
            /> 
            : <CreateWarehouse 
                filterDataForServer={filterDataForServer} 
            />;
    }
    return (id) 
        ? <UpdateWarehouse 
            id={ id } 
            filterDataForServer={filterDataForServer} 
            role={role} 
        /> 
        : <Route element={<Navigate to="signage/warehouses" />}/>;
};

export default WarehousePage;