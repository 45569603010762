import React from 'react';
import { Typography, } from '@mui/material';

import { parseAddress } from 'utils/address';
import None from 'components/common/None';

export const getColumns = ({ speedUnit, coordinateFormat, t, globalClasses, formatDate, formatSpeed, formatCoordinate, formatAddress, formatBoolean }) => [{
        Header: t('positionFixTime'),
        accessor: 'fixTime',
        Cell: ({ cell: { value } }) => formatDate(value),
        props: {
            className: globalClasses.tableCellFirst,
            style: {
                width: '14.3%',
            },
        },
    }, {
        Header: t('reportDriver'),
        accessor: 'driverName',
        Cell: ({ cell: { value } }) => value || <None/>,
        props: {
            style: {
                width: '14.3%',
            },
        },
    }, {
        Header: t('positionLatitude'),
        accessor: 'latitude',
        Cell: ({ cell: { value } }) => formatCoordinate('latitude', value, coordinateFormat),
        props: {
            className: globalClasses.tableCellNumber,
            style: {
                width: '10%',
            },
        },
    }, {
        Header: t('positionLongitude'),
        accessor: 'longitude',
        Cell: ({ cell: { value } }) => formatCoordinate('longitude', value, coordinateFormat),
        props: {
            className: globalClasses.tableCellNumber,
            style: {
                width: '10%',
            },
        },
    }, {
        Header: t('positionSpeed'),
        accessor: 'speed',
        Cell: ({ cell: { value } }) => formatSpeed(value, speedUnit),
        props: {
            className: globalClasses.tableCellNumber,
            style: {
                width: '10%',
            },
        },
    }, {
        Header: t('sharedAddress'),
        accessor: 'address',
        Cell: ({ cell: { value }}) => <div>{Object.values(formatAddress(parseAddress(value))).map(l => l && <Typography>{l}</Typography>)}</div>,
        props: {
            style: {
                width: '20%',
            },
        },
    }, {
        Header: t('positionIgnition'),
        accessor: 'attributes.ignition',
        Cell: ({ cell: { value } }) => t(formatBoolean(value)),
        props: {
            style: {
                width: '8%',
            },
        },
    },
    // {
    //     Header: t('deviceTotalDistance'),
    //     accessor: 'attributes.totalDistance',
    //     Cell: ({ cell: { value } }) => formatDistance(value, distanceUnit),
    // }
];