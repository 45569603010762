import _ from 'lodash';
import useTranslation from "hooks/useTranslation";
import React, { useEffect, useState } from "react";
import { DatePicker, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField, Tooltip, } from "@mui/material";
import { isValidDate } from "utils/date";
import { Event, QueryBuilder, } from "@mui/icons-material";
import * as yup from 'yup';
import { makeStyles } from "tss-react/mui";
import { getDateValidation } from './validation';

const useStyles = makeStyles()(theme => ({
    contentContainer: {
        height: '75%',
    },
    noResultContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
    },
    icon: {
        height: 100,
        width: 100,
    },
    iconError: {
        color: theme.palette.error.main,
    }
}));

export const DATE_PROP_NAME = 'date';

const DatePickerCell = ({ initialValue, id, checkAndUpdateTimesheet, }) => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const safeInitialValue = isValidDate(new Date(initialValue)) ? initialValue : new Date();
    const [value, setValue] = useState(safeInitialValue);
    const [error, setError] = useState('');

    useEffect(() => {
        if(initialValue !== value) {
            setValue(new Date(initialValue));
        }
        getDateValidation(t).validate(initialValue)
            .then(() => setError(''))
            .catch(error => setError(error.message))
    }, [initialValue]);
    
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
                inputFormat="DD/MM/YYYY"
                margin="normal"
                components={{
                    OpenPickerIcon: () => {
                        return ( error === '' 
                            ? <Event/>
                            : (
                                <Tooltip title={error} placement="top">
                                    <Event className={classes.iconError}/>
                                </Tooltip>
                            )
                        )
                    }
                }}
                OpenPickerButtonProps={{ tabIndex: -1 }}
                value={value}
                onChange={d => setValue(d)}
                onAccept={d => checkAndUpdateTimesheet(id, DATE_PROP_NAME, new Date(d).toISOString())}
                renderInput={(params) => 
                    <TextField 
                        onFocus={e => e.target.select()}
                        error={error !== ''}
                        onBlur={e => {
                            let newDate = value;

                            if(isValidDate(new Date(newDate))) {
                                checkAndUpdateTimesheet(id, DATE_PROP_NAME, new Date(newDate).toISOString());
                            } else {
                                checkAndUpdateTimesheet(id, DATE_PROP_NAME, null);
                            }
                        }}
                        {...params} 
                        inputProps={{
                            ...params.inputProps,
                            style: { textAlign: 'right' }
                        }}
                    />
                }
            />
        </LocalizationProvider>
    );
};

export default DatePickerCell;