import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Collapse,
    Divider,
    TableCell,
    TableRow,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useGlobalStyles } from 'context/StylesContext';
import { MapContext } from 'context/MapContext';
import useTranslation from 'hooks/useTranslation';
import useGetMotions from 'hooks/useGetMotions';
import MotionsSummaryTimeline from 'components/common/MotionsSummaryTimeline';
import MotionsSummaryText from 'components/common/MotionsSummaryText';
import Map from '../map/Map';
import { fitBounds } from '../map/mapUtil';
import SelectedDeviceMap from '../map/SelectedDeviceMap';
import PositionsMap from '../map/PositionsMap';
import MotionStatusBar from '../main/MotionStatusBar';
import AssetStatistics from './AssetStatistics';
import AssetLive from './AssetLive';
import AssetStatus from './AssetStatus';
import None from 'components/common/None';
import StatusView from '../map/StatusView';

const useStyles = makeStyles()(theme => ({
    detailsContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    liveAndSummaryAndMapContainer: {
        flex: 3,
        display: 'flex',
        flexDirection: 'row',
    },
    liveAndStatusContainer: {
        flex: 1,
        padding: 0,
        flexDirection: 'column',
        maxHeight: 420,
    },
    mapContainer: {
        flex: 1,
        display: 'flex',
        maxHeight: 420,
    },
    daySummaryAndStatisticsContainer: {
        flex: 1,
        flexDirection: 'column',
        padding: 0,
        maxHeight: 420,
    },
    daySummaryContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 16,
    },
    motionStatusBarContainer: {
        marginTop: "1vh",
        marginBottom: "1vh",
    },
    liveContainer: {
        flexDirection: 'column',
        padding: 16,
    },
    statusContainer: {
        flexDirection: 'column',
        padding: 16,
    },
    statisticsContainer: {
        height: '100%',
        padding: 16,
        flexDirection: 'column',
    },
    tripsContainer: {
        flex: 1,
        flexDirection: 'column',
    },
    noJourney: {
        textAlign: 'center'
    }
}));

const DetailAssetRow = ({ itemRow, visibleColumns, }) => {
    const { t } = useTranslation();
    const { geofences } = useSelector(state => ({ geofences: state.geofences.items }));
    const { map } = useContext(MapContext);
    const { classes } = useStyles();
    const { classes : globalClasses, cx } = useGlobalStyles();
    const { motions } = useGetMotions({ deviceId: itemRow.id, refreshInterval: 60 * 3 });
    const position = itemRow.original.allData.position;
    const item = itemRow.original.allData.item;
    const geofence = itemRow.original.allData.geofence;
    const mustExpand = itemRow.isExpanded;
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(mustExpand);
    }, [mustExpand]);

    return(
        <TableRow className={itemRow.isExpanded ? globalClasses.selectedItem : 'detailRowClosed'}>
            <TableCell 
                colSpan={visibleColumns.length}
                padding="none"
            >
                <Collapse in={open}>
                    <div className={classes.detailsContainer}>
                        <div className={classes.liveAndSummaryAndMapContainer}>
                            <div className={cx(globalClasses.infoContainer, classes.liveAndStatusContainer)}>
                                <div className={cx(globalClasses.infoContainer, classes.liveContainer)}>
                                    <span className={globalClasses.infoTitle}>{t('assetLiveTitle')}</span>
                                    <AssetLive item={item} position={position} geofence={geofence} />
                                </div>
                                

                                <Divider className={globalClasses.transparentDivider} variant="middle" />

                                <div className={cx(globalClasses.infoContainer, classes.statusContainer)}>
                                    <span className={globalClasses.infoTitle}>{t('itemStatusTitle')}</span>
                                    <AssetStatus item={item} position={position} />
                                </div>
                                
                            </div>

                            <div className={classes.mapContainer}>
                                { position && itemRow.isExpanded && <Map fitBounds={() => fitBounds(map, [position], 12)}>
                                        <SelectedDeviceMap/>
                                        <PositionsMap 
                                            displayedPositions={[position]} 
                                            renderPopupContent={({ deviceId }) => <StatusView deviceId={deviceId}/>}
                                        />
                                    </Map>
                                }
                            </div>

                            <div className={cx(globalClasses.infoContainer, classes.daySummaryAndStatisticsContainer)}>
                                <div className={cx(globalClasses.infoContainer, classes.daySummaryContainer)} >
                                    <span className={globalClasses.infoTitle}>{t('daySummary')}</span>
                                    <MotionsSummaryText
                                        currentPosition={position}
                                        device={item}
                                        motions={motions}
                                    />
                                    <div className={classes.motionStatusBarContainer}>
                                        <MotionStatusBar deviceId={item.id} motions={motions}/>
                                    </div>
                                </div>
                                <Divider className={globalClasses.transparentDivider} variant="middle" />
                                <div className={cx(globalClasses.infoContainer, classes.statisticsContainer)}>
                                    <span className={globalClasses.infoTitle}>{t('assetStatisticsTitle')}</span>
                                    {itemRow.isExpanded && <AssetStatistics item={item} position={position} />}
                                </div>
                            </div>
                        </div>
                        <Divider className={globalClasses.transparentDivider} orientation="vertical" flexItem variant="middle" />
                        <div className={cx(globalClasses.infoContainer, classes.tripsContainer)}>
                            <span className={globalClasses.infoTitle}>{t('assetTrips')}</span>
                            <MotionsSummaryTimeline 
                                geofences={Object.values(geofences)}
                                motions={motions}
                                defaultContent={<span className={classes.noJourney}><None text={t('noJourneyToday')}/></span>}
                            />
                        </div>
                    </div>
                </Collapse>
            </TableCell>
        </TableRow>
    );
};

export default DetailAssetRow;