import React, { memo, useEffect, useMemo, useRef, useState, useCallback } from "react";
import { magellanV1 } from "api/magellan";
import _ from 'lodash';
import ItemTable from "components/home/ItemTable";
import useAlertMessage from "hooks/useAlertMessage";
import useFormat from "hooks/useFormat";
import useTranslation from "hooks/useTranslation";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Fab, TablePagination, } from "@mui/material";
import { Add, } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import GlobalSearch from "components/common/GlobalSearch";
import { useGlobalStyles } from "context/StylesContext";
import usePagination from "hooks/usePagination";
import StateInfo from "components/common/StateInfo";
import { ReactComponent as TaskDone } from 'resources/task_done.svg';
import { ReactComponent as TaskNotFound } from 'resources/task_not_found.svg';
import { fetchItemsAction, removeItemAction, validateAndHideItemAction, checkAndUpdateAction } from "./asyncTimesheetValidationActions";
import { timesheetValidationActions, } from "store";
import ItemRow from "components/common/ItemRow";
import { getColumns } from "./columns";
import { getData } from "./data";
import { useExpanded, useSortBy, useTable } from "react-table";
import useFetchGlobalSearchFilters from "hooks/useFetchGlobalSearchFilters";

const useStyles = makeStyles()(theme => ({
    contentContainer: {
        height: '75%',
    },
    noResultContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
    },
    icon: {
        height: 100,
        width: 100,
    },
    iconError: {
        color: theme.palette.error.main,
    }
}));

const TimesheetValidationPage = () => {
    const { classes, theme } = useStyles();
    const { classes: globalClasses, cx } = useGlobalStyles();
    const { t } = useTranslation();
    const { formatDuration, formatLocalDate } = useFormat();
    const [filters, setFilters] = useState([]);
    const dispatch = useDispatch();
    const loading = useSelector(state => state.timesheetValidation.loading);
    const items = useSelector(state => state.timesheetValidation.items);
    const newItems = useSelector(state => state.timesheetValidation.newItems);
    const lastAction = useSelector(state => state.timesheetValidation.lastAction);
    const page = useSelector(state => state.timesheetValidation.pagination.page);
    const limit = useSelector(state => state.timesheetValidation.pagination.limit);
    const { offset, totalCount, setTotalCount, gotoPage, setPageSize, nbItemsOnPage } = usePagination({ 
        clientPagination:false,
        limit, 
        page,
        changePage: (newPage) => dispatch(timesheetValidationActions.updatePaginationPage(newPage)),
        changeLimit: (newLimit) => dispatch(timesheetValidationActions.refreshPagination({ page: 0, limit: newLimit })),
    });
    const { setAlertMessage, dataLoadingErrorAlertMessage, timesheetSavedAlertMessage, timesheetRemovedAlertMessage, updateErrorAlertMessage } = useAlertMessage();
    const staffers = useSelector(state => state.staffers.items);
    const tableEngineContainerRef = useRef(null);
    const { availableFilters } = useFetchGlobalSearchFilters({  api: magellanV1, location: '/api/timesheets/filters' })
    const columns = useMemo(() => getColumns({ staffers, dispatch, t, theme, globalClasses, formatLocalDate, formatDuration, }), []);
    const data = useMemo(() => getData(items, newItems, staffers, filters), [JSON.stringify(items), JSON.stringify(newItems)]);
    
    const { 
        toggleAllRowsExpanded,
        visibleColumns, 
        ...tableRest
    } = useTable(
        { 
            columns, 
            data, 
            autoResetExpanded: false, 
            autoResetSortBy: false,
            getRowId: useCallback(row => row.id, []),
            meta: { 
                updateTimesheet: (id, prop, value) => dispatch(timesheetValidationActions.updateItem({ id, prop, value })),
                checkAndUpdateTimesheet: (id, prop, value) => dispatch(checkAndUpdateAction({ 
                    id, 
                    prop, 
                    value,
                })),
                validateTimesheet: (id) => dispatch(validateAndHideItemAction({ 
                    id, 
                    items, 
                    nbItemsOnPage, 
                    setAlertMessage, 
                    timesheetSavedAlertMessage, 
                    updateErrorAlertMessage, 
                    t,
                    fetchNewItems: () => dispatch(fetchItemsAction({ 
                        mustShowLoading: false,
                        setAlertMessage, 
                        dataLoadingErrorAlertMessage, 
                        setLimit: setPageSize,
                        setTotalCount: setTotalCount,
                        limit,
                        offset, 
                        filters,
                    })),
                })), 
                removeTimesheet: (id) => dispatch(removeItemAction({ 
                    id, 
                    nbItemsOnPage, 
                    setAlertMessage, 
                    timesheetRemovedAlertMessage, 
                    updateErrorAlertMessage,
                    fetchNewItems: () => dispatch(fetchItemsAction({ 
                        mustShowLoading: false,
                        setAlertMessage, 
                        dataLoadingErrorAlertMessage, 
                        setLimit: setPageSize,
                        setTotalCount: setTotalCount,
                        limit,
                        offset, 
                        filters,
                    })),
                })),
                dispatch: dispatch,
            },
        }, 
        useSortBy,
        useExpanded,
    );
    
    useEffect(() => {
        if(lastAction === timesheetValidationActions.addNewItem.type) {
            let mainContainer = tableEngineContainerRef.current.parentNode;
            mainContainer.scrollTo(0, mainContainer.scrollHeight);
        }
    }, [lastAction, Object.keys(newItems).length]);

    useEffect(() => {
        if(filters !== undefined && limit !== undefined && offset !== undefined) {
            dispatch(fetchItemsAction({ 
                setAlertMessage, 
                dataLoadingErrorAlertMessage, 
                setLimit: setPageSize,
                setTotalCount: setTotalCount,
                limit,
                offset, 
                filters,
            }));
        }
    }, [JSON.stringify(filters), limit, offset]);

    const render = () => {
        if(loading) {
            return (
                <div className={cx(globalClasses.centerOfPageContainer, classes.contentContainer)}>
                    <CircularProgress/>
                </div>
            );
        } else if(items.length === 0 && filters.length === 0) {
            return renderNoTimesheetToValidate()
        } 
        return items.length === 0 ? renderNoResult() : renderTimesheetValidation();
    };

    const renderNoTimesheetToValidate = () => {
        return (
            <div className={cx(globalClasses.centerOfPageContainer, classes.contentContainer)}>
                <StateInfo 
                    logo={TaskDone}
                    title={t('noTimesheetToValidate')}
                    message={t('allTimesheetsValidatedConsultThemInReports')}
                />
            </div>
            
        );
    };

    const renderNoResult = () => {
        return (
            <div className={cx(globalClasses.centerOfPageContainer, classes.contentContainer)}>
                <StateInfo
                    logo={TaskNotFound}
                    title={t('noDataFound')}
                    message={t('editFiltersMessage')}
                />
            </div>
        );
    };

    const renderTimesheetValidation = () => {
        return (
            <ItemTable 
                {...tableRest}
            >
                { ({ itemRow }) => (
                    <ItemRow 
                        key={itemRow.id} 
                        itemRow={itemRow} 
                        visibleColumns={visibleColumns} 
                        toggleAllRowsExpanded={toggleAllRowsExpanded} 
                    />
                )}
            </ItemTable> 
        );
    };

    return (
        <div className={globalClasses.tablePageLayout}>     
            <div ref={tableEngineContainerRef} className={globalClasses.tableEngineContainer}>
                <GlobalSearch
                    filters={filters}
                    setFilters={(newFilters) => {
                        setFilters(newFilters);
                        gotoPage(0);
                    }}
                    availableFilters={availableFilters}
                />  
                <TablePagination
                    page={page}
                    rowsPerPage={limit}
                    count={totalCount}
                    onPageChange={(_, newPage) => gotoPage(newPage)}
                    onRowsPerPageChange={event => setPageSize(parseInt(event.target.value, 10))}
                    component="div"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('paginationOf')} ${count !== -1 ? count : `${t('paginationMoreThan')} ${to}`}`}
                    labelRowsPerPage={t('paginationRowsPerPage')}
                />
            </div>
            {render()}
            { !loading &&
                <Fab size="medium" color="primary" className={globalClasses.addButton} onClick={() => dispatch(timesheetValidationActions.addNewItem())}>
                    <Add />
                </Fab>
            }
        </div>
    );
};

export default memo(TimesheetValidationPage);
