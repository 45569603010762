import React from "react";

import { magellanV1 } from "api/magellan";
import ErrorFallback from "./ErrorFallback";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, };
    }
  
    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }
  
    componentDidCatch(catchedError) {
        const error = { 
            origin: 'webapp',
            contents: {
                message: catchedError.message,
                stack: catchedError.stack
            }
        };  
        magellanV1.post('/api/errors', error)
    }
  
    render() {
        if (this.state.hasError) {
            return <ErrorFallback message={this.state.error.message} stack={this.state.error.stack} />
        }
    
        return this.props.children;
    }
  }

  export default ErrorBoundary;