

export const getData = ({ vehicles, safeDrivingStats, fuelConsumptionByDevice }) => vehicles
.map(vehicle => {
    const safeDrivingStat = safeDrivingStats?.[vehicle?.id];
    const fuelConsumption = fuelConsumptionByDevice?.[vehicle?.id];
    let data = {};

    data =  {
        allData: { vehicle, safeDrivingStat },
        itemLabel: vehicle?.name,
        fuelConsumedPer100Km: fuelConsumption?.averageFuelConsumedPer100Km,
        safeDrivingScore: safeDrivingStat?.globalScore,
        safeDrivingHardAcceleration: safeDrivingStat?.totalHardAcceleration,
        safeDrivingHardBraking: safeDrivingStat?.totalHardBraking,
        safeDrivingHardCornering: safeDrivingStat?.totalHardCornering,
        safeDrivingOverspeed: safeDrivingStat?.totalOverspeed,
    };
    
    return data;
});