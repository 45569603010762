const getAddress = (item, formatAddressOneLine) => {
    const geofence = item?.geofences?.[0];

    let addressValue = geofence?.name;
    let isGeofence = Boolean(addressValue);
    if(!addressValue) {
        try {
            let addressJSON = JSON?.parse(item?.position?.address);
            addressValue = addressJSON && formatAddressOneLine(addressJSON);
        } catch {}
    }

    return { isGeofence, addressValue, geofence };
};

export const getData = (items, formatAddressOneLine, formatDeviceCategory) => items
    .sort((a, b) => a.date < b.date ? -1 : 1)
    .reduce((accumulator, current, index, array) => {
        let result;
        if(index === 0) {
            result = [{ start: current }];
        } else {
            let last = accumulator.pop();
            result = [...accumulator, { ...last, end: current }, { start: current }];
        }
        return result;
    }, [])
    .map(item => {
        const toolOrTrailer = item?.start?.beacon;
        const deviceAssociated = item?.start?.tracker;

        let { geofence: startGeofence, ...startAddress } = getAddress(item.start, formatAddressOneLine);
        let { geofence: endGeofence, ...endAddress } = getAddress(item.end, formatAddressOneLine);

        const data =  {
            allData: { item, toolOrTrailer, deviceAssociated, startGeofence, endGeofence },
            label: toolOrTrailer?.name,
            deviceAssociatedLabel: (item?.start?.attributes?.beaconDetected) ? deviceAssociated?.name : null,
            deviceAssociatedType: (item?.start?.attributes?.beaconDetected) ?  (deviceAssociated?.category && formatDeviceCategory(deviceAssociated.category)) : null,
            isAssociation: item?.start?.attributes?.beaconDetected,
            startDate: item?.start?.eventTime,
            startAddress: startAddress,
            endDate: item?.end?.eventTime,
            endAddress: endAddress,
        };

        return data;
    });