import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { InputAdornment, TextField, } from "@mui/material";

const BreakTimeField = ({ initialValue, id, prop, updateTimesheet, t } ) => {
    const [value, setValue] = useState(Math.floor(initialValue/60));

    useEffect(() => {
        let newValue = Math.floor(initialValue/60);
        if(value !== newValue) {
            setValue(newValue);
        }
    }, [initialValue]);

    return (
        <TextField
            onFocus={e => e.target.select()}
            inputProps={{
                style: { textAlign: 'end' }
            }}
            value={value}
            onChange={e => {
                setValue(previous => (e.target.value === '' || (!isNaN(e.target.value) && e.target.value >= 0  && Number.isInteger(Number(e.target.value))))  ? e.target.value : previous);
            }}
            onBlur={e => {
                if(e.target.value.trim() === '') {
                    setValue(0);
                }
                updateTimesheet(id, prop, e.target.value * 60);
            }}
            InputProps={{
                endAdornment: <InputAdornment position="end">{t('min')}</InputAdornment>,
            }}
        />
    );
};

export default BreakTimeField;