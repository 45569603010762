import { DeviceStatus, DeviceType, motionLocationIcon, MotionStatus } from './constant';

export const defineMotionStatusByType = (position, device) => {
    if(!position || !device){
        return MotionStatus.VEHICLE_UNKNOWN;
    }
    switch(device.category) {
        case DeviceType.SMARTPHONE:
            if(device.status === DeviceStatus.ONLINE && position.attributes.motion){
                return MotionStatus.SMARTPHONE_ON;
            }
            return MotionStatus.SMARTPHONE_OFF;
        case DeviceType.TOOL: 
            return device.status === DeviceStatus.ONLINE && position.attributes?.motion ? MotionStatus.TOOL_ON : MotionStatus.TOOL_OFF;
        case DeviceType.TRAILER:
            return position.attributes?.motion ? MotionStatus.TRAILER_ON : MotionStatus.TRAILER_OFF;
        case DeviceType.WAREHOUSE:
            return MotionStatus.WAREHOUSE_OFF;
        case DeviceType.CONSTRUCTION_MACHINE:
        case DeviceType.VEHICLE: 
            if(position.attributes.ignition && position.attributes.motion){
                return device.category === DeviceType.CONSTRUCTION_MACHINE ? MotionStatus.CONSTRUCTION_MACHINE_ON : MotionStatus.VEHICLE_ON;
            }
            return device.category === DeviceType.CONSTRUCTION_MACHINE ? MotionStatus.CONSTRUCTION_MACHINE_OFF : MotionStatus.VEHICLE_OFF;
        default: 
            return MotionStatus.VEHICLE_UNKNOWN;
    }        
}

export const getLocationMotionIcon = (position, device, selectedDeviceId) => {
    let icon = '';
    let motionStatus = defineMotionStatusByType(position, device);

    switch(motionStatus){
        case MotionStatus.SMARTPHONE_ON:  
            icon = device?.id === selectedDeviceId ? motionLocationIcon.SMARTPHONE_LOCATION_ON_SELECTED : motionLocationIcon.SMARTPHONE_LOCATION_ON;
            break;
        case MotionStatus.SMARTPHONE_OFF: 
            icon = device?.id === selectedDeviceId ? motionLocationIcon.SMARTPHONE_LOCATION_OFF_SELECTED : motionLocationIcon.SMARTPHONE_LOCATION_OFF;
            break;
        case MotionStatus.VEHICLE_ON:
            icon = device?.id === selectedDeviceId ? motionLocationIcon.CAR_LOCATION_ON_SELECTED : motionLocationIcon.CAR_LOCATION_ON;
            break;
        case MotionStatus.VEHICLE_OFF: 
            icon = device?.id === selectedDeviceId ? motionLocationIcon.CAR_LOCATION_OFF_SELECTED : motionLocationIcon.CAR_LOCATION_OFF;
            break;
        case MotionStatus.VEHICLE_UNKNOWN:
            icon = device?.id === selectedDeviceId ? motionLocationIcon.CAR_LOCATION_UNKNOWN_SELECTED : motionLocationIcon.CAR_LOCATION_UNKNOWN;
            break;
        case MotionStatus.CONSTRUCTION_MACHINE_ON:
            icon = device?.id === selectedDeviceId ? motionLocationIcon.CONSTRUCTION_MACHINE_LOCATION_ON_SELECTED : motionLocationIcon.CONSTRUCTION_MACHINE_LOCATION_ON;
            break;
        case MotionStatus.CONSTRUCTION_MACHINE_OFF:
            icon = device?.id === selectedDeviceId ? motionLocationIcon.CONSTRUCTION_MACHINE_LOCATION_OFF_SELECTED : motionLocationIcon.CONSTRUCTION_MACHINE_LOCATION_OFF;
            break;
        case MotionStatus.CONSTRUCTION_MACHINE_UNKNOWN:
            icon = device?.id === selectedDeviceId ? motionLocationIcon.CONSTRUCTION_MACHINE_LOCATION_UNKNOWN_SELECTED : motionLocationIcon.CONSTRUCTION_MACHINE_LOCATION_UNKNOWN;
            break;
        case MotionStatus.TOOL_ON:
            icon = device?.id === selectedDeviceId ? motionLocationIcon.TOOL_LOCATION_ON_SELECTED : motionLocationIcon.TOOL_LOCATION_ON;
            break;
        case MotionStatus.TOOL_OFF:
            icon = device?.id === selectedDeviceId ? motionLocationIcon.TOOL_LOCATION_OFF_SELECTED : motionLocationIcon.TOOL_LOCATION_OFF;
            break;
        case MotionStatus.TOOL_UNKNOWN:
            icon = device?.id === selectedDeviceId ? motionLocationIcon.TOOL_LOCATION_UNKNOWN_SELECTED : motionLocationIcon.TOOL_LOCATION_UNKNOWN;
            break;
        case MotionStatus.TRAILER_ON:
            icon = device?.id === selectedDeviceId ? motionLocationIcon.TRAILER_LOCATION_ON_SELECTED : motionLocationIcon.TRAILER_LOCATION_ON;
            break;
        case MotionStatus.TRAILER_OFF:
            icon = device?.id === selectedDeviceId ? motionLocationIcon.TRAILER_LOCATION_OFF_SELECTED : motionLocationIcon.TRAILER_LOCATION_OFF;
            break;
        case MotionStatus.TRAILER_UNKNOWN:
            icon = device?.id === selectedDeviceId ? motionLocationIcon.TRAILER_LOCATION_UNKNOWN_SELECTED : motionLocationIcon.TRAILER_LOCATION_UNKNOWN;
            break;
        case MotionStatus.WAREHOUSE_OFF:
            icon = device?.id === selectedDeviceId ? motionLocationIcon.WAREHOUSE_LOCATION_OFF_SELECTED : motionLocationIcon.WAREHOUSE_LOCATION_OFF;
            break;
        case MotionStatus.WAREHOUSE_UNKNOWN:
            icon = device?.id === selectedDeviceId ? motionLocationIcon.WAREHOUSE_LOCATION_UNKNOWN_SELECTED : motionLocationIcon.WAREHOUSE_LOCATION_UNKNOWN;
            break;
        default:
            icon = device?.id === selectedDeviceId ? motionLocationIcon.CAR_LOCATION_UNKNOWN_SELECTED : motionLocationIcon.CAR_LOCATION_UNKNOWN;
    }
    return icon;
}