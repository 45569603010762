import { AssetColumns, DeviceType } from 'utils/constant';
import { comparatorByCategoryThenByName } from 'utils/sort';

export const getData = (items, extractBeaconData, positions, geofences, formatAddressOneLine, formatDeviceCategory, groups, formatDistance, formatDuration) => items
    .map(item => {
        let data = {};
        if(item.category === DeviceType.TRAILER) {
            data = extractBeaconData(item);
            data.itemLabel = { item, deviceAssociated: data.allData.deviceAssociated, position: data.allData.positionDeviceAssociated }
        } else {
            let position = positions?.[item?.id];
            let geofence = geofences?.[item?.geofenceIds[0]];
            let trailerAssociated = items.find(beacon => beacon.detectedBy === item.id && beacon.category === DeviceType.TRAILER);
            //let positionTrailerAssociated = positions?.[trailerAssociated?.id];

            let addressValue = geofence?.name;
            let isGeofence = Boolean(addressValue);
            if(!addressValue) {
                addressValue = position && formatAddressOneLine(position?.address);
            }
            
            let itemOdometerOrHours = position?.attributes?.totalDistance && formatDistance(position?.attributes?.totalDistance, 'km');
            if (item.category === DeviceType.CONSTRUCTION_MACHINE) {
                itemOdometerOrHours = position?.attributes?.hours && formatDuration(position?.attributes?.hours);
            }

            data =  {
                allData: { item, trailerAssociated, position, geofence },
                [AssetColumns.ITEM_STATUS]: { item, position },
                [AssetColumns.ITEM_CATEGORY]: item?.category && formatDeviceCategory(item?.category),
                [AssetColumns.ITEM_LICENSE_PLATE]: item?.licensePlate,
                [AssetColumns.ITEM_LABEL]: { item, trailerAssociated, position },
                [AssetColumns.ITEM_ADDRESS]: { addressValue, isGeofence },
                [AssetColumns.ITEM_GROUP]: item?.groupId ? groups?.[item?.groupId]?.name : '',
                [AssetColumns.ITEM_ODOMETER_OR_HOUR]: itemOdometerOrHours,
                [AssetColumns.ITEM_LAST_DATA]: item.lastUpdate,
            };
        }

        return data;
    })
    .sort((item1, item2) => {
        const itemA = item1.allData.item;
        const itemB = item2.allData.item;
        return comparatorByCategoryThenByName(itemA.category, itemA.name, itemB.category, itemB.name);
    });