import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MagellanLogoGreen from 'resources/magellan_logo_green.svg';
import MagellanLogoBlue from 'resources/magellan_logo_blue.svg';
import MagellanLogoDark from 'resources/magellan_logo_dark.svg';
import { magellanV0 } from 'api/magellan';
import { sessionActions } from 'store';
import getTheme from 'theme';
import { ColorMode } from 'utils/constant';

const ColorModeContext = createContext(null);

const useColorMode = () => {
    const [colorMode, setColorMode] = useContext(ColorModeContext);
    let MagellanLogo;

    switch(colorMode) {
        case ColorMode.BLUE:
            MagellanLogo = MagellanLogoBlue;
            break;
        case ColorMode.DARK:
            MagellanLogo = MagellanLogoDark;
            break;
        case ColorMode.GREEN:
        default:
            MagellanLogo = MagellanLogoGreen;
    };

    return { colorMode, setColorMode, MagellanLogo };
}

const ColorModeProvider = ({ children }) => {
    const user = useSelector(state => state.session.user);
    const dispatch = useDispatch();
    const userColorMode = user?.attributes?.colorMode;
    const colorMode = Object.values(ColorMode).includes(userColorMode) ? userColorMode : ColorMode.GREEN;
    const theme = getTheme(colorMode);
 
    const setColorMode = async (newColorMode) => {
        const submission = { ...user, attributes: { ...user.attributes, colorMode: newColorMode}};
        delete submission?.password;
        dispatch(sessionActions.updateColorMode(newColorMode));
        magellanV0.put(`/api/users/${user.id}`, submission).catch(_ => {});
    };

    useEffect(() => {
        var link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.head.appendChild(link);
        }
        link.href = `/Magellan_favicon_${colorMode}.ico`;
    }, [colorMode]);
    
    return ( theme &&
        <ColorModeContext.Provider value={[colorMode, setColorMode]}>
            {children(theme)}
        </ColorModeContext.Provider>
    );
    
};

export { ColorModeProvider, useColorMode };