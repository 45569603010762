import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { pdf } from '@react-pdf/renderer';

import { downloadFile } from 'utils/downloadFile';
import { useAttributePreference } from 'hooks/usePreferences';
import useFormat from 'hooks/useFormat';
import { speedFromKnots } from 'utils/converter';
import useTranslation from 'hooks/useTranslation';
import Graph from './Graph';
import { magellanV0 } from 'api/magellan';
import useAlertMessage from 'hooks/useAlertMessage';
import useReport from 'hooks/useReport';
import { ReportFormat } from 'utils/constant';
import { convertChartToPng } from 'utils/convertChartToPng';
import { Image } from '@react-pdf/renderer';
import TemplatePdf from '../templates/TemplatePdf';
import useDeviceReport from 'hooks/useDeviceReport';
import { useGlobalStyles } from 'context/StylesContext';


const chartPngWidth = 1350;
const chartPngHeight = 625;

const useChartReport = () => {
    const { t } = useTranslation();
    const { formatNumber, formatDate, formatAddressOneLine, formatDeviceCategory, } = useFormat();
    const { classes: globalClasses } = useGlobalStyles();
    const [type, setType] = useState('speed');
    const speedUnit = useAttributePreference('speedUnit');
    const { setAlertMessage, gettingReportErrorAlertMessage } = useAlertMessage();
    const availableReportActions = [ReportFormat.JSON, ReportFormat.PDF];
    const { deviceId, setDeviceId, period, setPeriod, from, setFrom, to, setTo, items, setItems, devices, query, getFiltersKey, pdfReportFilters, ...restDeviceReport } = useDeviceReport({ availableReportActions });
    const { loading, setLoading, pdfData, setPdfData, companyName, companyAddress, ...restReport } = useReport({ setItems });
    const handleSubmit = async (reportFormat) => {
        const response = await magellanV0.get(`/api/reports/route?${query.toString()}`);
        if (response.status === 200) {
            const positions = response.data;
            let formattedPositions = positions.map(position => {
                return {
                    speed: Number(speedFromKnots(position.speed, speedUnit)),
                    altitude: position.altitude,
                    accuracy: position.accuracy,
                    fixTime: formatDate(position.fixTime)
                }
            });

            if(reportFormat === ReportFormat.PDF) {
                setPdfData(formattedPositions);
            } else {
                setItems(formattedPositions);
            }
        } else {
            setAlertMessage(gettingReportErrorAlertMessage);
        }
    }

    const renderTemplate = (chartPng) => {
        return (
            <TemplatePdf 
                reportTitle={t('reportChartTitlePdf')}
                companyName={companyName}
                companyAddress={formatAddressOneLine(companyAddress)}
                filters={pdfReportFilters} 
                isPortrait={false}
            >
                <Image src={chartPng} />
            </TemplatePdf>
        );
    } 
    
    const generatePdf = (chartPng) => {
        const doc = renderTemplate(chartPng);
        const file = pdf(doc);
        file.toBlob().then(blob => {
            downloadFile(blob, 'chartReport.pdf');
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        })
        setPdfData([]);
    }

    useEffect(() => {
        if(pdfData && pdfData.length > 0) {
            const chartPng = (
                <Graph 
                    type={type} 
                    items={pdfData} 
                    t={t}
                    formatNumber={formatNumber}
                    speedUnit={speedUnit}
                    width={chartPngWidth}
                    height={chartPngHeight}
                    chartRef={ref => convertChartToPng(ref, chartPngWidth, chartPngHeight, generatePdf, e => {})}
                />
            );
            const helperDiv = document.createElement('tmp');
            const containerTmp = createRoot(helperDiv);
            containerTmp.render(chartPng);
        }
    }, [JSON.stringify(pdfData)]); 

    return { 
        t, 
        formatNumber, 
        formatDeviceCategory,
        globalClasses, 
        pdfData, 
        type, 
        setType, 
        items, 
        loading, 
        speedUnit, 
        deviceId, 
        setDeviceId, 
        period, 
        setPeriod, 
        from, 
        setFrom, 
        to, 
        setTo, 
        devices, 
        availableReportActions, 
        getFiltersKey, 
        ...restDeviceReport,
        ...restReport, 
        handleSubmit 
    };
};

export default useChartReport;