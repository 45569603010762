import Gauge from './Gauge';

export const getColumns = ({ t, globalClasses, cx, formatDecimalNumber, }) => [ 
    {
        accessor: 'itemLabel',
        Header: t('itemLabel'),
        props: {
            className: globalClasses.tableCellFirst,
            style: {
                width: '12%',
            }
        },
    },
    {
        accessor: 'fuelConsumedPer100Km',
        Header: t('fuelConsumedPer100Km'),
        Cell: ({ cell: { value }}) => value && value !== -1 ? formatDecimalNumber(value) : t('notApplicableAbbreviation'),
        props: {
            className: globalClasses.tableCellNumber,
            style: {
                width: '10%',
            }
        },
    },
    {
        accessor: 'safeDrivingScore',
        id: 'safeDrivingScore',
        Header: t('safeDrivingScore'),
        Cell: ({ cell: { value } }) => (!value && value !== 0) 
            ? <div style={{ width: '100%', }}>{t('notApplicableAbbreviation')}</div>
            : formatDecimalNumber(value),
        props: {
            className: globalClasses.tableCellNumber,
            style: {
                width: '8%',
            }
        },
    },
    {
        accessor: 'safeDrivingScore',
        id: 'safeDrivingScoreGauge',
        Header: () => null,
        Cell: ({ cell: { value } }) => (!value && value !== 0) ? null : <Gauge value={value} displayValue={false} />,
        props: {
            style: {
                paddingLeft: 16,
                width: '22%',
            }
        },
        disableSortBy: true,
    },
    {
        accessor: 'safeDrivingHardAcceleration',
        Header: t('safeDrivingHardAcceleration'),
        props: {
            className: globalClasses.tableCellNumber,
            style: {
                width: '12%',
            }
        },
    },
    {
        accessor: 'safeDrivingHardBraking',
        Header: t('safeDrivingHardBraking'),
        props: {
            className: globalClasses.tableCellNumber,
            style: {
                width: '12%',
            }
        },
    },
    {
        accessor: 'safeDrivingHardCornering',
        Header: t('safeDrivingHardCornering'),
        props: {
            className: globalClasses.tableCellNumber,
            style: {
                width: '12%',
            }
        },
    },
    {
        accessor: 'safeDrivingOverspeed',
        Header: t('safeDrivingOverspeed'),
        props: {
            className: cx(globalClasses.tableCellNumber, globalClasses.tableCellLast),
            style: {
                width: '12%',
            }
        },
    },
];