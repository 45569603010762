import React from 'react';
import LocationOn from '@mui/icons-material/LocationOn';

import MovementStatus from 'components/common/MovementStatus';
import SignalStatus from 'components/common/SignalStatus';
import TimeCounter from 'components/common/TimeCounter';
import None from 'components/common/None';
import { BeaconsColumns } from 'utils/constant';
import { containsText, matchExactText } from 'utils/filters';

export const getColumns = (t, cx, classes, globalClasses) => [ 
    {
        accessor: BeaconsColumns.ITEM_STATUS,
        Header: t('itemStatusTitle'),
        Cell: ({ cell: { value: { item, position } }}) => {
            return (
                <div className={cx(globalClasses.iconStatusContainer, classes.iconStatusContainer)}>
                    <MovementStatus device={item} position={position} />
                    <SignalStatus device={item} />
                 </div>
            );
        },
        disableSortBy: true,
        filter: (rows, id, filterValues) => matchExactText(rows, filterValues, (r) => t(r.values[id].item.status)),
        props: {
            className: globalClasses.tableCellFirst,
            style: {
                width: '6%',
            }
        },
    },
    {
        accessor: BeaconsColumns.ITEM_CATEGORY,
        Header: t('itemCategory'),
        filter: (rows, id, filterValues) => containsText(rows, filterValues, (r) => r.values[id]),
        props: {
            style: {
                width: '6%',
            }
        },
    },
    {
        accessor: BeaconsColumns.ITEM_LABEL,
        Header: t('itemLabel'),
        filter: (rows, id, filterValues) => containsText(rows, filterValues, (r) => r.values[id]),
        props: {
            style: {
                width: '12%',
            }
        },
    },
    {
        accessor: BeaconsColumns.ITEM_TOOL_DEVICE_ASSOCIATED,
        Header: t('toolDeviceAssociated'),
        Cell: ({ cell: { value: { deviceAssociated, positionDeviceAssociated } } }) =>
            <>
                <MovementStatus 
                    device={deviceAssociated} 
                    position={positionDeviceAssociated} 
                    className={globalClasses.decorativeIcon}                    
                />
                {deviceAssociated?.name || <None text={t('itemDeviceNone')}/>}
            </>,
        props: {
            style: {
                width: '18%',
            }
        },
        headerProps: {
            className: globalClasses.tableAddressHeader,
        }
    },
    {
        accessor: BeaconsColumns.ITEM_ADDRESS,
        Header: t('itemAddress'),
        Cell: ({ cell: { value: { addressValue, isGeofence, expired } } }) => {
            let className = globalClasses.decorativeIcon;
            if(isGeofence) {
                if(expired) {
                    className = cx(globalClasses.decorativeIcon, globalClasses.disabled);
                }
            } else {
                className = cx(globalClasses.decorativeIcon, globalClasses.invisible);
            }

            return (
                <>
                    <LocationOn className={className}/>
                    {addressValue || <None text={t('itemAddressNone')}/>}
                </>
            )
        },
        filter: (rows, id, filterValues) => containsText(rows, filterValues, (r) => r.values[id].addressValue),  
        props: {
            style: {
                width: '24%',
            },
        },
        headerProps: {
            className: globalClasses.tableAddressHeader,
        },
    },
    {
        accessor: BeaconsColumns.ITEM_GROUP,
        Header: t('itemGroup'),
        Cell: ({ cell: { value } }) => value || <None text={t('itemGroupNone')}/>,
        filter: (rows, id, filterValues) => containsText(rows, filterValues, (r) => r.values[id]),
        props: {
            style: {
                width: '16%',
            }
        },
    },
    {
        accessor: BeaconsColumns.ITEM_LAST_DATA,
        Header: t('itemLastData'),
        Cell: ({ cell: { value } }) => <TimeCounter time={new Date(value)} />,
        props: {
            style: {
                width: '12%',
            }
        },
    },
];