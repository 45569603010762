import React, { useState, useEffect } from 'react';
import {
    Card,
    CardHeader,
    FormControlLabel,
    Switch,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TextField,
    Collapse,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';
import { useFormik } from 'formik';

import {magellanV0, magellanV1} from 'api/magellan';
import useAlertMessage from 'hooks/useAlertMessage';
import useTranslation from 'hooks/useTranslation';
import { AlarmType, DeviceType, EventType } from 'utils/constant';
import Timetable from '../../common/Timetable';
import SelectTextField from '../../common/SelectTextField';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import useConfirmationDialog from 'hooks/useConfirmationDialog';
import { useGlobalStyles } from 'context/StylesContext';
import { getInitialTimetable } from 'utils/timetable';
import { useTheme } from '@emotion/react';

const useStyles = makeStyles()(theme => ({
    cardClosed: {
        rowGap: '0%',
    },
}));

const DevicesSelector = Object.freeze({
    'GROUPS': 'groups',
    'DEVICES': 'devices',
    'TOOLS': 'tools',
    'TRAILERS': 'trailers',
});

const NotificationForm = ({ onSubmit, initialValues, editionMode, id }) => {
    const { t } = useTranslation();
    const { open, setOpen, title: dialogTitle, message, callback, openConfirmationDialog } = useConfirmationDialog();
    const { classes, cx } = useStyles();
    const { classes: globalClasses } = useGlobalStyles();
    const theme = useTheme();
    const [dialogOptions, setDialogOptions] = useState({open: false, title: '', message: '', setNewSelector: () => null});
    const { setAlertMessage, dataLoadingErrorAlertMessage } = useAlertMessage();
    const formik = useFormik({
        initialValues,
        validationSchema: yup.object().shape({
            name: yup.string()
                .trim()
                .required(t('validationRequired'))
                .max(128, `\${max} ${t('validationLengthMax')}`),
            type: yup.string()
                .required(t('validationRequired')),
            currentDevicesSelector: yup.string(),
            groups: yup.object().when('currentDevicesSelector', {
                is: DevicesSelector.GROUPS,
                then:(schema)=> schema.shape({
                    finalItems: yup.array().min(1, t('validationRequired')),
                }),
            }),
            devices: yup.object().when('currentDevicesSelector', {
                is: DevicesSelector.DEVICES,
                then:(schema)=> schema.shape({
                    finalItems: yup.array().min(1, t('validationRequired')),
                }),
            }),
            calendar: yup.object().shape({
                mondayStartHour : yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate'))
                    .nullable(),
                mondayEndHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate'))
                    .nullable(),
                tuesdayStartHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate'))
                    .nullable(),
                tuesdayEndHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate'))
                    .nullable(),
                wednesdayStartHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate'))
                    .nullable(),
                wednesdayEndHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate'))
                    .nullable(),
                thursdayStartHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate'))
                    .nullable(),
                thursdayEndHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate'))
                    .nullable(),
                fridayStartHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate'))
                    .nullable(),
                fridayEndHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate'))
                    .nullable(),
                saturdayStartHour : yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate'))
                    .nullable(),
                saturdayEndHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate'))
                    .nullable(),
                sundayStartHour : yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate'))
                    .nullable(),
                sundayEndHour: yup.mixed()
                    .notOneOf(['Invalid Date'], t('validationDate'))
                    .nullable(),
            })
            .nullable(),
        }),
        onSubmit: (values, _) => onSubmit(values, initialValues), 
    });

    useEffect(() => {
        Promise.all([
            magellanV0.get('/api/groups', { headers: { 'Accept': 'application/json'}}),
            magellanV0.get('/api/devices', { headers: { 'Accept': 'application/json'}}),
            (id && initialValues.currentDevicesSelector !== 'always') && magellanV1.get(`/api/groups?notificationId=${id}`),
            (id && initialValues.currentDevicesSelector !== 'always') && magellanV1.get(`/api/devices?notificationId=${id}`)
        ]).then(([allGroupsResponse, allDevicesResponse, initialGroupsResponse, initialDevicesResponse]) => {
            if(id){
                if(initialValues.currentDevicesSelector === 'always') {
                    formik.setFieldValue('groups', {...formik.values.groups, allItems: allGroupsResponse.data});
                    formik.setFieldValue('devices', {...formik.values.devices, allItems: allDevicesResponse.data});
                } else if(initialGroupsResponse.data.length > 0) {
                    formik.setFieldValue('groups', {
                        allItems: allGroupsResponse.data,
                        initialItems: initialGroupsResponse.data.map(g => g.id),
                        finalItems: initialGroupsResponse.data.map(g => g.id)
                    });
                    formik.setFieldValue('devices', {...formik.values.devices, allItems: allDevicesResponse.data});
                    formik.setFieldValue('currentDevicesSelector', DevicesSelector.GROUPS);
                } else {
                    formik.setFieldValue('devices', {
                        allItems: allDevicesResponse.data,
                        initialItems: initialDevicesResponse.data.map(d => d.id),
                        finalItems: initialDevicesResponse.data.map(d => d.id)
                    });
                    formik.setFieldValue('groups', {...formik.values.groups, allItems: allGroupsResponse.data});
                    switch(initialDevicesResponse.data?.[0]?.category) {
                        case DeviceType.TOOL:
                            formik.setFieldValue('currentDevicesSelector', DevicesSelector.TOOLS);
                            break;
                        case DeviceType.TRAILER:
                            formik.setFieldValue('currentDevicesSelector', DevicesSelector.TRAILERS);
                            break;
                        case DeviceType.SMARTPHONE:
                        case DeviceType.VEHICLE:
                        case DeviceType.CONSTRUCTION_MACHINE:
                        default:
                            formik.setFieldValue('currentDevicesSelector', DevicesSelector.DEVICES);
                    }
                    
                }
            } else {
                formik.setFieldValue('groups', {...formik.values.groups, allItems: allGroupsResponse.data})
                formik.setFieldValue('devices', {...formik.values.devices, allItems: allDevicesResponse.data});
            }
        })
        .catch(error => {
            setAlertMessage(dataLoadingErrorAlertMessage);
        });
    }, []);

    const handleChangeType = e => {
        let newType = e.target.value;
        if (newType === EventType.BEACON_IN || newType === EventType.BEACON_OUT) {
            if (formik.values.currentDevicesSelector === DevicesSelector.DEVICES) {
                changeTypeAndDeviceSelectorIfNeeded(newType, t('devicesDeselected'));
            } else {
                formik.setFieldValue('type', newType);
            }
        } else if (formik.values.currentDevicesSelector === DevicesSelector.TOOLS) {
            changeTypeAndDeviceSelectorIfNeeded(newType, t('toolsDeselected'));
        } else if (formik.values.currentDevicesSelector === DevicesSelector.TRAILERS && newType !== AlarmType.lowBattery.key && newType !== AlarmType.criticalBattery.key && newType !== AlarmType.accident.key) {
            changeTypeAndDeviceSelectorIfNeeded(newType, t('trailersDeselected'));
        } else {
            formik.setFieldValue('type', newType);
        }
    }

    const changeTypeAndDeviceSelectorIfNeeded = (newType, message) => {
        if (formik.values.devices.finalItems.length > 0) {
            openConfirmationDialog(
                t('warning'), 
                t('notificationTypeNotCompatibleWithDeviceSelectorWarningMessage') +  ' ' + t('trailersDeselected'), 
                () => {
                    formik.setFieldValue('currentDevicesSelector', null);
                    formik.setFieldValue('devices', {...formik.values.devices, finalItems: []});
                    formik.setFieldValue('type', newType);
                    setOpen(false);
                }
            );
        } else {
            formik.setFieldValue('currentDevicesSelector', null);
            formik.setFieldValue('type', newType);
        }
    }

    const handleNotificators = (notificator, checked) => {
        let newNotificators;
        if(checked){
            newNotificators = [...formik.values.notificators, notificator];
        } else {
            newNotificators = formik.values.notificators.filter(n => n !== notificator);
        }
        formik.setFieldValue('notificators', newNotificators);
    }

    const handleChangeCurrentDevicesSelector = (newDevicesSelector) => {
        if (newDevicesSelector !== DevicesSelector.DEVICES && formik.values.currentDevicesSelector === DevicesSelector.DEVICES && formik.values.devices.finalItems.length > 0) {
            openConfirmationDialog(
                t('warning'), 
                t('selectorsSameTimeWarningMessage') +  ' ' + t('devicesDeselected'), 
                () => {
                    formik.setFieldValue('currentDevicesSelector', newDevicesSelector);
                    formik.setFieldValue('devices', {...formik.values.devices, finalItems: []});
                    setOpen(false);
                }
            );
        } else if (newDevicesSelector !== DevicesSelector.TOOLS && formik.values.currentDevicesSelector === DevicesSelector.TOOLS && formik.values.devices.finalItems.length > 0) {
            openConfirmationDialog(
                t('warning'), 
                t('selectorsSameTimeWarningMessage') +  ' ' + t('toolsDeselected'), 
                () => {
                    formik.setFieldValue('currentDevicesSelector', newDevicesSelector);
                    formik.setFieldValue('devices', {...formik.values.devices, finalItems: []});
                    setOpen(false);
                }
            );
        } else if (newDevicesSelector !== DevicesSelector.TRAILERS && formik.values.currentDevicesSelector === DevicesSelector.TRAILERS && formik.values.devices.finalItems.length > 0) {
            openConfirmationDialog(
                t('warning'), 
                t('selectorsSameTimeWarningMessage') +  ' ' + t('trailersDeselected'), 
                () => {
                    formik.setFieldValue('currentDevicesSelector', newDevicesSelector);
                    formik.setFieldValue('devices', {...formik.values.devices, finalItems: []});
                    setOpen(false);
                }
            );
        } else if (newDevicesSelector !== DevicesSelector.GROUPS && formik.values.currentDevicesSelector === DevicesSelector.GROUPS && formik.values.groups.finalItems.length > 0) {
            openConfirmationDialog(
                t('warning'), 
                t('selectorsSameTimeWarningMessage') +  ' ' + t('groupsDeselected'),
                () => {
                    formik.setFieldValue('currentDevicesSelector', newDevicesSelector);
                    formik.setFieldValue('groups', {...formik.values.groups, finalItems: []});
                    setOpen(false);
                }
            );
        } else {
            formik.setFieldValue('currentDevicesSelector', newDevicesSelector);
        }
    }

    const handleChangeDevicesSelected = (event, name) => {
        let finalItems;
        const value = Number(event.target.value);
        if(event.target.checked){
            finalItems = [...formik.values[name].finalItems, value];
        } else {
            finalItems = formik.values[name].finalItems.filter(id => id !== value);
        }
        formik.setFieldValue(name, {...formik.values[name], finalItems});
    };

    const handleChangeCurrentCalendarSelector = (isAllTheTime) => {
        if(isAllTheTime) {
            openConfirmationDialog(
                t('warning'), 
                t('calendarWillBeLostWarningMessage'), 
                () => {
                    formik.setFieldValue('calendar', null);
                    setOpen(false);
                }
            );
        } else {
            formik.setFieldValue('calendar', getInitialTimetable());
        }
    };

    return (
        <form id="form" onSubmit={e => {
            e.preventDefault();
            formik.submitForm();
        }}>
            <CardHeader title={t('signageProperties')} />
            <Card className={classes.propertyCard}>
                <TextField 
                    required
                    id="name"
                    name="name"
                    label={t('signageLabel')}
                    disabled={!editionMode}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
                <SelectTextField 
                    id="type"
                    name="type" 
                    label={t('category')} 
                    value={formik.values.type}
                    disabled={!editionMode} 
                    mandatory
                    options={[{type: EventType.DEVICE_ONLINE}, {type: EventType.DEVICE_OFFLINE}, {type: EventType.DEVICE_INACTIVE}, 
                        {type: EventType.DEVICE_MOVING}, {type: EventType.DEVICE_STOPPED}, {type: EventType.DEVICE_OVERSPEED},  {type: EventType.GEOFENCE_ENTER},
                        {type: EventType.GEOFENCE_EXIT},  {type: EventType.IGNITION_ON},  {type: EventType.IGNITION_OFF}, {type: EventType.BEACON_IN}, 
                        {type: EventType.BEACON_OUT}, { type: AlarmType.plugged.key }, { type: AlarmType.unplugged.key }, { type: AlarmType.lowBattery.key }, 
                        { type: AlarmType.criticalBattery.key }, { type: AlarmType.tow.key }, { type: AlarmType.accident.key } ]}
                    //endpoint="/api/notifications/notificators"
                    valuePropertyName="type"
                    namePropertyLabel="type"
                    formatLabel={(label) => {
                        if (Object.values(EventType).includes(label)) {
                            return t(label);
                        } else if (label in AlarmType) {
                            return t(AlarmType?.[label].translatorKey);
                        } else {
                            return label;
                        }
                    }}
                    onChange={handleChangeType}
                    onBlur={formik.handleBlur}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                />
            </Card>
            <CardHeader title={t('notificationNotificators')}/>
            <Card className={globalClasses.switchCard}>
                <FormControlLabel
                    style={{
                        display: "flex",
                        width: '100%',
                        justifyContent: "space-between",
                        marginLeft: '0px',
                    }}
                    disabled={!editionMode}
                    control={<Switch
                        name="notificators"
                        onBlur={formik.handleBlur}
                        checked={formik.values.notificators.includes('web')} 
                        onChange={e => handleNotificators('web', e.target.checked)}
                    />}
                    label={t('notificatorWeb')}
                    labelPlacement="start"
                />
                <FormControlLabel
                    style={{
                        display: "flex",
                        width: '100%',
                        justifyContent: "space-between",
                        marginLeft: '0px',
                    }}
                    disabled={!editionMode}
                    control={<Switch
                        name="notificators"
                        onBlur={formik.handleBlur}
                        checked={formik.values.notificators.includes('mail')} 
                        onChange={e => handleNotificators('mail', e.target.checked)}
                    />}
                    label={t('notificatorMail')}
                    labelPlacement="start"
                />
                <FormControlLabel
                    style={{
                        display: "flex",
                        width: '100%',
                        justifyContent: "space-between",
                        marginLeft: '0px',
                    }}
                    disabled={!editionMode}
                    control={<Switch
                        name="notificators"
                        onBlur={formik.handleBlur}
                        checked={formik.values.notificators.includes('firebase')} 
                        onChange={e => handleNotificators('firebase', e.target.checked)}
                    />}
                    label={t('notificatorFirebase')}
                    labelPlacement="start"
                />
            </Card>
            <CardHeader title={t('deviceTitle')}/>
            <Card className={formik.values.currentDevicesSelector !== 'always' ? globalClasses.switchCard: cx(globalClasses.switchCard, classes.cardClosed)}>
                <FormControlLabel
                    style={{
                        display: "flex",
                        width: '100%',
                        justifyContent: "space-between",
                        marginLeft: '0px',
                    }}
                    disabled={!editionMode}
                    control={<Switch
                        name="always"
                        onBlur={formik.handleBlur}
                        checked={formik.values.currentDevicesSelector === 'always'} 
                        onChange={e => handleChangeCurrentDevicesSelector(e.target.checked ? 'always' : null)}
                    />}
                    label={t('notificationAlways')}
                    labelPlacement="start"
                />
                <Collapse in={formik.values.currentDevicesSelector !== 'always'}>
                        <Accordion 
                            square 
                            expanded={formik.values.currentDevicesSelector === DevicesSelector.GROUPS} 
                            onChange={() => handleChangeCurrentDevicesSelector(DevicesSelector.GROUPS)}
                            disabled={!editionMode}
                        >
                            <AccordionSummary>{t('signageGroups')}</AccordionSummary>
                            <AccordionDetails>
                            {formik.values.groups.allItems && 
                                formik.values.groups.allItems.map(item => {
                                    return (
                                        <FormControlLabel
                                            key={item.id}
                                            style={{
                                                display: "flex",
                                                width: '100%',
                                                justifyContent: "space-between",
                                                marginLeft: '0px',
                                            }}
                                            disabled={!editionMode}
                                            control={<Switch 
                                                value={item.id} 
                                                checked={formik.values.groups?.finalItems ? formik.values.groups?.finalItems?.some(id => id === item.id) : false} 
                                                onChange={e => handleChangeDevicesSelected(e, 'groups')}
                                            />}
                                            label={item.name}
                                            labelPlacement="start"
                                        />
                                    )
                                })
                            }
                            </AccordionDetails>
                        </Accordion>
                        <Accordion 
                            square 
                            expanded={formik.values.currentDevicesSelector === DevicesSelector.DEVICES} 
                            onChange={() => handleChangeCurrentDevicesSelector(DevicesSelector.DEVICES)}
                            disabled={!editionMode || formik.values.type === EventType.BEACON_IN || formik.values.type === EventType.BEACON_OUT}
                        >
                            <AccordionSummary>{t('deviceTitle')}</AccordionSummary>
                            <AccordionDetails>
                            {formik.values.devices.allItems && 
                                formik.values.devices.allItems
                                    .filter(item => [DeviceType.VEHICLE, DeviceType.SMARTPHONE, DeviceType.CONSTRUCTION_MACHINE].includes(item.category))
                                    .map(item => {
                                    return (
                                        <FormControlLabel
                                            key={item.id}
                                            style={{
                                                display: "flex",
                                                width: '100%',
                                                justifyContent: "space-between",
                                                marginLeft: '0px',
                                            }}
                                            disabled={!editionMode}
                                            control={<Switch 
                                                value={item.id} 
                                                checked={formik.values.devices?.finalItems ? formik.values.devices?.finalItems?.some(id => id === item.id) : false} 
                                                onChange={e => handleChangeDevicesSelected(e, 'devices')}
                                            />}
                                            label={item.name}
                                            labelPlacement="start"
                                        />
                                    )
                                })
                            }
                            </AccordionDetails>
                        </Accordion>
                        <Accordion 
                            square 
                            expanded={formik.values.currentDevicesSelector === DevicesSelector.TOOLS} 
                            onChange={() => handleChangeCurrentDevicesSelector(DevicesSelector.TOOLS)}
                            disabled={!editionMode || (formik.values.type !== EventType.BEACON_IN && formik.values.type !== EventType.BEACON_OUT && formik.values.type !== '')}
                        >
                            <AccordionSummary>{t('toolTitle')}</AccordionSummary>
                            <AccordionDetails>
                            {formik.values.devices.allItems && 
                                formik.values.devices.allItems
                                    .filter(item => item.category === DeviceType.TOOL)
                                    .map(item => {
                                    return (
                                        <FormControlLabel
                                            key={item.id}
                                            style={{
                                                display: "flex",
                                                width: '100%',
                                                justifyContent: "space-between",
                                                marginLeft: '0px',
                                            }}
                                            disabled={!editionMode}
                                            control={<Switch 
                                                value={item.id} 
                                                checked={formik.values.devices?.finalItems ? formik.values.devices?.finalItems?.some(id => id === item.id) : false} 
                                                onChange={e => handleChangeDevicesSelected(e, 'devices')}
                                            />}
                                            label={item.name}
                                            labelPlacement="start"
                                        />
                                    )
                                })
                            }
                            </AccordionDetails>
                        </Accordion>
                        <Accordion 
                            square 
                            expanded={formik.values.currentDevicesSelector === DevicesSelector.TRAILERS} 
                            onChange={() => handleChangeCurrentDevicesSelector(DevicesSelector.TRAILERS)}
                            disabled={!editionMode || (
                                formik.values.type !== EventType.BEACON_IN 
                                    && formik.values.type !== EventType.BEACON_OUT 
                                    && formik.values.type !== AlarmType.lowBattery.key
                                    && formik.values.type !== AlarmType.criticalBattery.key
                                    && formik.values.type !== AlarmType.accident.key
                                    && formik.values.type !== ''
                                )}
                        >
                            <AccordionSummary>{t('trailerTitle')}</AccordionSummary>
                            <AccordionDetails>
                            {formik.values.devices.allItems && 
                                formik.values.devices.allItems
                                    .filter(item => item.category === DeviceType.TRAILER)
                                    .map(item => {
                                    return (
                                        <FormControlLabel
                                            key={item.id}
                                            style={{
                                                display: "flex",
                                                width: '100%',
                                                justifyContent: "space-between",
                                                marginLeft: '0px',
                                            }}
                                            disabled={!editionMode}
                                            control={<Switch 
                                                value={item.id} 
                                                checked={formik.values.devices?.finalItems ? formik.values.devices?.finalItems?.some(id => id === item.id) : false} 
                                                onChange={e => handleChangeDevicesSelected(e, 'devices')}
                                            />}
                                            label={item.name}
                                            labelPlacement="start"
                                        />
                                    )
                                })
                            }
                            </AccordionDetails>
                        </Accordion>
                </Collapse>
            </Card>
            <ConfirmationDialog
                open={open}
                setOpen={setOpen}
                title={dialogTitle}
                message={message}
                callback={callback}
            />
            <CardHeader title={t('timetable')}/>
            <Card className={(formik.values.calendar) ? globalClasses.timetableCard: cx(globalClasses.timetableCard, classes.cardClosed)}>
                <FormControlLabel
                    style={{
                        display: "flex",
                        width: '100%',
                        justifyContent: "space-between",
                        marginLeft: '0px',
                    }}
                    disabled={!editionMode}
                    control={<Switch
                        name="calendar"
                        onBlur={formik.handleBlur}
                        checked={formik.values.calendar === null} 
                        onChange={e => handleChangeCurrentCalendarSelector(e.target.checked)}
                    />}
                    label={t('notificationAllTheTime')}
                    labelPlacement="start"
                />
                <Collapse in={formik.values.calendar !== null}>
                    <Timetable 
                        formik={formik} 
                        editionMode={editionMode}
                        offMessage={t('turnedOff')}    
                    />
                </Collapse>
            </Card>
        </form>
    );
};


export default NotificationForm;