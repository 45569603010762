import React, { useEffect, useMemo, useState, useCallback } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useExpanded, useSortBy, useTable } from 'react-table';
import { pdf } from '@react-pdf/renderer';

import useTranslation from 'hooks/useTranslation';
import useFormat from 'hooks/useFormat';
import useDeviceReport from 'hooks/useDeviceReport';
import { AsyncServerTasksType, EventType, ReportFormat } from 'utils/constant';
import useReport from 'hooks/useReport';
import { magellanV0, } from 'api/magellan';
import { useGlobalStyles } from 'context/StylesContext';
import TemplatePdf from '../templates/TemplatePdf';
import { downloadFile } from 'utils/downloadFile';
import AddressPdf from 'components/reports/templates/AddressPdf';
import TablePdf from '../templates/TablePdf';
import { parseAddress } from 'utils/address';
import { getColumns } from '.';

const useEventReport = () => {
    const { t } = useTranslation();
    const { formatAddressOneLine, formatDate, formatDeviceCategory, formatEventType } = useFormat();
    const { classes: globalClasses, cx } = useGlobalStyles();
    const [eventTypes, setEventTypes] = useState(['allEvents']);
    const availableReportActions = [ReportFormat.JSON, ReportFormat.PDF, ReportFormat.EXCEL, ReportFormat.MAIL];
    const { deviceId, setDeviceId, period, setPeriod, from, setFrom, to, setTo, items, setItems, devices, query, pdfReportFilters, getFiltersKey, ...restDeviceReport } = useDeviceReport({ otherFilters: eventTypes, availableReportActions });
    const geofences = useSelector(state => state.geofences.items);
    const { loading, setLoading, pdfData, setPdfData, companyName, companyAddress, ...restReport } = useReport({ 
        api: magellanV0, 
        report: 'reports/events', 
        taskType: AsyncServerTasksType.EVENTS_REPORT_EMAIL, 
        query,
        getFiltersKey,
        addExtraQueryParams: (query) => eventTypes.forEach(it => query.append('type', it)),
        setItems,
    });
    const extractAddress = event => {
        let isGeofence = true;
        let addressValue = geofences?.[event?.geofenceId]?.name;
        if (!addressValue) {
            isGeofence = false;
            if(event?.position?.address) {
                let address = event?.position?.address;
                if(typeof address === 'string') {
                    address = parseAddress(address);
                }
                addressValue = formatAddressOneLine(address);
            }
        }
        return { isGeofence, addressValue }
    };
    const columns = useMemo(() => getColumns(cx, globalClasses, t, formatDate, formatEventType, extractAddress), []);
    const data = useMemo(() => items, [JSON.stringify(items)]);
    const { 
        toggleAllRowsExpanded,
        visibleColumns, 
        ...tableRest
    } = useTable(
        { 
            columns, 
            data, 
            autoResetExpanded: false, 
            autoResetSortBy: false,
            getRowId: useCallback(row => row.id, []),
        }, 
        useSortBy,
        useExpanded,
    );

    const pdfReportColumns = React.useMemo(() => [
        {
            accessor: 'itemEventTime',
            Header: 'Date', 
            Cell: ({ cell: { value } }) => formatDate(value),
            cellWidth: '22%'
        },
        {
            accessor: 'itemType',
            Header: 'Type',
            Cell: ({ cell: { value } }) => value,
            cellWidth: '35%',
        },
        {
            accessor: 'itemAddress',
            Header: t('itemAddress'),
            Cell: ({ cell: { value: { addressValue, isGeofence } } }) => <AddressPdf addressValue={addressValue} isGeofence={isGeofence} t={t}/>,
            cellWidth: '50%',
        },
    ], []);

    const pdfDataFormatted = useMemo(() => pdfData.map(item => (
        {
            itemEventTime: item.eventTime,
            itemType: formatEventType(item),
            itemAddress: extractAddress(item)
        }
    ), [pdfData]));
    
    const { rows, headerGroups, prepareRow } = useTable({ columns: pdfReportColumns, data: pdfDataFormatted });
    
    const renderTemplate = () => {
        if(eventTypes.includes(EventType.ALL_EVENTS)) {
            pdfReportFilters.push({ key: t('typesFilter'), value: t(EventType.ALL_EVENTS)});
        } else {
            pdfReportFilters.push({ key: t('typesFilter'), value: eventTypes.map(e => t(e)).join(', ')});
        }
        
        return (
            <TemplatePdf 
                reportTitle={t('reportEventsTitlePdf')}
                companyName={companyName}
                companyAddress={formatAddressOneLine(companyAddress)}
                filters={pdfReportFilters} 
                isPortrait={true}
            >
                <TablePdf
                    headerGroups={headerGroups} 
                    rows={rows} 
                    prepareRow={prepareRow}
                />
            </TemplatePdf>
        );
    } 

    useEffect(() => {
        if(rows && rows.length > 0) {
            const doc = renderTemplate();
            const file = pdf(doc);
            file.toBlob().then(blob => {
                downloadFile(blob, 'eventReport.pdf');
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            })
            setPdfData([]);
        }
    }, [JSON.stringify(rows)]); 

    return { 
        t, 
        formatAddressOneLine, 
        formatDate, 
        formatDeviceCategory,
        formatEventType,
        globalClasses, 
        cx, 
        items, 
        loading, 
        eventTypes, 
        setEventTypes, 
        geofences, 
        extractAddress, 
        deviceId, 
        setDeviceId, 
        period, 
        setPeriod, 
        from, 
        setFrom, 
        to, 
        setTo, 
        devices, 
        eventTypes, 
        setEventTypes, 
        availableReportActions, 
        getFiltersKey,
        ...restDeviceReport,
        ...restReport,
        ...tableRest,
    };
};

export default useEventReport