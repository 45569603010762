import { useEffect } from "react";
import _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { notificationsActions } from "store";

import MagellanMiniIcon from 'resources/magellan_mini_logo.png'
import useFormatNotificationTitle from "hooks/useFormatNotificationTitle";
import { EventType } from "utils/constant";

const HandleNotifications = () => {
    const newNotifications = useSelector(state => state.notifications.newItems);
    const notificationsHistory = useSelector(state => state.notifications.items);
    const dispatch = useDispatch();
    const { formatTitle } = useFormatNotificationTitle();

    const threatNewNotification = async (notifications) => {
        await displayNotifications(notifications);
        dispatch(notificationsActions.addToHistory(notifications));
    };

    const displayNotifications = async notifications => {
        if ("Notification" in window) {
            if (Notification.permission === "granted") {
                for (let notification of notifications) {
                    let body = `${notification.type === EventType.BEACON_IN || notification.type === EventType.BEACON_OUT ? notification.beacon.name : notification.tracker.name} ${formatTitle(notification)}`;
                    switch(notification.type) {
                        case EventType.GEOFENCE_ENTER:
                        case EventType.GEOFENCE_EXIT: 
                            body += ` ${notification.concernedGeofence.name}`;
                            break;
                        case EventType.BEACON_IN:
                        case EventType.BEACON_OUT:
                            body += ` ${notification.tracker.name}`;
                            break;
                        default:
                    }
                    const browserNotification = new Notification('Magellan', {
                        icon: MagellanMiniIcon,
                        body,
                        timestamp: Date.now()});
                    browserNotification.onclick = () => window.focus();
                }
            } else if (Notification.permission !== "denied") {
                Notification.requestPermission(permission => {
                    if (permission === "granted") {
                        displayNotifications(notifications);
                    }
                });
            }
        }
    };

    useEffect(() => {
        let savedNotificationsStr = localStorage.getItem('notifications');
        let savedNotifications = savedNotificationsStr ? JSON.parse(savedNotificationsStr) : [];
        dispatch(notificationsActions.refresh(savedNotifications));
    }, []);

    useEffect(() => {
        if(newNotifications && newNotifications.at(-1)){
            threatNewNotification(_.cloneDeep(newNotifications.at(-1)));
        }
    }, [newNotifications]);

    useEffect(() => {
        let allNotifications = _.cloneDeep(notificationsHistory);
        let last100Notifications = Object.values(allNotifications).slice(-100);
        localStorage.setItem('notifications', JSON.stringify(last100Notifications));
    }, [notificationsHistory]);

    return null;
};

export default HandleNotifications;