import _ from 'lodash';
import ItemTable from "components/home/ItemTable";
import React from "react";
import { Autocomplete, Button, CircularProgress, TablePagination, TextField, Tooltip, } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import ReportLayoutPage from 'components/reports/ReportLayoutPage';
import PeriodSelector from 'components/common/PeriodSelector';
import ReportShowButton from 'components/reports/ReportShowButton';
import FileExportReportButtons from 'components/reports/FileExportReportButtons';
import StateInfo from "components/common/StateInfo";
import { ReactComponent as TaskNotFound } from 'resources/task_not_found.svg';
import useTimesheetReport from './useTimesheetReport';
import ItemRow from 'components/common/ItemRow';

const useStyles = makeStyles()(theme => ({
    contentContainer: {
        height: '75%',
    },
}));

const TimesheetReport = () => {
    const { classes } = useStyles();
    const {
        t,  
        globalClasses, 
        cx, 
        items, 
        loading, 
        stafferId,
        setStafferId,
        period, 
        setPeriod,
        from,
        setFrom,
        to,
        setTo,
        handleSubmit,
        handleDisplay,
        tasks,
        getFiltersKey,
        displayDisabled,
        pdfDisabled,
        excelDisabled,
        emailDisabled,
        page,
        limit,
        totalCount,
        gotoPage,
        setPageSize,
        staffersOptions,
        ...rest
    } = useTimesheetReport();

    const render = () => {
        if(loading) {
            return (
                <div className={cx(globalClasses.centerOfPageContainer, classes.contentContainer)}>
                    <CircularProgress/>
                </div>
            );
        }
        return items.length === 0 ? renderNoResult() : renderTimesheetReport();
    };

    const renderNoResult = () => {
        return (
            <div className={cx(globalClasses.centerOfPageContainer, classes.contentContainer)}>
                <StateInfo
                    logo={TaskNotFound}
                    title={t('noDataFound')}
                    message={t('editFiltersMessage')}
                />
            </div>
        );
    };
    
    const renderTimesheetReport = () => {
        return (
            <ItemTable 
                {...rest}
            >
                { ({itemRow, visibleColumns, toggleAllRowsExpanded}) => (
                    <ItemRow 
                        key={itemRow.id} 
                        itemRow={itemRow} 
                        visibleColumns={visibleColumns} 
                        toggleAllRowsExpanded={toggleAllRowsExpanded} 
                    />
                )}
            </ItemTable>
        );
    };

    return (
        <ReportLayoutPage 
            loading={loading}
            filter={
                <div className={globalClasses.rootReportEngineContainer}>
                    <div className={globalClasses.reportFiltersContainer}>
                        <Autocomplete 
                            options={staffersOptions}
                            getOptionLabel={s =>  s.id === 'allStaffers' ? s.label : `${s?.firstname} ${s?.name}`}
                            value={staffersOptions.find(s => s.id === stafferId)}
                            onChange={(_, value) => setStafferId(value?.id || null)}
                            renderInput={(params) => <TextField {...params} label={t('stafferName')} variant='outlined'/>}
                        />
                        <PeriodSelector 
                            period={period} 
                            setPeriod={setPeriod} 
                            from={from}
                            setFrom={setFrom}
                            to={to}
                            setTo={setTo}
                        />
                       <ReportShowButton
                            disabled={displayDisabled}
                            handleSubmit={handleDisplay}
                        />
                    </div>
                    <div className={globalClasses.reportButtonsContainer}>
                        <FileExportReportButtons
                            pdfDisabled={pdfDisabled}
                            excelDisabled={excelDisabled}
                            emailDisabled={emailDisabled}
                            tasks={tasks}
                            handleSubmit={handleSubmit}
                            getFiltersKey={getFiltersKey}
                        />
                    </div>
                </div>
            }
        >
            {render()}
            <TablePagination
                page={page}
                rowsPerPage={limit}
                count={totalCount}
                onPageChange={(_, newPage) => gotoPage(newPage)}
                onRowsPerPageChange={event => setPageSize(parseInt(event.target.value, 10))}
                component="div"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('paginationOf')} ${count !== -1 ? count : `${t('paginationMoreThan')} ${to}`}`}
                labelRowsPerPage={t('paginationRowsPerPage')}
            />
        </ReportLayoutPage>
    );
};

export default TimesheetReport;