import { AlarmType, EventType } from "../utils/constant";
import useTranslation from './useTranslation';

const useFormatNotificationTitle = () => {
    const { t } = useTranslation();
    
    const formatTitle = (event) => {
        switch(event.type){
            case EventType.DEVICE_ONLINE:
                return t('deviceIsOnlineMessage');
            case EventType.DEVICE_OFFLINE:
                return t('deviceIsOfflineMessage');
            case EventType.DEVICE_MOVING:
                return t('deviceIsMovingMessage');
            case EventType.DEVICE_STOPPED:
                return t('deviceStoppedMessage');
            case EventType.GEOFENCE_ENTER:
                return t('deviceEntersIntoGeofenceMessage');
            case EventType.GEOFENCE_EXIT:
                return t('deviceExitsGeofenceMessage');
            case EventType.IGNITION_ON:
                return t('deviceStartsMessage');
            case EventType.IGNITION_OFF:
                return t('deviceShutsDownMessage');
            case EventType.DEVICE_OVERSPEED:
                return t('devicesIsGoingTooFastMessage');
            case EventType.DEVICE_INACTIVE:
                return t('deviceIsInactive'); 
            case EventType.BEACON_IN:
                return t('beaconAssociatedMessage');
            case EventType.BEACON_OUT:
                return t('beaconDisassociatedMessage'); 
            case EventType.ALARM:
                switch(event.attributes.alarm) {
                    case AlarmType.unplugged.key:
                        return t('unpluggedMessage');
                    case AlarmType.plugged.key:
                        return t('pluggedMessage');
                    case AlarmType.lowBattery.key:
                        return t('lowBatteryMessage');
                        case AlarmType.criticalBattery.key:
                            return t('criticalBatteryMessage');
                    case AlarmType.tow.key:
                        return t('towMessage');
                    case AlarmType.accident.key:
                        return t('accidentMessage');
                    default:
                }
            default : return '';             
        }
    }

    return {formatTitle};
};

export default useFormatNotificationTitle;