import React from 'react';

import { DevicesViewColumns, DeviceType } from 'utils/constant';
import EditCollectionView from '../../common/EditCollectionView';
import DevicesView from '../DevicesView';

const ToolsPage = () => {
    return (
        <EditCollectionView 
            content={DevicesView} 
            forceNoLimit={true}
            editPath="tool" 
            endpoint="devices" 
            type={DeviceType.TOOL}
            columns={[
                DevicesViewColumns.LABEL,
                DevicesViewColumns.TRACKER_IDENTIFIER,
                DevicesViewColumns.BRAND,
                DevicesViewColumns.MODEL,
                DevicesViewColumns.GROUP,
            ]}
        />
    );
}

export default ToolsPage;