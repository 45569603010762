import React, { useState } from 'react';
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    createTheme,
    ThemeProvider,
    StyledEngineProvider,
    useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import {magellanV0} from 'api/magellan';
import useTranslation from 'hooks/useTranslation';
import useAlertMessage from 'hooks/useAlertMessage';
import { DeviceType } from 'utils/constant';

const useStyles = makeStyles()(theme => ({
    field: {
        '&:hover': {
            cursor: 'pointer',
        },
        cursor: 'pointer',
    },
}));

const AccumulatorDialog = ({ id, position, disabled, deviceType }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(deviceType === DeviceType.CONSTRUCTION_MACHINE 
        ? Math.round(position.attributes.hours/60000) || 0
        : Math.round(position.attributes.totalDistance/1000) || 0);
    const [error, setError] = useState(null);
    const { classes } = useStyles();
    const { setAlertMessage, savedAlertMessage, updateErrorAlertMessage } = useAlertMessage();

    const buttonDialogTheme = createTheme({
        overrides:{
            MuiButton: {
                styleOverrides: {
                    label : {
                        color: theme.palette.common.black,
                        textTransform: 'none',
                        '&:hover':{
                            color: theme.palette.secondary.main,
                        },
                    }
                }
            },
        }
    });

    const updatePosition = async (newValue) => {
        let item = {deviceId: id};
        switch(deviceType){
            case  DeviceType.VEHICLE: 
                item.totalDistance = newValue * 1000;
                break;
            case  DeviceType.CONSTRUCTION_MACHINE: 
                item.hours = newValue * 60000;
                break;
            default: 
                item.totalDistance = newValue * 1000;
                break;
        }
        await magellanV0.put(`/api/devices/${id}/accumulators`, item, { headers: {'Content-Type': 'application/json'} })
                .then(response => {
                    setAlertMessage(savedAlertMessage);
                    setOpen(false);
                })
                .catch(error => {
                    setAlertMessage(updateErrorAlertMessage);
                });
    }

    const handleChange = (event) => {
        setValue(event.target.value);
        yup.number()
            .typeError(t('validationNumber'))
            .required(t('validationRequired'))
            .min(0, `${t('validationMinValue')} \${min}`)
            .max(999999, `${t('validationMaxValue')} \${max}`)
            .validate(event.target.value)
            .then(() => {
                setError(null);
            })
            .catch(error => {
                setError(error.errors);
            });
    };

    const openDialog = disabled ? null : () => setOpen(true);

    return <>
        <TextField 
            className={classes.field}
            onClick={openDialog}
            label={deviceType === DeviceType.CONSTRUCTION_MACHINE ? t('constructionMachineAccumulator') : t('vehicleAccumulator')}
            value={value}
            inputProps={{className: classes.field}}
            disabled={disabled}
        />
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{style: {backgroundColor: theme.palette.background.default}}}>
            <DialogTitle>{deviceType === DeviceType.CONSTRUCTION_MACHINE ? t('constructionMachineAccumulator') : t('vehicleAccumulator')}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                {deviceType === DeviceType.CONSTRUCTION_MACHINE ? t('constructionMachineAccumulatorMessage') : t('vehicleAccumulatorMessage')}
            </DialogContentText>
            <TextField
                autoFocus
                value={value}
                onChange={handleChange}
                helperText={error ? error : null}
                error={Boolean(error)}
                margin="dense"
                label={deviceType === DeviceType.CONSTRUCTION_MACHINE ? t('constructionMachineAccumulator') : t('vehicleAccumulator')}
                type="tel"
                fullWidth
            />
            </DialogContent>
            <DialogActions>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={buttonDialogTheme}>
                        <Button color="primary" onClick={() => setOpen(false)} >
                            {t('sharedCancel')}
                        </Button>
                        <Button 
                            style={{ 
                                color: 'black',
                                '&:hover':{
                                    color: theme.palette.secondary.main,
                                } 
                            }}  onClick={() => updatePosition(value)}  disabled={Boolean(error)}>
                            {t('sharedSave')}
                        </Button>
                    </ThemeProvider>
                </StyledEngineProvider>
            </DialogActions>
        </Dialog>
    </>;
}

export default AccumulatorDialog;