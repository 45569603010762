import useFormat from 'hooks/useFormat';

const useBeaconData = (devices, positions, geofences, groups) => {
    const { formatAddressOneLine, formatDeviceCategory } = useFormat();

    const extractBeaconData = (item) => {
        const deviceAssociated = devices.find(device => device.id === item.detectedBy) || null;
        const positionDeviceAssociated = positions?.[deviceAssociated?.id];
        const position = positions?.[item?.id];
        let geofence = null;
        let addressValue;
        let isGeofence;
        if(deviceAssociated) {
            geofence = geofences?.[deviceAssociated?.geofenceIds?.[0]];
        } else { 
            geofence = geofences?.[item?.geofenceIds?.[0]];
        }

        addressValue = geofence?.name;
        isGeofence = Boolean(addressValue);
        if(!addressValue) {
            if(positionDeviceAssociated) {
                addressValue = formatAddressOneLine(positionDeviceAssociated?.address);
            } else {
                addressValue = position ? formatAddressOneLine(position?.address) : '';
            }
        }

        return {
            allData: { item, deviceAssociated, positionDeviceAssociated, position, geofence },
            itemStatus: { item, position: positionDeviceAssociated || position },
            itemCategory: item?.category && formatDeviceCategory(item?.category),
            itemLabel: item?.name,
            toolDeviceAssociated: { deviceAssociated, positionDeviceAssociated },
            itemAddress: { addressValue, isGeofence, expired: !Boolean(item.detectedBy) },
            itemGroup: item?.groupId ? groups?.[item?.groupId]?.name : '',
            itemLastData: item.lastUpdate,
        };
    };

    return { extractBeaconData };
};

export default useBeaconData;