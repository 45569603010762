import React from 'react';

import MovementStatus from 'components/common/MovementStatus';
import { AccountCircleOutlined, KeyboardArrowDown, KeyboardArrowUp, } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import None from 'components/common/None';
import GeofenceOrAddress from 'components/common/GeofenceOrAddress';
import CheckingProgression from './CheckingProgression';

export const getColumns = (t, theme, globalClasses, formatTime) => [ 
    {
        accessor: 'itemStatus',
        Header: t('itemStatusTitle'),
        Cell: ({ cell: { value }}) => <AccountCircleOutlined style={{ color: (value % 2 !== 0) ? theme.palette.on.main : theme.palette.off.main }} />,
        disableSortBy: true,
        props: {
            className: globalClasses.tableCellFirst,
            style: {
                width: '5%',
            }
        },
    },
    {
        accessor: 'stafferLastname',
        Header: t('stafferLastname'),
        props: {
            style: {
                width: '10%',
            }
        },
    },
    {
        accessor: 'stafferFirstname',
        Header: t('stafferFirstname'),
        props: {
            style: {
                width: '10%',
            }
        },
    },
    {
        accessor: 'startTime',
        Header: t('itemStartTime'),
        Cell: ({ cell: { value }}) => value ? formatTime(new Date(value)) : <None/>,
        props: {
            style: {
                width: '9%',
            }
        },
    },
    {
        accessor: 'endTime',
        Header: t('itemEndTime'),
        Cell: ({ cell: { value }}) => value ? formatTime(new Date(value)) : <None/>,
        props: {
            style: {
                width: '9%',
            }
        },
    },
    {
        accessor: 'address',
        Header: t('itemAddress'),
        Cell: ({ cell: {value : { addressValue, isGeofence } } }) => <GeofenceOrAddress addressValue={addressValue} isGeofence={isGeofence}/>,
        props: {
            style: {
                width: '20%',
            }
        },
        headerProps: {
            className: globalClasses.tableAddressHeader,
        },
    },
    {
        accessor: 'device',
        Header: t('itemDevice'),
        Cell: ({ cell: { value: { device, position } }}) => ( device 
            ? (
                <span>
                    <>  
                        <MovementStatus device={device} position={position} className={globalClasses.decorativeIcon} />
                        {device?.name}
                    </>
                </span>
            )
            : (
                <span className={globalClasses.tableIconColumn}><None/></span>
            )
        ),
        props: {
            style: {
                width: '18%',
            }
        },
        headerProps: {
            className: globalClasses.tableAddressHeader,
        }
    },
    {
        Header: () => null,
        accessor: 'checkingTimestamps',
        Cell: ({ cell: { value }}) => <CheckingProgression checkingTimestamps={value}/>,
        disableSortBy: true, 
        props: {
            style: {
                width: '15%',
            }
        },
    },
    {
        Header: () => null,
        accessor: 'itemStatus',
        id: 'expander',
        Cell: ({ row, cell: { value } }) => (
            <IconButton {...row.getToggleRowExpandedProps({color: 'primary', size: 'large', disabled: value < 1})} >
                { row.isExpanded 
                    ? <KeyboardArrowUp/>
                    : <KeyboardArrowDown/>
                }
            </IconButton>
        ),    
        disableSortBy: true,    
        props: {
            className: globalClasses.tableCellExpander,
            style: {
                width: '4%',
            }
        }, 
    },
];