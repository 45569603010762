import React from 'react';
import { MenuItem, Box, Paper, TextField, Autocomplete } from '@mui/material';
import { ResponsiveContainer } from 'recharts';

import useChartReport from './useChartReport';
import Graph from './Graph';
import PeriodSelector from 'components/common/PeriodSelector';
import ReportLayoutPage from '../ReportLayoutPage';
import ReportShowButton from '../ReportShowButton';
import FileExportReportButtons from '../FileExportReportButtons';
import { sortByThenBy } from 'utils/sort';


const ChartReportPage = () => {
    const { 
        pdfData, 
        t, 
        formatDeviceCategory,
        loading, 
        globalClasses, 
        cx, 
        items, 
        geofences, 
        deviceId, 
        setDeviceId, 
        period, 
        setPeriod, 
        from, 
        setFrom, 
        to, 
        setTo, 
        type,
        setType,
        devices, 
        getFiltersKey,    
        tasks,
        handleSubmit,
        displayDisabled,
        pdfDisabled,
        excelDisabled,
        emailDisabled,
        ...rest } = useChartReport();

    return (
        <ReportLayoutPage loading={loading} filter={
            <div className={globalClasses.rootReportEngineContainer}>
                <div className={globalClasses.reportFiltersContainer}>
                    <Autocomplete 
                        options={devices.sort((deviceA, deviceB) => sortByThenBy(deviceA.category, deviceA.name.toLowerCase(), deviceB.category, deviceB.name.toLowerCase()))}
                        groupBy={d => formatDeviceCategory(d?.category)}
                        getOptionLabel={d => d?.name || ''}
                        value={devices.find(d => d.id === deviceId)}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        onChange={(_, value) => setDeviceId(value?.id || null)}
                        renderInput={(params) => <TextField {...params} label={t('reportDevice')} variant='outlined'/>}
                    />
                    <PeriodSelector 
                        period={period} 
                        setPeriod={setPeriod} 
                        from={from}
                        setFrom={setFrom}
                        to={to}
                        setTo={setTo}
                    />
                     <TextField 
                        variant="outlined" 
                        select
                        value={type} 
                        onChange={e => setType(e.target.value)}
                        label={t('reportChartType')}
                    >
                        <MenuItem value="speed">{t('positionSpeed')}</MenuItem>
                        <MenuItem value="accuracy">{t('positionAccuracy')}</MenuItem>
                        <MenuItem value="altitude">{t('positionAltitude')}</MenuItem>
                    </TextField>
                    <ReportShowButton
                        disabled={displayDisabled}
                        handleSubmit={handleSubmit}
                    />
                </div>
                <div className={globalClasses.reportButtonsContainer}>
                    <FileExportReportButtons
                        pdfDisabled={pdfDisabled}
                        excelDisabled={excelDisabled}
                        emailDisabled={emailDisabled}
                        tasks={tasks}
                        handleSubmit={handleSubmit}
                        getFiltersKey={getFiltersKey}
                    />
                </div>
            </div>
        }
    >
            <Paper>
                <Box height={400}>
                    <ResponsiveContainer>
                        <Graph t={t} type={type} items={items} {...rest} />
                    </ResponsiveContainer>
                </Box>
            </Paper>
        </ReportLayoutPage>
    )
}

export default ChartReportPage;
