import React, { useState, useEffect } from 'react';
import {
    Collapse,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material';

import { magellanV1 } from 'api/magellan';
import { useGlobalStyles } from 'context/StylesContext';
import { makeStyles } from 'tss-react/mui';
import useAlertMessage from 'hooks/useAlertMessage';
import GeofenceOrAddress from 'components/common/GeofenceOrAddress';
import { getGeofenceOrAddress } from 'utils/address';
import MovementStatus from 'components/common/MovementStatus';
import None from 'components/common/None';

const useStyles = makeStyles()(theme => ({
    detailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    empty: {
        width: '40%'
    },
    checkingTime: {
        width:' 10%',
    },
    checkingAddress: {
        width:' 25%',
    }, 
    checkingDevice: {
        width: '25%',
    }
}));

const DetailPresenceRow = ({ geofences, devices, formatAddressOneLine, formatTime, visibleColumns, itemRow }) => {
    const { classes : globalClasses, } = useGlobalStyles();
    const { classes } = useStyles();
    const { setAlertMessage, dataLoadingErrorAlertMessage } = useAlertMessage();
    const [checkings, setCheckings] = useState([]);

    useEffect(() => {
        let from = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
        let to = new Date(new Date().setHours(23, 59, 59, 999)).toISOString();
        let stafferId = itemRow.original.allData.item.id;
        magellanV1.get('/api/checkings', { params: { from, to, stafferId }})
            .then(response => setCheckings(response.data[stafferId]))
            .catch(error => setAlertMessage(dataLoadingErrorAlertMessage));
    }, [itemRow.original.itemStatus]);

    return (
        <TableRow>
            <TableCell 
                colSpan={visibleColumns.length}
                padding="none"
            >
                <Collapse in={itemRow.isExpanded && checkings.length > 0}>
                    <Table>
                        <TableBody>
                            { checkings
                                .sort((checking1, checking2) => checking1.timestamp < checking2.timestamp ? 1 : -1)
                                .map(checking => {
                                let position = checking?.position;
                                let device = devices?.[checking?.deviceId];
                                let geofence = geofences?.[position?.attributes?.geofenceIds[0]];
                                let { isGeofence, addressValue } = getGeofenceOrAddress(geofence, position, formatAddressOneLine)
                                
                                return (
                                    <TableRow key={checking.timestamp}>
                                        <TableCell className={classes.empty}/>
                                        <TableCell className={classes.checkingTime}>{formatTime(new Date(checking.timestamp))}</TableCell>
                                        <TableCell className={classes.checkingAddress}><GeofenceOrAddress isGeofence={isGeofence} addressValue={addressValue}/></TableCell>
                                        <TableCell className={classes.checkingDevice}>{ device 
                                            ? (
                                                <span>
                                                    <MovementStatus device={device} position={position} className={globalClasses.decorativeIcon} />
                                                    {device?.name}
                                                </span>
                                            )
                                            : <None/>
                                        }
                                        </TableCell>
                                    </TableRow>                                        
                                );
                            })}
                        </TableBody>
                    </Table>
                </Collapse>
            </TableCell>
        </TableRow> 
    );
};

export default DetailPresenceRow;