import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {magellanV0} from 'api/magellan';
import useAlertMessage from 'hooks/useAlertMessage';
import useTranslation from 'hooks/useTranslation';
import EditCollectionView from '../../common/EditCollectionView';
import Loading from '../../common/Loading';

const useStyles = makeStyles()(theme => ({
    columnAction: {
        width: theme.spacing(1),
        padding: theme.spacing(0, 1),
    },
}));

const GroupsView = ({ updateTimestamp, onMenuClick, displayAddButton }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);  
    const { setAlertMessage, dataLoadingErrorAlertMessage } = useAlertMessage();

    useEffect(() => {
        magellanV0.get('/api/groups', {headers: {'Accept': 'application/json'}})
            .then(response => {
                setItems(response.data);
            })
            .catch(err => {
                setAlertMessage(dataLoadingErrorAlertMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [updateTimestamp]);

    return <>
    <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>{t('sharedName')}</TableCell>
                    <TableCell className={classes.columnAction} />
                </TableRow>
            </TableHead>
            <TableBody>
                {loading
                    ? <TableRow><TableCell colSpan="100%"><Loading/></TableCell></TableRow>
                    : items
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell className={classes.columnAction} padding="none">
                                    <IconButton
                                        color='primary'
                                        onClick={(event) => onMenuClick(event.currentTarget, item.id)}
                                        size="large">
                                        <MoreVertIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))
                }               
            </TableBody>
        </Table>
    </TableContainer>
    {!loading && displayAddButton(-1)}
    </>;
}

const GroupsPage = () => {
    return (
        <EditCollectionView content={GroupsView} editPath="group" endpoint="groups"/>
    );
}

export default GroupsPage;
