import React from 'react';
import { useParams } from 'react-router-dom';

import { coordinatesToPolygonWkt } from '../../home/map/mapUtil';
import CreateGeofence from './CreateGeofence';
import UpdateGeofence from './UpdateGeofence';

const GeofencePage = ({ role }) => {
    const { id } = useParams();

    const filterDataForServer = data => {
        let item = {...data};  
        delete item.groups;
        item.id = id;    
        //item.area = coordinatesToPolygonWkt(data.area);
        return item;   
    }

    return (id) 
        ? <UpdateGeofence id={ id } filterDataForServer={filterDataForServer} role={role} /> 
        : <CreateGeofence filterDataForServer={filterDataForServer}/>;
};

export default GeofencePage;