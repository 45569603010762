import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import AccountPage from '../components/settings/AccountPage';
import NotificationsPage from '../components/settings/notification/NotificationsPage';
import NotificationPage from '../components/settings/notification/NotificationPage';
import GroupsPage from '../components/settings/group/GroupsPage';
import GroupPage from '../components/settings/group/GroupPage';
import { UserAndManagerEnabled } from '../authorization';
import { ManagerEnabled } from '../authorization';

const SettingsRoutes = () => {
    const AccountPageForUserAndManager = UserAndManagerEnabled(AccountPage);
    const NotificationsPageForUserAndManager = UserAndManagerEnabled(NotificationsPage);
    const NotificationPageForUserAndManager = UserAndManagerEnabled(NotificationPage);
    const GroupsForManager = ManagerEnabled(GroupsPage);
    const GroupForManager = ManagerEnabled(GroupPage);

    return (
        <Routes>
            <Route exact path="account/:id" element={<AccountPageForUserAndManager/>}/>
            <Route exact path="notifications" element={<NotificationsPageForUserAndManager/>}/>
            <Route exact path="notification">
                <Route exact path="" element={<NotificationPageForUserAndManager/>}/>
                <Route exact path=":id" element={<NotificationPageForUserAndManager/>}/>
            </Route>
            <Route exact path="groups" element={<GroupsForManager/>}/>
            <Route exact path="group">
                <Route exact path="" element={<GroupForManager/>}/>
                <Route exact path=":id" element={<GroupForManager/>}/>
            </Route>
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    )
}

export default SettingsRoutes;