import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useTranslation from 'hooks/useTranslation';
import ItemTable from '../ItemTable';
import { BeaconsColumns, DeviceStatus, DeviceType, Filters, Parameters } from 'utils/constant';
import { useGlobalStyles } from 'context/StylesContext';
import { makeStyles } from 'tss-react/mui';
import useBeaconData from 'hooks/useBeaconData';
import ItemRow from 'components/common/ItemRow';
import { devicesActions } from 'store';
import { getColumns, getData } from '.';
import { useTable, useSortBy, useExpanded, usePagination, useFilters as useTableFilters, useGlobalFilter } from 'react-table';
import { Button, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Menu, TablePagination } from '@mui/material';
import GlobalSearch from 'components/common/GlobalSearch';
import { globalFilterFunction, setTableFilters } from 'utils/filters';
import useFilters from 'hooks/useFilters';
import { Check, FilterAlt } from '@mui/icons-material';
import useMenuAnchor from 'hooks/useMenuAnchor';
import useFormat from 'hooks/useFormat';
import useMemoizePagination from 'hooks/useMemoizePagination';


const useStyles = makeStyles()(theme => ({
    iconStatusContainer: {
        justifyContent: 'flex-start'
    }
}));

const deviceTypesTakenIntoAccount = [
    DeviceType.TOOL,
];

const ToolsPage = () => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const { classes: globalClasses, cx } = useGlobalStyles();
    const { formatDeviceCategory } = useFormat();
    const { menuAnchor, handleMenuOpen, handleMenuClose } = useMenuAnchor();
    const { devices, geofences, positions, groups } = useSelector(state => ({ 
        devices: Object.values(state.devices.items),
        geofences: state.geofences.items, 
        positions: state.positions.items, 
        groups: state.groups.items 
    }));
    const filters = useSelector(state => state.devices.tools.filters);
    const pagination = useSelector(state => state.devices.tools.pagination);
    const dispatch = useDispatch();
    const items = devices.filter(device => deviceTypesTakenIntoAccount.includes(device.category));
    const { extractBeaconData } = useBeaconData(devices, positions, geofences, groups);    
    const columns = useMemo(() => getColumns(t, cx, classes, globalClasses), []);
    const data = useMemo(() => getData(items, extractBeaconData), [JSON.stringify(items)]);
    const defaultColumn = useMemo(() => ({
        Filter: () => null
    }), []);
    const { 
        toggleAllRowsExpanded,
        setPageSize,
        gotoPage,
        rows,
        setAllFilters: setOtherTableFilters,
        setGlobalFilter: setGlobalTableFilter,
        state: {
            pageIndex,
            pageSize,
        },
        ...tableRest
    } = useTable(
        { 
            columns, 
            data, 
            autoResetExpanded: false, 
            autoResetSortBy: false,
            autoResetPage: false,
            autoResetFilters: false,
            defaultColumn,
            globalFilter: globalFilterFunction,
            getRowId: useCallback(row => row.allData.item.id, []),
            initialState: {
                pageSize: 50,
                pageIndex: 0,
            }
        }, 
        useTableFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
    );    
    const { handleFilterChange, handleGlobalSearchChange } = useFilters({ 
        filters, 
        filtersId: Parameters.ASSET_FILTERS, 
        setTableFilters: (newFilters) => setTableFilters(newFilters, setGlobalTableFilter, setOtherTableFilters), 
        setFiltersInCache: (newFilters) => dispatch(devicesActions.refreshToolsFilters(newFilters)),
        resetPage: () => dispatch(devicesActions.updateToolsPaginationPage(0)),
    });
    const { handleChangePage, handleChangeLimit } = useMemoizePagination({ 
        parameterKey: Parameters.TOOL_PAGINATION,
        gotoPage,
        setPageSize,
        savedPage: pagination.page,
        savedLimit: pagination.limit,
        savePage: (newPage) => dispatch(devicesActions.updateToolsPaginationPage(newPage)),
        saveLimit: (newLimit) => dispatch(devicesActions.updateToolsPaginationLimit(newLimit)),
    }); 

    const renderTable = () => {
        return (
            <ItemTable 
                rows={rows}
                {...tableRest}
            >
                { ({itemRow, ...rest}) => {
                    let rowProps = {};
                    if(itemRow.values.itemStatus.item.detectedBy === null) {
                        rowProps = { className: globalClasses.notLinked };
                    } 

                    return (
                        <ItemRow 
                            key={itemRow.id} 
                            itemRow={itemRow} 
                            rowProps={rowProps}
                            onClick={() =>{
                                if(itemRow.isExpanded) {
                                    itemRow.toggleRowExpanded(false);
                                    dispatch(devicesActions.deselect());
                                } else {
                                    toggleAllRowsExpanded(false);
                                    itemRow.toggleRowExpanded(true);
                                    dispatch(devicesActions.select(itemRow?.id));
                                }
                            }}
                            {...rest}
                        />
                    );
                }}
            </ItemTable>
        );
    };

    return (
        <div className={globalClasses.tablePageLayout}>
            <div className={globalClasses.tableEngineContainer}>
                <GlobalSearch
                    filters={filters}
                    setFilters={(getFilters) => handleGlobalSearchChange(getFilters)}
                    availableFilters={[
                        'all', 
                        BeaconsColumns.ITEM_LABEL,  
                        BeaconsColumns.ITEM_GROUP, 
                        BeaconsColumns.ITEM_CATEGORY
                    ]}
                />
                <div className={globalClasses.tableEngineFilterAndPagination} style={{overflow: 'auto'}}>
                    <div onClick={handleMenuOpen} className={globalClasses.tableEngineFilterButton}>
                        <span>{t('filters')}</span>
                        <FilterAlt className={globalClasses.actionIcon}/>
                    </div>
                    <Menu
                        MenuListProps={{
                            className: globalClasses.listCheckableItemList
                        }}
                        anchorEl={menuAnchor}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={Boolean(menuAnchor)}
                        onClose={handleMenuClose}
                    >
                        <List 
                            // subheader={
                            //     <ListSubheader className={classes.filterCategory}>
                            //         <ListItemIcon className={globalClasses.listCheckableItemCheck}>
                            //             <Check className={globalClasses.deselectedIcon}/>
                            //         </ListItemIcon>
                            //         {t('itemStatusTitle')}
                            //     </ListSubheader>
                            // }
                            disablePadding
                        >
                            { [DeviceStatus.ONLINE, DeviceStatus.OFFLINE].map(value => (
                                <ListItemButton
                                    className={globalClasses.listCheckableItemItem}
                                    key={value}
                                    dense
                                    onClick={(event) => handleFilterChange(BeaconsColumns.ITEM_STATUS, event.currentTarget.textContent)}
                                >
                                    <ListItemIcon className={globalClasses.listCheckableItemCheck}>
                                        { filters.find(f => f.filterKey === BeaconsColumns.ITEM_STATUS)?.values?.includes(t(value))
                                            ? <Check/>
                                            : <Check className={globalClasses.deselectedIcon}/>
                                        }
                                    </ListItemIcon>
                                    <span className={globalClasses.listCheckableItemText}>{t(value)}</span>
                                </ListItemButton>
                            ))}
                        </List>
                    </Menu>
                    <TablePagination
                        page={pageIndex}
                        rowsPerPage={pageSize}
                        count={rows.length}
                        onPageChange={(_, newPageIndex) => handleChangePage(newPageIndex)}
                        onRowsPerPageChange={(event) => handleChangeLimit(event.target.value)}
                        component="div"
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('paginationOf')} ${count !== -1 ? count : `${t('paginationMoreThan')} ${to}`}`}
                        labelRowsPerPage={t('paginationRowsPerPage')}
                    />
                </div>
            </div>
            {renderTable()}
        </div>
    );
};

export default ToolsPage;