import React from 'react';
import _ from 'lodash';
import { PDFViewer } from '@react-pdf/renderer';
import TemplatePdf from './TemplatePdf';
import useTranslation from 'hooks/useTranslation';
import { useTable } from 'react-table';
import useFormat from 'hooks/useFormat';
import { useSelector } from 'react-redux';

const DisplayTemplate = () => {
    const { t } = useTranslation();
    const { formatDate, formatAddressOneLine } = useFormat();
    const companyName = useSelector(state => state.company.item?.name);
    const companyAddress = useSelector(state => state.company.item?.address, 
        (prev, next) =>  _.isEqual(prev, next));

    const columns = React.useMemo(() => [
        {
            accessor: 'eventTime',
            Header: 'Date', 
            Cell: ({ cell: { value } }) => formatDate(value),
            cellWidth: '20%'
        },
        {
            accessor: 'type',
            Header: 'Type',
            Cell: ({ cell: { value } }) => t(value),
            cellWidth: '30%',
        },
        {
            accessor: 'deviceId',
            Header: 'Id du device',
            cellWidth: '40%'
        }
    ], []);

    let filters = [
        {
            key: 'Device:',
            value: 'Voiture Bruno'
        },
        {
            key: 'From:',
            value: '22/05/2022 09:44'
        },
        {
            key: 'To:',
            value: '22/05/2022 10:44'
        }
    ];

    const data = React.useMemo(() => events, []);
    const { rows, headerGroups, prepareRow } = useTable({ columns, data });

    return (
            <PDFViewer style={{ width: '100%', height: '100%'}}>
                <TemplatePdf 
                    headerGroups={headerGroups} 
                    rows={rows} 
                    prepareRow={prepareRow}
                    reportTitle={"événements"}
                    companyName={companyName}
                    companyAddress={formatAddressOneLine(companyAddress)}
                    filters={filters} 
                    isPortrait={true}
                />    
            </PDFViewer>
        
    );
};

const events = [
    {
        "id": 353115,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T06:02:49.000+00:00",
        "positionId": 3695663,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 353116,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceExit",
        "eventTime": "2022-05-20T06:07:50.000+00:00",
        "positionId": 3695664,
        "geofenceId": 19,
        "maintenanceId": 0
    },
    {
        "id": 353448,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceEnter",
        "eventTime": "2022-05-20T06:50:46.000+00:00",
        "positionId": 3696986,
        "geofenceId": 24,
        "maintenanceId": 0
    },
    {
        "id": 353449,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T06:50:46.000+00:00",
        "positionId": 3696986,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 355858,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceExit",
        "eventTime": "2022-05-20T10:52:16.000+00:00",
        "positionId": 3704338,
        "geofenceId": 24,
        "maintenanceId": 0
    },
    {
        "id": 355894,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T11:25:12.000+00:00",
        "positionId": 3704461,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 356287,
        "attributes": {},
        "deviceId": 118,
        "type": "deviceMoving",
        "eventTime": "2022-05-20T11:25:12.000+00:00",
        "positionId": 3704461,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 356502,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T14:42:06.000+00:00",
        "positionId": 3709229,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357199,
        "attributes": {},
        "deviceId": 118,
        "type": "deviceStopped",
        "eventTime": "2022-05-20T16:43:07.000+00:00",
        "positionId": 3712982,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357200,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceEnter",
        "eventTime": "2022-05-20T16:43:07.000+00:00",
        "positionId": 3712982,
        "geofenceId": 19,
        "maintenanceId": 0
    },
    {
        "id": 357206,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T16:43:08.000+00:00",
        "positionId": 3713017,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357207,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceExit",
        "eventTime": "2022-05-20T17:06:56.000+00:00",
        "positionId": 3713018,
        "geofenceId": 19,
        "maintenanceId": 0
    },
    {
        "id": 357234,
        "attributes": {},
        "deviceId": 118,
        "type": "deviceMoving",
        "eventTime": "2022-05-20T17:06:56.000+00:00",
        "positionId": 3713018,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357235,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T17:23:54.000+00:00",
        "positionId": 3713309,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357263,
        "attributes": {},
        "deviceId": 118,
        "type": "deviceStopped",
        "eventTime": "2022-05-20T19:24:49.000+00:00",
        "positionId": 3713587,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357264,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T19:24:50.000+00:00",
        "positionId": 3713588,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357268,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T19:35:15.000+00:00",
        "positionId": 3713600,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357271,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T19:38:52.000+00:00",
        "positionId": 3713616,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357321,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceEnter",
        "eventTime": "2022-05-20T20:00:29.000+00:00",
        "positionId": 3713970,
        "geofenceId": 19,
        "maintenanceId": 0
    },
    {
        "id": 357322,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T20:05:47.000+00:00",
        "positionId": 3713973,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357344,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceExit",
        "eventTime": "2022-05-20T20:41:02.000+00:00",
        "positionId": 3714342,
        "geofenceId": 19,
        "maintenanceId": 0
    },
    {
        "id": 357345,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T20:41:02.000+00:00",
        "positionId": 3714342,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357375,
        "attributes": {},
        "deviceId": 118,
        "type": "deviceMoving",
        "eventTime": "2022-05-20T20:41:02.000+00:00",
        "positionId": 3714342,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357377,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T20:46:34.000+00:00",
        "positionId": 3714690,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357376,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T20:52:18.000+00:00",
        "positionId": 3714689,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357419,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T21:10:30.000+00:00",
        "positionId": 3715334,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357418,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T21:16:32.000+00:00",
        "positionId": 3715332,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357446,
        "attributes": {},
        "deviceId": 118,
        "type": "deviceStopped",
        "eventTime": "2022-05-20T21:39:05.000+00:00",
        "positionId": 3715594,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357447,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceEnter",
        "eventTime": "2022-05-20T21:39:05.000+00:00",
        "positionId": 3715594,
        "geofenceId": 19,
        "maintenanceId": 0
    },
    {
        "id": 357448,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T21:39:05.000+00:00",
        "positionId": 3715594,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 353115,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T06:02:49.000+00:00",
        "positionId": 3695663,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 353116,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceExit",
        "eventTime": "2022-05-20T06:07:50.000+00:00",
        "positionId": 3695664,
        "geofenceId": 19,
        "maintenanceId": 0
    },
    {
        "id": 353448,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceEnter",
        "eventTime": "2022-05-20T06:50:46.000+00:00",
        "positionId": 3696986,
        "geofenceId": 24,
        "maintenanceId": 0
    },
    {
        "id": 353449,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T06:50:46.000+00:00",
        "positionId": 3696986,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 355858,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceExit",
        "eventTime": "2022-05-20T10:52:16.000+00:00",
        "positionId": 3704338,
        "geofenceId": 24,
        "maintenanceId": 0
    },
    {
        "id": 355894,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T11:25:12.000+00:00",
        "positionId": 3704461,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 356287,
        "attributes": {},
        "deviceId": 118,
        "type": "deviceMoving",
        "eventTime": "2022-05-20T11:25:12.000+00:00",
        "positionId": 3704461,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 356502,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T14:42:06.000+00:00",
        "positionId": 3709229,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357199,
        "attributes": {},
        "deviceId": 118,
        "type": "deviceStopped",
        "eventTime": "2022-05-20T16:43:07.000+00:00",
        "positionId": 3712982,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357200,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceEnter",
        "eventTime": "2022-05-20T16:43:07.000+00:00",
        "positionId": 3712982,
        "geofenceId": 19,
        "maintenanceId": 0
    },
    {
        "id": 357206,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T16:43:08.000+00:00",
        "positionId": 3713017,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357207,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceExit",
        "eventTime": "2022-05-20T17:06:56.000+00:00",
        "positionId": 3713018,
        "geofenceId": 19,
        "maintenanceId": 0
    },
    {
        "id": 357234,
        "attributes": {},
        "deviceId": 118,
        "type": "deviceMoving",
        "eventTime": "2022-05-20T17:06:56.000+00:00",
        "positionId": 3713018,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357235,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T17:23:54.000+00:00",
        "positionId": 3713309,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357263,
        "attributes": {},
        "deviceId": 118,
        "type": "deviceStopped",
        "eventTime": "2022-05-20T19:24:49.000+00:00",
        "positionId": 3713587,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357264,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T19:24:50.000+00:00",
        "positionId": 3713588,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357268,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T19:35:15.000+00:00",
        "positionId": 3713600,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357271,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T19:38:52.000+00:00",
        "positionId": 3713616,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357321,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceEnter",
        "eventTime": "2022-05-20T20:00:29.000+00:00",
        "positionId": 3713970,
        "geofenceId": 19,
        "maintenanceId": 0
    },
    {
        "id": 357322,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T20:05:47.000+00:00",
        "positionId": 3713973,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357344,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceExit",
        "eventTime": "2022-05-20T20:41:02.000+00:00",
        "positionId": 3714342,
        "geofenceId": 19,
        "maintenanceId": 0
    },
    {
        "id": 357345,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T20:41:02.000+00:00",
        "positionId": 3714342,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357375,
        "attributes": {},
        "deviceId": 118,
        "type": "deviceMoving",
        "eventTime": "2022-05-20T20:41:02.000+00:00",
        "positionId": 3714342,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357377,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T20:46:34.000+00:00",
        "positionId": 3714690,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357376,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T20:52:18.000+00:00",
        "positionId": 3714689,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357419,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T21:10:30.000+00:00",
        "positionId": 3715334,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357418,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T21:16:32.000+00:00",
        "positionId": 3715332,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357446,
        "attributes": {},
        "deviceId": 118,
        "type": "deviceStopped",
        "eventTime": "2022-05-20T21:39:05.000+00:00",
        "positionId": 3715594,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357447,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceEnter",
        "eventTime": "2022-05-20T21:39:05.000+00:00",
        "positionId": 3715594,
        "geofenceId": 19,
        "maintenanceId": 0
    },
    {
        "id": 357448,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T21:39:05.000+00:00",
        "positionId": 3715594,
        "geofenceId": 0,
        "maintenanceId": 0
    },{
        "id": 353115,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T06:02:49.000+00:00",
        "positionId": 3695663,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 353116,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceExit",
        "eventTime": "2022-05-20T06:07:50.000+00:00",
        "positionId": 3695664,
        "geofenceId": 19,
        "maintenanceId": 0
    },
    {
        "id": 353448,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceEnter",
        "eventTime": "2022-05-20T06:50:46.000+00:00",
        "positionId": 3696986,
        "geofenceId": 24,
        "maintenanceId": 0
    },
    {
        "id": 353449,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T06:50:46.000+00:00",
        "positionId": 3696986,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 355858,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceExit",
        "eventTime": "2022-05-20T10:52:16.000+00:00",
        "positionId": 3704338,
        "geofenceId": 24,
        "maintenanceId": 0
    },
    {
        "id": 355894,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T11:25:12.000+00:00",
        "positionId": 3704461,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 356287,
        "attributes": {},
        "deviceId": 118,
        "type": "deviceMoving",
        "eventTime": "2022-05-20T11:25:12.000+00:00",
        "positionId": 3704461,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 356502,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T14:42:06.000+00:00",
        "positionId": 3709229,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357199,
        "attributes": {},
        "deviceId": 118,
        "type": "deviceStopped",
        "eventTime": "2022-05-20T16:43:07.000+00:00",
        "positionId": 3712982,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357200,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceEnter",
        "eventTime": "2022-05-20T16:43:07.000+00:00",
        "positionId": 3712982,
        "geofenceId": 19,
        "maintenanceId": 0
    },
    {
        "id": 357206,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T16:43:08.000+00:00",
        "positionId": 3713017,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357207,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceExit",
        "eventTime": "2022-05-20T17:06:56.000+00:00",
        "positionId": 3713018,
        "geofenceId": 19,
        "maintenanceId": 0
    },
    {
        "id": 357234,
        "attributes": {},
        "deviceId": 118,
        "type": "deviceMoving",
        "eventTime": "2022-05-20T17:06:56.000+00:00",
        "positionId": 3713018,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357235,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T17:23:54.000+00:00",
        "positionId": 3713309,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357263,
        "attributes": {},
        "deviceId": 118,
        "type": "deviceStopped",
        "eventTime": "2022-05-20T19:24:49.000+00:00",
        "positionId": 3713587,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357264,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T19:24:50.000+00:00",
        "positionId": 3713588,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357268,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T19:35:15.000+00:00",
        "positionId": 3713600,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357271,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T19:38:52.000+00:00",
        "positionId": 3713616,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357321,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceEnter",
        "eventTime": "2022-05-20T20:00:29.000+00:00",
        "positionId": 3713970,
        "geofenceId": 19,
        "maintenanceId": 0
    },
    {
        "id": 357322,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T20:05:47.000+00:00",
        "positionId": 3713973,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357344,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceExit",
        "eventTime": "2022-05-20T20:41:02.000+00:00",
        "positionId": 3714342,
        "geofenceId": 19,
        "maintenanceId": 0
    },
    {
        "id": 357345,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T20:41:02.000+00:00",
        "positionId": 3714342,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357375,
        "attributes": {},
        "deviceId": 118,
        "type": "deviceMoving",
        "eventTime": "2022-05-20T20:41:02.000+00:00",
        "positionId": 3714342,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357377,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T20:46:34.000+00:00",
        "positionId": 3714690,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357376,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T20:52:18.000+00:00",
        "positionId": 3714689,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357419,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOn",
        "eventTime": "2022-05-20T21:10:30.000+00:00",
        "positionId": 3715334,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357418,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T21:16:32.000+00:00",
        "positionId": 3715332,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357446,
        "attributes": {},
        "deviceId": 118,
        "type": "deviceStopped",
        "eventTime": "2022-05-20T21:39:05.000+00:00",
        "positionId": 3715594,
        "geofenceId": 0,
        "maintenanceId": 0
    },
    {
        "id": 357447,
        "attributes": {},
        "deviceId": 118,
        "type": "geofenceEnter",
        "eventTime": "2022-05-20T21:39:05.000+00:00",
        "positionId": 3715594,
        "geofenceId": 19,
        "maintenanceId": 0
    },
    {
        "id": 357448,
        "attributes": {},
        "deviceId": 118,
        "type": "ignitionOff",
        "eventTime": "2022-05-20T21:39:05.000+00:00",
        "positionId": 3715594,
        "geofenceId": 0,
        "maintenanceId": 0
    },
];

export default DisplayTemplate;