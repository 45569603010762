import React, { useRef, useLayoutEffect, useContext } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { MapContext } from 'context/MapContext';

const Map = ({ children, fitBounds, open = true }) => {
    const targetRef = useRef();
    const { width, height } = useResizeDetector({ 
        targetRef,
        refreshRate: 50
    });
    const { map, mapReady } = useContext(MapContext);
    const mapReadyFirstTime = useRef(true);
   
    useLayoutEffect(() => {
        let currentEl;
        if(mapReady && open) {
            currentEl = targetRef.current;
            currentEl.appendChild(map._container);
            map.resize();

            return () => {
                if(currentEl?.contains(map._container)){
                    currentEl.removeChild(map._container);
                } 
            };
        }

       
    }, [mapReady, targetRef, open]);

    useLayoutEffect(() => {
        if(mapReady) {
            map.resize();
        }
        
    }, [mapReady, height, width]);

    useLayoutEffect(() => {
        if(mapReady && mapReadyFirstTime.current && fitBounds){
            fitBounds();
            mapReadyFirstTime.current = false;
        }
    }, [mapReady]);

    return (
        <div style={{ width: '100%', height: '100%' }} ref={targetRef}>
            {children}
        </div>
    );
};

export default Map;
