import React from 'react';
import { useFormik } from 'formik';
import { Card, CardHeader, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import useTranslation from 'hooks/useTranslation';
import SelectTextField from 'components/common/SelectTextField';

const useStyles = makeStyles()(theme => ({  
    commentCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
}));

const ToolForm = ({ onSubmit, editionMode, initialValues }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const formik = useFormik({
        initialValues,
        validationSchema: yup.object().shape({
            name: yup.string()
                .trim()
                .required(t('validationRequired'))
                .max(128, `\${max} ${t('validationLengthMax')}`),
            uniqueId: yup.string()
                .trim()
                    .required(t('validationRequired'))
                    .max(128, `\${max} ${t('validationLengthMax')}`),
            brand: yup.string()
                .trim()
                .max(128, `\${max} ${t('validationLengthMax')}`),
            model: yup.string()
                .trim()
                .max(128, `\${max} ${t('validationLengthMax')}`),
            chassisNumber: yup.string()
                .trim()
                .max(32, `\${max} ${t('validationLengthMax')}`),
            comment: yup.string()
                .trim()
                .max(4000, `\${max} ${t('validationLengthMax')}`),
        }),
        onSubmit
    });

    return <>
        <form id="form" onSubmit={e => {
            e.preventDefault();
            formik.submitForm();
        }}>
            <CardHeader title={t('signageProperties')} />
            <Card>
                <TextField 
                    required
                    id="name"
                    name="name"
                    label={t('signageLabel')}
                    disabled={!editionMode}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
                <TextField 
                    required
                    id="uniqueId"
                    name="uniqueId"
                    label={t('signageTrackerIdentifier')}
                    disabled={!editionMode}
                    value={formik.values.uniqueId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.uniqueId && Boolean(formik.errors.uniqueId)}
                    helperText={formik.touched.uniqueId && formik.errors.uniqueId}
                />
            </Card>
            <CardHeader title={t('signageGroup')} />
            <Card>
                <SelectTextField 
                    id="groupId" 
                    name="groupId" 
                    classes={{width: '45%'}}
                    label={t('signageGroup')} 
                    value={formik.values.groupId}
                    disabled={!editionMode} 
                    endpoint="/api/groups"
                    valuePropertyName="id"
                    namePropertyLabel="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.groupId && Boolean(formik.errors.groupId)}
                    helperText={formik.touched.groupId && formik.errors.groupId}
                />   
            </Card>
            <CardHeader title={t('signageCharacteristics')} />
            <Card>
                <TextField 
                    id="brand"
                    name="brand"
                    label={t('signageBrand')}
                    disabled={!editionMode}
                    value={formik.values.brand}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.brand && Boolean(formik.errors.brand)}
                    helperText={formik.touched.brand && formik.errors.brand}
                />
                <TextField 
                    id="model"
                    name="model"
                    label={t('signageModel')}
                    disabled={!editionMode}
                    value={formik.values.model}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.model && Boolean(formik.errors.model)}
                    helperText={formik.touched.model && formik.errors.model}
                />
                <TextField 
                    id="chassisNumber"
                    name="chassisNumber"
                    label={t('signageSerialNumber')}
                    disabled={!editionMode}
                    value={formik.values.chassisNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.chassisNumber && Boolean(formik.errors.chassisNumber)}
                    helperText={formik.touched.chassisNumber && formik.errors.chassisNumber}
                />
            </Card>
            <CardHeader title={t('signageComment')} />
            <Card className={classes.commentCard}>
                    <TextField 
                        id="comment"
                        name="comment"
                        label={t('signageComment')}
                        multiline
                        rows={10}
                        maxRows={35}
                        fullWidth={true} 
                        disabled={!editionMode}
                        value={formik.values.comment}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.comment && Boolean(formik.errors.comment)}
                        helperText={formik.touched.comment && formik.errors.comment}
                    />         
            </Card>
        </form>
    </>;
};

export default ToolForm;