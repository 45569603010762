import useFormat from 'hooks/useFormat';
import React from 'react';
import { makeStyles } from 'tss-react/mui';


const useStyles = makeStyles()(theme => ({
    scoreContainer: {
        display: 'flex',
        width: 'auto',
    },
    text: {
        flex: 1,
        minWidth: 50,
    },
    gaugeContainer: {
        flex: 11,
    },
    gauge: {
        height: 15,
        backgroundColor: theme.palette.secondary.main,
    }
}));

const Gauge = ({ value, displayValue = true }) => {
    const { classes } = useStyles();
    const { formatDecimalNumber } = useFormat();

    return (
        <div className={classes.scoreContainer}>
                {displayValue && <span className={classes.text}>{formatDecimalNumber(value)}</span>}
                <div className={classes.gaugeContainer}>
                    <div className={classes.gauge} style={{ width: `${value}%` }}/>
                </div>
                
        </div>
    );
};

export default Gauge;