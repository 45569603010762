import { useContext } from 'react';

import { AlertMessageContext } from '../context/AlertMessageContext';
import useTranslation from './useTranslation';
import { AlertMessageSeverity } from 'utils/constant';

const useAlertMessage = () => {
    const { t } = useTranslation();
    const { message, setAlertMessage } = useContext(AlertMessageContext);

    const dataLoadingErrorAlertMessage = {
        show: true,
        severity: AlertMessageSeverity.ERROR,
        message: t('dataLoadingError')
    };

    const savedAlertMessage = {
        show: true,
        severity: AlertMessageSeverity.SUCCESS,
        message: t('saved')
    };

    const creationErrorAlertMessage = {
        show: true,
        severity: AlertMessageSeverity.ERROR,
        message: t('creationError')
    };

    const updateErrorAlertMessage = {
        show: true,
        severity: AlertMessageSeverity.ERROR,
        message: t('updateError')
    };

    const addressNotFoundWarningAlertMessage = {
        show: true,
        severity: AlertMessageSeverity.WARNING,
        message: t('addressNotFound')
    };

    const gettingReportErrorAlertMessage = {
        show: true,
        severity: AlertMessageSeverity.ERROR,
        message: t('gettingReportError')
    };

    const sendCommandDelayInfoAlertMessage = {
        show: true,
        severity: AlertMessageSeverity.INFO,
        message: t('commandDelayInfoMessage'),
    };

    const sendCommandErrorAlertMessage = {
        show: true,
        severity: AlertMessageSeverity.ERROR,
        message: t('sendCommandError')
    };

    const timesheetSavedAlertMessage = {
        show: true, 
        severity: AlertMessageSeverity.SUCCESS,
        message: t('timesheetSavedSuccessMessage'),
    };

    const timesheetRemovedAlertMessage = {
        show: true, 
        severity: AlertMessageSeverity.SUCCESS,
        message: t('timesheetRemovedSuccessMessage'),
    };


    return { message, setAlertMessage, dataLoadingErrorAlertMessage, savedAlertMessage, creationErrorAlertMessage, 
        updateErrorAlertMessage, addressNotFoundWarningAlertMessage, gettingReportErrorAlertMessage, sendCommandDelayInfoAlertMessage, sendCommandErrorAlertMessage,
        timesheetSavedAlertMessage, timesheetRemovedAlertMessage,     
    };
}

export default useAlertMessage;