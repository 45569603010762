import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTable } from 'react-table/dist/react-table.development';
import { pdf } from '@react-pdf/renderer';

import { downloadFile } from 'utils/downloadFile';
import useTranslation from 'hooks/useTranslation';
import useFormat from 'hooks/useFormat';
import { useAttributePreference } from 'hooks/usePreferences';
import { AsyncServerTasksType, ReportFormat } from 'utils/constant';
import useReport from 'hooks/useReport';
import { useGlobalStyles } from 'context/StylesContext';
import { magellanV0 } from 'api/magellan';
import TemplatePdf from '../templates/TemplatePdf';
import AddressPdf from '../templates/AddressPdf';
import TablePdf from '../templates/TablePdf';
import useDeviceReport from 'hooks/useDeviceReport';
import NonePdf from '../templates/NonePdf';
import { parseAddress } from 'utils/address';
import { getColumns } from '.';
import { useExpanded, useSortBy } from 'react-table';

const useTripReport = () => {
    const { t } = useTranslation();
    const { formatDistance, formatSpeed, formatDuration, formatDate, formatAddressOneLine, formatDeviceCategory, } = useFormat();
    const { classes: globalClasses, cx} = useGlobalStyles();
    const distanceUnit = useAttributePreference('distanceUnit');
    const speedUnit = useAttributePreference('speedUnit');
    const availableReportActions = [ReportFormat.JSON, ReportFormat.PDF, ReportFormat.EXCEL, ReportFormat.MAIL];
    const { deviceId, setDeviceId, period, setPeriod, from, setFrom, to, setTo, items, setItems, devices, query, pdfReportFilters, getFiltersKey, ...restDeviceReport } = useDeviceReport({ availableReportActions });
    const [stafferId, setStafferId] = useState(null);
    const displayDisabled = !availableReportActions.includes(ReportFormat.JSON) || (!deviceId && !stafferId) || to.diff(from, 'months', true) > 1;
    const pdfDisabled = !availableReportActions.includes(ReportFormat.PDF) || (!deviceId && !stafferId) || to.diff(from, 'months', true) > 1;
    const excelDisabled = !availableReportActions.includes(ReportFormat.EXCEL) || (!deviceId && !stafferId);
    const emailDisabled = !availableReportActions.includes(ReportFormat.MAIL) || (!deviceId && !stafferId);
    if (query && stafferId) {
        query?.append('driverId', stafferId);
    }
    const geofences = useSelector(state => state.geofences.items);
    const staffers = useSelector(state => state.staffers.items);
    const { loading, setLoading, pdfData, setPdfData, companyName, companyAddress, ...restReport } = useReport({
        api: magellanV0,
        report: 'reports/trips', 
        taskType: AsyncServerTasksType.TRIPS_REPORT_EMAIL,
        query,
        getFiltersKey,
        setItems,
    });
    const staffersOptions = Object.values(staffers).sort((stafferA, stafferB) => stafferA.firstname < stafferB.firstname ? -1 : 1);

    const updateStafferId = (newStafferId) => {
        if (newStafferId) {
            setDeviceId(null);
        }
        setStafferId(newStafferId);
    };

    const updateDeviceId = (newDeviceId) => {
        if (newDeviceId) {
            setStafferId(null);
        }
        setDeviceId(newDeviceId);
    };
    const columns = useMemo(() => getColumns(devices, geofences, speedUnit, distanceUnit, globalClasses, cx, t, formatDate, formatSpeed, formatDistance, formatAddressOneLine, formatDuration), []);
    const data = useMemo(() => items, [JSON.stringify(items)]);
    const { 
        toggleAllRowsExpanded,
        visibleColumns, 
        ...tableRest
    } = useTable(
        { 
            columns, 
            data, 
            autoResetExpanded: false, 
            autoResetSortBy: false,
            getRowId: useCallback(row => row.startPositionId, []),
        }, 
        useSortBy,
        useExpanded,
    );
    
    const extractStartAddress = trip => {
        let isGeofence = false;
        let addressValue = geofences?.[trip?.startGeofenceIds?.[0]]?.name;
        if (addressValue) {
            isGeofence = true;
        } else {
            if(trip?.startAddress) {
                let address = trip?.startAddress;
                if(typeof address === 'string') {
                    address = parseAddress(address);
                }
                addressValue = formatAddressOneLine(address);
            }
        }
        return { isGeofence, addressValue }
    };

    const extractEndAddress = trip => {
        let isGeofence = false;
        let addressValue = geofences?.[trip?.endGeofenceIds?.[0]]?.name;
        if (addressValue) {
            isGeofence = true;
        } else {
            if(trip?.endAddress) {
                let address = trip?.endAddress;
                if(typeof address === 'string') {
                    address = parseAddress(address);
                }
                addressValue = formatAddressOneLine(address);
            }
        }
        return { isGeofence, addressValue }
    };

    const pdfReportColumns = React.useMemo(() => [
        {
            accessor: 'itemDeviceName',
            Header: t('reportDevice'), 
            Cell: ({ cell: { value } }) => value || <NonePdf t={t}/>,
            cellWidth: '11%'
        },
        {
            accessor: 'itemStartTime',
            Header: t('reportStartTime'), 
            Cell: ({ cell: { value } }) => formatDate(value),
            cellWidth: '12%'
        },
        {
            accessor: 'itemDriverName',
            Header: t('driverName'), 
            Cell: ({ cell: { value } }) => value || <NonePdf t={t}/>,
            cellWidth: '11%'
        },
        {
            accessor: 'itemStartAddress',
            Header: t('reportStartAddress'),
            Cell: ({ cell: { value: { addressValue, isGeofence } } }) => <AddressPdf addressValue={addressValue} isGeofence={isGeofence} t={t}/>,
            cellWidth: '22%',
        },
        {
            accessor: 'itemEndTime',
            Header: t('reportEndTime'), 
            Cell: ({ cell: { value } }) => formatDate(value),
            cellWidth: '12%'
        },
        {
            accessor: 'itemEndAddress',
            Header: t('reportEndAddress'),
            Cell: ({ cell: { value: { addressValue, isGeofence } } }) => <AddressPdf addressValue={addressValue} isGeofence={isGeofence} t={t}/>,
            cellWidth: '22%',
        },
        {
            accessor: 'itemDistance',
            Header: t('sharedDistance'),
            Cell: ({ cell: { value } }) => formatDistance(value, distanceUnit),
            cellWidth: '8%',
        },
        {
            accessor: 'itemAverageSpeed',
            Header: t('reportAverageSpeed'),
            Cell: ({ cell: { value } }) => formatSpeed(value, speedUnit),
            cellWidth: '8%',
        },
        {
            accessor: 'itemDuration',
            Header: t('reportDuration'),
            Cell: ({ cell: { value } }) => formatDuration(value),
            cellWidth: '5%',
        },
        
    ], []);

    const pdfDataFormatted = useMemo(() => pdfData.map(item => (
        {
            itemDeviceName: devices?.find(device => device.id === item.deviceId)?.name,
            itemStartTime: item.startTime,
            itemStartAddress: extractStartAddress(item),
            itemEndTime: item.endTime,
            itemEndAddress: extractEndAddress(item),
            itemDistance: item.distance,
            itemAverageSpeed: item.averageSpeed,
            itemDuration: item.duration            
        }
    ), [pdfData]));
    
    const { rows, headerGroups, prepareRow } = useTable({ columns: pdfReportColumns, data: pdfDataFormatted });
    
    const renderTemplate = () => {
        return (
            <TemplatePdf 
                reportTitle={t('reportTripsTitlePdf')}
                companyName={companyName}
                companyAddress={formatAddressOneLine(companyAddress)}
                filters={pdfReportFilters} 
                isPortrait={false}
            >
                <TablePdf
                    headerGroups={headerGroups} 
                    rows={rows} 
                    prepareRow={prepareRow}
                />
            </TemplatePdf>
        );
    } 

    useEffect(() => {
        if(rows && rows.length > 0) {
            const doc = renderTemplate();
            const file = pdf(doc);
            file.toBlob().then(blob => {
                downloadFile(blob, 'tripReport.pdf');
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            })
            setPdfData([]);
        }
    }, [JSON.stringify(rows)]); 

    return { 
        t, 
        formatDistance, 
        formatSpeed, 
        formatDuration, 
        formatDate, 
        formatAddressOneLine, 
        formatDeviceCategory,
        globalClasses, 
        cx, 
        distanceUnit, 
        speedUnit, 
        geofences, 
        items, 
        setItems, 
        loading, 
        deviceId, 
        updateDeviceId, 
        period, 
        setPeriod, 
        from, 
        setFrom, 
        to, 
        setTo, 
        devices, 
        pdfReportFilters, 
        getFiltersKey, 
        availableReportActions, 
        staffersOptions,
        stafferId,
        updateStafferId,
        ...restDeviceReport,
        ...restReport,
        displayDisabled,
        excelDisabled,
        pdfDisabled,
        emailDisabled,
        ...tableRest
    };
};

export default useTripReport;