import React from 'react';

import { magellanV1 } from 'api/magellan';
import { MANAGER } from 'authorization/role';
import useAlertMessage from 'hooks/useAlertMessage';
import withSignageLayout from 'hoc/withSignageLayout';
import { useSignage } from 'hooks/useSignage';
import useTranslation from 'hooks/useTranslation';
import Loading from 'components/common/Loading';
import SignageHeader from 'components/common/SignageHeader';
import WarehouseForm from './WarehouseForm';

const UpdateWarehouse = ({ filterDataForServer, role }) => {
    const { setAlertMessage, savedAlertMessage, updateErrorAlertMessage } = useAlertMessage();
    const {editionMode, setEditionMode, item, setItem, loading, setLoading, isReadonly, id} = useSignage({ readApi: magellanV1, createApi: magellanV1, updateApi: magellanV1 }, 'devices', false);

    const onSubmit = async (formValues) => {
        let submission = filterDataForServer({...formValues});
        setLoading(true);
        await magellanV1.put(`/api/devices/${id}`, submission, { headers: {'Content-Type': 'application/json'} })
            .then(() => {
                setItem(formValues);
                setEditionMode(false);
                setAlertMessage(savedAlertMessage);
            })
            .catch(error => {
                setAlertMessage(updateErrorAlertMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading 
                ? <Loading/>
                : <>
                    <SignageHeader editionMode={editionMode} setEditionMode={setEditionMode} editionEnabled={role === MANAGER && !isReadonly} title={item && item.name}/>
                    <WarehouseForm editionMode={editionMode} initialValues={item} onSubmit={onSubmit} />
                </>
            }
        </>
    );
}

export default withSignageLayout()(UpdateWarehouse);