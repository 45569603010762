import { useGlobalStyles } from 'context/StylesContext';
import { differenceInMinutes } from 'date-fns';
import React, { useEffect, useState } from 'react';

const CheckingProgression = ({ checkingTimestamps }) => {
    const { theme } = useGlobalStyles();
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => setCurrentTime(new Date()), 60000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div style={{ width: '100%', height: 5, display: 'flex' }}>
            { checkingTimestamps.map((timestamp, index, timestamps) => {
                let width;
                if(index === 0) {
                    width = differenceInMinutes(new Date(timestamp), new Date(new Date().setHours(0, 0, 0, 0))) / 1440 * 100;
                } else {
                    width = differenceInMinutes(new Date(timestamp), new Date(timestamps.at(index - 1))) / 1440 * 100;
                }

                return <div key={timestamp} style={{ backgroundColor: index % 2 === 0 ? theme.palette.off.main : theme.palette.on.main, height: '100%', width: `${width}%`}}/>;
            })}
            <div style={{ backgroundColor: checkingTimestamps.length % 2 === 0 ? theme.palette.off.main : theme.palette.on.main, height: '100%', width: `${differenceInMinutes(new Date(), new Date(checkingTimestamps.at(- 1) || new Date().setHours(0, 0, 0, 0))) / 1440 * 100}%`}}/>
            <div style={{ backgroundColor: theme.palette.empty.main, height: '100%', width: `${differenceInMinutes(new Date(new Date().setHours(23, 59, 59, 999)), new Date()) / 1440 * 100}%`}}/>
        </div>
        
    );
};

export default CheckingProgression;