import { createSlice } from '@reduxjs/toolkit';
import { navigatorLanguage } from 'hooks/useTranslation';

const { reducer, actions } = createSlice({
    name: 'languages',
    initialState: {
        items: {},
        selectedId: null
    },
    reducers: {
        refresh(state, action) {
            state.items = {};
            action.payload.forEach(item => state.items[item['id']] = item);
        },
        select(state, action) {
            state.selectedId = action.payload;
        },
    }
});

export { actions as languagesActions };
export { reducer as languagesReducer };