import { accordionSummaryClasses, outlinedInputClasses, toggleButtonClasses } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ColorMode } from 'utils/constant';

const getTheme = (colorMode) => {
    let palette;

    switch (colorMode) {
        case ColorMode.BLUE:
            palette = {
                mode: 'light',
                primary:{
                    main: '#fafafa',
                },
                secondary: {
                    main: '#137dcc'
                },
                tertiary: {
                    main: '#0D568B',
                },
                quaternary: {
                    main: '#137dcc',
                },
                background: {
                    default: '#fafafa'
                },
                on: {
                    main: '#137dcc',
                },
                off: {
                    main: '#bdbdbd',
                },
                unknown: {
                    main: '#122232',
                },
                empty: {
                    main: '#e8e8e8'
                },
                selected: {
                    main: '#137dcc44'
                }, 
                unsafe: {
                    main: '#d32f2f',
                },
                safe: {
                    main: '#2e7d32',
                },
                warning: {
                    main: '#ffa726',
                },
                tableHeader: {
                    main: '#bcd8ed',
                },
            }
            break;
        case ColorMode.DARK:
            palette = {
                mode: 'dark',
                primary:{
                    main: '#121212',
                },
                secondary: {
                    main: '#fafafa'
                },
                tertiary: {
                    main: '#101010',
                },
                quaternary: {
                    main: '#0e0e0e'
                },
                background: {
                    default: '#121212',
                },
                on: {
                    main: '#0e0e0e',
                },
                off: {
                    main: '#8d8d8d',
                },
                unknown: {
                    main: '#ffffff',
                },
                empty: {
                    main: '#d8d8d8'
                },
                selected: {
                    main: '#FAFAFA18'
                }, 
                unsafe: {
                    main: '#d32f2f',
                },
                safe: {
                    main: '#2e7d32',
                },
                warning: {
                    main: '#ffa726',
                },
                tableHeader: {
                    main: '#282828',
                },
            };
            break;
        case ColorMode.GREEN:
        default:
            palette = {
                mode: 'light',
                primary:{
                    main: '#fafafa',
                },
                secondary: {
                    main: '#18b3b3'
                },
                tertiary: {
                    main: '#103c50',
                },
                quaternary: {
                    main: '#122232',
                },
                on: {
                    main: '#18b3b3',
                },
                off: {
                    main: '#bdbdbd',
                },
                unknown: {
                    main: '#122232',
                },
                empty: {
                    main: '#e8e8e8'
                },
                text: {
                    primary: '#575756',
                },
                selected: {
                    main: '#18b3b318'
                }, 
                unsafe: {
                    main: '#d32f2f',
                },
                safe: {
                    main: '#2e7d32',
                },
                warning: {
                    main: '#ffa726',
                },
                tableHeader: {
                    main: '#e9f8f8',
                },
            };
            break;
    }
    let theme = createTheme({
        palette,
        typography: {
            fontSize: 12,
            body1: {
                fontSize: '0.75rem',
            }
        },
        zIndex: {
            drawer: 1100,
            appBar: 1200,
        },
        fixedSize: {
            toolbar: {
                height: '64px',
            },
            updateBanner: {
                height: '36px',
            },
        }
    });
    
    theme = createTheme(theme, {
        colorMode,
        components: {
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        border: '1px solid rgba(0, 0, 0, .125)',
                        boxShadow: 'none',
                        '&:not(:last-child)': {
                            borderBottom: 0,
                        },
                        '&:before': {
                            display: 'none',
                        },
                        '&.Mui-expanded': {
                            margin: 0,
                        },
                    },
                }
            },
            MuiAccordionDetails: {
                styleOverrides: {
                    root: {
                        padding: theme.spacing(2),
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: theme.spacing(2),
                    },
                }
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'rgba(0, 0, 0, .03)',
                        borderBottom: '1px solid rgba(0, 0, 0, .125)',
                        marginBottom: -1,
                        minHeight: 56,
                        [`& .${accordionSummaryClasses['expanded']}`]: {
                            minHeight: 56,
                        },
                    },
                    content: {
                        [`& .${accordionSummaryClasses['expanded']}`]: {
                            margin: '12px 0',
                        },
                    },
                    expanded: {},
                }
                
            },
            MuiAlert: {
                styleOverrides: {
                    icon: {
                        "& .MuiSvgIcon-root":{ 
                            color : theme.palette.primary.main,
                        },
                    },
                },
            },
            MuiAutocomplete:{
                styleOverrides: {
                    paper: {
                        backgroundColor: theme.palette.primary.main,
                    },
                    groupLabel: {
                        backgroundColor: theme.palette.primary.main,
                    }
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    },
                    outlinedPrimary: {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.tertiary.main
                    },
                    containedSecondary: {
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.primary.main,
                    },
                }
            },     
            MuiCard:{
                styleOverrides: {
                    root : {
                        display: 'grid',
                        columnGap: '10%',
                        rowGap: theme.spacing(2),
                        gridTemplateColumns: '45% 45%',
                        paddingTop: theme.spacing(4),
                        paddingBottom: theme.spacing(4),
                        paddingLeft: theme.spacing(4),
                        paddingRight: theme.spacing(4),
                        width: '100%',
                        backgroundColor : theme.palette.primary.main,
                        marginBottom: theme.spacing(3),
                        marginTop: theme.spacing(1),
                    }
                }
            },
            MuiCardHeader:{
                styleOverrides: {
                    root:{
                        padding: 0,
                    }
                }      
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        borderRadius: 5,
                        borderColor: theme.palette.secondary.main,
                        maxHeight: 26,
                    },
                }
            },
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        '*': {
                            'scrollbarWidth': 'thin',
                            'scollbarColor': `${theme.palette.tertiary.main}77 ${theme.palette.tertiary.main + 18}`
                        },
                        '*::-webkit-scrollbar': {
                            width: '8px',
                            backgroundColor: theme.palette.tertiary.main + 18,
                        },
                        '*::-webkit-scrollbar-thumb': {
                            borderRadius: '10px',
                            WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
                            backgroundColor: theme.palette.tertiary.main + '77',
                        },
                        '*::selection': {
                            backgroundColor: theme.palette.secondary.main,
                            color: theme.palette.primary.main,
                        }
                    }
                }
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        '& .MuiButton-text':{
                            color: theme.palette.text.primary,
                        }
                    }
                } 
            },   
            MuiDivider:{
                styleOverrides: {
                    root: {
                        backgroundColor : theme.palette.secondary.main,
                    }
                }
                
            }, 
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        //width: '100%',
                        size: 'small',
                        borderColor: theme.palette.secondary.main,
                    }
                },
                defaultProps: {
                    size: "small",
                    fullwidth: 'true',
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        color: theme.palette.secondary.main,
                        '&.Mui-focused':{
                            color: theme.palette.secondary.main,
                        }
                    },
                }
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            backgroundColor: 'transparent'
                        },
                        '&.Mui-disabled .MuiSvgIcon-root': {
                            color: theme.palette.off.main,
                        },
                    },
                    colorPrimary: {
                        backgroundColor: theme.palette.primary.main,
                        "&:hover": {
                            backgroundColor: theme.palette.secondary.main,
                        },
                        "&:hover .MuiSvgIcon-root":{
                            color: theme.palette.primary.main
                        }
                    },
                }            
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        color: theme.palette.secondary.main,
                        fontSize: '0.75rem',
                    },
                }
            },
            MuiList : {
                styleOverrides: {
                    root: {
                        backgroundColor: theme.palette.primary.main,
                    }
                }
            },
            MuiListItem : {
                styleOverrides: {
                    root: {
                        color: theme.palette.secondary.main,
                        
                    },
    
                    button: {
                        "&:hover, &.Mui-selected, &.Mui-selected:hover":{
                            backgroundColor : theme.palette.secondary.main,
                            color: theme.palette.primary.main,
                        },
                        "&:hover .MuiSvgIcon-root, &.Mui-selected .MuiSvgIcon-root": {
                            color : theme.palette.primary.main,
                        },
                    },
                }
            },
            MuiListSubheader: {
                styleOverrides: {
                    root: {
                        color : theme.palette.secondary.main,
                    }
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            backgroundColor: theme.palette.selected.main,
                        }
                    },
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        [`&.${outlinedInputClasses['focused']} .${outlinedInputClasses['notchedOutline']}`]: {
                            borderColor: theme.palette.secondary.main, 
                            borderWidth: 0.1,
                        }
                    }
                }
            },
            MuiTablePagination: {
                styleOverrides: {
                    actions: {
                        '& button svg': {
                            color: theme.palette.action.active,
                        }
                    }
                },
            },
            MuiPaper:{
                styleOverrides: {
                    root : {
                        backgroundColor : theme.palette.primary.main,
                    },
                    '& > MuiButton': {
                        label:{
                            color: theme.palette.secondary.main,
                        }
                        
                    }
                }
                
            },
            MuiSlider: {
                styleOverrides: {
                    thumb: {
                        color: theme.palette.secondary.main,
                    },
                    mark: {
                        color: theme.palette.empty.main,
                    },
                    markActive: {
                        color: theme.palette.secondary.main,
                        backgroundColor: theme.palette.secondary.main,
                    }
                },
            },
            MuiSnackbar: {
                defaultProps: {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    }
                },
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        color: theme.palette.secondary.main,
                    },
                }
            },
            MuiSwitch: {
                defaultProps: {
                    size: 'small',
                    color: 'secondary'
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: '8px 4px',
                        color: 'inherit',
                        '& > *': {
                            marginLeft: 0,
                            justifyContent: 'flex-start',
                        }
                    },
                    head: {
                        padding: "6px 4px",
                        backgroundColor: theme.palette.tableHeader.main,
                    },
                    paddingNone: {
                        padding: 0,
                    },
                    paddingCheckbox: {
                        padding: "0px 0px 0px 4px",
                    }
                }
            },
            MuiTextField: {
                defaultProps: {
                    variant: 'outlined',
                    size: 'small',
                }
            },
            MuiTimeline: {
                styleOverrides: {
                    root: {
                        margin: 0,
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                },
            },
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        backgroundColor: theme.palette.common.white,
                        [`& .${toggleButtonClasses['selected']}`]: {
                            backgroundColor: theme.palette.secondary.main,
                            color: theme.palette.common.white,
                            '&:hover': {
                                backgroundColor: theme.palette.secondary.main,
                                color: theme.palette.common.white,
                                opacity: 1
                            }
                        },
                        '&:hover': {
                            backgroundColor: theme.palette.secondary.main,
                            color: theme.palette.common.white,
                            opacity: 0.5,
                        }
                    },
                }            
            },
        }
    });  
    return theme;
}


export default getTheme;