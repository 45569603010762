import React from 'react';

import { magellanV0 } from 'api/magellan';
import withSignageLayout from 'hoc/withSignageLayout';
import { useSignage } from 'hooks/useSignage';
import SignageHeader from '../../common/SignageHeader';
import Loading from '../../common/Loading';
import GroupForm from './GroupForm';

const UpdateGroup = ({ role }) => {
    const {item, editionMode, setEditionMode, updateData, loading, isReadonly} = useSignage({ readApi: magellanV0, createApi: magellanV0, updateApi: magellanV0 }, 'groups', false);

    const onSubmit = (formValues) => {
        updateData(formValues);
    };

    return (
        <>
            {loading 
                ? <Loading/>
                : <>
                    <SignageHeader editionMode={editionMode} setEditionMode={setEditionMode} editionEnabled={true && !isReadonly} title={item && item.name}/>
                    <GroupForm editionMode={editionMode} onSubmit={onSubmit} initialValues={item}/>
                </>
            }
        </>
    );
}

export default withSignageLayout()(UpdateGroup);