import Address from 'components/common/Address';
import BasicValue from 'components/common/BasicValue';
import ContactStatus from 'components/common/ContactStatus';
import MovementStatus from 'components/common/MovementStatus';
import None from 'components/common/None';
import { useGlobalStyles } from 'context/StylesContext';
import useFormat from 'hooks/useFormat';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { DeviceType } from 'utils/constant';

const ReplayPopupContent = ({ position, device }) => {
    const { t } = useTranslation();
    const { formatLocalDate } = useFormat();
    const { classes: globalClasses } = useGlobalStyles();

    return (
        <div>
             { device && position 
                ? <>
                    <div className={globalClasses.iconStatusContainer}>
                        { device.category !== DeviceType.TRAILER && <ContactStatus device={device} position={position} />}
                        <MovementStatus device={device} position={position} />
                    </div>
                </>
                : null
            }
            <BasicValue
                label={t('date')}
                value={formatLocalDate(position.fixTime)}
            />
            <BasicValue
                label={t('itemAddress')}
                value={position?.address
                    ? <Address address={position.address} placement="start" />
                    : <None text={t('itemAddressNone')}/>
                }
            />
        </div>
        
    );
};

export default ReplayPopupContent;