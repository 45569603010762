import React from 'react';
import { makeStyles } from 'tss-react/mui';

import useTranslation from 'hooks/useTranslation';

const useStyles = makeStyles()(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.secondary.main,
        height: theme.fixedSize.updateBanner.height,
        zIndex:  theme.zIndex.appBar,
        width: '100%',
    },
    text: {
        color: 'white',
        cursor: 'pointer',
    }
}));

const UpdateBanner = ({ action }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();

    return (
        <div className={classes.root} >
            <span 
                className={classes.text} 
                onClick={action}
            >
                {t('newVesionAvailable')}
            </span>
        </div>
    );
};

export default UpdateBanner;