import React from 'react';

import { magellanV1 } from 'api/magellan';
import withSignageLayout from 'hoc/withSignageLayout';
import { useSignage } from 'hooks/useSignage';
import Loading from '../common/Loading';
import SignageHeader from '../common/SignageHeader';
import DeviceForm from './DeviceForm';

const CreateDevice = ({ filterDataForServer, deviceType, initialValues, validationSchema }) => {
    const {editionMode, setEditionMode, createData, loading} = useSignage({ readApi: magellanV1, createApi: magellanV1, updateApi: magellanV1 }, 'devices', true, false);

    const onSubmit = (formValues) => {
        createData(filterDataForServer({...formValues}));
    };

    return (
        <>
            {loading 
                ? <Loading/>
                : <>
                    <SignageHeader editionMode={editionMode} setEditionMode={setEditionMode} editionEnabled={true}/>
                    <DeviceForm 
                        editionMode={editionMode} 
                        onSubmit={onSubmit} 
                        initialValues={initialValues} 
                        validationSchema={validationSchema} 
                        deviceType={deviceType}
                    />                
                </>
            }
        </>
    );
}

export default withSignageLayout()(CreateDevice);