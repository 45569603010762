import { createSlice, } from "@reduxjs/toolkit";
import _ from 'lodash';
import { fetchItems } from "./asyncCheckingActions";

const initialState = {
    loading: false,
    items: {},
    lastItems: {},
};

export const { reducer, actions: checkingActions } = createSlice({
    name: 'checkings',
    initialState, 
    reducers: {
        load(state, action) {
            state.loading = action.payload;
        },
        addItem(state, action) {
            state.items[action.payload.stafferId].push(action.payload.timestamp);
            state.lastItems[action.payload.stafferId] = action.payload;
        },
    },
    extraReducers: {
        [fetchItems.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchItems.fulfilled]: (state, action) => {
            state.items = {};
            Object.entries(action.payload)
                .forEach(([key, items]) => {
                    state.items[key] = items.map(item => item.timestamp);
                    if(items.at(-1)) {
                        state.lastItems[key] = items.at(-1);
                    } else {
                        state.lastItems[key] = null;
                    }
                });
            state.loading = false;
        },
    }
});