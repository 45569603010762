import React, { useState, useEffect, useCallback } from 'react';
import { format, isToday, isYesterday } from 'date-fns';
import { Tooltip } from '@mui/material';
import useFormat from 'hooks/useFormat';

const TimeCounter = ({ time }) => {
    const { formatTimeToNow, currentLocale } = useFormat();

    const formatTime = useCallback(() => {
        return formatTimeToNow(time);
    }, [time]);
    const [timeDisplayed, setTimeDisplayed] = useState(formatTime);

    useEffect(() => {
        let formatedTime = formatTime();
        setTimeDisplayed(formatedTime)
        let intervalId = setInterval(() => {
            formatedTime = formatTime();
            setTimeDisplayed(formatedTime);
        }, [60000]);
        return () => clearInterval(intervalId);
    },[formatTime]);

    const renderTimeCounter = () => {
        return <span>{timeDisplayed.charAt(0).toUpperCase() + timeDisplayed.slice(1)}</span>
    }

    return (!isToday(time) && !isYesterday(time)) 
        ? <Tooltip title={format(time, 'PPPp', { local: currentLocale })} placement="top">
            {renderTimeCounter()}
        </Tooltip>
        : renderTimeCounter();
};

export default TimeCounter;