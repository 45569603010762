import React from 'react';
import { CircularProgress } from '@mui/material';
import { useGlobalStyles } from 'context/StylesContext';

const Loading = ({ message }) => {
    const { classes: globalClasses } = useGlobalStyles();

    return <div className={globalClasses.centerOfPageContainer}><CircularProgress color='secondary'/></div>;
}

export default Loading;