import React from 'react';
import { BatteryFull, LocalGasStation } from '@mui/icons-material';
import useTranslation from 'hooks/useTranslation';
import { BatteryState, DeviceStatus, DeviceType } from 'utils/constant';
import BasicValue from './BasicValue';
import { useGlobalStyles } from 'context/StylesContext';

const FuelOrBatteryStatus = ({ item, position}) => {
    const { t } = useTranslation();
    const { classes: globalClasses, theme } = useGlobalStyles();   
    let label = t('itemStatusBattery');
    let iconColor = theme.palette.off.main;
    let isFuelIcon = false;
    let text = t('itemStatusUnknown');
    switch(item?.category) {
        case DeviceType.TRAILER:
            switch(position?.attributes?.batteryState) {
            case BatteryState.OK: 
                iconColor = theme.palette.on.main;
                text = t('batteryOk'); 
                break;
            case BatteryState.WARNING:
                iconColor = theme.palette.warning.main;
                text = t('batteryWarning');
                break;
            case BatteryState.CRITICAL:
                iconColor = theme.palette.unsafe.main;
                text = t('battryCritical');
                break;
            default:
                iconColor = theme.palette.off.main;
                text = t('itemStatusUnknown');
            }
            break;
        case DeviceType.SMARTPHONE: 
            if (position?.attributes?.batteryLevel !== undefined && item?.status === DeviceStatus.ONLINE) {
                iconColor = theme.palette.on.main
                text = `${Math.round(position?.attributes?.batteryLevel)}%`;
            } else {
                iconColor = theme.palette.off.main;
                text = t('itemStatusOffline');
            }
            break;
        case DeviceType.VEHICLE:
        case DeviceType.CONSTRUCTION_MACHINE:
        default:
            isFuelIcon = true;
            label = t('itemStatusFuel');
            if (position?.attributes?.fuelLevel !== undefined) {
                iconColor = theme.palette.on.main;
                text = `${position?.attributes?.fuelLevel}%`;
            } else {
                iconColor = theme.palette.off.main;
                text = t('itemStatusOffline');
            }

    }

    return <BasicValue
        alignment='center'
        label={label}
        value={
            <div className={globalClasses.indicatorContainer}>
                <div className={globalClasses.iconSize}>
                    { isFuelIcon 
                        ? <LocalGasStation style={{ color: iconColor }}/>
                        : <BatteryFull style={{ color: iconColor }}/>
                    }
                    
                </div>
                <span>{text}</span>
            </div>
        }
    />
};

export default FuelOrBatteryStatus;