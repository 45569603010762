import React from 'react';
import { LocationOn, Replay } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import None from 'components/common/None';
import { parseAddress } from 'utils/address';

export const getColumns = (devices, geofences, speedUnit, distanceUnit, globalClasses, cx, t, formatDate, formatSpeed, formatDistance, formatAddressOneLine, formatDuration) => [{
    Header: t('reportDevice'),
    accessor: 'deviceId',
    Cell: ({ cell: { value } }) => devices?.find(device => device.id === value)?.name || '',
    props: {
        className: globalClasses.tableCellFirst,
        style: {
            width: '12%',
        }
    },
}, {
    Header: t('reportDriver'),
    accessor: 'driverName',
    Cell: ({ cell: { value } }) => value || <None/>,
    props: {
        style: {
            width: '12%',
        }
    }, 
    cellProps: {
        style: {
            padding: 8,
            textAlign: 'start',
            width: '12%',
        }
    },
}, {
    Header: t('reportStartTime'),
    accessor: 'startTime',
    Cell: ({ cell: { value } }) => formatDate(value),
    props: {
        style: {
            width: '8%',
        }
    },
}, {
    Header: t('reportStartAddress'),
    accessor: 'startAddress',
    Cell: ({ cell: { row: { original: row } } }) => {
        let isGeofence = false;
        let addressValue = geofences?.[row?.startGeofenceIds?.[0]]?.name;
        if (addressValue) {
            isGeofence = true;
        } else {
            if(row?.startAddress) {
                let address = row?.startAddress;
                if(typeof address === 'string') {
                    address = parseAddress(address);
                }
                addressValue = formatAddressOneLine(address);
            }
        }
        return (
            <>
                <LocationOn className={isGeofence ? globalClasses.decorativeIcon : cx(globalClasses.decorativeIcon, globalClasses.invisible)}/>
                {addressValue || <None text={t('itemAddressNone')}/>}
            </>
        );
    },
    props: {
        style: {
            width: '18%',
        }
    },
    headerProps: {
        className: globalClasses.tableAddressHeader,
    },
}, {
    Header: t('reportEndTime'),
    accessor: 'endTime',
    Cell: ({ cell: { value } }) => formatDate(value),
    props: {
        style: {
            width: '8%',
        }
    },
}, {
    Header: t('reportEndAddress'),
    accessor: 'endAddress',
    Cell: ({ cell: { row: { original: row } } }) => {
        let isGeofence = true;
        let addressValue = geofences?.[row?.endGeofenceIds?.[0]]?.name;
        if (!addressValue) {
            isGeofence = false;
            if(row?.endAddress) {
                let address = row?.endAddress;
                if(typeof address === 'string') {
                    address = parseAddress(address);
                }
                addressValue = formatAddressOneLine(address);
            }
        }
        return (
            <>
                <LocationOn className={isGeofence ? globalClasses.decorativeIcon : cx(globalClasses.decorativeIcon, globalClasses.invisible)}/>
                {addressValue || <None text={t('itemAddressNone')}/>}
            </>
        );
    },
    props: {
        style: {
            width: '18%',
        }
    },
    headerProps: {
        className: globalClasses.tableAddressHeader,
    },
}, {
    Header: t('sharedDistance'),
    accessor: 'distance',
    Cell: ({ cell: { value } }) => formatDistance(value, distanceUnit),
    props: {
        className: globalClasses.tableCellNumber,
        style: {
            width: '6%',
        }
    },
}, {
    Header: t('reportAverageSpeed'),
    accessor: 'averageSpeed',
    Cell: ({ cell: { value } }) => formatSpeed(value, speedUnit),
    props: {
        className: globalClasses.tableCellNumber,
        style: {
            width: '6%',
        }
    },
}, {
    Header: t('reportDuration'),
    accessor: 'duration',
    Cell: ({ cell: { value } }) => formatDuration(value),
    props: {
        className: globalClasses.tableCellNumber,
        style: {
            width: '6%',
        }
    },
}, { 
    Header: '',
    id: 'startPositionId',
    Cell: ({ cell: { row: { original: { deviceId, startTime, endTime } } } }) => (
        <Link 
            target="_blank" 
            to={`../../replay?${new URLSearchParams({ deviceId, from: new Date(startTime).toISOString(), to: new Date(endTime).toISOString() }).toString()}`} 
            relative={true} 
        >
            <Replay/>
        </Link>
    ),
    disableSortBy: true,
    props: {
        className: globalClasses.tableCellFixedSize,
        style: {
            width: '6%',
        }
    },
}];