import _ from 'lodash';
import { Document, Page, View, Svg, Defs, LinearGradient, Stop, Rect, Image, Text, Path } from "@react-pdf/renderer";
import { format } from "date-fns";
import MagellanLogo from 'resources/magellan_logo.png';
import { styles } from "./sharedPdfReportStyles";

const TemplatePdf = ({ reportTitle, companyAddress, companyName, filters, isPortrait, children }) => {


    return (
        <Document >
            <Page style={styles.body} orientation={isPortrait ? 'portrait': 'landscape'}>
            <View style={styles.header} fixed={true}>
                {/* <Svg viewBox={`0 0 ${isPortrait ? 595.28 : 841.89} ${styles.header.height}`} width="100%" height="100%" style={styles.headerBackground}>
                    <Defs>
                    <LinearGradient id="myLinearGradient">
                        <Stop offset="5%" stopColor="#103c50" />
                        <Stop offset="95%" stopColor="#102b3c" />
                    </LinearGradient>
                    </Defs>
                    <Rect width="100%" height="100%" fill="url('#myLinearGradient')" />
                </Svg> */}
                <View style={styles.headerContent}>
                    <Image src={MagellanLogo} style={styles.logo}/>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </View>    
            </View>
            <View style={styles.banner}>
                <Text style={styles.reportTitle}>{reportTitle}</Text>
                <View style={styles.filtersContainer}>
                    { filters.map(({key, value}) => <Text key={key} style={styles.filterText}>{key} {value}</Text>)}
                </View>
            </View>
            <View style={styles.content}>
                {children}
            </View>
            <View style={styles.footer} fixed={true}>
                {/* <Svg viewBox={`0 0 ${isPortrait ? 595.28 : 841.89} ${styles.footer.height}`}  width="100%" height="100%" style={styles.headerBackground}>
                    <Defs>
                        <LinearGradient id="myLinearGradient">
                        <Stop offset="5%" stopColor="#103c50" />
                        <Stop offset="95%" stopColor="#102b3c" />
                        </LinearGradient>
                    </Defs>
                    <Rect width="100%" height="100%" fill="url('#myLinearGradient')" />
                </Svg> */}
                <View style={styles.headerContent}>
                    <Text style={styles.footerText}>www.magellan.be</Text>
                    <Text style={styles.footerText}>{format(new Date(), 'dd/MM/yyyy HH:mm')}</Text>
                    <View style={styles.company}>
                        <Text style={styles.footerText}>{companyName}</Text>
                        <Text style={styles.footerText}>{companyAddress}</Text>
                    </View>
                </View>
            </View>
            </Page>
        </Document>
    );
}

export default TemplatePdf;