import React, { useEffect, useCallback, useState, useRef } from 'react';
import {
    IconButton,
    OutlinedInput,
    InputAdornment,
    FormControl,
    ClickAwayListener,
    CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from 'tss-react/mui';

import nominatim from 'api/nominatim';
import { useGlobalStyles } from 'context/StylesContext';
import useAlertMessage from 'hooks/useAlertMessage';
import useTranslation from 'hooks/useTranslation';

const useStyles = makeStyles()(theme => ({
    textField: {
        // "& .MuiOutlinedInput-input": {

        // },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main,

        },
        // "&:hover .MuiOutlinedInput-input": {

        // },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main,
        },
        // "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {

        // },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main,
        }
    },
    input: {
        backgroundColor: theme.palette.primary.main,
        height: 29,
    },
    inputOpen: {
        width: 250,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    inputClose: {
        width: 29,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        paddingLeft: 3,
    },
    loading: {
        margin: 30
    }, 
    borderOpen: {
        width: 'auto',
    }
}));

const AddressSearchBar = ({ zoomToCoordinates }) => {
    const { t } = useTranslation();
    const { classes, cx, theme } = useStyles();
    const { classes: globalClasses } = useGlobalStyles();
    const [searchTerm, setSearchTerm] = React.useState("");
    const [searchTermSubmitted, setSearchTermSubmitted] = React.useState("");
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const searchField = useRef(null);
    const { setAlertMessage, addressNotFoundWarningAlertMessage } = useAlertMessage();
    const handleShortcuts = useCallback(event => {
        if (event.key === 'Escape'){
            setOpen(false);
        } 
    }, [open]);

    useEffect(() => {  
        window.addEventListener("keydown", handleShortcuts);  
        return () => {
            window.removeEventListener("keydown", handleShortcuts);
        }
    }, [handleShortcuts]);

    useEffect(() => {
        let input = searchField.current.querySelector("input");
        if (open) {
            input.focus();
        } else {
            input.blur();
        }
    }, [open]);

    useEffect(() => {
        if (searchTermSubmitted.trim()) {
            setLoading(true);
            nominatim.get(`/search?q=${searchTermSubmitted}`)
                .then((response) => {
                    if(response.data){
                        zoomToCoordinates([response.data[0].lon, response.data[0].lat]);
                    }
                })
                .catch((error) => {
                    setAlertMessage(addressNotFoundWarningAlertMessage);
                })
                .finally(() => {
                    setSearchTermSubmitted("");
                    setOpen(false);
                    setLoading(false);
                });
        }
    }, [searchTermSubmitted]);

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSubmitSearch = () => {
        setSearchTermSubmitted(searchTerm);
    };

    return (
        <ClickAwayListener  onClickAway={() => setOpen(false)}>
            <div className={open ? cx(globalClasses.mapControlButtonBorder, classes.borderOpen) : globalClasses.mapControlButtonBorder}>
                <FormControl 
                    className={cx(classes.textField, (open ? classes.textFieldClose : classes.textFieldOpen))} 
                    variant="outlined"
                >
                    <OutlinedInput
                        ref={searchField}
                        className={cx(classes.input, (open ? classes.inputOpen : classes.inputClose))}
                        type="text"
                        value={searchTerm}
                        onChange={handleChange}
                        onKeyDownCapture={(event) => {
                            if (event.key === 'Enter') {
                                handleSubmitSearch();
                            }
                        }}
                        startAdornment={!open &&
                            <InputAdornment className={classes.searchIcon} position="start">
                                <IconButton onClick={() => setOpen(true)} edge="start" size="large">
                                    <SearchIcon style={{fill: theme.palette.mode === 'light' ? theme.palette.common.black : theme.palette.common.white }} />
                                </IconButton>
                            </InputAdornment>
                        }
                        endAdornment={open &&
                            (loading 
                                ? <InputAdornment position="end"><CircularProgress color="secondary" style={{padding: '10px'}}/></InputAdornment>
                                : <InputAdornment className={classes.searchIcon} position="end">
                                    <IconButton onClick={handleSubmitSearch} edge="end" size="large">
                                        <SearchIcon style={{fill: theme.palette.mode === 'light' ? theme.palette.common.black : theme.palette.common.white }} />
                                    </IconButton>
                                </InputAdornment>)
                        }
                    />
                </FormControl>
            </div>
        </ClickAwayListener>
    );
};

export default AddressSearchBar;
