import React from 'react';
import { useNavigate } from 'react-router-dom';

import {magellanV0, magellanV1} from 'api/magellan';
import withSignageLayout from 'hoc/withSignageLayout';
import { useSignage } from 'hooks/useSignage';
import useAlertMessage from 'hooks/useAlertMessage';
import Loading from '../../common/Loading';
import SignageHeader from '../../common/SignageHeader';
import GeofenceForm from './GeofenceForm';

const initialValues = {
    name: '',
    description: '',
    area: '',
    groups: {},
};

const CreateGeofence = ({ filterDataForServer }) => {
    const {editionMode, setEditionMode, loading, setLoading} = useSignage({ readApi: magellanV0, createApi: magellanV0, updateApi: magellanV0 }, 'geofences', true, false);
    const navigate = useNavigate();  
    const { setAlertMessage, creationErrorAlertMessage, savedAlertMessage, updateErrorAlertMessage } = useAlertMessage(); 

    const onSubmit = async (formValues) => {
        setLoading(true);
        await magellanV1.post(`/api/geofences`, filterDataForServer({...formValues}))
            .then(response => {
                if(response.data.id){
                    Promise.all([
                        formValues.groups.groupToLink.map(g => magellanV0.post('/api/permissions', {groupId : g, geofenceId : response.data.id}))
                    ])
                        .then(res => {
                            setAlertMessage(savedAlertMessage);
                            setEditionMode(false);
                            navigate(`${response.data.id}`, { replace: true });
                        })
                        .catch(error => {
                            setAlertMessage(updateErrorAlertMessage);
                        });
                }
            })
            .catch(error => {
                setAlertMessage(creationErrorAlertMessage);
                setLoading(false);
            })
    };

    return (
        <>
            {loading 
                ? <Loading/>
                : <>
                    <SignageHeader 
                        editionMode={editionMode} 
                        setEditionMode={setEditionMode} 
                        editionEnabled={true}
                    />
                    <GeofenceForm 
                        editionMode={editionMode} 
                        onSubmit={onSubmit} 
                        initialValues={initialValues}
                    />
                </>
            }
            
        </>
    );
}

export default withSignageLayout()(CreateGeofence);