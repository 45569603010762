import { magellanV1 } from 'api/magellan';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const usePagination = ({ clientPagination = true, items=[], parameterKey, page, limit, changePage, changeLimit }) => {
    const userId = useSelector(state => state.session.user.id);
    const [totalCount, setTotalCount] = useState(items.length);
    const offset = limit * page;
    let nbItemsOnPage = limit;
    if(totalCount < limit * (page + 1)) {
        nbItemsOnPage = totalCount - offset;
    }
    const paginatedItems = clientPagination ? items.slice(offset, offset + limit) : items;
    
    const gotoPage = (newPage) => {
        changePage(newPage);
        if (clientPagination) {
            magellanV1.post(`api/users/${userId}/parameters/${parameterKey}`, {
                page: newPage || 0,
                limit: limit || 50,
            }).catch();
        }
    };

    const setPageSize = (newLimit) => {
        changeLimit(newLimit);
        if (clientPagination) {
            magellanV1.post(`api/users/${userId}/parameters/${parameterKey}`, {
                page: 0,
                limit: newLimit || 50,
            }).catch();
        }
        
    };

    return { 
        setPageSize, 
        gotoPage, 
        offset, 
        totalCount: clientPagination ? items.length : totalCount, 
        setTotalCount: clientPagination ? () => {} : setTotalCount, 
        nbItemsOnPage,
        paginatedItems
    };
};
 
export default usePagination;