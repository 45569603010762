import React, { useCallback, useEffect, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Autocomplete, CircularProgress, Container, Paper, Slider, TextField, Tooltip, } from '@mui/material';

import useTranslation from 'hooks/useTranslation';
import useFormat from 'hooks/useFormat';
import Map from '../map/Map';
import ReplayPathMap from './ReplayPathMap';
import PositionsMap from '../map/PositionsMap';
import { magellanV0 } from 'api/magellan';
import useAlertMessage from 'hooks/useAlertMessage';
import { ReportFormat } from 'utils/constant';
import useDeviceReport from 'hooks/useDeviceReport';
import PeriodSelector from 'components/common/PeriodSelector';
import { parseAddress } from 'utils/address';
import { MapContext } from 'context/MapContext';
import ZoomInAndOutMapControl from '../map/ZoomInAndOutMapControl';
import AddressSearchBar from '../main/AddressSearchBar';
import GeofencePointerToggleMapControl from '../map/GeofencePointerToggleMapControl';
import SelectorMapControl from '../map/SelectorMapControl';
import ScaleMapControl from '../map/ScaleMapControl';
import FitBoundsMapControl from '../map/FitBoundsMapControl';
import useFitBounds from '../useFitBounds';
import { useGlobalStyles } from 'context/StylesContext';
import GeofenceMap from '../map/GeofenceMap';
import Drawer from 'components/common/Drawer';
import MotionsSummaryTimeline from 'components/common/MotionsSummaryTimeline';
import useGetMotions from 'hooks/useGetMotions';
import { groupPositionsByTrip } from '.';
import StopsMap from './StopsMap';
import ReplayPopupContent from './ReplayPopupContent';
import StopPopupContent from './StopPopupContent';
import moment from 'moment';

const useStyles = makeStyles()(theme => ({
    content: {
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
    },
    sliderContainer: {
        position: 'absolute',
        zIndex: 0,
        bottom: theme.spacing(5),
        left: '50%',
        transform: 'translateX(-50%)',
    },
    sliderContent: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    pannel: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        rowGap: theme.spacing(2),
    },
    filtersContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(2),
        padding: theme.spacing(2),
    },
    motionsSummaryTimelineContainer: {
        padding: `0px ${theme.spacing(2)}`,
        height: '100%',
        overflow: 'auto',
    },
    loadingContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    },
    loading: {
        color: theme.palette.secondary.main,
    },
}));

const ReplayPage = () => {
    const { t } = useTranslation();
    const { formatLocalDate } = useFormat();
    const { classes } = useStyles();
    const { classes: globalClasses } = useGlobalStyles();
    const [index, setIndex] = useState(0);
    const { setAlertMessage, dataLoadingErrorAlertMessage } = useAlertMessage();
    const { deviceId, setDeviceId, period, setPeriod, from, setFrom, to, setTo, items: positions, setItems: setPositions, query, devices } = useDeviceReport({ availableReportActions: [ReportFormat.JSON] });
    const [loading, setLoading] = useState(false);
    const { map, mapReady } = useContext(MapContext);
    const { deselectDeviceAndZoomToCoordinates, deselectDeviceAndFitDevicesPositions } = useFitBounds({ positions, map });
    const geofences = useSelector(state => state.geofences.items);
    const { motions } = useGetMotions({ 
        deviceId, 
        from: from.toDate(),
        to: to.toDate(),
    });
    const [selectedTripId, setSelectedTripId] = useState(null);
    const positionsByTrip = groupPositionsByTrip(positions, motions.trips);
    const maxDuration = moment.duration(1, 'week');
    const [forcedRefresh, setForcedRefreshed] = useState(new Date().getTime());

    const handleSubmit = useCallback(async () => {
        try {
            if (query && query.has('deviceId') && query.has('from') && query.has('to')) {
                setLoading(true);
                const response = await magellanV0.get(`/api/positions?${query.toString()}`);
                if (response.status === 200) {
                    let data = response.data.map(pos => ({...pos, address: parseAddress(pos.address)}));
                    setIndex(0);
                    setPositions(data);
                }
                setLoading(false);
            }
        } catch (error) {
            setAlertMessage(dataLoadingErrorAlertMessage);
            setLoading(false);
        };        
    }, [query?.toString()]);;

    const toggleSelectedTripIdSafe = (tripId) => {
        setIndex(0);
        setSelectedTripId(oldTripId => (tripId !== oldTripId) ? tripId : null);
    };

    useEffect(() => {
        handleSubmit();
    }, [handleSubmit]);

    return (
        <div className={classes.content}>
            <Map>
                <ReplayPathMap 
                    positionsByTrip={positionsByTrip} 
                    allPositions={positions}
                    selectedTripId={selectedTripId}
                    setSelectedTripId={toggleSelectedTripIdSafe}
                    forceRefresh={() => setForcedRefreshed(new Date().getTime())}
                />
                <StopsMap 
                    positions={positions
                        .filter(p => motions.stops
                            .map(s => s.positionId)
                            .includes(p.id)
                        )
                    } 
                    renderPopupContent={({ position }) => <StopPopupContent position={position}/>}
                />
                {index < positions.length &&
                    <PositionsMap 
                        forcedRefresh={forcedRefresh}
                        displayedPositions={[(selectedTripId ? positionsByTrip?.[selectedTripId]?.positions?.[index] : positions[index])]} 
                        renderPopupContent={({ position, device }) => <ReplayPopupContent position={position} device={device}/>}
                    />
                }
                <GeofenceMap />
                <div hidden={!mapReady} className={globalClasses.mapControlButtonsLayout}>
                    <ZoomInAndOutMapControl zoomIn={() => map.zoomIn()} zoomOut={() => map.zoomOut()} />
                    <AddressSearchBar zoomToCoordinates={deselectDeviceAndZoomToCoordinates}/>
                    <GeofencePointerToggleMapControl />
                    <FitBoundsMapControl fitBounds={deselectDeviceAndFitDevicesPositions} positions={Object.values(positions)}/>
                    <SelectorMapControl/>
                    <ScaleMapControl/>
                </div>
            </Map>
            <Drawer pinned={true} open={true} setOpen={() => {}}>
                <div className={classes.pannel}>
                    <div className={classes.filtersContainer}>
                    <Autocomplete 
                        options={devices.sort((deviceA, deviceB) => deviceA.name < deviceB.name ? -1 : 1)}
                        groupBy={d => t(d?.category)}
                        onChange={(_, value) => {
                            toggleSelectedTripIdSafe(null);
                            setDeviceId(value?.id || null);
                        }}
                        getOptionLabel={d => d?.name || ''}
                        value={devices.find(d => d.id === deviceId)}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        renderInput={(params) => <TextField {...params} label={t('reportDevice')} variant='outlined'/>}
                    />
                    <PeriodSelector 
                        period={period} 
                        setPeriod={value => {
                            toggleSelectedTripIdSafe(null);
                            setPeriod(value);
                        }}
                        from={from}
                        setFrom={setFrom}
                        to={to}
                        setTo={setTo}
                        maxDuration={maxDuration}
                    />
                    </div>
                    { loading 
                        ? (
                            <div className={classes.loadingContainer}>
                                <CircularProgress className={classes.loading}/>
                            </div>
                        )
                        : ( 
                            <div className={classes.motionsSummaryTimelineContainer}>
                                <MotionsSummaryTimeline 
                                    motions={motions}
                                    geofences={Object.values(geofences)}
                                    selectable={true}
                                    selectedTripId={selectedTripId}
                                    setSelectedTripId={toggleSelectedTripIdSafe}
                                    openInReplay={false}
                                />
                            </div>
                        )
                    }
                </div>
                
            </Drawer>
            <Container maxWidth="sm" className={classes.sliderContainer}>
                { ((!selectedTripId && positions.length && Object.values(positionsByTrip).length > 0) || (selectedTripId && positionsByTrip?.[selectedTripId]?.positions?.length)) &&
                    <Paper className={classes.sliderContent}>
                        <Slider
                            max={(selectedTripId ? positionsByTrip?.[selectedTripId]?.positions?.length : positions.length) -1}
                            step={null}
                            marks={positions.map((_, index) => ({ value: index }))}
                            value={index}
                            onChange={(_, index) => setIndex(index)}
                            valueLabelDisplay="auto"
                            valueLabelFormat={i => i < positions.length ? formatLocalDate(positions?.[i]?.['fixTime']) : ''}
                        />
                    </Paper>
                }
            </Container>
        </div>
    );
}

export default ReplayPage;