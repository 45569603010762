import React from 'react';
import LocationOn from '@mui/icons-material/LocationOn';

import { useGeofencePointerVisibility } from 'context/GeofencePointerContext';
import { useGlobalStyles } from 'context/StylesContext';

const GeofencePointerToggleMapControl = () => {
    const [geofencePointerVisible, setGeofencePointerVisible] = useGeofencePointerVisibility();
    const { classes, cx, theme } = useGlobalStyles();

    return (
        <div className={classes.mapControlButtonBorder}>
            <div
                className={cx(classes.mapControlButton, classes.mapControlButtonBorderRadius)} 
                onClick={() => setGeofencePointerVisible(visibility => !visibility)}
            >
                <LocationOn style={{fill: theme.palette.mode === 'light' ? theme.palette.common.black : theme.palette.common.white}}/>
            </div>
        </div>
    );
};

export default GeofencePointerToggleMapControl;