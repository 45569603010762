import { Tooltip } from '@mui/material';
import _ from 'lodash';
import React from 'react';

const TruncableText = ({ texts, maxLength: length, ...rest }) => {

    return ( length && texts.some(text => text?.length > length)
        ? <Tooltip title={texts.join('\n')}>
            <div {...rest}>
                { texts.map(text => text && <span key={text}>{_.truncate(text, { length })}</span>)}
            </div>
            
        </Tooltip>
        : <div {...rest}>
            {texts.map(text => text && <span key={text}>{text}</span>)}
        </div>
    )
};

export default TruncableText;