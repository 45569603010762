import React from 'react';
import { Tooltip } from '@mui/material';
import { FaLeaf } from 'react-icons/fa';
import { makeStyles } from 'makeStyles';

import useFormat from 'hooks/useFormat';
import useTranslation from 'hooks/useTranslation';
import { AlarmType } from 'utils/constant';
import BasicValue from 'components/common/BasicValue';


const useStyles = makeStyles()(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        padding: '0px 10px 0px 0px',
    }, 
    tooltip: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
    },
    alarms: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        gap: 5,
    },
    ecoAndNumber: {
        display: 'flex',
        gap: 10
    }
}));

const AssetStatistic = ({ label, safeDrivingStats, distanceOverriden }) => {
    const { t } = useTranslation();
    const { formatTripDistance, formatDecimalNumber } = useFormat();
    const { classes } = useStyles();

    const getDistance = () => {

        let totalDistance = safeDrivingStats?.scoresByPeriods?.reduce((acc, score) => acc + score.totalDistance, 0);
        let result = formatTripDistance(totalDistance);
        
        return distanceOverriden || result;
    }

    const renderTooltip = () => {
        return (
            <div className={classes.tooltip}>
                <div className={classes.alarms}>
                    <span>{`${safeDrivingStats.totalHardAcceleration} ${t(AlarmType.hardAcceleration.translatorKey)}`}</span>
                </div>
                <div className={classes.alarms}>
                    <span>{`${safeDrivingStats.totalHardBraking} ${t(AlarmType.hardBraking.translatorKey)}`}</span>
                </div>
                <div className={classes.alarms}>
                    <span>{`${safeDrivingStats.totalHardCornering} ${t(AlarmType.hardCornering.translatorKey)}`}</span>
                </div>
                <div className={classes.alarms}>
                    <span>{`${safeDrivingStats.totalOverspeed} ${t(AlarmType.overspeed.translatorKey)}`}</span>
                </div>
            </div>
        );
    };

    const renderEcoCounter = () => {
        return (
            <div className={classes.ecoAndNumber}>
                <span>{safeDrivingStats?.globalScore ? formatDecimalNumber(safeDrivingStats.globalScore, 0, 0) : t('notApplicableAbbreviation')}</span>
                <FaLeaf/>
            </div>
        );
    };

    return (
        <div className={classes.container}>
            <BasicValue
                label={label}
                value={getDistance()}
            />
                <Tooltip 
                    placement='right'
                    title={renderTooltip()}
                >
                    {renderEcoCounter()}
                </Tooltip>
        </div>
    );
};

export default AssetStatistic;