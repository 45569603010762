import { useState, useEffect } from 'react';

import {magellanV0, magellanV1} from 'api/magellan';
import useAlertMessage from 'hooks/useAlertMessage';

const useGroupManager = ({ id, endpoint, formik, allCheckedByDefault = false }) => {
    const [groups, setItems] = useState({allItems: [], initialItems: [], finalItems: []})
    const { setAlertMessage, dataLoadingErrorAlertMessage } = useAlertMessage();
    const finalItemsLength = groups?.finalItems?.length;

    useEffect(() => {
        let api = endpoint === 'geofence' ? magellanV1 : magellanV0;
        Promise.all([
            magellanV0.get('/api/groups', { headers: { 'Accept': 'application/json'}}),
            id && api.get(`/api/groups?${endpoint}Id=${id}`)
        ]).then(([allItemsResponse, itemItemsResponse]) => {
            if(id){    
                setItems({
                    allItems: allItemsResponse.data, 
                    initialItems: itemItemsResponse.data.map(g => g.id), 
                    finalItems: itemItemsResponse.data.map(g => g.id)
                });
            }else{
                if(allCheckedByDefault) {
                    setItems(g => ({
                        ...g,
                        finalItems: allItemsResponse.data.map(g => g.id),
                        allItems: allItemsResponse.data,

                    }));
                } else {
                    setItems(g => ({
                        ...g,
                        allItems: allItemsResponse.data,
                    }));
                }
            }
        })
        .catch(error => {
            setAlertMessage(dataLoadingErrorAlertMessage);
        });
    }, []);

    useEffect(() => {
        formik.setFieldValue('groups', {
            groupToUnlink: groups.initialItems.filter(g => !groups.finalItems.includes(g)),
            groupToLink: groups.finalItems.filter(g => !groups.initialItems.includes(g))
        });
    }, [finalItemsLength]);

    const handleChange = (event) => {
        let finalItems;
        const value = Number(event.target.value);
        if(event.target.checked){
            finalItems = [...groups.finalItems, value];
        } else {
            finalItems = groups.finalItems.filter(id => id !== value);
        }
        setItems(g => ({...g, finalItems}));
    };

    return { handleChangeGroups : handleChange, groups };
};

export default useGroupManager;