import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { asyncServerTasksActions } from 'store';
import { ReportFormat } from '../utils/constant';
import useTranslation from './useTranslation';
import useAlertMessage from './useAlertMessage';

const useReport = ({ api, report, taskType, query, addExtraQueryParams, getFiltersKey, pagination, setItems }) => {
    const [loading, setLoading] = useState(false);
    const [pdfData, setPdfData] = useState([]);
    const companyName = useSelector(state => state.company.item?.name);
    const companyAddress = useSelector(state => state.company.item?.address, 
        (prev, next) =>  _.isEqual(prev, next));    
    const { t } = useTranslation();
    const { setAlertMessage, gettingReportErrorAlertMessage } = useAlertMessage();
    const tasks = useSelector(state => state.asyncServerTasks?.[taskType]);
    const dispatch = useDispatch();

    useEffect(() => {
        if(tasks !== undefined) {
            let lastCompletedTask =  Object.values(tasks?.completedTask)?.at(-1);
            if(lastCompletedTask){
                if(lastCompletedTask.succeeded){
                    setAlertMessage({
                        show: true,
                        type: 'success',
                        message: t('reportAvailableInMailBox')
                    });
                    dispatch(asyncServerTasksActions.archiveAsyncTask(lastCompletedTask))
                } else {
                    setAlertMessage(gettingReportErrorAlertMessage);
                    dispatch(asyncServerTasksActions.archiveAsyncTask(lastCompletedTask))
                }
            }
        }
        
    }, [tasks?.completedTask]);

    const handleSubmit = async (reportFormat) => {
        if(addExtraQueryParams) {
            addExtraQueryParams(query);
        }
        let options = undefined;
        
        const task = {
            loading: true,
            type: taskType,
            filtersKey: getFiltersKey(),
        };
        let mailUrl = '';
        switch(reportFormat) {
            case ReportFormat.MAIL:
                dispatch(asyncServerTasksActions.initiateAsyncTask(task));
                options = api.options;
                mailUrl = '/mail';
                break;
            case ReportFormat.EXCEL:
                setLoading(true);
                options = {};
                options.headers = {};
                options.headers.Accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                options.responseType = 'blob';
                break;
            case ReportFormat.PDF:
            case ReportFormat.JSON:
            default: 
                setLoading(true);
        }
        await api.get(`/api/${report}${mailUrl}?${query.toString()}`, options)
            .then(response => {
                switch(reportFormat) {
                    case ReportFormat.MAIL:
                        dispatch(asyncServerTasksActions.markAsyncTaskAsBegun({
                            taskId: response.data.taskId,
                            type: taskType,
                            message: t('reportCreating'),
                            ...task
                        }));
                        break;
                    case ReportFormat.EXCEL:
                        window.location.assign(window.URL.createObjectURL(response.data));
                        setLoading(false);
                        break;
                    case ReportFormat.PDF:
                        if(pagination) {
                            if(response.data.totalCount > 0) {
                                setPdfData(response.data.data);
                            } else {
                                setAlertMessage(gettingReportErrorAlertMessage);
                            }
                        } else {
                            if(response.data.length > 0) {
                                setPdfData(response.data);
                            } else {
                                setAlertMessage(gettingReportErrorAlertMessage);
                            }
                        }
                        break;
                    case ReportFormat.JSON:
                    default:
                        if(pagination) {
                            pagination?.setPageSize(response.data.limit);
                            pagination?.setTotalCount(response.data.totalCount);
                            setItems(response.data.data);
                        } else {
                            setItems(response.data);
                        }
                        setLoading(false);
                }
            })
            .catch(e => {
                setAlertMessage(gettingReportErrorAlertMessage);
                if(reportFormat === ReportFormat.MAIL){
                    dispatch(asyncServerTasksActions.markAsyncTaskAsAborted({
                        loading: false,
                        type: taskType,
                        message: t('gettingReportError'),
                        ...task,
                    }));
                }
                
            });
    }

    return { tasks, handleSubmit, loading, setLoading, pdfData, setPdfData, companyName, companyAddress };
}

export default useReport;