import React, { createContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { magellanV0NotIntercept401 } from "api/magellan";

const AuthenticationContext = createContext(null);

const AuthenticationProvider = ({children}) => {
    const dispatch = useDispatch();
    const dataPresent = useSelector(state => Boolean(state.session.server) && Boolean(state.session.user));
    const [authenticated, setAuthenticated] = useState(dataPresent);

    const handleLogout = async () => {
        const response = await fetch('/api/session', { method: 'DELETE' });
        if (response.ok) {
            setAuthenticated(false);
        }
    };

    useEffect(() => {
        if(authenticated) {
            magellanV0NotIntercept401.get('api/session')
                .then(response => {})
                .catch(error => {
                    setAuthenticated(false);
                });
        }
        
    }, []);

    useEffect(() => {
        if(!authenticated){
            dispatch({'type': 'USER_LOGOUT'});
        }
    }, [authenticated]);

    return (
        <AuthenticationContext.Provider value={{authenticated, setAuthenticated, handleLogout}}>
            {children({ authenticated })}
        </AuthenticationContext.Provider>
    );
};

export {AuthenticationProvider, AuthenticationContext};